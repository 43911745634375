import { CommunityLoader } from "../../../generalComponents/loaders/CommunityLoader/CommunityLoader";
import styles from "./ActivityStartButton.module.css";

export const  ActivityStartButton = ({ isLoading, onClick }: any) => {
  return (
    <div className={styles["container"]}>
      {isLoading ? (
        <div className={styles["loader_container"]}>
          <CommunityLoader color="#0D8ECC" />
        </div>
      ) : (
        <button className={styles["start_activity_btn"]} onClick={onClick}>
          Start Activity
        </button>
      )}
    </div>
  );
};

export default ActivityStartButton;
