import React from "react";
import { useRttOnlineContext } from "../../../../store/context/rtt-online-context";
import styles from "./BookingCard.module.css";
import moment from "moment";
import { dateTimeFromUTCtoLocal } from "../../../../utils/date-utils";

function BookingCard({ topic, isBooked }: any) {
  const { themeName, eventDate, eventTime, weekDuration, eventDay, id } = topic;

  const { localDate, localTime } = dateTimeFromUTCtoLocal(eventDate, eventTime);

  return (
    <div
      style={{ backgroundColor: "#0aa9e5", opacity: isBooked ? "1" : "0.4" }}
      className={styles["booking_card"]}
    >
      <div className={styles["cart_topics_title"]}>{themeName}</div>
      <div className={styles["booking_date"]}>
        {eventDate ? localDate : "--"}
      </div>
      <div className={styles["booking_time"]}>
        {eventTime ? localTime : "--"}
      </div>
      <div className={styles["booking_status"]}>
        {isBooked ? "Booked" : "Not Booked"}
      </div>
    </div>
  );
}

export default BookingCard;
