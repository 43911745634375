import { useState } from "react";
import { CommunityLoader } from "../../../../../components/generalComponents/loaders/CommunityLoader/CommunityLoader";
import styles from "./RttDemoVideoModal.module.css";

const RttDemoVideoModal = ({ closeRttDemoModal }: any) => {
  return (
    <div className={styles.tncModalBackground}>
      {/* <div className={styles.tncModalContainer}> */}
      <YouTubeVideo
        videoLink="https://www.youtube.com/embed/8dPVlyPhBN4"
        hideIframe={closeRttDemoModal}
      />
      {/* </div> */}
    </div>
  );
};

const YouTubeVideo = ({ videoLink, hideIframe }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onIframeLoad = () => {
    setIsLoading(false);
  };
  const onVideoEnded = () => {
    hideIframe();
  };
  return (
    <div className={styles["video_wrapper"]}>
      {isLoading && (
        <div
          className={`${styles["loading_container"]} animate__animated animate__fadeInBottomRight `}
        >
          <CommunityLoader />
        </div>
      )}
      <div className={styles["video_container"]}>
        <iframe
          style={{
            borderRadius: "25px",
            visibility: isLoading ? "hidden" : "visible",
          }}
          width="100%"
          height="100%"
          src={`${videoLink}?enablejsapi=1&origin=http://localhost:8100/`}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          onLoad={onIframeLoad}
        ></iframe>
      </div>
      <button className={styles["video_close_button"]} onClick={hideIframe}>
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
};

export default RttDemoVideoModal;
