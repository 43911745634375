import styles from "./FormSteps.module.css";

const FormSteps = ({ currentStep, totalSteps }: any) => {
  console.log("current step", currentStep);

  return (
    <div className={styles["steps_container"]}>
      {Array(totalSteps)
        .fill(0)
        .map((number, index) => {
          return (
            <>
              {index !== 0 && (
                <div
                  className={`${styles["step_line"]} ${
                    index < currentStep
                      ? styles["step_line_completed"]
                      : styles["step_line_incomplete"]
                  }`}
                ></div>
              )}

              <div
                className={`${styles["step"]} ${
                  index + 1 < currentStep && styles["completed_step"]
                } ${index + 1 === currentStep && styles["current_step"]} `}
              >
                {index + 1}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default FormSteps;

/*

Case 1: 4 Steps

- totalSteps: 4
- totalQuestions: 4
- currentQuestion: 0

Case 2: 3 Steps

- totalSteps: 3
- totalQuestions: 3
- currentQuestion: 1

Case 3: 2 Steps

- totalSteps: 2





*/
