const countIndividualElements = (array: any, key: any) => {
  const activityName: any = [];
  array.forEach((activity: any) => {
    if (!activityName.includes(activity[key])) {
      activityName.push(activity[key]);
    }
  });
  return activityName.length;
};

const lowestValueFromArray = (array: any, key: any) => {
  array.reduce((acc: any, curr: any) => {
    return acc < Number(curr.key) ? acc : Number(curr.key);
  }, array[0][key]);
};

const isExerciseCompleted = (
  programName: any,
  userExercise: any,
  programExercise: any,
  programDay: any
) => {
  return userExercise
    .filter((ex: any) => ex.data.programId === programName)
    .some((ex: any) => {
      return ex.data.exerciseId === programExercise[programDay].exerciseId;
    });
};

export { countIndividualElements, lowestValueFromArray, isExerciseCompleted };
