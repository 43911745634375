import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../store/context/user-context";
import styles from "./ReferFriend.module.css";
import moment from "moment";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import { getHttpData } from "../../../utils/http-util";
import { imagesLink } from "../../../constants/Services/services-constants";

const ReferFriend = () => {
  const userCtx = useUserContext();
  const user = userCtx.user;

  const [myCoupons, setMyCoupons] = useState(Array());
  const [myRefers, setMyRefers] = useState(Array());

  const [userReferralData, setUserReferralData] = useState(Object);

  const [isCopiedLink, setIsCopiedLink] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsCopiedLink(false);
    }, 10000);
  }, [isCopiedLink]);

  useEffect(() => {
    (async () => {
      try {
        const response: any = await getHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon`,
          {
            params: {
              userId: userCtx.user.id,
              status: "ASSIGNED",
            },
          }
        );
        setMyCoupons(response.data);
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        const response: any = await getHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/referral`,
          {
            params: {
              userId: userCtx.user.id,
            },
          }
        );
        setUserReferralData(response.data[0]);
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        const response: any = await getHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/referral`,
          {
            params: {
              referredByUserId: userCtx.user.id,
            },
          }
        );
        setMyRefers(response.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar color="secondary">
            <IonTitle>Referrals</IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/parents" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundImage: `url("/assets/parentLanding/background-cropped.svg")`,
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "repeat-y",
          }}
        >
          <div className={styles.referralSection}>
            <img
              src="/assets/referral/referral-banner.png"
              alt="referral-banner"
              style={{ width: "100%" }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1rem",
                gap: "8px",
              }}
            >
              <h2 style={{ color: "#808080", fontWeight: "bold" }}>
                Your Unique Referral Link
              </h2>

              <div className={styles.referralLinkContainer}>
                <div className={styles.linkContainer}>
                  {`${imagesLink}?r={userReferralData.id}`}
                </div>
                {isCopiedLink ? (
                  <button
                    className={`${styles.copyLinkButtonSuccess} animate_animated  animate__fadeIn`}
                  >
                    Copied
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setIsCopiedLink(true);
                      navigator.clipboard.writeText(
                        `${imagesLink}?r=${userReferralData.id}`
                      );
                    }}
                    className={styles.copyLinkButton}
                  >
                    Copy
                  </button>
                )}
              </div>

              <p
                style={{
                  color: "#808080",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Copy and Share above link with your friends and family with
                children in the age group 0-5 years
              </p>
            </div>
          </div>

          <div className={styles.statusOfReferenceSection}>
            <p
              style={{
                backgroundColor: "#FFC107",
                fontWeight: "bold",
                fontSize: "1rem",
                width: "fit-content",
                padding: "4px 8px",
                borderRadius: "8px",
              }}
            >
              Status of your reference
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {/* <p style={{ color: "#808080", fontSize: "14px" }}>
                Waiting for your friends to join
              </p>
              <img
                className={styles.birdImg}
                src="/assets/referral/referral-bird.svg"
                alt=""
              />

              <p style={{ color: "#808080", fontSize: "14px" }}>
                You will see them in this space when they will join !
              </p> */}

              <table className={styles.couponTable}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Parent Name</th>
                    <th>Joined On</th>
                    <th>Parent Subscription Status</th>
                  </tr>
                </thead>
                <tbody>
                  {myRefers.map((refer, index) => {
                    return (
                      <tr key={refer.id}>
                        <td> {index + 1} </td>
                        <td>
                          <p>{refer.userName}</p>
                        </td>
                        <td>
                          <p> {moment(refer.createdAt).format("LL")}</p>
                        </td>
                        <td>
                          <div className="progress">
                            <div
                              className={`progress-bar ${
                                refer.status === "SUBSCRIBED"
                                  ? "bg-success"
                                  : "bg-warning"
                              }`}
                              role="progressbar"
                              style={{
                                width: `${
                                  refer.status === "SUBSCRIBED" ? `100%` : `50%`
                                }`,
                              }}
                              aria-valuenow={
                                refer.status === "SUBSCRIBED" ? 100 : 50
                              }
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                          <p>
                            {refer.status === "SUBSCRIBED"
                              ? "Subscribed(100%)"
                              : "Signed Up(50%)"}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.couponCodeSection}>
            <p
              style={{
                backgroundColor: "#28A745",
                color: "white",
                fontWeight: "bold",
                fontSize: "1rem",
                width: "fit-content",
                padding: "4px 8px",
                borderRadius: "8px",
              }}
            >
              Your Coupon Codes
            </p>

            {myCoupons.length === 0 ? (
              <p>There are No coupons Available</p>
            ) : (
              <div className={styles.couponCodes}>
                <table className={styles.couponTable}>
                  <thead>
                    <tr>
                      <th>Coupons</th>
                      <th>Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myCoupons.map((coupon) => {
                      return (
                        <tr key={coupon.id}>
                          <td>
                            <img
                              style={{ width: "3rem" }}
                              src="/assets/referral/gift-icon.png"
                              alt=""
                            />
                            <p>
                              {coupon.discountPercent === ""
                                ? `₹${coupon.discountAmount}/- Discount Benefit Coupon`
                                : `${coupon.discountPercent}% - Discount Benefit Coupon`}
                            </p>
                          </td>
                          <td>
                            <p>{coupon.code}</p>
                          </td>
                          <td>
                            <a
                              href={`/parents/subscriptions/coupon/${coupon.code}`}
                              className={styles.redeemNowBtn}
                            >
                              Redeem Now
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className={styles.referralTermsSection}>
            <p
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                fontWeight: "bold",
                fontSize: "1rem",
                width: "fit-content",
                padding: "4px 8px",
                borderRadius: "8px",
              }}
            >
              Referral Benefits & Terms
            </p>

            <ul className={styles.termsList}>
              <li>
                <p>
                  (1) Copy & Share above link with as many of your friends and
                  family with children in the age group 0-5 years
                </p>
              </li>
              <li>
                <p>
                  (2) Using this link they will also be able to signup for
                  BrianCells Program and enjoy the program benefits for their
                  children
                </p>
              </li>

              <li>
                <p>
                  (3) As a token of appreciation and Referral Benefit, you will
                  receive -
                </p>
              </li>

              <li>
                <p>
                  (a) Flat extra 10% discount coupon codes for every parent who
                  will join Paid subscription of Main BrainCells Program through
                  your reference.
                </p>
              </li>
              <li>
                <p>
                  (b) You will be able to redeem coupon codes for your new
                  subscriptions of Language Programs, any other New Programs
                </p>
              </li>
              <li>
                <p>
                  (c) You will be also be able to redeem coupon codes for your
                  BrainCells program renewals
                </p>
              </li>
            </ul>
          </div>

          <p
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
              color: "white",
              margin: "2rem",
            }}
          >
            Copyright © 2022 BrainCells | Thought River EduTech Private Limited
            All rights reserved.
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReferFriend;
