import styles from "./RttOnlineBookingPage.module.css";
import Confetti from "react-confetti";

const RttOnlineSuccessModal = ({ bookedTopics = 0 }: any) => {
  const numberOfBookedEvents = bookedTopics.length;

  return (
    <>
      <div className={styles["modal_wrapper"]}>
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={450}
          recycle={false}
        />
        <div className={styles["modal"]}>
          <h1 className={styles["modal_heading"]}>Congratulations!</h1>
          <p className={styles["modal_text"]}>
            You have booked {numberOfBookedEvents}{" "}
            {numberOfBookedEvents > 1 ? "sessions" : "session"}.
          </p>
          <button className={styles["modal_button"]}>
            <a href="/rtt-online?st=booked"> See your booked topics</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default RttOnlineSuccessModal;
