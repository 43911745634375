import { useEffect } from "react";
import styles from "./TabSwitcher.module.css";

const TabSwitcher = ({
  selectedId,
  tabSelector,
  programData,
  isZeroToOne,
  openPricingInfoModal,
}: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const tab: any = queryParams.get("tab");
  const tabConfig: any = {
    O: { index: 0, durationMonths: 1 },
    TW: { index: 2, durationMonths: 12 },
    TH: { index: 1, durationMonths: 3 },
  };
  let sortedProgramData = programData.sort((a: any, b: any) => {
    return a.programDuration - b.programDuration;
  });

  if (tab) {
    sortedProgramData = programData.filter(
      (item: any) => item.programDuration === tabConfig[tab].durationMonths
    );
  }

  const clickHandler = (index: number) => {
    if (tab) {
    } else {
      tabSelector(index);
    }
  };

  useEffect(() => {
    if (tab) {
      tabSelector(tabConfig[tab].index);
    }
  }, []);

  return (
    <div className={styles["tabs_wrapper"]}>
      {/* <button
        style={{
          left: selectedId === 0 ? "2rem" : selectedId === 1 ? "45%" : "75%",
          transform:
            selectedId === 0
              ? "translateX(0)"
              : selectedId === 1
              ? "translateX(-100%)"
              : "translateX(0)",
        }}
        className={`animate__animated animate__slideInLeft ${styles["info_btn"]}`}
        onClick={() => openPricingInfoModal()}
      >
        <i className="fas fa-info-circle"></i>
      </button> */}
      {sortedProgramData.map((data: any, index: number) => {
        const { programDuration } = data;
        return (
          <div
            key={index}
            onClick={() => clickHandler(index)}
            className={`${selectedId === index && styles["selected_tab"]} ${
              styles["tabs"]
            }`}
          >
            <p
              className={styles["tabs_para"]}
            >{`${programDuration}-${data.programDurationUnit}`}</p>
            <span className={styles["tabs_para_sub"]}>
              {/* {isZeroToOne
                ? ""
                : programDuration === 1
                ? "Recurring"
                : "One Time"} */}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TabSwitcher;
