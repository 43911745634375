import moment from "moment";
import { v1 as uuidv1 } from "uuid";
import {
  RUMBLE_TUMBLE_GUIDE,
  RUMBLE_TUMBLE_TIME,
} from "../constants/Program/program-constants";
import { imagesLink } from "../constants/Services/services-constants";

export const getUUID = () => {
  const newUUID = uuidv1();
  return newUUID;
};

export const nextDate = (dayIndex: number) => {
  var today = new Date();
  today.setDate(
    today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1
  );
  return today;
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};
export const shuffleArr = (arr: any) => {
  return [...arr].sort(() => Math.random() - 0.5);
};

export const detectIsMobileDevice = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

declare global {
  interface Window {
    MSStream: any;
  }
}

export const detectIsIOSDevice = () => {
  if (/android/i.test(navigator.userAgent)) {
    return false;
  }
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.userAgent) ||
    // iPad on iOS 13 detection
    "ontouchend" in document
  );
};

export const detectIsAndroidDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /Android/i.test(userAgent);
};

// export const detectIsIOSDevice = () => {
//   const isIOS =
//     /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

//   if (isIOS) {
//     // This device is using iOS
//     return true;
//   } else {
//     // This device is not using iOS
//     return false;
//   }
// };

export const preFetchImages: any = (imagesArr: any) => {
  let startIndex = 0;
  let isFetching: boolean = imagesArr.length > 0;

  const preFetchNextImage = (imgLink: any, crrIndex: any) => {
    const img = new Image();
    img.src = `${imagesLink}${imgLink}`;
    img.onload = () => {
      if (imagesArr[crrIndex + 1] && isFetching) {
        preFetchNextImage(imagesArr[crrIndex + 1], crrIndex + 1);
      }
    };
  };
  preFetchNextImage(imagesArr[0], startIndex);
  return () => {
    isFetching = false;
  };
};

export const convertTwentyFourToTwelve = (time: any) => {
  const timeArr = time.split(":");
  const hour = timeArr[0];
  const min = timeArr[1];
  const ampm = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;
  const time12 = hour12 + ":" + min + " " + ampm;
  return time12;
};

export const checkImage = (imageSrc: string, good: any, bad: any) => {
  var img = new Image();
  img.onload = good;
  img.onerror = bad;
  img.src = imageSrc;
};

export const speechUtterance = (
  speech: string,
  lang: string,
  callBackFunc: any,
  speed = 1
) => {
  const utterance = new SpeechSynthesisUtterance(speech);
  utterance.lang = lang;
  utterance.rate = speed;
  utterance.voice = speechSynthesis.getVoices().filter(function (voice) {
    return voice.lang === "en-US" && voice.name === "Samantha";
  })[0];
  utterance.onend = function () {
    callBackFunc();
    return;
  };
  speechSynthesis.speak(utterance);
};

export const isRttTeller = (programName: any) => {
  return {
    isRtt:
      programName === RUMBLE_TUMBLE_GUIDE || programName === RUMBLE_TUMBLE_TIME
        ? true
        : false,
    programName: programName,
    isGuide: programName === RUMBLE_TUMBLE_GUIDE ? true : false,
  };
};

export const programDayToExerciseNumber = (programDay: any) => {
  return Number(programDay - Math.floor(programDay / 7));
};

export const timeStampToDate = (timeStamp: any) => {
  const today = moment.unix(moment().unix());
  const postDate = moment.unix(timeStamp);
  const formattedPostDate = moment.unix(timeStamp).format("YYYY-MM-DD");
  const formattedToday = today.format("YYYY-MM-DD");
  const formattedTommorow = today.add(1, "day").format("YYYY-MM-DD");
  const formattedYesterday = today.subtract(2, "days").format("YYYY-MM-DD");

  // console.log(
  //   formattedToday,
  //   formattedTommorow,
  //   formattedYesterday,
  //   formattedPostDate,
  //   moment(formattedPostDate).isSame(formattedYesterday, "day")
  // );
  const calDate = () => {
    if (moment(formattedToday).isSame(formattedPostDate, "day")) {
      return "Today";
    }
    if (moment(formattedPostDate).isSame(formattedTommorow, "day")) {
      return "Tomorrow";
    }
    if (moment(formattedPostDate).isSame(formattedYesterday, "day")) {
      return "Yesterday";
    } else {
      return moment(formattedPostDate, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
  };

  const time = postDate.format("h:mm A");
  const date = calDate();

  return {
    date: date,
    time: time,
  };
};

export const convertTimeStampToDate = (
  timestamp: any,
  format: any = "YYYY-MM-DD"
) => {
  const dateString = moment.unix(timestamp).format(format);
  return dateString;
};
export const convertTimeStampToTime = (timestamp: any) => {
  const timeString = moment.unix(timestamp).format("hh:mm A");
  return timeString;
};

export const convertTimeStampToDay = (timestamp: any) => {
  return moment.unix(timestamp).format("dddd");
};
