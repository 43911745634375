import React from "react";
import styles from "./ShareModal.module.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { ImCross } from "react-icons/im";

const ShareModal = ({ link, title, setShowShareOptions }: any) => {
  return (
    <div className={styles["modal-wrapper"]}>
      <div className={styles["modal"]}>
        <div className={styles["modal-header"]}>
          <h3>Share on</h3>
          <button onClick={() => setShowShareOptions(false)}>
            <ImCross />
          </button>
        </div>
        <div className={styles["modal-header-main"]}>
          <TwitterShareButton
            title={title}
            via=""
            hashtags={[]}
            related={["related"]}
            url={`${link}`}
          >
            {/* <FacebookIcon /> */}
            <TwitterIcon />
          </TwitterShareButton>

          <FacebookShareButton title={title} url={`${link}`}>
            {/* <FacebookIcon /> */}
            <FacebookIcon />
          </FacebookShareButton>

          <WhatsappShareButton title={title} url={`${link} `}>
            {/* <FacebookIcon /> */}
            <WhatsappIcon />
          </WhatsappShareButton>

          <TelegramShareButton title={title} url={`${link}`}>
            {/* <FacebookIcon /> */}
            <TelegramIcon />
          </TelegramShareButton>

          <EmailShareButton title={title} url={`${link}`}>
            {/* <FacebookIcon /> */}
            <EmailIcon />
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
