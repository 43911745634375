import { useEffect, useRef } from "react";
import styles from "./ExerciseLoader.module.css";

const ExerciseLoader = ({ colorCode }: any) => {
  const loaderRef: any = useRef();

  useEffect(() => {
    loaderRef.current.style.setProperty("--bgcolor", colorCode);
  }, [colorCode]);

  return (
    <div
      ref={loaderRef}
      className={styles["loadingio-spinner-ellipsis-obdvglnfqi"]}
    >
      <div className={styles["ldio-tlng32g1cug"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default ExerciseLoader;
