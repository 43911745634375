import styles from "./ActivityWrapper.module.css";

const ActivityWrapper = ({
  children,
  endActivity,
  coverFullScreen = false,
}: any) => {
  return coverFullScreen === true ? (
    <div className={styles["activity_wrapper"]}>
      <div onClick={endActivity} className={styles["activity_wrapper_end"]}>
        <i className="fas fa-times"></i>
      </div>
      {children}
    </div>
  ) : (
    <div
      className={`${styles["activity_wrapper"]} ${styles["activity_wrapper_custom"]}`}
    >
      <div onClick={endActivity} className={styles["activity_wrapper_end"]}>
        <i className="fas fa-times"></i>
      </div>
      <div className={styles["activity_background"]}>{children}</div>
    </div>
  );
};

export { ActivityWrapper };
