import { useEffect, useRef, useState } from "react";
import styles from "./AvatarSelection.module.css";
import ProfilePictureRound from "../ProfilePictureRound/ProfilePictureRound";
import useImageUpload from "../../../../hooks/useImgUpload";
import { useCommunityContext } from "../../../../store/context/community-context";
import { cloudinaryAvatarLink } from "../../../../constants/Services/services-constants";
import { useUserContext } from "../../../../store/context/user-context";

const AvatarSelection = (props: any) => {
  const { user } = useUserContext();

  const boyAvatarArr = [
    {
      id: 1,
      imageFile: "boy1.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/boy1.svg",
    },
    {
      id: 2,
      imageFile: "boy2.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/boy2.svg",
    },
    {
      id: 3,
      imageFile: "boy3.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/boy3.svg",
    },
    {
      id: 4,
      imageFile: "boy4.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/boy4.svg",
    },
  ];
  const girlAvatarArr = [
    {
      id: 5,
      imageFile: "girl1.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/girl5.svg",
    },
    {
      id: 6,
      imageFile: "girl2.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/girl6.svg",
    },
    {
      id: 7,
      imageFile: "girl3.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/girl7.svg",
    },
    {
      id: 8,
      imageFile: "girl4.svg",
      avatarLink:
        "https://program.braincells.in/assets/community/avatar/girl8.svg",
    },
  ];

  // https://res.cloudinary.com/lucidui-cl-a/image/upload/avatar/9.svg
  const tncModalRef = useRef(null);

  const communityCtx = useCommunityContext();
  const { avatarDispatch, avatarState } = communityCtx;

  const { selectImage, avatarImageUpload } = useImageUpload();

  const [selectedAvatar, setSelectedAvatar] = useState("");

  const avatarImageTestLink =
    "https://program.braincells.in/assets/community/avatar/";

  // useOnClickOutside(tncModalRef, () => {
  //   avatarDispatch({ type: "HIDE_AVATAR_SELECTION" });
  // });

  const Avatar = ({ profilePictureLink }: any) => {
    return (
      <img
        className={styles["profile_picture"]}
        src={`${profilePictureLink}`}
        alt=""
        onError={(e: any) => {
          e.target.src = "https://via.placeholder.com/150";
        }}
      />
    );
  };

  const avatarSelectionHandler = (num: any) => {
    if (num <= 4) {
      avatarImageUpload(boyAvatarArr[num - 1].avatarLink);
    } else {
      avatarImageUpload(girlAvatarArr[num - 1].avatarLink);
    }
  };

  return (
    <div className={styles.tncModalBackground}>
      <div
        className={`${styles.tncModalContainer} animate__animated animate__slideInUp`}
        ref={tncModalRef}
      >
        <div className={styles["close_btn"]}>
          <i
            onClick={() => {
              avatarDispatch({ type: "HIDE_AVATAR_SELECTION" });
            }}
            className="fas fa-times-circle"
          ></i>
        </div>
        <div className={styles["user_avatar_container"]}>
          <ProfilePictureRound
            profilePictureLink={`${cloudinaryAvatarLink}${user.id}`}
          />
        </div>
        <div className={styles["user_avatar__list_container"]}>
          <div className={styles["user_avatar__heading"]}>
            <h3>Choose your Avatar</h3>
          </div>
          <div className={styles["user_avatar__list"]}>
            <div className={styles["user_avatar_list__first_row"]}>
              {boyAvatarArr.map((avatar: any) => {
                return (
                  <div
                    onClick={() => {
                      avatarSelectionHandler(avatar.id);
                      setSelectedAvatar(avatar.id);
                      avatarDispatch({ type: "HIDE_AVATAR_SELECTION" });
                    }}
                    key={avatar.id}
                    className={
                      selectedAvatar === avatar.id
                        ? styles["selected_avatar"]
                        : styles["user_avatar__list__item"]
                    }
                  >
                    <Avatar
                      profilePictureLink={`${avatarImageTestLink}/boy${avatar.id}.svg`}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles["user_avatar_list__second_row"]}>
              {girlAvatarArr.map((avatar: any) => {
                return (
                  <div
                    onClick={() => {
                      avatarSelectionHandler(avatar.id);
                      setSelectedAvatar(avatar.id);
                    }}
                    key={avatar.id}
                    className={
                      selectedAvatar === avatar.id
                        ? styles["selected_avatar"]
                        : styles["user_avatar__list__item"]
                    }
                  >
                    <Avatar
                      profilePictureLink={`${avatarImageTestLink}/girl${avatar.id}.svg`}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles["horizontal_line_container"]}>
            <hr className={styles["horizontal_line"]} />
            <span>OR</span>
          </div>

          <div className={styles["user_avatar__custom_avatar"]}>
            <input type={"file"} onChange={selectImage} />
            <button
              onClick={avatarImageUpload}
              className={styles["confirm_avatar_button"]}
            >
              Confirm Avatar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarSelection;
