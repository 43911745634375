const giveIconClass = (activityName: string) => {
  switch (activityName) {
    case "Mindfulness":
      return { iconClass: "fas fa-bullseye", priority: 0 };
    case "Mindful-Target":
      return { iconClass: "fas fa-bullseye", priority: 0 };
    case "Todays-Acoustics":
      return { iconClass: "fa fa-music", priority: 5 };
    case "Bit-Cards":
      return { iconClass: "fa fa-image", priority: 1 };
    case "Bit-Cards-Revision":
      return { iconClass: "fa fa-image", priority: 1 };
    case "Word-Cards":
      return { iconClass: "fa fa-file-word", priority: 3 };
    case "Word-Play":
      return { iconClass: "fab fa-weebly", priority: 3 };
    case "Word-Pop":
      return { iconClass: "fas fa-balance-scale", priority: 3 };
    case "Dot-Cards":
      return { iconClass: "fas fa-infinity", priority: 4 };
    case "Linking-Memory":
      return { iconClass: "fas fa-brain", priority: 6 };
    case "Linking-Memory-Activity":
      return { iconClass: "fas fa-link", priority: 5 };
    case "Mandala":
      return { iconClass: "fas fa-shapes", priority: 5 };
    case "Mandala-Activity":
      return { iconClass: "fas fa-shapes", priority: 5 };
    case "Weekly-Plan":
      return { iconClass: "fas fa-tasks", priority: 10 };
    case "Tactile-Method":
      return { iconClass: "fas fa-dna", priority: 10 };
    case "Auditory-Method":
      return { iconClass: "fas fa-assistive-listening-systems", priority: 10 };
    case "Clairvoyance-Play":
      return { iconClass: "fas fa-dna", priority: 10 };
    case "Physical-Development":
      return { iconClass: "fas fa-running", priority: 10 };
    case "Whos-The-Guest":
      return { iconClass: "fa fa-question-circle", priority: 1 };
    case "Olfactory-Technique":
      return { iconClass: "fas fa-dna", priority: 10 };
    case "Vision-Chart":
      return { iconClass: "fas fa-bullseye", priority: 10 };
    case "STEM-Activity":
      return { iconClass: "fas fa-flask", priority: 10 };
    case "You-Got-It":
      return { iconClass: "fa fa-check", priority: 1 };
    case "Quantitude":
      return { iconClass: "fas fa-sort-amount-up", priority: 5 };
    case "Pattern-Match":
      return { iconClass: "fas fa-shapes", priority: 10 };
    case "High-Contrast-Cards":
      return { iconClass: "fas fa-shield-alt", priority: 10 };
    case "Memory-Grid":
      return { iconClass: "fa fa-th", priority: 3 };
    case "Clubhouse":
      return { iconClass: "fas fa-school", priority: 10 };
    case "Flash-Cards":
      return { iconClass: "fa fa-id-card", priority: 10 };
    case "Pattern-Matching":
      return { iconClass: "fas fa-dice-two", priority: 10 };
    case "Maze-Puzzle":
      return { iconClass: "fa fa-puzzle-piece", priority: 10 };
    case "Life-Cycle":
      return { iconClass: "fas fa-sync", priority: 10 };
    case "Connections":
      return { iconClass: "fa fa-link", priority: 10 };
    case "Learn-It":
      return { iconClass: "fas fa-book-reader", priority: 10 };
    case "Peg-Memory":
      return { iconClass: "fas fa-screwdriver", priority: 8 };
    case "Peg-Memory-Advanced":
      return { iconClass: "fas fa-screwdriver", priority: 8 };
    case "Say-With-Me":
      return { iconClass: "fas fa-user-friends", priority: 10 };
    case "Card-Flip":
      return { iconClass: "fa fa-id-card", priority: 3 };
    case "Grid-Match":
      return { iconClass: "fa fa-th", priority: 3 };
    case "Photo-Play":
      return { iconClass: "fa fa-camera", priority: 7 };
    case "Encyclo-Book":
      return { iconClass: "fa fa-book", priority: 5 };
    case "Quiz-Score":
      return { iconClass: "fa fa-book", priority: 10 };
    case "Memory-Stack":
      return { iconClass: "fas fa-sort-amount-up", priority: 10 };
    case "Math-Solve":
      return { iconClass: "fas fa-calculator", priority: 10 };
    case "Compare-It":
      return { iconClass: "fas fa-equals", priority: 10 };
    case "Sorto":
      return { iconClass: "fa fa-sort", priority: 10 };
    case "Weigh-Me-In":
      return { iconClass: "fas fa-balance-scale", priority: 10 };
    case "Grid-Puzzle":
      return { iconClass: "fa fa-th", priority: 10 };
    case "Lifecycle-Play":
      return { iconClass: "fa fa-camera", priority: 10 };
    case "Name-Play":
      return { iconClass: "fa fa-camera", priority: 1 };
    case "Peg-Memory-Advanced":
      return { iconClass: "fa fa-camera", priority: 5 };
    case "Link-Memory":
      return { iconClass: "fa fa-camera", priority: 5 };
    case "Word-Card-Activity":
      return { iconClass: "fab fa-weebly", priority: 3 };
    case "Bit-Card-Activity":
      return { iconClass: "fa fa-image", priority: 1 };
    case "Dot-Card-Activity":
      return { iconClass: "fas fa-infinity", priority: 4 };
    case "Demo-Video":
      return { iconClass: "fas fa-video", priority: 1 };
    case "Number-Personality":
      return { iconClass: "fas fa-list-ol", priority: 10 };
    case "Geometric-Progression":
      return { iconClass: "fas fa-shapes", priority: 10 };
    case "Skip-Counting":
      return { iconClass: "fas fa-forward", priority: 10 };
    case "Downloadables":
      return { iconClass: "fas fa-download", priority: 10 };
    case "Join-The-Session":
      return { iconClass: "fas fa-chalkboard-teacher", priority: 10 };
    // 0-1 new activities
    case "Milestones":
      return { iconClass: "fas fa-check-double", priority: 10 };
    case "Sensory-Development":
      return { iconClass: "fas fa-baby", priority: 10 };
    // Custom activities
    case "Custom-Flash-Cards":
      return { iconClass: "fa fa-image", priority: 10 };
    case "Treasure-Quest":
      return { iconClass: "fas fa-gem", priority: 10 };
    default:
      return "";
  }
};

const giveIconClassRTT = (activityName: any) => {
  switch (activityName) {
    case "You-Got-It":
      return { iconClass: "fa fa-check", priority: 1 };
    case "Neurobics":
      return { iconClass: "fas fa-memory", priority: 3 };
    case "RBEology-Mandala":
      return { iconClass: "fas fa-shapes", priority: 8 };
    case "At-Home-Practice":
      return { iconClass: "fa fa-home", priority: 12 };
    case "Creative-Juice":
      return { iconClass: "fas fa-lightbulb", priority: 10 };
    case "Bodily-Kinesthetic":
      return { iconClass: "fas fa-walking", priority: 11 };
    case "Sanskrit-Language":
      return { iconClass: "fas fa-language", priority: 7 };
    case "Fun-And-Learn":
      return { iconClass: "fas fa-chalkboard-teacher", priority: 9 };
    case "Mindfulness-Activity":
      return { iconClass: "far fa-eye", priority: 2 };
    case "RBEology-Memory-Dash":
      return { iconClass: "fas fa-memory", priority: 8 };
    case "RBEology-Linking-Memory":
      return { iconClass: "fas fa-link", priority: 8 };
    case "RBEology-Spot-It":
      return { iconClass: "fas fa-search", priority: 8 };
    case "Guide":
      return { iconClass: "fas fa-book", priority: 0 };
    case "Weekly-Plan":
      return { iconClass: "fas fa-tasks", priority: 1 };
    case "Whos-The-Guest":
      return { iconClass: "fa fa-question-circle", priority: 1 };
    case "Memory-Grid":
      return { iconClass: "fa fa-th", priority: 1 };
    case "Dot-Cards":
      return { iconClass: "fas fa-infinity", priority: 5 };
    case "Word-Cards":
      return { iconClass: "fa fa-file-word", priority: 4 };
    case "Bit-Cards":
      return { iconClass: "fa fa-image", priority: 6 };
    case "Group-Thinking":
      return { iconClass: "fas fa-brain", priority: 12 };
    case "Mindfulness":
      return { iconClass: "fas fa-bullseye", priority: 2 };
    default:
  }
};

const giveIconClassRTTOnline = (activityName: any) => {
  switch (activityName) {
    case "Downloadables":
      return { iconClass: "fas fa-download", priority: 1 };
    case "Join-The-Session":
      return { iconClass: "fa fa-check", priority: 2 };
    case "Mindfulness-Activity":
      return { iconClass: "fas fa-bullseye", priority: 3 };
    case "Neurobics":
      return { iconClass: "fas fa-memory", priority: 4 };
    case "Sanskrit-Language":
      return { iconClass: "fas fa-language", priority: 5 };
    case "Bit-Cards":
      return { iconClass: "fa fa-image", priority: 6 };
    case "RBEology-Memory-Dash":
      return { iconClass: "fas fa-memory", priority: 7 };
    case "RBEology-Linking-Memory":
      return { iconClass: "fas fa-link", priority: 7 };
    case "RBEology-Spot-It":
      return { iconClass: "fas fa-search", priority: 7 };
    case "RBEology-Mandala":
      return {
        iconClass: "fas fa-shapes",
        priority: 7,
      };
    case "RBEology-mandala":
      return {
        iconClass: "fas fa-shapes",
        priority: 7,
      };
    case "Fun-And-Learn":
      return { iconClass: "fas fa-chalkboard-teacher", priority: 8 };
    case "Creative-Juice":
      return { iconClass: "fas fa-lightbulb", priority: 9 };
    case "Bodily-Kinesthetic":
      return { iconClass: "fas fa-walking", priority: 10 };
    case "Work-It-Out":
      return { iconClass: "fa fa-home", priority: 11 };
    default:
  }
};

export { giveIconClass, giveIconClassRTT, giveIconClassRTTOnline };
