import moment from "moment";
import {
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
  ACTIVITY_PORT,
} from "../configs/ServerConfig";
import { getHttpData, postHttpData } from "./http-util";
export const getExerciseData = async (
  programTypeParam: any,
  ageGroup: any,
  programName: any,
  userId: any,
  isZeroToOneV2: any = false
) => {
  const exerciseData: any = await Promise.all([
    getHttpData(
      programTypeParam
        ? `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_program?ageGroup=${ageGroup}${
            isZeroToOneV2 ? "_v2" : ""
          }&programName=${programName}${programTypeParam}`
        : `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_program?ageGroup=${ageGroup}${
            isZeroToOneV2 ? "_v2" : ""
          }&programName=${programName}`
    ),
    getHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/exerciseAttendance?userId=${userId}`
    ),
  ]);

  return {
    programExercise: exerciseData[0].data,
    userExercise: exerciseData[1].data,
  };
};

export const programAttendance = async (userId: any, programName: any) => {
  const postResponse = await postHttpData(
    `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/activity_tracking`,
    {
      type: "PROGRAM_LOADED",
      userId,
      timestamp: moment.utc().valueOf(),
      data: { programName },
    }
  );
};
