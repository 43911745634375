import { IonContent, IonPage } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useRttOnlineContext } from "../../../store/context/rtt-online-context";
import BookingCard from "../components/BookingCard/BookingCard";
import styles from "./RttOnlineBookingPage.module.css";
import { useHistory } from "react-router";
import { getHttpData } from "../../../utils/http-util";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
} from "../../../configs/ServerConfig";
import {
  convertUtcDayToLocal,
  currentUtcTimeDate,
} from "../../../utils/date-utils";
import Razorpay from "./Razorpay/Razorpay";
import { getUUID } from "../../../utils/general-utils";
import { useUserContext } from "../../../store/context/user-context";
import CircleLoader from "../../Parents/Subscription/components/CircleLoader";
import RttOnlineSuccessModal from "./SuccessModal/RttOnlineSuccessModal";
import useCoupon from "../../../hooks/useCoupon";

const DATE_FORMAT = "DD-MM-YYYY";
const MAX_BOOKING_WEEK_DAY_NUMBER = 6; //SATURDAY

const RttOnlineBookingPage = () => {
  //STATE
  const [teacherData, setTeacherData] = useState<any>();
  const [bookedTopics, setBookedTopics] = useState<any>([]);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //HOOKS
  const history = useHistory();
  //CONTEXT
  const {
    user: { id: userId, email, name, ageGroup, childName, phone },
  } = useUserContext();
  const {
    rttDataState,
    rttDataState: { selectedTopics, selectedTeacher, isFirstBooking },
    rttDataDispatch,
  } = useRttOnlineContext();

  //CONSTANTS
  const listedTopics = bookedTopics.length > 0 ? bookedTopics : selectedTopics;
  const getTeacherData = async (selectedTeacher: any) => {
    setIsLoading(true);
    const response: any = await getHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineTeacherData?id=${selectedTeacher.id}`
    );

    setTeacherData(response.data[0]);
    setIsLoading(false);
  };

  const convertToUTC = (date: any) => {
    var utc = moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
    return moment(date).utc().format(DATE_FORMAT);
  };

  const dateFromWeekday = (weekday: any, date: any) => {
    const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    return moment(formattedDate).day(weekday.toUpperCase()).format(DATE_FORMAT);
  };
  const createBookingData = (selectedDay: any, selectedTime: any) => {
    const { utcWeekDay, utcStartTime, utcEndTime } = selectedTime;
    const bookingData: any = [];
    selectedTopics.forEach((topicData: any) => {
      const { weekStartDate, themeName, id: topicId, imagePath } = topicData;
      const { meetingLink, id: teacherId, name: teacherName } = teacherData;
      bookingData.push({
        id: getUUID(),
        eventId: topicId,
        themeName: themeName,
        eventDate: dateFromWeekday(utcWeekDay, weekStartDate),
        eventDay: selectedDay,
        eventTime: utcStartTime,
        meetLink: meetingLink,
        teacherId: teacherId,
        teacherName: teacherName,
        userId: `${userId}`,
        userEmail: email,
        userName: name,
        createdAt: currentUtcTimeDate(),
        editedAt: "",
        packageId: "",
        imagePath,
        ageGroup: ageGroup,
        childName,
        phone,
      });
    });
    setBookedTopics(bookingData);
  };

  useEffect(() => {
    if (selectedTeacher) {
      getTeacherData(selectedTeacher);
    } else {
      history.push("/rtt-online");
    }
  }, [selectedTeacher]);
  return (
    <IonPage>
      <IonContent>
        {showSuccessModal && (
          <RttOnlineSuccessModal bookedTopics={bookedTopics} />
        )}
        {isLoading ? (
          <CircleLoader />
        ) : (
          <div
            style={{
              backgroundImage: `url(${"/assets/newPortalMsg/whos-the-guest-bg.jpeg"})`,
            }}
            className={styles["booking_page_wrapper"]}
          >
            {/* Back Button */}
            <a href="/rtt-online" className={styles.closeModalBtn}>
              <i className="fas fa-arrow-left"></i>
            </a>
            <div className={styles["booking_page"]}>
              <div className={styles["teacher_info_container"]}>
                <h2 className={styles["page_heading"]}>Book Session</h2>
                <div className={styles["avatar_container"]}>
                  <img
                    className={styles["avatar"]}
                    src={teacherData?.profilePicture}
                    alt="avatar"
                  />
                </div>
                <div className={styles["teacher_info_text"]}>
                  <p className={styles["teacher_info_name"]}>
                    {teacherData?.name}
                  </p>
                  <p className={styles["teacher_info_description"]}>
                    {teacherData?.description}
                  </p>
                </div>
              </div>
              {teacherData && (
                <DateTimeSelection
                  timeSlotData={teacherData.availability}
                  createBookingData={createBookingData}
                />
              )}
              <div className={styles["bookings_container"]}>
                <label className={styles["bookings_label"]}>
                  Selected Topics
                </label>
                <div className={styles["booking_cards"]}>
                  {listedTopics.map((topic: any, index: number) => {
                    return (
                      <BookingCard
                        key={index}
                        topic={topic}
                        isBooked={bookedTopics.length > 0}
                        bookingData={{}}
                      />
                    );
                  })}
                </div>
              </div>
              <PaymentLogic
                bookingData={bookedTopics}
                showSuccessModal={() => {
                  setShowSuccessModal(true);
                }}
                teacherImg={teacherData?.profilePicture}
              />
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

const DateTimeSelection = ({ timeSlotData, createBookingData }: any) => {
  const timeSlotUpdatedData: any = {};
  timeSlotData.forEach((timeSlot: any) => {
    const { weekDay, startTime, endTime } = timeSlot;
    const { localWeekDay, localTime } = convertUtcDayToLocal(
      weekDay,
      startTime,
      12
    );
    const localAndUtcTime = {
      localWeekDay,
      localTime,
      utcWeekDay: weekDay,
      utcStartTime: startTime,
      utcEndTime: endTime,
    };
    if (!timeSlotUpdatedData[localWeekDay]) {
      timeSlotUpdatedData[localWeekDay] = [localAndUtcTime];
    } else {
      timeSlotUpdatedData[localWeekDay] = [
        ...timeSlotUpdatedData[localWeekDay],
        localAndUtcTime,
      ];
    }
  });

  const weekdays = Object.keys(timeSlotUpdatedData).filter((weekday: any) => {
    // return moment(weekday, "dddd").weekday() > moment().weekday();
    const todaysWeekDayNumber = moment().weekday();
    const maximumWeekDayNumber =
      todaysWeekDayNumber < MAX_BOOKING_WEEK_DAY_NUMBER
        ? todaysWeekDayNumber
        : 0;
    return moment(weekday, "dddd").weekday() > maximumWeekDayNumber;
  });

  const datesArray = weekdays;
  const [selectedDate, setSelectedDate] = useState<any>(datesArray[0]);
  const [selectedTime, setSelectedTime] = useState<any>();
  const [showConfirmation, setShowConfirmation] = useState<any>(false);
  const [bookingConfirmed, setBookingConfirmed] = useState<any>(false);

  const timeClickHandler = (time: any) => {
    setSelectedTime(time);
    setShowConfirmation(true);
  };

  const confirmationHandler = () => {
    setShowConfirmation(false);
    // onTimeConfirmation(selectedTime);
    setBookingConfirmed(true);
    createBookingData(selectedDate, selectedTime);
  };

  const cancelHandler = () => {
    setShowConfirmation(false);
  };

  const dateFromWeekdayUTC = (weekday: any, date: any) => {
    const formattedDate = moment.utc(date, "DD-MM-YYYY").format("YYYY-MM-DD");

    return moment
      .utc(formattedDate)
      .day(weekday.toUpperCase())
      .format(DATE_FORMAT);
  };

  const dateInfoFromUtcToLocal = (weekStartDate: any, day: any, time: any) => {
    const date = dateFromWeekdayUTC(day, weekStartDate);
    const dateTime = dateTimeFromUTCtoLocal(date, time);
    return dateTime;
  };

  const dateTimeFromUTCtoLocal = (date: any, time: any) => {
    const formattedDate = moment.utc(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    const formattedTime = moment.utc(time, "HH:mm").format("HH:mm");
    const dateTime = moment.utc(`${formattedDate} ${formattedTime}`);
    return dateTime.local().format("DD-MM-YYYY HH:mm");
  };

  dateTimeFromUTCtoLocal("03-01-2021", "21:00");

  const dateFromWeekdayLocal = (weekday: any, date: any) => {
    const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

    return moment
      .utc(formattedDate)
      .day(weekday.toUpperCase())
      .format(DATE_FORMAT);
  };

  dateFromWeekdayUTC("monday", "03-01-2021");
  dateFromWeekdayLocal("monday", "03-01-2021");

  return (
    <div className={styles["selection_wrapper"]}>
      <label className={styles["selection_wrapper_label"]}>
        Select Date & time
      </label>
      <div className={styles["selection_container"]}>
        <div className={styles["date_selection"]}>
          {datesArray.map((date: any, index: number) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setSelectedDate(date);
                }}
                className={
                  styles[
                    `${
                      selectedDate === date
                        ? "date_selected"
                        : "date_selection_date"
                    }`
                  ]
                }
              >
                {/* <p>{moment(date, "YYYY-MM-DD").format("dddd")}</p> */}
                <p>{date}</p>
              </button>
            );
          })}
        </div>
        <div className={styles["time_selection"]}>
          {timeSlotUpdatedData[selectedDate].map((time: any, index: number) => {
            const { localTime } = time;
            return (
              <div
                key={index}
                onClick={() => timeClickHandler(time)}
                className={
                  styles[
                    `${
                      selectedTime === time
                        ? "time_selected"
                        : "time_selection_time"
                    }`
                  ]
                }
              >
                {localTime}
              </div>
            );
          })}
        </div>
        {showConfirmation && (
          <div className={styles["selection_confirmation"]}>
            <button
              onClick={confirmationHandler}
              className={styles["confirmation_button"]}
            >
              Confirm
            </button>
            <button
              onClick={cancelHandler}
              className={styles["confirmation_cancel"]}
            >
              Cancel
            </button>
          </div>
        )}
        {bookingConfirmed && (
          <div className={styles["confirmed_complete"]}>
            <p className={styles["confirmed_para"]}>
              You have confirmed the day {selectedDate} and time{" "}
              {selectedTime.localTime}.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentLogic = ({ bookingData, showSuccessModal, teacherImg }: any) => {
  const {
    rttDataState: {
      isFirstBooking,
      rttCouponData,
      rttCouponData: { couponType, couponAmount, couponCode, couponId },
      selectedTopics,
    },
  } = useRttOnlineContext();
  const numberOfSessionsSelected = selectedTopics.length;
  const isCouponApplied = Object.keys(rttCouponData).length > 0;
  const initialAmount = Number(numberOfSessionsSelected) * 300;
  const totalPayable =
    numberOfSessionsSelected === 1 && isFirstBooking
      ? 0
      : isCouponApplied
      ? couponType === "percent"
        ? //PERCENT DISCOUNT
          Number((initialAmount * (1 - couponAmount / 100)).toFixed(0))
        : initialAmount - couponAmount >= 0
        ? //AMOUNT DISCOUNT
          initialAmount - couponAmount
        : 0
      : initialAmount;

  return (
    <footer className={styles["footer_tab_bar"]}>
      <div className={styles["footer_total_selected_program"]}>
        <p className={styles["footer_total_selected_number"]}>
          {numberOfSessionsSelected}{" "}
          {numberOfSessionsSelected > 1 ? "sessions" : "session"}
        </p>

        <div className={styles["footer_price_container"]}>
          <p className={styles["footer_total_price"]}>
            ₹{Number(numberOfSessionsSelected) * 1000}{" "}
          </p>
          <p
            className={`${styles["footer_total_price"]} ${
              isFirstBooking &&
              Number(numberOfSessionsSelected) === 1 &&
              styles["line_through"]
            } ${isCouponApplied && styles["line_through"]}`}
          >
            ₹{initialAmount}
          </p>
          {isFirstBooking && Number(numberOfSessionsSelected) === 1 ? (
            <p className={styles["footer_total_price"]}>FREE</p>
          ) : isCouponApplied ? (
            <p className={styles["footer_total_price"]}>₹{totalPayable}</p>
          ) : null}
        </div>
      </div>
      <div>
        <Razorpay
          payable={bookingData.length > 0}
          initialAmount={initialAmount}
          amount={totalPayable}
          postData={{ bookingData }}
          showSuccessModal={showSuccessModal}
          numberOfSessionsSelected={numberOfSessionsSelected}
          teacherImg={teacherImg}
          couponCode={couponCode}
          couponId={couponId}
        />
      </div>
    </footer>
  );
};

export default RttOnlineBookingPage;
