import { IonRouterLink } from "@ionic/react";
import styles from "./Tnc.module.css";
const TncCheckbox = ({
  modalDetails,
  closeModal,
  isTncAccepted,
  checkboxClick,
}: any) => {
  return (
    <>
      <div
        className={`${styles["checkbox_container"]} ${
          modalDetails.showModal &&
          "animate_animated animate__shakeX animate__repeat-2"
        }`}
      >
        <input
          checked={isTncAccepted}
          onChange={checkboxClick}
          type="checkbox"
          name=""
          id=""
        />
        <IonRouterLink href="/parents/tnc">
          <label className={styles["checkbox_label"]}>
            Agree to terms and conditions
          </label>
        </IonRouterLink>
      </div>
    </>
  );
};

export default TncCheckbox;
