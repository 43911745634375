import React, { useEffect, useState } from "react";
import styles from "./Razorpay.module.css";

import axios, { AxiosResponse, AxiosError } from "axios";
import {
  getHttpData,
  postHttpData,
  putHttpData,
} from "../../../../utils/http-util";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { getUUID } from "../../../../utils/general-utils";
import { useUserContext } from "../../../../store/context/user-context";
import moment from "moment";
import { currentUtcTimeDate } from "../../../../utils/date-utils";
import { useHistory } from "react-router";
import {
  keyLive,
  keyTest,
  paymentMode,
} from "../../../../configs/RazorpayConfig";
import { dateTimeFromUTCtoLocal } from "../../../../utils/date-utils";
import CouponAppliedSuccess from "../../../../components/UI/CouponAppliedSuccess/CouponAppliedSuccess";

interface MyData {
  // Define the shape of the data you'll be sending in the POST request.
  name: string;
  age: number;
}

declare global {
  interface Window {
    Razorpay: any;
  }
}

interface Options {
  key: string;
  amount: number;
  name: string;
  description: string;
  image: string;
  secret: string;
  handler: (response: any) => void;
  prefill: {
    name: string;
    contact: string;
    email: string;
  };
  notes: {
    address: string;
  };
  theme: {
    color: string;
    hide_topbar: boolean;
  };
}

interface RazorpayProps {
  amount: number;
}

const Razorpay = (props: any) => {
  const {
    amount,
    postData: { bookingData },
    payable,
    showSuccessModal,
    numberOfSessionsSelected,
    teacherImg,
    initialAmount,
    couponCode,
    couponId,
  } = props;
  const originalAmount = initialAmount;
  const paidAmount = amount;
  const discountAmount = originalAmount - paidAmount;
  const baseAmount: any = Number((paidAmount / 1.18).toFixed(2));
  const gstAmount: any = Number((paidAmount - baseAmount).toFixed(2));
  const [keyId, setKeyid] = useState(
    paymentMode === "LIVE" ? keyLive : keyTest
  );
  //CONTEXT
  const {
    user: { id, email, name, phone },
  } = useUserContext();
  //HOOKS
  const createNotification = async () => {
    const res: any = await postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/events`,
      {
        userId: id,
        timestamp: moment().unix(),
        data: bookingData.map((bookedData: any) => {
          const { eventDate, eventTime } = bookedData;
          const { localDate, localTime, readableTime } = dateTimeFromUTCtoLocal(
            eventDate,
            eventTime
          );
          return { ...bookedData, localTime, localDate, readableTime };
        }),
        type: "RTT_ONLINE_SUBSCRIBED",
      }
    );
  };

  const createBooking = async (razorpayResponse: any) => {
    const newPackageId = getUUID();
    const bookingPostPromiseArr = bookingData.map((data: any) => {
      return postHttpData(
        `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineBooking`,
        { ...data, packageId: newPackageId }
      );
    });

    const packagePostPromise = postHttpData(
      // `https://staging2.braincells.in:8082/rttOnlinePackage`,
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlinePackage`,
      {
        id: newPackageId,
        numberOfEventsBooked: `${bookingData.length}`,
        userId: `${id}`,
        paymentId: razorpayResponse.razorpay_payment_id,
        paymentAmount: `${amount}`,
        createdAt: currentUtcTimeDate(),
      }
    );
    const paymentHistoryPostData = postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/payment_history`,
      {
        userId: `${id}`,
        userName: name,
        programAmount: initialAmount,
        discountAmount: discountAmount,
        paidAmount: amount,
        //PAID_AMOUNT = BASE_AMOUNT + 18% OF
        //PAID_AMOUNT/1.18, 2 DECIMALS
        baseAmount: baseAmount,
        gstAmount: gstAmount,
        subscriptionId: newPackageId,
        programName: `RTT-ONLINE-${bookingData.length}-${
          bookingData.length > 1 ? "SESSIONS" : "SESSION"
        }`,
        redeemedCouponCode: couponCode ? couponCode : null,
        redeemedCouponName: null,
      }
    );
    if (couponCode) {
      putHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon?id=${couponId}`,
        {
          status: `REDEEMED`,
        }
      );
    }
    createNotification();

    const response = await Promise.all([
      ...bookingPostPromiseArr,
      packagePostPromise,
    ]);
    showSuccessModal();
  };

  const options: Options = {
    key: keyId,
    amount: amount * 100,
    secret: "luEOmOjmkquCKtFPKDfSgt3o",
    name: "Braincells",
    description: `Booked ${bookingData.length} RTT-Online sessions.`,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJq7VVlAHh-7WnJyByjUx_WczLLdOM9gAfJxwg14f13A&s",
    handler: async function (razorpayResponse) {
      createBooking(razorpayResponse);
    },
    prefill: {
      name,
      contact: phone,
      email,
    },
    notes: {
      address: `Booked ${bookingData.length} RTT-Online sessions.`,
    },
    theme: {
      color: "#3A86FF",
      hide_topbar: false,
    },
  };

  const openPayModal = (options: Options) => {
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      {payable ? (
        <>
          <button
            style={{
              color: "#fff",
              cursor: "pointer",
            }}
            className={`${styles["footer_payment_button"]} animate__animated animate__heartBeat animate__infinite`}
            onClick={() => {
              if (payable) {
                //! REMOVE TOMORROW
                if (amount <= 0) {
                  createBooking({
                    razorpay_payment_id: "introductoryOneSessionFree",
                  });
                } else {
                  openPayModal(options);
                }
              }
            }}
          >
            <p
              style={{
                color: "#fff",
              }}
            >
              Book {numberOfSessionsSelected}{" "}
              {numberOfSessionsSelected > 1 ? "sessions" : "session"} with me
              <img
                src={teacherImg}
                className={styles["teacher_profile"]}
                alt="teacher-profile"
              />
            </p>
            <i className="fas fa-caret-right fa-lg"></i>
          </button>
        </>
      ) : null}
    </>
  );
};

export default Razorpay;
