import { useEffect, useRef, useState } from "react";
import styles from "./CouponApplicationModal.module.css";
import useCoupon from "../../../hooks/useCoupon";
import CouponSuccessModal from "../../../pages/Parents/Subscription/components/CouponSuccessModal/CouponSuccessModal";
const CouponApplicationModal = ({
  closeApplicationModal,
  setCouponInfo,
  couponInfo,
  payableAmount,
}: any) => {
  //HOOKS
  const { getCouponData, couponData, couponStatus } = useCoupon();
  //STATE
  const [couponSuccessful, setCouponSuccessful] = useState<any>(false);
  //REF
  const inputRef = useRef<any>();
  //CONSTANTS
  const showCouponStatus = couponStatus.length > 0;

  const applyCoupon = async () => {
    if (inputRef.current.value.length > 0) {
      getCouponData("code", inputRef.current.value);
    }
  };

  useEffect(() => {
    console.log(couponData);
    if (couponData.length > 0) {
      const couponObj = couponData[0];
      if (couponObj.discountAmount === "0") {
        const discountPercent =
          couponObj.discountPercent[
            couponObj.discountPercent.split("").length - 1
          ] === "%"
            ? Number(couponObj.discountPercent.split("%")[0])
            : Number(couponObj.discountPercent);

        setCouponSuccessful(true);
        console.log("hello");

        console.log(couponData);
        //! COUPON PERCENTAGE
        setCouponInfo({
          couponCode: couponObj.code,
          couponType: "percent",
          couponAmount: discountPercent,
          couponId: couponObj.id,
        });
        // setTotalPayableAmount(
        //   Number((totalPayable * (1 - discountPercent / 100)).toFixed(0))
        // );
      } else {
        //! COUPON AMOUNT
        console.log("hello");
        setCouponSuccessful(true);
        setCouponInfo({
          couponCode: couponObj.code,
          couponType: "fixed",
          couponAmount: Number(couponObj.discountAmount),
          couponId: couponObj.id,
        });
        // setTotalPayableAmount(totalPayable - Number(couponObj.discountAmount));
      }
    }
  }, [couponData]);

  //   hideCoupon,
  //   couponData: { couponCode, couponType, couponAmount },
  console.log(couponSuccessful);

  return couponSuccessful ? (
    <CouponSuccessModal
      hideCoupon={closeApplicationModal}
      couponData={couponInfo}
    />
  ) : (
    <div className={styles["wrapper"]}>
      <div className={styles["container"]}>
        <div className={styles["close_icon_container"]}>
          <button
            className={styles["close_icon_button"]}
            title="close modal"
            onClick={closeApplicationModal}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className={styles["header"]}>
          <p className={styles["header_heading"]}>
            Please enter your coupon code
          </p>
        </div>
        <div className={styles["body"]}>
          <input
            title="input"
            ref={inputRef}
            className={styles["coupon_input"]}
            placeholder="enter coupon code here"
          ></input>
          <button className={styles["apply_button"]} onClick={applyCoupon}>
            Apply Coupon
          </button>
          <p
            className={styles["coupon_error"]}
            style={{ opacity: showCouponStatus ? "1" : "0", color: "red" }}
          >
            {showCouponStatus ? couponStatus : "ans"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CouponApplicationModal;
