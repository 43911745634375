import { v4 as uuid } from "uuid";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
} from "../../../../configs/ServerConfig";
import { useUserContext } from "../../../../store/context/user-context";
import { postHttpData } from "../../../../utils/http-util";
import styles from "./Emojis.module.css";
const emojis = [
  {
    id: uuid(),
    image: "/assets/parentLanding/sad.png",
    rating: 1,
    type: "detractors",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/sad.png",
    rating: 2,
    type: "detractors",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/sad.png",
    rating: 3,
    type: "detractors",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/sad.png",
    rating: 4,
    type: "detractors",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/sad.png",
    rating: 5,
    type: "detractors",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/sad.png",
    rating: 6,
    type: "detractors",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/neutral.png",
    rating: 7,
    type: "passives",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/neutral.png",
    rating: 8,
    type: "passives",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/smiling.png",
    rating: 9,
    type: "promoters",
  },
  {
    id: uuid(),
    image: "/assets/parentLanding/smiling.png",
    rating: 10,
    type: "promoters",
  },
];

export const Emojis = ({
  SetShowThanks,
  SetFeedBackForm,
  SetShowNps,
  SetNpsModal,
  SetRatingScore,
}: any) => {
  const MODAL_CLOSE_TIMER = 3500;
  const ShowThanksAndClose = () => {
    SetShowThanks(true);
    setTimeout(() => {
      SetNpsModal(false);
    }, MODAL_CLOSE_TIMER);
  };

  const {
    user: { id },
  } = useUserContext();

  const postData = (rating: number) => {
    return postHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/userInfo`,
      {
        userId: `${id}`,
        "data-type": "nps-score",
        "nps-score": rating,
        review: "",
      }
    );
  };

  const emojiClickHandler = (emojiType: string, rating: number) => {
    if (emojiType === "detractors") {
      SetFeedBackForm(true);
    }
    if (emojiType === "passives") {
      ShowThanksAndClose();
      postData(rating);
    }
    if (emojiType === "promoters") {
      ShowThanksAndClose();
      console.log(postData(rating));
    }
    SetRatingScore(rating);
    SetShowNps(false);
  };

  return (
    <div className={styles["emojiContainer"]}>
      <h2 className={styles["npsTitle"]}>
        How likely are you to recommend Braincells program to your friends and
        family?
      </h2>
      <div className={styles["npsEmojiContainer"]}>
        {emojis.map((emoji) => {
          return (
            <div
              className={styles["npsEmoji"]}
              onClick={() => emojiClickHandler(emoji.type, emoji.rating)}
              key={emoji.id}
            >
              <img
                className={styles["npsEmojiImage"]}
                src={emoji.image}
                alt={emoji.type}
              />
              <p className={`${styles["npsEmojiRating"]} `}> {emoji.rating}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
