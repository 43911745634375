import { useState } from "react";
import { MAIN_REST_API_SERVER, MAIN_REST_PORT } from "../configs/ServerConfig";
import { useUserContext } from "../store/context/user-context";
import { getHttpData } from "../utils/http-util";

const useCoupon = () => {
  const {
    user: { id },
  } = useUserContext();

  const [couponStatus, setCouponStatus] = useState<any>("");
  const [couponData, setCouponData] = useState<any>([]);

  const getCouponData = async (
    couponIdentificationType: any,
    couponIdentification: any
  ) => {
    try {
      const firstResponse: any = await Promise.all([
        getHttpData(`${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon`, {
          params: {
            [couponIdentificationType]: couponIdentification,
            userId: id,
            status: "ASSIGNED",
          },
        }),
        getHttpData(`${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon`, {
          params: {
            code: couponIdentification,
            usageType: "GENERIC",
            status: "ASSIGNED",
          },
        }),
      ]);
      const isCouponAvailable = [
        ...firstResponse[0]["data"],
        ...firstResponse[1]["data"],
      ];
      if (isCouponAvailable.length === 0) {
        setCouponStatus("Coupon not applicable.");
      } else {
        setCouponStatus(`${couponIdentification} applied successfully!`);
        console.log(isCouponAvailable);
        setCouponData(isCouponAvailable);
      }
    } catch {
      setCouponStatus("Failed to check coupon.");
    }
  };
  return { getCouponData, couponData, couponStatus };
};

export default useCoupon;
