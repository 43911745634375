import { useEffect, useRef, useState } from "react";
import { IonContent, IonPage, IonButton, IonImg } from "@ionic/react";
import { DashCard } from "../../components/Dashboard/DashCard";
import { useUserContext } from "../../store/context/user-context";
import styles from "./Dashboard.module.css";
import { earth, language } from "ionicons/icons";
import { ParentVerificationCaptcha } from "../../components/generalComponents/ParentVerificationCaptcha";
import moment from "moment";
import { tts } from "../../utils/Genie";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { postHttpData } from "../../utils/http-util";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../configs/ServerConfig";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { detectIsMobileDevice } from "../../utils/general-utils";
import NewPortalModal from "../../components/generalComponents/NewPortalModal/NewPortalModal";
import "../../pages/Home/Intro.css";
import SurveryModal from "../Parents/Home/Components/SurveyModal";
import useIsZeroTwoToOne from "../../hooks/useIsZeroToOne";
import { imagesLink } from "../../constants/Services/services-constants";

export const Dashboard = () => {
  const userCtx = useUserContext();
  const subscriptionCtx = useSubscriptionContext();
  const dashboardHandle = useFullScreenHandle();
  const [showNewPortalMsg, setNewPortalMsg] = useState(
    localStorage.getItem("SHOW_NEW_PORTAL_MESSAGE") === null ? true : false
  );
  const BrainCells = "BrainCells";
  const LanguageLearningGerman = "Language-Learning-German";
  const LanguageLearningFrench = "Language-Learning-French";
  const LanguageLearningJapanese = "Language-Learning-Japanese";
  const RumbleTumbleTime = "Rumble-Tumble-Time";
  const RumbleTumbleTimeGuide = "Rumble-Tumble-Guide";
  const RumbleTumbleTimeOnline = "Rumble-Tumble-Time-Online";
  console.log(userCtx.user.ageGroup);

  const { isZeroToOneV2 } = useIsZeroTwoToOne();

  useEffect(() => {
    var todayDate = moment().format("DD/MM/YYYY");
    document.cookie = "todayDate=" + todayDate;

    setTimeout(function () {
      const localStorageUser = JSON.parse(
        localStorage.getItem("USER_DATA") || ""
      );
      let childName = localStorageUser.childName;

      var now = new Date(),
        hour = now.getHours();

      var greetings = "Hi " + childName;
      if (hour >= 0 && hour <= 11) {
        greetings += ". Good morning";
      } else if (hour >= 12 && hour <= 16) {
        greetings += ". Good afternoon";
      } else if (hour >= 17 && hour <= 24) {
        greetings += ". Good evening";
      }

      var msg = new SpeechSynthesisUtterance();
      const prom = tts(greetings, "ENGLISH");
      prom
        .then((res: any) => {
          let base64MP3 = res.data;
          var snd = new Audio("data:audio/mp3;base64," + base64MP3);
          const promise = snd.play();
          snd.onended = function () {};
        })
        .catch((error: any) => {
          console.log(error);
        });
    }, 1000);

    (async () => {
      const res = await postHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/activity_tracking`,
        {
          type: "DASHBOARD_ACCESS",
          userId: userCtx.user.id,
          timestamp: moment.utc().valueOf(),
          data: {},
        }
      );
    })();
  }, []);

  const [showCaptchaAlert, setshowCaptchaAlert] = useState(false);
  const [showCaptchaForSubscription, setshowCaptchaForSubscription] =
    useState(false);
  const isActiveSubscriptionIncludes = (subscriptionName: string) => {
    let isSubIncludes: boolean = false;

    const findSub = subscriptionCtx.activeSubscriptions.find(
      (item) =>
        item.programName === subscriptionName &&
        item.programType === "GENIUS" &&
        item.status === "ACTIVE"
    );

    if (findSub !== undefined) {
      isSubIncludes = true;
    }

    return isSubIncludes;
  };

  const isProgramIncludes = (programName: string) => {
    let isProgramInclude: boolean = false;

    const findProgram = subscriptionCtx.activeSubscriptions.find(
      (item) => item.programName === programName
    );
    if (findProgram !== undefined) {
      isProgramInclude = true;
    }
    return isProgramInclude;
  };

  const showBookDemoRef = useRef(true);

  showBookDemoRef.current = !(
    isActiveSubscriptionIncludes(BrainCells) ||
    isActiveSubscriptionIncludes(LanguageLearningGerman) ||
    isActiveSubscriptionIncludes(LanguageLearningFrench) ||
    isActiveSubscriptionIncludes(LanguageLearningJapanese) ||
    isActiveSubscriptionIncludes(RumbleTumbleTime) ||
    isActiveSubscriptionIncludes(RumbleTumbleTimeGuide)
  );

  const CarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const enterFullScreen = () => {
    if (
      !dashboardHandle.active &&
      detectIsMobileDevice() &&
      !document.fullscreenElement
    )
      dashboardHandle.enter();
  };
  const swiperSlidesPerView = () => {
    let counter = 0;

    if (isProgramIncludes(BrainCells)) counter++;
    if (isProgramIncludes(LanguageLearningGerman)) counter++;
    if (isProgramIncludes(LanguageLearningFrench)) counter++;
    if (isProgramIncludes(LanguageLearningJapanese)) counter++;

    if (counter >= 3) {
      return 3;
    } else {
      return counter;
    }
  };

  const totalNumberOfPrograms = () => {
    let counter = 0;

    if (isProgramIncludes(BrainCells)) counter++;
    if (isProgramIncludes(LanguageLearningGerman)) counter++;
    if (isProgramIncludes(LanguageLearningFrench)) counter++;
    if (isProgramIncludes(LanguageLearningJapanese)) counter++;

    return counter;
  };
  const totalNumberOfRttPrograms = () => {
    let counter = 0;

    if (isProgramIncludes(RumbleTumbleTime)) counter++;
    if (isProgramIncludes(RumbleTumbleTimeGuide)) counter++;

    return counter;
  };

  const isShowingRttOnlineProgram = () => {
    if (
      userCtx.user?.ageGroup === "4-Plus" ||
      userCtx.user?.ageGroup === "2-4"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <FullScreen handle={dashboardHandle}>
          {showNewPortalMsg && (
            <NewPortalModal
              myModalDetails={[showNewPortalMsg, setNewPortalMsg]}
            />
          )}
          <div
            style={{
              backgroundImage: `url("/assets/dashboard/background.svg")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "100vh",
              position: "relative",
            }}
          >
            <div onClick={enterFullScreen} className={styles.content}>
              {isActiveSubscriptionIncludes(RumbleTumbleTime) ||
              isActiveSubscriptionIncludes(RumbleTumbleTimeGuide) ? (
                <div
                  className={`${styles.container} animate__animated animate__zoomIn`}
                >
                  {isProgramIncludes(RumbleTumbleTime) ? (
                    isActiveSubscriptionIncludes(RumbleTumbleTime) ? (
                      <DashCard
                        subText="Welcome to Braincells!"
                        href="/program/Rumble-Tumble-Time"
                        color="#fa3948"
                        icon={earth}
                        img={"/assets/dashboard/rumble-tumble-time.svg"}
                      />
                    ) : null
                  ) : null}

                  {isProgramIncludes(RumbleTumbleTimeGuide) ? (
                    isActiveSubscriptionIncludes(RumbleTumbleTimeGuide) ? (
                      <DashCard
                        subText="Welcome to Braincells!"
                        href="/program/Rumble-Tumble-Guide"
                        color="#fa3948"
                        icon={earth}
                        img={"/assets/dashboard/rumble-tumble-guide.svg"}
                      />
                    ) : null
                  ) : null}
                </div>
              ) : (
                <div
                  className={`${styles.container} animate__animated animate__zoomIn`}
                >
                  {totalNumberOfPrograms() > 3 ? (
                    <Swiper
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      spaceBetween={0}
                      slidesPerView={
                        detectIsMobileDevice() ? 1 : swiperSlidesPerView()
                      }
                      className="mySwiper"
                    >
                      {isProgramIncludes(BrainCells) ? (
                        isActiveSubscriptionIncludes(BrainCells) ? (
                          <SwiperSlide>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/BrainCells"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/braincells-button.svg"}
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide>
                            <DashCard
                              subText="Welcome to Braincells!"
                              // href={
                              //   isZeroToOneV2
                              //     ? "/program/BrainCells/zeroToOne"
                              //     : "/program/BrainCells?s=TRIAL"
                              // }
                              href={"/program/BrainCells?s=TRIAL"}
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/braincells-trial.svg"}
                            />
                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/blue-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </SwiperSlide>
                        )
                      ) : null}

                      <SwiperSlide>
                        <DashCard
                          subText="Free Worksheets"
                          href="/free-programs/free_downloadable"
                          color="#fa3948"
                          icon={earth}
                          img={"/assets/freePrograms/free_worksheet.svg"}
                        />
                      </SwiperSlide>

                      <SwiperSlide>
                        <DashCard
                          subText="Storytelling"
                          href="/free-programs/story_telling"
                          color="#fa3948"
                          icon={earth}
                          img={"/assets/freePrograms/storytelling.svg"}
                        />
                      </SwiperSlide>

                      {isShowingRttOnlineProgram() && (
                        <SwiperSlide>
                          <DashCard
                            subText="Rumble Tumble Time Online"
                            href="/rtt-online"
                            color="#fa3948"
                            icon={earth}
                            img={
                              "/assets/dashboard/rumble-tumble-time-online.svg"
                            }
                          />
                        </SwiperSlide>
                      )}

                      {isProgramIncludes(LanguageLearningGerman) ? (
                        isActiveSubscriptionIncludes(LanguageLearningGerman) ? (
                          <SwiperSlide>
                            <DashCard
                              subText="languages"
                              href="/program/Language-Learning-German"
                              color="#ff7139"
                              icon={language}
                              img={
                                "/assets/dashboard/german-language-button.svg"
                              }
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/Language-Learning-German?s=TRIAL"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/german-trial.svg"}
                            />
                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/green-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </SwiperSlide>
                        )
                      ) : null}
                      {isProgramIncludes(LanguageLearningFrench) ? (
                        isActiveSubscriptionIncludes(LanguageLearningFrench) ? (
                          <SwiperSlide>
                            <DashCard
                              subText="languages"
                              href="/program/Language-Learning-French"
                              color="#ff7139"
                              icon={language}
                              img={
                                "/assets/dashboard/french-language-button.svg"
                              }
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/Language-Learning-French?s=TRIAL"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/french-trial.svg"}
                            />

                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/pink-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </SwiperSlide>
                        )
                      ) : null}
                      {isProgramIncludes(LanguageLearningJapanese) ? (
                        isActiveSubscriptionIncludes(
                          LanguageLearningJapanese
                        ) ? (
                          <SwiperSlide>
                            <DashCard
                              subText="languages"
                              href="/program/Language-Learning-Japanese"
                              color="#ff7139"
                              icon={language}
                              img={
                                "/assets/dashboard/japanese-language-button.svg"
                              }
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/Language-Learning-Japanese?s=TRIAL"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/japanese-trial.svg"}
                            />
                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/purple-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </SwiperSlide>
                        )
                      ) : null}
                    </Swiper>
                  ) : (
                    <div className={styles.staticDashboardProgramButtons}>
                      {isProgramIncludes(BrainCells) ? (
                        isActiveSubscriptionIncludes(BrainCells) ? (
                          <DashCard
                            subText="Welcome to Braincells!"
                            href={
                              isZeroToOneV2
                                ? "/program/BrainCells/zeroToOne"
                                : "/program/BrainCells"
                            }
                            color="#fa3948"
                            icon={earth}
                            img={"/assets/dashboard/braincells-button.svg"}
                          />
                        ) : (
                          <div>
                            <DashCard
                              subText="Welcome to Braincells!"
                              // href="/program/BrainCells?s=TRIAL"
                              href={"/program/BrainCells?s=TRIAL"}
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/braincells-trial.svg"}
                            />
                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/blue-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        )
                      ) : null}

                      <DashCard
                        subText="Free Worksheets"
                        href="/free-programs/free_downloadable"
                        color="#fa3948"
                        icon={earth}
                        img={"/assets/freePrograms/free_worksheet.svg"}
                      />

                      <DashCard
                        subText="Storytelling"
                        href="/free-programs/story_telling"
                        color="#fa3948"
                        icon={earth}
                        img={"/assets/freePrograms/storytelling.svg"}
                      />

                      {isProgramIncludes(LanguageLearningGerman) ? (
                        isActiveSubscriptionIncludes(LanguageLearningGerman) ? (
                          <DashCard
                            subText="languages"
                            href="/program/Language-Learning-German"
                            color="#ff7139"
                            icon={language}
                            img={"/assets/dashboard/german-language-button.svg"}
                          />
                        ) : (
                          <div>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/Language-Learning-German?s=TRIAL"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/german-trial.svg"}
                            />
                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/green-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        )
                      ) : null}
                      {isProgramIncludes(LanguageLearningFrench) ? (
                        isActiveSubscriptionIncludes(LanguageLearningFrench) ? (
                          <DashCard
                            subText="languages"
                            href="/program/Language-Learning-French"
                            color="#ff7139"
                            icon={language}
                            img={"/assets/dashboard/french-language-button.svg"}
                          />
                        ) : (
                          <div>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/Language-Learning-French?s=TRIAL"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/french-trial.svg"}
                            />

                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/pink-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        )
                      ) : null}
                      {isProgramIncludes(LanguageLearningJapanese) ? (
                        isActiveSubscriptionIncludes(
                          LanguageLearningJapanese
                        ) ? (
                          <DashCard
                            subText="languages"
                            href="/program/Language-Learning-Japanese"
                            color="#ff7139"
                            icon={language}
                            img={
                              "/assets/dashboard/japanese-language-button.svg"
                            }
                          />
                        ) : (
                          <div>
                            <DashCard
                              subText="Welcome to Braincells!"
                              href="/program/Language-Learning-Japanese?s=TRIAL"
                              color="#fa3948"
                              icon={earth}
                              img={"/assets/dashboard/japanese-trial.svg"}
                            />
                            <button
                              onClick={() =>
                                setshowCaptchaForSubscription(true)
                              }
                              className={styles.subscribeNowBtn}
                            >
                              <img
                                src="/assets/dashboard/purple-subscribe-now.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        )
                      ) : null}
                    </div>
                  )}
                </div>
              )}

              <div className={`${styles.communityButtonContainer}`}>
                <a href="/community" className={styles.communityButtonLink}>
                  <img
                    src="/assets/community/community-canvas-button.svg"
                    alt="community-canvas-button"
                  />
                </a>
              </div>

              <div className={`${styles.parentAccessButtonContainer}`}>
                <IonButton
                  fill="clear"
                  onClick={() => setshowCaptchaAlert(true)}
                  className={`${styles.parentAccessButton} `}
                >
                  <IonImg
                    style={{ height: "100%", width: "100%" }}
                    src="/assets/dashboard/parent-login.svg"
                  ></IonImg>
                </IonButton>
                <div className={styles.parentCaptchaContainer}>
                  {showCaptchaAlert ? (
                    <ParentVerificationCaptcha
                      showState={[showCaptchaAlert, setshowCaptchaAlert]}
                      navigateTo={"/parents"}
                    />
                  ) : null}
                </div>
                <div className={styles.parentCaptchaContainer}>
                  {showCaptchaForSubscription ? (
                    <ParentVerificationCaptcha
                      showState={[
                        showCaptchaForSubscription,
                        setshowCaptchaForSubscription,
                      ]}
                      navigateTo={"/parents/subscriptions"}
                    />
                  ) : null}
                </div>
              </div>
              {/* <div className={styles.languageButtonContainer}>
              <IonButton
                fill="clear"
                href="/trophy-case"
                className={styles.languageButton} 
              >
                <img
                  src={"/assets/dashboard/trophies-button2.svg"}
                  alt={"languageButton"}
                />
              </IonButton>
            </div> */}
            </div>{" "}
            {showBookDemoRef.current ? (
              <div className={styles.demoButtonContainer}>
                <a target="_blank" href="http://course.braincells.in/enquiry/">
                  <img src="/assets/dashboard/book-a-demo.svg" alt="" />
                </a>
              </div>
            ) : null}
          </div>
        </FullScreen>
      </IonContent>
    </IonPage>
  );
};
