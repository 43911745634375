import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonCard,
  IonImg,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
} from "@ionic/react";
import { InlineWidget } from "react-calendly";
import { useUserContext } from "../../../store/context/user-context";

import styles from "./MasterClassRegistration.module.css";

export const MasterClassRegistration = () => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const [showModal, setShowModal] = useState(false);
  var upcomingMasterClass = new Date();
  var nextMasterClass = new Date();
  upcomingMasterClass.setDate(
    upcomingMasterClass.getDate() +
      ((4 + 7 - upcomingMasterClass.getDay()) % 7 || 7)
  );
  nextMasterClass.setDate(
    nextMasterClass.getDate() + ((4 + 7 - nextMasterClass.getDay()) % 14 || 14)
  );
  let dummyDetails = {
    id: "1234",
    name: "Timothy Parker",
    childName: "Naresh Parker",
    date: null,
  };
  const [details, setDetails] = useState(dummyDetails);
  function changeDetails(val: any) {
    let x: any = details;
    x["date"] = val.target.value;
  }

  function register() {
    let x: any = details;
    if (x["date"] != null) {
      console.log(details);
    } else {
      alert("Pls Select a date!!!");
    }
  }

  const [isVideoDisplayed, setIsVideoDisplayed] = useState(false);
  const userCtx = useUserContext();

  console.log(userCtx.user);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/parents" />
          </IonButtons>
          <IonTitle>MasterClass Registration</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <p className={styles.masterclassHeading}>Let's get you started</p>

        <div className={styles.mainContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.videoImgHolder}>
              {isVideoDisplayed ? (
                <iframe
                  style={{ borderRadius: "25px" }}
                  width="90%"
                  height="315"
                  src="https://www.youtube.com/embed/qnKK5BX5uaM?rel=0&autoplay=1"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              ) : (
                <img
                  onClick={() => setIsVideoDisplayed(true)}
                  style={{ borderRadius: "20px", marginBottom: "25px" }}
                  width="90%"
                  src="assets/masterclass/braincells-masterclass-intro.png"
                />
              )}
            </div>

            <div className={styles.stepOneContainer}>
              <p className={styles.stepsHeading}>STEP 1</p>

              <p className={styles.stepsHeading}>
                Book your FREE MasterClass with BrainCells Founders
              </p>

              <p className={styles.stepsContent}>
                More than 15,000 parents attended so far. Grab your seat Now !
              </p>
            </div>

            <div className={styles.calendlyContainer}>
              <InlineWidget
                url="https://calendly.com/tejasa-and-pratik-braincells/braincells-masterclass-one-on-one-demo"
                styles={{
                  height: "1200px",
                }}
                prefill={{
                  email: userCtx.user.email,
                  name: userCtx.user.name,

                  customAnswers: {
                    a1: userCtx.user.phone,
                  },
                }}
              />
            </div>
          </div>

          <div className={styles.rightContainer}>
            <div className={styles.stepsContainer}>
              <p className={styles.stepsHeading}>STEP 2</p>

              <p className={styles.stepsHeading}>Try BrainCells Activities</p>

              <p className={styles.stepsContent}>
                Checkout the program curriculum
              </p>
            </div>

            <div className={styles.trialBtnContainer}>
              <button className={styles.trialActivitiesBtn}>
                <div className={styles.trialBtnText}>
                  Try out FREE TRIAL Activities
                </div>
              </button>

              <button
                style={{ fontSize: "12px" }}
                className={styles.programBtn}
              >
                <img
                  width="30px"
                  style={{ marginRight: "10px" }}
                  src="/assets/masterclass/brainCells-logo-small.png"
                />
                <div
                  data-toggle="modal"
                  data-target="#modal-program"
                  className={styles.programBtnText}
                >
                  Explore Program Curriculum
                </div>
                <img
                  style={{ marginLeft: "25px" }}
                  src="/assets/masterclass/right-arrow.png"
                />
              </button>
            </div>

            <div className={styles.stepsContainer}>
              <p className={styles.stepsHeading}>STEP 3</p>

              <p className={styles.stepsHeading}>
                Ready ? Subscribe to Program
              </p>

              <p className={styles.stepsContent}>
                Choose from 3 subsription plans
              </p>
            </div>

            <div className={styles.subscriptionContainer}>
              <div className={styles.singleSubscriptionContainer}>
                <img
                  className={styles.braincellsOneMonthImg}
                  src="/assets/masterclass/BrainCells-1-month.png"
                  alt=""
                />

                <p className={styles.masterclassHeading}>
                  BrainCells - 1 month
                </p>

                <p>30 Days</p>

                <p>26 Exercises</p>

                <p>12 Activities</p>

                <p className={styles.masterclassHeading}>INR 600 /-</p>

                <button
                  style={{ width: "100%", fontWeight: "600" }}
                  className={styles.trialActivitiesBtn}
                >
                  <div className={styles.trialBtnText}>Subscribe Now</div>
                </button>
              </div>

              <div className={styles.singleSubscriptionContainer}>
                <img
                  className={styles.mostPopularImg}
                  src="/assets/masterclass/most-popular.png"
                  alt=""
                />
                <img
                  className={styles.braincellsOneMonthImg}
                  src="/assets/masterclass/BrainCells-3-months.png"
                  alt=""
                />

                <p className={styles.masterclassHeading}>
                  BrainCells - 3 months
                </p>

                <p>90 Days</p>

                <p>78 Exercises</p>

                <p>60 Activities</p>

                <p className={styles.masterclassHeading}>INR 1,500 /-</p>

                <button
                  style={{ width: "100%", fontWeight: "600" }}
                  className={styles.trialActivitiesBtn}
                >
                  <div className={styles.trialBtnText}>Subscribe Now</div>
                </button>
              </div>

              <div className={styles.singleSubscriptionContainer}>
                <img
                  className={styles.braincellsOneMonthImg}
                  src="/assets/masterclass/BrainCells-12-months.png"
                  alt=""
                />

                <p className={styles.masterclassHeading}>
                  BrainCells - 12 months
                </p>

                <p>365 Days</p>

                <p>312 Exercises</p>

                <p>120 Activities</p>

                <p className={styles.masterclassHeading}>INR 5,000 /-</p>

                <button
                  style={{ width: "100%", fontWeight: "600" }}
                  className={styles.trialActivitiesBtn}
                >
                  <div className={styles.trialBtnText}>Subscribe Now</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
