import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButton,IonButtons, IonBackButton, IonCard, IonImg, IonCardTitle, IonInput} from '@ionic/react';
import { useState } from 'react';
import styles from './SetName.module.css';
import { Redirect } from 'react-router';
function SetName() {
    const[name,setName] = useState<string>()
    const [boolRedirect, setRedirect] = useState(false);
  function changeName(val:any){ 
    setName(val.target.value)
  }
  function validate(val:any){
    if(name!=null){    
      setRedirect(true); 
    }
    
  }
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
      <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>Profile Creation</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      
      <div className={styles.mainContainer} >

        <div className={styles.creationContainer}>
            <h1>Enter Child's Name</h1>
            
            <input onChange={(val)=>{changeName(val)}}  ></input>
            <IonButton  color="primary" style={{fontSize:'150%'}} size='large' onClick={validate} > 
              {
                boolRedirect ? (<Redirect to='/profile-creation/set-birth-date' ></Redirect>):('Next')
                
              }
             </IonButton>
        </div>
      </div>
      
    </IonContent>
  </IonPage>
  )
}



export default SetName
// style={{height:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}