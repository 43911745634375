import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonDatetime, IonToolbar,IonButton,IonButtons, IonBackButton, IonCard, IonImg, IonCardTitle, IonInput} from '@ionic/react';
import { useState } from 'react';


import styles from './SetDevice.module.css';

function SetDevice(props:any) {
    
    const [deviceImageURL,setdevice] = useState();
    let urlList = ['https://png.pngtree.com/png-vector/20191026/ourmid/pngtree-laptop-icon-png-image_1871608.jpg','https://p7.hiclipart.com/preview/946/358/459/ipad-air-2-ipad-mini-2-ipad-4-mini-thumbnail.jpg','http://assets.stickpng.com/thumbs/61d2f96992b57c0004c64748.png']
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
      <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>Profile Creation</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      
      <div className={styles.mainContainer} >

        <div className={styles.creationContainer}>
          <h1> Select device </h1>
          <div className={styles.deviceContainer}>
            {
            urlList.map((url,i)=>{
              return(
                <div>
                <input name='avatar' id={url} type='radio' value={url} />
                <label className={styles.deviceLabel} htmlFor={url} > 
                  <IonCard className={styles.deviceComponent} href='/dashboard'>
                     <img  src={url} style={{height:'10rem',width:'10rem'}} />
                     
                  </IonCard>
                </label>
              </div>
              )
            })
            }
          </div>
            
        </div>
        
      </div>
      
    </IonContent>
  </IonPage>
  )
}





export default SetDevice