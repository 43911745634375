import styles from "./TeacherSelectionModal.module.css";
import TeacherProfileCard from "../TeacherProfileCard/TeacherProfileCard";
import { TeacherData } from "../../RttOnlineProgram";
import { useRttOnlineContext } from "../../../../../store/context/rtt-online-context";
import { useEffect } from "react";

export interface Props {
  teacherModalDetails: [Object, Function];
  teacherData: Array<TeacherData>;
  showAnimation: Function;
}

const TeacherSelectionModal = (props: Props) => {
  const [modalDetails, setModalDetails] = props.teacherModalDetails;

  const showAnimation = props.showAnimation;

  const { rttData, getAllRttData, rttDataState, rttDataDispatch } =
    useRttOnlineContext();

  const teacherData = props.teacherData;

  useEffect(() => {
    if (teacherData.length === 1) {
      rttDataDispatch({
        type: "SET_TEACHER_DATA",
        payload: teacherData[0],
      });
    }
  }, []);

  return (
    <div className={styles.tncModalBackground}>
      <div className={styles.tncModalContainer}>
        <div
          onClick={() => {
            rttDataDispatch({ type: "EMPTY_CART" });
            setModalDetails({ ...modalDetails, showModal: false });
          }}
          className={styles.closeModalBtn}
        >
          <i className="fas fa-arrow-left"></i>
        </div>
        <h2 className={styles["heading"]}>Select Teacher</h2>

        <div className={styles["teacher_cards_container"]}>
          {teacherData.map((teacher: TeacherData) => {
            return (
              <TeacherProfileCard
                key={teacher.id}
                teacher={teacher}
                showAnimation={showAnimation}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeacherSelectionModal;
