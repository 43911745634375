import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "firebase/compat/auth";
import { useUserContext } from "../../store/context/user-context";
import loginPage from "../../assets/img/login-page.png";
import WOW from "wowjs";
import classes from "./SignIn.module.css";
import axios from "axios";
import { decodeToken, isExpired } from "react-jwt";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../configs/ServerConfig";
import { useHistory } from "react-router";

export const SignIn = () => {
  const thisYear = new Date().getFullYear();
  const history = useHistory();
  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [showLoginError, setShowLoginError] = useState({
    showError: false,
    errorMessage: "",
  });

  const userCtx = useUserContext();
  const subscriptionCtx = useSubscriptionContext();

  const handleKeyDown = (e: any) => {
    if (e.code === "Enter") {
      loginHandler(e);
    }
  };

  document.onkeydown = (e) => {
    handleKeyDown(e);
  };

  const loginHandler = async (e: any) => {
    e.preventDefault();
    setLoginData({
      email: "",
      password: "",
    });

    try {
      const response = await axios.post(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/login`,
        loginData
      );

      const myJwtToken = response.data.jwt;

      const decodedToken: any = decodeToken(myJwtToken);
      const isTokenExpired = isExpired(myJwtToken);

      if (!isTokenExpired) {
        localStorage.setItem("USER_TOKEN", myJwtToken);
        localStorage.setItem("USER_DATA", JSON.stringify(decodedToken.sub));

        subscriptionCtx.loadSubscriptionContext(
          decodedToken.sub.id,
          myJwtToken
        );

        userCtx.loginUser(decodedToken.sub);
      }

      history.push("/dashboard");
      window.location.reload();
    } catch (err: any) {
      console.log(err);

      if (err.response.status === 401) {
        setShowLoginError({
          showError: true,
          errorMessage: "The given password is Invalid!",
        });
      } else if (err.response.status === 404) {
        setShowLoginError({
          showError: true,
          errorMessage: "User not found, login with registered account!",
        });
      } else {
        setShowLoginError({
          showError: true,
          errorMessage: "Unable to login, try again later!",
        });
      }
    }
  };

  return (
    <IonContent className="wow fadeInUp" data-wow-duration="1000ms">
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div
        className="row ion-padding"
        style={{ textAlign: "center", alignItems: "center" }}
      >
        <div className="col-lg-6 col-md-12 col-sm-6">
          <img src={loginPage} style={{ width: "75%" }} />
        </div>
        <div
          className="col-lg-6 col-md-12 col-sm-6"
          style={{ textAlign: "center" }}
        >
          <IonRow style={{ textAlign: "center" }}>
            <IonCol>
              <img
                src="/assets/logo/brainCells-logo-small.png"
                className={classes.braincellsLogo}
              />
              <p className={classes.logoText + " login-box-msg"}>BrainCells</p>
            </IonCol>
          </IonRow>
          <form onSubmit={loginHandler}>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating"> Email</IonLabel>
                  <IonInput
                    required
                    type="email"
                    value={loginData.email}
                    onIonChange={(e) =>
                      setLoginData({ ...loginData, email: e.detail.value! })
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating"> Password</IonLabel>
                  <IonInput
                    required
                    type="password"
                    value={loginData.password}
                    onIonChange={(e) =>
                      setLoginData({ ...loginData, password: e.detail.value! })
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {showLoginError.showError ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      border: "1px solid red",
                      fontWeight: "bolder",
                    }}
                  >
                    {showLoginError.errorMessage}
                  </p>
                ) : null}
                <p style={{ fontSize: "small" }}>
                  By clicking LOGIN you agree to our{" "}
                  <a target="_blank" href="/parents/TNC">
                    Policy
                  </a>
                </p>
                <IonButton type="submit" expand="block">
                  Login
                </IonButton>
                <br />
                <p style={{ fontSize: "medium", marginBottom: "1rem" }}>
                  <a href="https://course.braincells.in/request-pass">
                    Forgot Password
                  </a>
                </p>
                <p style={{ fontSize: "medium" }}>
                  <a href="/signup">Don't have an account? Sign up!</a>
                </p>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="row" style={{ color: "#747474" }}>
          <strong>
            Version v4.0.2 | Copyright &copy; {thisYear} BrainCells | Thought
            River EduTech Private Limited |{" "}
            <a target="_blank" href="/parents/TNC">
              Privacy Policy
            </a>
          </strong>
        </div>
      </div>

      {/* <ul className={classes.bgBubbles}>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul> */}
    </IonContent>
  );
};

// export const SignIn = () => {
//   // Configure Firebase.

// const userCtx = useContext(UserContext);

// firebase.initializeApp(FirebaseConfig);

// function signInAttempSuccessful(authResult:any, redirectUrl:any) {
//   console.log(authResult);
//   console.log(authResult.additionalUserInfo.providerId); //google.com /phone //password
//   console.log(authResult.operationType) //"signIn"
//   console.log('...........');
//   const name = authResult.user.displayName;
//   const email = authResult.user.email;
//   const id = authResult.user.uid;
//   const userObj = {
//     id:id,
//     name:name,
//     email:email,
//   }
//   // console.log(userObj);
//   userCtx.loginUser(userObj);
//   return true;
// }
// function signInAttempFailure(error:any) {
//   alert('Sign in failed. Please try again !!!')
// }
// function firebaseUiShown(){
//   console.log('Firebase UI widget rendered')
// }

// // Configure FirebaseUI.
// const uiConfig:any = {
//   // Popup signin flow rather than redirect flow.
//   // Redirect to /dashboard after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '/dashboard',
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [
//     { provider:firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//       defaultCountry: 'IN'
//     },
//     firebase.auth.EmailAuthProvider.PROVIDER_ID,
//     firebase.auth.GoogleAuthProvider.PROVIDER_ID,

//   ],
//   callbacks:{
//     signInSuccessWithAuthResult: signInAttempSuccessful,
//     signInFailure: signInAttempFailure,
//     uiShown: firebaseUiShown,
//   }
// };

//   return (
//        <>
//         <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
//       </>
//   );
// }
