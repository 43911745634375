import React from "react";
import styles from "./TopicCard.module.css";
import { useHistory } from "react-router";
import { imagesLink } from "../../../../../../constants/Services/services-constants";

const Card = ({
  topic,
  isLocked,
  programType,
  openLockedModal,
  closeLockedModal,
  myFunc,
  selectedCardRef,
  startActivity,
}: any) => {
  const history = useHistory();

  const cardClickHandler = () => {
    selectedCardRef.current = topic?.groupId;
    if (isLocked) {
      openLockedModal();
      return;
    } else {
      if (programType === "story_telling") {
        startActivity();
      } else {
        history.push(`/free-programs/${programType}/${topic?.id}`);
      }
    }
  };

  const inAws: boolean = topic?.displayImageMain?.split("//").length > 1;

  return (
    <div onClick={cardClickHandler} className={styles["topic_card"]}>
      <img
        className={`${
          isLocked
            ? styles["locked_topic_card_image"]
            : styles["topic_card_image"]
        }`}
        src={`${inAws ? "" : imagesLink}${topic?.displayImageMain}`}
        alt="topic card"
      />

      {isLocked && (
        <img
          className={styles["lock_icon"]}
          src="/assets/freePrograms/lock.png"
          alt="lock-icon"
        />
      )}
    </div>
  );
};

export default Card;
