export const paymentMode: any =
  process.env.REACT_APP_PAYMENT_LIVE === "true" ? "LIVE" : "TEST"; //TEST/LIVE
//RECURRING
export const recurringKeyTest = "rzp_test_rjyYbZmvOqYnti";
export const recurringKeyLive = "rzp_live_tvo1sruUK8jRzG"; //rzp_live_tvo1sruUK8jRzG
export const recurringRzrpaySecret = "P7eYDQ0kRrQPirgNrqF9o9Vb";
//ONE TIME
export const keyTest = "rzp_test_BSqwSt2lOvPmFq";
export const keyLive = "rzp_live_33ocquVZq7kOAh";
export const rzrpaySecret = "luEOmOjmkquCKtFPKDfSgt3o";
export const planId =
  process.env.REACT_APP_PAYMENT_LIVE === "true"
    ? "plan_LKAZFMnPLXb3I0"
    : "plan_LKAeU0B1wVmRVM";
