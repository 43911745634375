import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import React from "react";
import { useHistory } from "react-router";
import styles from "./FAQ.module.css";

export const FAQ = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar color="secondary">
            <IonTitle>Instructions for BrainCells Activities</IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/parents" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div
          style={{
            backgroundImage: `url("/assets/newPortalMsg/whos-the-guest-bg.jpeg")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className={styles.faqContainer}
        >
          <div className={styles.accordianContainer}>
            <IonAccordionGroup multiple={true} value={["first"]}>
              <IonAccordion value="first">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Memory Grid</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. Memory grid is effective way of creating photographic
                      memory in our children.
                    </li>
                    <li>
                      2. By seeing the random pictures in a grid for 5 seconds,
                      child creates a mental image/ photo of it in the mind.
                    </li>
                    <li>
                      3. Then he/ she is supposed to recollect the sequence of
                      the images and drag and drop the pictures in the same
                      sequence.
                    </li>
                    <li>4. Let him/her take their own time.</li>
                    <li>
                      5. For age group 2-4, parents can ask questions and help
                      their children drag and drop pictures.
                    </li>
                    <li>
                      6. This activity needs to be performed on laptop/
                      desktop/tablet/ iPad.
                    </li>
                    <li>
                      7. This activity will progress with more pictures and more
                      grids from level 1 to level 6.
                    </li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="second">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Linking Memory</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>1. Stories create magic for children</li>
                    <li>
                      2. Stories work like a charm in the learning process of a
                      child
                    </li>
                    <li>3. We have always known these facts</li>
                    <li>
                      4. Linking memory activity implements it in a even better
                      way
                    </li>
                    <li>
                      5. Its a great combination of Photographic Ability &
                      Creative Story telling
                    </li>
                    <li>
                      6. In this activity, Cards with pictures are shown to the
                      child in a flashing sequence and a beautiful story is
                      weaved around it
                    </li>
                    <li>7. Once the story is over it is revisited</li>
                    <li>
                      8. Children are now required to recollect the story step
                      by step as it appeared infront of them
                    </li>
                    <li>
                      9. Two pictures are shown and system asks the question.
                      Child is supposed to find the correct link in the story
                    </li>
                    <li>
                      10. Children love this acctivity and recollect the
                      sequence of the story as is
                    </li>
                    <li>
                      11. Again, it takes little bit of practice for child to
                      get used to the activity
                    </li>
                    <li>
                      12. If its for the first time, do not judge the child. He
                      / she will get upto speed automatically
                    </li>
                    <li>
                      13. We will be doing multiple linking memory stories in
                      our course
                    </li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="third">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Mindfulness Eye Training</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. We wonder how can children do meditation or be mindful
                    </li>
                    <li>
                      2. How is mindfulness or meditation achieved? By focusing
                      on one thing at a time, right?
                    </li>
                    <li>3. Simple way to do that is eye training</li>
                    <li>
                      4. When child focuses on just the object moving on the
                      path, their entire focus is on that point.
                    </li>
                    <li>
                      5. & with this simple exercise practice of mindfulness
                      starts.
                    </li>
                    <li>
                      6. Eye training exercises are very beneficial for
                      strengthening the eye muscles as well.
                    </li>
                    <li>
                      7. Make the child sit in front of the screen 2 to 3 feets
                      away
                    </li>
                    <li>
                      8. Eye training exercise will run for 3 times after
                      pressing the butoon "Start the Activity"
                    </li>
                    <li>
                      <img
                        style={{ width: "30rem", margin: "1rem 0" }}
                        src="/assets/faq/faqImg.png"
                        alt="faqImg"
                      />
                    </li>
                    <li>
                      9. Thats it. Do not run the exercise more than once in a
                      day.
                    </li>
                    <li>
                      10. We will be doing multiple different path exercises and
                      with multiple objects.
                    </li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="You Got It">
                <IonItem slot="header" color="secondary">
                  <IonLabel>You Got It</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>1. Again a perfect right brain activity.</li>
                    <li>
                      2. This is little window for the child to comprehend and
                      recognize what he / she has seen in flash cards
                    </li>
                    <li>3. Way of doing revision the right brain way</li>
                    <li>
                      4. Child will see two cards with objects / animals /
                      things etc. which children have seen in Daily Flash
                      Sequences
                    </li>
                    <li>
                      5. System will speak / pronounce name of one of the
                      objects to find it out
                    </li>
                    <li>
                      6. Then Child is supposed to select the object which is
                      asked to find
                    </li>
                    <li>7. Wallah, You Got It</li>
                    <li>
                      8. For age group 1-2 You can simple perform the activity
                      for child. Start the activity. Let the child see the cards
                      and listen to what is being asked. You can repeat the
                      question to the child after the genie. If child points at
                      the image. Thats great. Otherwise its totally fine if
                      child cannot point. Solve the puzzle for the child by
                      cliking on the right image and let the child know.
                    </li>
                    <li>
                      9. It is alright if child does not get it right in first
                      attempt or for first few exercises. Let him/her attempt
                      the the activity.
                    </li>
                    <li>
                      10. Do not repleat the activity more than twice a day.
                    </li>
                    <li>
                      11. After getting used to the activity, children solve
                      this at lightening speeds, which is result of fast
                      comprehension and analytical processing
                    </li>
                    <li>
                      12. Belive me, it works for adults as well. And at the
                      same time it's lot of fun. Give it a try !
                    </li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="Whos the Guest">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Whos the Guest</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. This is a perfect right brain technique activity. A
                      perfect way to build on top of flash card sequence your
                      child is processing everyday.
                    </li>
                    <li>
                      2. It enhances the photographic ability of the child and
                      simultaneously harnesses logical reasoning which enables
                      figuring out differences
                    </li>
                    <li>
                      3. Unlike other puzzles, this activity is done In the
                      Right Way - Through flashing of cards.
                    </li>
                    <li>
                      4. Child will see a set of 3 or 5 flashing cards according
                      to the age group
                    </li>
                    <li>
                      5. Now an extra card will be added to the set and child
                      will be asked to tell which is the newly added card i.e.
                      Who's the Guest?
                    </li>
                    <li>6. This will repeat for multiple sets</li>
                    <li>
                      7. It is alright if child does not get it right in first
                      attempt or for first few exercises. Let him/her attempt
                      the the puzzle.
                    </li>
                    <li>
                      8. Do not repleat the activity more than twice a day.
                    </li>
                    <li>
                      9. After getting used to the activity, children solve this
                      at lightening speeds, which is result of fast analytical
                      processing
                    </li>
                    <li>
                      10. Belive me, it works for adults as well. And at the
                      same time it's lot of fun. Give it a try !
                    </li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="Home Book">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Home Book</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. The best way for child to have their memories stored in
                      one place. And to be able to create the story of lifetime
                    </li>
                    <li>
                      2. Whenever you see the Home-Book under the Exercise, you
                      will be building the story for the child
                    </li>
                    <li>
                      3. You need to answer few questions related to child and
                      his/her surroundings
                    </li>
                    <li>
                      4. These details will appear in the Home Book in a
                      Beautiful Book format
                    </li>
                    <li>5. Here is the sample link to Samar's Home-Book</li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="Mandala">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Mandala</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. Mandala is a unique way and a very effective way of
                      creating photographic memory in our children
                    </li>
                    <li>
                      2. This is also a great way of learning shapes and colors
                    </li>
                    <li>
                      3. Not just for children, but even for adults this is a
                      great Memory Boosting Activity. Parents can also give it a
                      try :)
                    </li>
                    <li>
                      4. For age group 1-3 years - Parents can use the option of
                      and clicking on button Choose from Image OR simply show
                      the mandala card to the child for 5 to 10 seconds and
                      paint the image for the child. Speak out loud about colors
                      and shapes you are painting at the time of activity.
                    </li>
                    <li>
                      5. For age group 3-4 & 4-Plus years -By seeing the
                      symmetrical mandala picture only for 5 seconds, child
                      creates a mental image/ photo of it in the mind & then
                      child is supposed to recollect the colors and color the
                      blank image exactly like original colored one.
                    </li>
                    <li>
                      6. Let him/her take their own time in coloring activity
                    </li>
                    <li>
                      7. This activity needs to be performed on Tablet/ iPad /
                      mobile device for Age group 2-4. As this age group
                      children will be able to touch the screen and fill the
                      colors
                    </li>
                    <li>
                      8. Age 4-Plus can choose Laptop or Tablet/Mobile as per
                      their liking, as this age group can manage mouse or laptop
                      trackpad
                    </li>
                    <li>
                      9. click here as shown below to load the Mandala Activity
                    </li>
                    <li>
                      <img
                        style={{ width: "30rem", margin: "1rem 0" }}
                        src="/assets/faq/faqMandala1.png"
                        alt=""
                      />
                    </li>
                    <li>
                      10. There could be multiple Mandala images present Let the
                      child see the image for 5 seconds & then click on the
                      button - "Paint this Image now"
                    </li>
                    <li>
                      <img
                        style={{ width: "30rem", margin: "1rem 0" }}
                        src="/assets/faq/faqMandala2.png"
                        alt=""
                      />
                    </li>
                    <li>
                      11. Child can select the color from the pallete and start
                      painting the image
                    </li>
                    <li>
                      <img
                        style={{ width: "30rem", margin: "1rem 0" }}
                        src="/assets/faq/faqMandala4.png"
                        alt=""
                      />
                    </li>
                    <li>
                      12. Tell them that they are supposed to choose the color
                      and paint the image like original one Let them take their
                      own time here
                    </li>
                    <li>
                      13. Its not important to color the entire image. If they
                      put the right color in right shape or place, thats the
                      objective.
                    </li>
                    <li>
                      14. Even if they make mistake, its alright Move to next
                      image once they are done
                    </li>

                    <li>
                      <img
                        style={{ width: "30rem", margin: "1rem 0" }}
                        src="/assets/faq/faqMandala3.png"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </IonAccordion>
              <IonAccordion value="Flash Sequence">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Flash Sequence</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. Flash sequence of words(Word-Cards), images(Bit-Cards)
                      and quantities(Dot-Cards) are really powerful tools of
                      Right Brain Education.
                    </li>
                    <li>
                      2. Run the app on a Chrome browser Laptop / Desktop /
                      Tablet for better learning experience for your child.
                      Bigger screen the Better !
                    </li>
                    <li>
                      3. All the Dot cards, Bit cards and word cards needs to be
                      repeated 5-6 times in a week and max twice daily. Total of
                      6 to 12 times in a week
                    </li>
                    <li>
                      4. Use mobile only when Laptop or Desktop is not available
                    </li>
                    <li>
                      5. Keep the device volume on while playing the flash
                      sequence. App speaks the words, quantities and images it
                      shows on the screen.
                    </li>
                    <li>
                      6. Keep the screen 2-3 feet away from the child's eyes.
                    </li>
                    <li>
                      7. If child is in a different mood, you can do the
                      activity later in the day when she / he is happy again.
                    </li>
                    <li>
                      8. Play Dot cards, Bit cards and word cards Flash Sequence
                      minimum 1 time to maximum 2 times in a day.
                    </li>
                    <li>
                      9. Do not test the child to see if he / she has grasped
                      the words / quantities / bits.
                    </li>
                    <li>
                      10. We will have problem solving activity where you will
                      see your child's progress.
                    </li>
                    <li>
                      11. Along with other activities, Flash sequence will be
                      present under the daily Exercise in Left panel. Under
                      Three categories - Bit-Cards, Word-Cards, Dot-Cards
                    </li>
                    <li>
                      12. After clicking on (Word-Cards), images(Bit-Cards) or
                      quantities(Dot-Cards), click on Start the Exercise button
                    </li>
                  </ul>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </div>

          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            We are always there to help you. Call us on +91 84596 28238 if you
            need any specific details
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};
