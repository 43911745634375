import { useEffect } from "react";
import { decodeToken, isExpired } from "react-jwt";
import { useHistory } from "react-router";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../configs/ServerConfig";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import { useUserContext } from "../../store/context/user-context";
import { postHttpData } from "../../utils/http-util";
import CircleLoader from "../../pages/Parents/Subscription/components/CircleLoader";

const Authenticator = () => {
  const subscriptionCtx = useSubscriptionContext();
  const userCtx = useUserContext();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const myJwtToken: any = queryParams.get("t");
  const userId: any = queryParams.get("u");
  const coupon: any = queryParams.get("c");
  const page: any = queryParams.get("p");
  const tab: any = queryParams.get("tab");

  const tokenFromLocalStorage = localStorage.getItem("USER_TOKEN");

  const login = (myJwtToken: any, page: any) => {
    const decodedToken: any = decodeToken(myJwtToken);
    const isTokenExpired = isExpired(myJwtToken);
    if (!isTokenExpired) {
      localStorage.setItem("USER_TOKEN", myJwtToken);
      localStorage.setItem("USER_DATA", JSON.stringify(decodedToken.sub));

      userCtx.loginUser(decodedToken.sub);

      subscriptionCtx.loadSubscriptionContext(decodedToken.sub.id, myJwtToken);
      switch (page) {
        case "dashboard":
          history.push("/dashboard");
          break;
        case "subscription":
          if (coupon) {
            history.push(
              `/parents/subscriptions/coupon/${coupon}${
                tab ? `?tab=${tab}` : ""
              }`
            );
          } else {
            history.push(`/parents/subscriptions${tab ? `?tab=${tab}` : ""}`);
          }
          break;
        case "community":
          history.push("/community");
          break;
        case "rtt-online-demo":
          history.push("/rtt-online?st=demo");
          break;
        case "rtt-online-booked":
          history.push("/rtt-online?st=booked");
          break;
        case "free-downloadable":
          history.push("/free-programs/free_downloadable");
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (myJwtToken) {
        login(myJwtToken, "dashboard");
      }
      if (userId) {
        const jwtToken = await postHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/proxy_login`,
          { userId: userId.toString() }
        );
        if (page) {
          login(jwtToken.jwt, page);
        } else {
          login(jwtToken.jwt, "subscription");
        }
      }
    })();
  }, []);

  return <CircleLoader />;
};

export default Authenticator;
