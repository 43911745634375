import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router";
import { useUserContext } from "../../../store/context/user-context";
import styles from "./ProfileSettings.module.css";
import { IonBackButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import textToSpeech from "../../../utils/textToSpeech";
import axios from "axios";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import ToggleSwitch from "../../../components/UI/ToggleSwitch/ToggleSwitch";
import { useSubscriptionContext } from "../../../store/context/subscription-context";
import EndSubscriptionDialogBox from "../../../components/generalComponents/EndSubscription/EndSubscriptionDialogBox";

export const ProfileSettings = () => {
  const [activeTab, setActiveTab] = useState("PROFILE");
  const userCtx = useUserContext();
  const user = userCtx.user;
  const [myProfileData, setMyProfileData] = useState({
    parentsName: user.name,
    childFirstName: user.childName,
    childDOB: user.childBirthDate,
  });

  const myJwtToken = localStorage.getItem("USER_TOKEN");

  const [playbackSpeed, setPlaybackSpeed] = useState(
    Number(localStorage.getItem("PLAYBACK_SPEED"))
  );

  const [dotCardType, setDotCardType] = useState(
    localStorage.getItem("DOTCARD_TYPE") || "dot"
  );

  const [disableIntro, setDisableIntro] = useState(
    JSON.parse(localStorage.getItem("DISABLE_INTRO") || "false")
  );
  console.log(disableIntro);

  const introDisableHandler = () => {
    console.log("intro disable handle");
    setDisableIntro(true);
    localStorage.setItem("DISABLE_INTRO", "true");
  };
  const introEnableHandler = () => {
    console.log("intro enable handle");
    setDisableIntro(false);
    localStorage.setItem("DISABLE_INTRO", "false");
  };

  useEffect(() => {
    console.log(dotCardType);
    localStorage.setItem("DOTCARD_TYPE", dotCardType);
  }, [dotCardType]);

  useEffect(() => {
    localStorage.setItem("PLAYBACK_SPEED", `${playbackSpeed}`);
  }, [playbackSpeed]);

  const updateProfileHandler = (e: any) => {
    e.preventDefault();
    (async () => {
      try {
        const response = await axios.put(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/user?id=${user.id}`, /// FIXME: change `user` to `users` in production
          {
            name: myProfileData.parentsName,
            childName: myProfileData.childFirstName,
          },
          {
            headers: {
              Authorization: `Bearer ${myJwtToken}`,
            },
          }
        );
        if (response.status === 200) {
          localStorage.setItem(
            "USER_DATA",
            JSON.stringify({
              ...user,
              name: myProfileData.parentsName,
              childName: myProfileData.childFirstName,
              childBirthDate: myProfileData.childDOB,
            })
          );
        }
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    })();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/parents" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundImage: `url("/assets/parentLanding/background-cropped.svg")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className={styles.profileSettings}
        >
          <div className={styles.myProfileSettingsContainer}>
            <form
              className={styles.myProfileForm}
              method="post"
              onSubmit={(e) => updateProfileHandler(e)}
            >
              <div className={styles.formSingleInput}>
                <label htmlFor="inputName" className="col-sm-6 col-form-label">
                  Parents Name
                </label>
                <div className="col-sm-6">
                  <input
                    onChange={(e) =>
                      setMyProfileData({
                        ...myProfileData,
                        parentsName: e.target.value,
                      })
                    }
                    type="text"
                    className="form-control"
                    value={myProfileData.parentsName}
                    id="parentName"
                    placeholder="Parents Name"
                  />
                </div>
              </div>
              <div className={styles.formSingleInput}>
                <label htmlFor="inputEmail" className="col-sm-6 col-form-label">
                  Email
                </label>
                <div className="col-sm-6">
                  <input
                    type="email"
                    className="form-control"
                    value={user.email}
                    id="email"
                    placeholder="email"
                    disabled
                  />
                </div>
              </div>
              <div className={styles.formSingleInput}>
                <label htmlFor="inputName2" className="col-sm-6 col-form-label">
                  Childs First Name
                </label>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className={`col-sm-6 ${styles.childsInputContainer}`}
                >
                  <input
                    onChange={(e) =>
                      setMyProfileData({
                        ...myProfileData,
                        childFirstName: e.target.value,
                      })
                    }
                    type="text"
                    className="form-control"
                    value={myProfileData.childFirstName}
                    name="childName"
                    id="childName"
                    placeholder="Childs Name"
                  />
                  <button
                    onClick={() =>
                      textToSpeech(
                        myProfileData.childFirstName,
                        "ENGLISH",
                        () => {}
                      )
                    }
                    type="button"
                    className={styles.genieBtn}
                  >
                    Genie <i className="fa fa-volume-up" aria-hidden="true" />
                  </button>
                  {/* <button id="cNameBtn" className="btn btn-xs btn-info">
                    Save
                  </button> */}
                </div>
              </div>
              <div className={styles.formSingleInput}>
                <label htmlFor="ageGroup" className="col-sm-6 col-form-label">
                  Childs Age Group when program subscribed
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    value={user.ageGroup}
                    id="ageGroup"
                    placeholder="Age Group"
                    disabled
                  />
                </div>
              </div>
              <div className={styles.formSingleInput}>
                <label htmlFor="inputName2" className="col-sm-6 col-form-label">
                  Childs Date of Birth
                </label>
                <div className={`col-sm-6 ${styles.childsInputContainer}`}>
                  <input
                    onChange={(e) => {
                      setMyProfileData({
                        ...myProfileData,
                        childDOB: e.target.value,
                      });
                    }}
                    type="date"
                    className="form-control"
                    value={myProfileData.childDOB}
                    name="childBirthDate"
                    id="childBirthDate"
                    placeholder="dd-mm-yyyy"
                  />
                  <button
                    type="submit"
                    id="cBDateBtn"
                    className="btn btn-xs btn-info"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className={styles.playbackSpeedContainer}>
                <div style={{ display: "flex" }}>
                  <label
                    htmlFor="inputEmail"
                    className="col-sm-6 col-form-label"
                  >
                    Speed
                  </label>
                  <div
                    className={`col-sm-6 ${styles.playbackRangeSliderContainer}`}
                  >
                    <input
                      onChange={(e) => setPlaybackSpeed(Number(e.target.value))}
                      value={playbackSpeed}
                      type="range"
                      min="0.8"
                      max="1.6"
                      step="0.1"
                      name="rate"
                      id="rate"
                    />
                  </div>
                </div>
                <div className={styles.playbackSpeedBtnContainer}>
                  <button
                    onClick={() => setPlaybackSpeed(1.2)}
                    type="button"
                    className={styles.resetPlaybackBtn}
                  >
                    Reset Default Speed
                  </button>
                  <button
                    onClick={() =>
                      textToSpeech(
                        `Hi ${user.childName}, How are you doing today?, Do you want to listen to some words. Lotus, Tomato, Airplane, Today, One, Two, Three, Four, Thank you.`,
                        "ENGLISH",
                        () => {},
                        playbackSpeed
                      )
                    }
                    type="button"
                    className={styles.hearGenie}
                  >
                    Hear Genie{" "}
                    <i className="fa fa-volume-up" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className={styles.formSingleInput}>
                <label htmlFor="inputName2" className="col-sm-6 col-form-label">
                  Disable Activity Intro
                </label>
                <div className={`col-sm-6 ${styles.childsInputContainer}`}>
                  <ToggleSwitch
                    value={disableIntro}
                    onToggleTrue={introDisableHandler}
                    onToggleFalse={introEnableHandler}
                  />
                </div>
              </div>
              <div className={styles.dotCardTypesContainer}>
                <p className={styles.dotCardTypeTitle}>Dot Card Types</p>

                <div className={styles.dotCardGroup}>
                  <div className={styles.dotCardInputContainer}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="dot"
                      id="dot"
                      checked={dotCardType === "dot"}
                      onClick={() => setDotCardType("dot")}
                    />
                    <label htmlFor="dot" className="form-check-label">
                      <img
                        alt="redDot"
                        width="42px"
                        src="/assets/profileSettings/redDot.png"
                      />
                      Red Dot Cards (Random Placement) ideal for Age group 0-1,
                      1-2, 2-4
                    </label>
                  </div>
                  <div className={styles.dotCardInputContainer}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="dot"
                      id="dot_teddy"
                      checked={dotCardType === "dot_teddy"}
                      onChange={() => setDotCardType("dot_teddy")}
                    />
                    <label htmlFor="dot_teddy" className="form-check-label">
                      <img
                        alt="teddy"
                        width="45px"
                        src="/assets/profileSettings/teddy.png"
                      />
                      Teddy Dot Cards (Random Placement) ideal for Age group 2-4
                      &amp; 4-Plus
                    </label>
                  </div>
                  <div className={styles.dotCardInputContainer}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="dot"
                      id="dot_doll"
                      checked={dotCardType === "dot_doll"}
                      onChange={() => setDotCardType("dot_doll")}
                    />
                    <label htmlFor="dot_doll" className="form-check-label">
                      <img
                        alt="doll"
                        width="45px"
                        src="/assets/profileSettings/doll.png"
                      />
                      Doll Dot Cards (structured Placement) ideal for Age group
                      4-Plus
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.dotCardInputContainer}>
                <EndSubscriptionDialogBox location="profile-settings" />
              </div>
              <hr />
              <div className={styles.logoutBtnContainer}>
                <div className={`col-sm-6 ${styles.childsInputContainer}`}>
                  <button
                    onClick={() => userCtx.logoutUser()}
                    className={`${styles.logoutBtn}`}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
