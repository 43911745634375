import { useEffect, useState } from "react";
import {
  PATH_MIDDLE,
  PATH_START,
  TROPHY_BOX_CLOSED,
  TROPHY_BUTTON,
} from "../../constants/Program/program-constants";
import { Excercise } from "./Excercise";
import styles from "./Path.module.css";
import Confetti from "react-confetti";
import { IonRouterLink } from "@ionic/react";

const Path = ({
  week,
  currentProgramDay,
  index,
  currentExercise,
  programTypeParam,
}: any) => {
  const pathSrc = index === 0 ? PATH_START : PATH_MIDDLE;
  const animate = week.some(
    (excercise: any) => excercise.programDay === currentProgramDay
  );
  const openTrophyBox = week.filter(
    (exercise: any) =>
      exercise.showTrophy === true && exercise.completionStatus === "closed"
  );
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const showAnimationModal = () => {
    setShowAnimation(true);
  };
  return (
    <div className={styles["path"]}>
      <img src={pathSrc} alt="Path" className={styles["path-img"]} />
      <div>
        {week.map((exercise: any, index: any) => {
          return (
            <Excercise
              key={index}
              excercise={exercise}
              index={index}
              animate={animate}
              currentExercise={currentExercise}
              exerciseDisplayName={exercise.exerciseDisplayName}
              programTypeParam={programTypeParam}
              showAnimationModal={showAnimationModal}
            />
          );
        })}
      </div>
      {openTrophyBox.length > 0 && showAnimation && (
        <TrophyCaseAnimation trophyExerciseData={openTrophyBox[0]} />
      )}
      {/* {true && <TrophyCaseAnimation />} */}
    </div>
  );
};

const TrophyCaseAnimation = ({ trophyExerciseData }: any) => {
  const { exerciseId } = trophyExerciseData;
  const [startAnimation, setStartAnimation] = useState<boolean>(false);
  const [showAward, setShowAward] = useState<boolean>(false);
  const [showAnimationCount, setShowAnimationCount] = useState<any>(0);
  const fetchImages = (arr: any, funcToRunAfterImageLoads: any = () => {}) => {
    let totalImages = arr.length;
    let fetchedImages = 0;
    arr.forEach((imgLink: any) => {
      const img = new Image();
      img.src = imgLink;
      img.onload = () => {
        fetchedImages++;
        if (totalImages === fetchedImages) {
          funcToRunAfterImageLoads();
        }
      };
    });
  };

  const showBoxOpenAnimation = () => {
    setTimeout(() => {
      setStartAnimation(true);
    }, 1000);
    setTimeout(() => {
      setShowAward(true);
    }, 1750);
  };

  useEffect(() => {
    fetchImages([TROPHY_BOX_CLOSED, TROPHY_BUTTON]);
    setTimeout(() => {
      showBoxOpenAnimation();
    }, 3000);
  }, []);

  return (
    <div className={styles["animation_container"]}>
      {showAward && (
        <Confetti
          // confettiSource={{
          //   x: 0.5 * window.innerWidth,
          //   y: 0.5 * window.innerHeight,
          //   w: 0,
          //   h: 20,
          // }}
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={250}
          recycle={false}
        />
      )}
      <div
        className={`${styles["content_container"]} animate__animated animate__slideInUp`}
      >
        <div className={styles["content_wrapper"]}>
          <div className={styles["animation_image"]}>
            {/* <img
          className={`${styles["animation_image_img"]} animate__animated animate__bounce animate__zoomIn`}
          src="/assets/program/trophy-gif.gif"
          alt="Trophy Box"
        /> */}
            {showAward ? (
              <img
                className={`${styles["animation_image_img"]} ${
                  showAward ? "animate__animated animate__jackInTheBox" : ""
                }  `}
                alt=""
                src={TROPHY_BUTTON}
              />
            ) : (
              <img
                className={`${styles["animation_image_img"]} ${
                  startAnimation && "animate__animated animate__zoomOut"
                }  `}
                alt=""
                src={TROPHY_BOX_CLOSED}
              />
            )}
          </div>
          {showAward && (
            <IonRouterLink href={`/excercise/BrainCells/${exerciseId}`}>
              <button
                className={`${
                  styles["reward_button"]
                } ${"animate__animated animate__flipInX"}`}
              >
                Go to bonus exercise
              </button>
            </IonRouterLink>
          )}
        </div>
        <div className={styles["background_blur"]}></div>
      </div>
    </div>
  );
};

export { Path };
