import React, { useContext, useState } from "react";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../configs/ServerConfig";
import { getHttpData } from "../../utils/http-util";

const SubscriptionContext = React.createContext({
  activeSubscriptions: Array(),
  registerSub: (subObjList: any) => {},
  loadSubscriptionContext: (userId: number, token: string) => {},
});

export const SubscriptionContextProvider = (props: any) => {
  const [activeSubscriptions, setActiveSubscriptions] = useState(
    JSON.parse(localStorage.getItem("activeSubscriptions") || "[]")
  );

  const loadSubscriptionContext = async (userId: number, token: string) => {
    try {
      const response: any = await getHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/subscription?userId=${userId}`
      );
      registerSubscription(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const registerSubscription = (subObjList: any) => {
    const subList = JSON.stringify(subObjList);
    if (subList) {
      localStorage.setItem("activeSubscriptions", subList);
      setActiveSubscriptions(subObjList);
    }
  };

  const addSubscription = (subObj: any) => {
    const newSubList = [...activeSubscriptions, subObj];
    setActiveSubscriptions(newSubList);
    localStorage.setItem("activeSubscriptions", JSON.stringify(newSubList));
  };

  const subscriptionContext = {
    activeSubscriptions: activeSubscriptions,
    registerSub: registerSubscription,
    loadSubscriptionContext: loadSubscriptionContext,
  };
  return (
    <SubscriptionContext.Provider value={subscriptionContext}>
      {props.children}
    </SubscriptionContext.Provider>
  );
};

const useSubscriptionContext = () => useContext(SubscriptionContext);

export { useSubscriptionContext };
