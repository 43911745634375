import styles from "./CommunityFooter.module.css";

const CommunityFooter = ({ activeTab }: any) => {
  const communityTabButtonClassName =
    activeTab === "community"
      ? "activeTab"
      : "community_footer__my_canvas_button";

  const mycanvasTabButtonClassName =
    activeTab === "mycanvas"
      ? "activeTab"
      : "community_footer__my_canvas_button";

  const progressbarLink = JSON.parse(
    sessionStorage.getItem("PROGRESSBAR_LINK") || "null"
  );
  console.log(progressbarLink);
  const startActivitiesLink =
    progressbarLink === null ? "/dashboard" : progressbarLink;

  console.log(startActivitiesLink);

  return (
    <div className={styles["community_footer"]}>
      <div className={styles["community_footer__my_canvas_button_container"]}>
        <a href="/community" className={styles[communityTabButtonClassName]}>
          <i className="fas fa-users fa-lg"></i>
          <span>Community</span>
          <span>Canvas</span>
        </a>
      </div>
      <a
        href={startActivitiesLink}
        className={styles["community_footer__start_activities_button"]}
      >
        <p>Start</p>
        <p>BrainCells</p>
        <p>Activities</p>
      </a>
      <div className={styles["community_footer__my_canvas_button_container"]}>
        <a
          href="/community/my-canvas"
          className={styles[mycanvasTabButtonClassName]}
        >
          <i className="fas fa-child fa-lg"></i>
          My Canvas
        </a>
      </div>
    </div>
  );
};

const StartActivitiesButton = () => {
  return (
    <a href="/dashboard" className={styles["start_activities_button"]}>
      <p>Start</p>
      <p>BrainCells</p>
      <p>Activities</p>
    </a>
  );
};

export default CommunityFooter;
