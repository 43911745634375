import React, { useState, useEffect } from "react";
import styles from "./CountDown.module.css";
import moment from "moment";
import { useUserContext } from "../../../store/context/user-context";
import { useCountdown } from "../../../hooks/useCountdown";

const Countdown = ({ func = () => {}, showUntilExpires = true }: any) => {
  const { minTwo, secOne, secTwo, startCount } = useCountdown(func);

  useEffect(() => {
    startCount("countdown");
  }, []);

  return (
    <div className={styles["countdown_container"]}>
      <div className={styles["countdown_wrapper"]}>
        <div className={styles["countdown"]}>
          <div className={styles["countdown_header"]}>MINUTES</div>
          <div className={styles["countdown_body"]}>
            <div className={styles["countdown_body_sub"]}>0</div>
            <div className={styles["countdown_body_sub"]}>{minTwo}</div>
          </div>
        </div>
        <div className={styles["countdown"]}>
          <div className={styles["countdown_header"]}>SECONDS</div>
          <div className={styles["countdown_body"]}>
            <div className={styles["countdown_body_sub"]}>{secOne}</div>
            <div className={styles["countdown_body_sub"]}>{secTwo}</div>
          </div>
        </div>
      </div>
      {showUntilExpires && (
        <p className={styles["countdown_text"]}>Until Coupon Expires</p>
      )}{" "}
    </div>
  );
};

export default Countdown;
