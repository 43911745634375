import { useEffect } from "react";
import { useCountdown } from "../../../hooks/useCountdown";
import styles from "./CountDownSmall.module.css";

const CountdownSmall = ({ modalData, func = () => {} }: any) => {
  const setShowModalTrue = modalData;
  const { minTwo, secOne, secTwo, startCount } = useCountdown(func);

  useEffect(() => {
    startCount("countdown small countdown");
  }, []);

  return (
    <div
      onClick={() => setShowModalTrue()}
      className={styles["countdown_wrapper"]}
    >
      <div className={styles["countdown"]}>
        <div className={styles["countdown_header"]}>MINUTES</div>
        <div className={styles["countdown_body"]}>
          <div className={styles["countdown_body_sub"]}>0</div>
          <div className={styles["countdown_body_sub"]}>{minTwo}</div>
        </div>
      </div>
      <div className={styles["countdown"]}>
        <div className={styles["countdown_header"]}>SECONDS</div>
        <div className={styles["countdown_body"]}>
          <div className={styles["countdown_body_sub"]}>{secOne}</div>
          <div className={styles["countdown_body_sub"]}>{secTwo}</div>
        </div>
      </div>
    </div>
  );
};

export default CountdownSmall;
