import React, { useContext, useEffect, useState } from "react";
import { cloudinaryAvatarLink } from "../../constants/Services/services-constants";
import { checkImage } from "../../utils/general-utils";

const UserContext = React.createContext({
  user: {
    email: "",
    createdAt: "",
    status: "",
    childBirthDate: "",
    ageGroup: "",
    cohort: "",
    name: "",
    phone: "",
    subscriptionStartDate: null,
    last_login: "",
    id: 166,
    childName: "",
    subscriptionEndDate: null,
  },
  isAvatarSelected: false,
  isUserLoggedIn: false,
  loginUser: (userObj: any) => {},
  updateUserData: (userObj: any) => {},
  logoutUser: () => {},
});
export const UserContextProvider = (props: any) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    localStorage.getItem("USER_TOKEN") ? true : false
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("USER_DATA") || "{}")
  );
  const [isAvatarSelected, setIsAvatarSelected] = useState(false);

  const avatarLink = `${cloudinaryAvatarLink}${user.id}`;

  useEffect(() => {
    checkImage(
      avatarLink,
      () => setIsAvatarSelected(true),
      () => setIsAvatarSelected(false)
    );
  }, []);

  const updateUserData = (userObj: any) => {
    setUser(userObj);
  };

  const loginUser = (userObj: any) => {
    setIsUserLoggedIn(true);
    setUser(userObj);
  };

  const logoutUser = () => {
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("USER_DATA");
    localStorage.removeItem("activeSubscriptions");
    setIsUserLoggedIn(false);
    setUser({});
  };

  const userContext = {
    //add user states here.
    user: user,
    setUser: setUser,
    isUserLoggedIn: isUserLoggedIn,
    isAvatarSelected: isAvatarSelected,
    setIsUserLoggedIn: setIsUserLoggedIn,
    loginUser: loginUser,
    logoutUser: logoutUser,
    updateUserData: updateUserData,
  };
  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

export { useUserContext };
