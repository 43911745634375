import { useState } from "react";
import styles from "./DropDown.module.css";
import { v4 as uuid } from "uuid";
import DropdownCard from "./DropdownCard/DropdownCard";
import { IonButton } from "@ionic/react";
import { detectIsMobileDevice } from "../../../../utils/general-utils";
import RadioBtnCardPopup from "./RadioBtnCardPopup/RadioBtnCardPopup";

export function DropDown({
  selectedProfessionName,
  setSelectedProfessionName,
}: any) {
  const [toggle, setToggle] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const [selectedCard, setSelectedCard] = useState<any>();

  const selectCardHandler = (card: any) => {
    setSelectedCard(card);
  };

  const profession = [
    {
      id: uuid(),
      title: "All Rounder",
      strengths: "All Round Strengths",
      characteristics: ["All Round Characteristics"],
      bgColor: "#F2A3CA",
    },
    {
      id: uuid(),
      title: "Spatial Intelligence",
      strengths: "Visual and Spatial Judgement",
      characteristics: ["Draws for fun", "Good at puzzles"],
      bgColor: "#FE9BB0",
    },
    {
      id: uuid(),
      title: "Bodily Kinesthetic Intelligence",
      strengths: "Physical Movement, Motor Control",
      characteristics: ["Skilled at Sports", "Excellent Physical Coordination"],
      bgColor: "#05D6FF",
    },
    {
      id: uuid(),
      title: "Musical Intelligence",
      strengths: "Rythm and Music",
      characteristics: [
        "Appreciation for Music",
        "Thinks in sounds and patterns",
      ],
      bgColor: "#6CDF46",
    },
    {
      id: uuid(),
      title: "Linguistic Intelligence",
      strengths: "Words, Language and Writing",
      characteristics: [
        "Enjoys reading and writing",
        "Good at public speaking",
      ],
      bgColor: "#BF8FFD",
    },
    {
      id: uuid(),
      title: "Logical Mathematical Skills",
      strengths: "Analyzing Problems, Mathematical Operations",
      characteristics: [
        "Fast Problem Solver",
        "Understands complex computations",
      ],
      bgColor: "#75DED0",
    },
    {
      id: uuid(),
      title: "Interpersonal Intelligence",
      strengths: "Understanding and relating to others",
      characteristics: [
        "Strong emotional intelligence skills",
        "Creates healthy relationships",
      ],
      bgColor: "#FDD36F",
    },
    {
      id: uuid(),
      title: "Intrapersonal Intelligence",
      strengths: "Introspection and self reflection",
      characteristics: [
        "Understands one's own strengths, weaknesses",
        "Highly self-aware",
      ],
      bgColor: "#FFB5AA",
    },
    {
      id: uuid(),
      title: "Naturalistic Intelligence",
      strengths: "Seeing patterns and relationships to nature",
      characteristics: [
        "Interested in areas like botany, biology, zoology",
        "Appreciation for nature",
      ],
      bgColor: "#F2A3CA",
    },
  ];

  const toggleHandler = () => {
    setToggle((prev) => !prev);
  };

  const professionHandler = (selectedProfession: string) => {
    setSelectedProfessionName(selectedProfession);
    setToggle(false);
    console.log("selected");
    setIsOptionSelected(true);
  };

  const [popup, setPopup] = useState({
    isPopupOpen: false,
    popupData: {},
  });

  const closePopupHandler = () => {
    setPopup({ isPopupOpen: false, popupData: {} });
  };

  return (
    <div
      className={`${styles["dropDown"]} animate__animated animate__slideInRight`}
    >
      {popup.isPopupOpen && (
        <RadioBtnCardPopup
          data={popup.popupData}
          closePopupHandler={closePopupHandler}
        />
      )}
      <p className={`${styles["modalQuestions"]}`}>
        In which of these area do you want your child to be exceptional?
      </p>
      {/* <IonButton onClick={() => professionHandler("All Rounder")}>
        All Rounder
      </IonButton> */}
      <div className={styles["radio_cards_container"]}>
        {profession.map((obj, index: number) => {
          return (
            <div key={index} className={styles["radio_btn_container"]}>
              <input
                onChange={(e) => {
                  console.log(obj.title);
                  selectCardHandler(obj);
                  professionHandler(obj.title);
                }}
                id={`${index}`}
                type="radio"
                name="profession"
                value={obj.title}
              />
              <label htmlFor={`${index}`}> {obj.title} </label>
              <div
                onClick={() => {
                  setPopup({ isPopupOpen: true, popupData: obj });
                }}
                className={styles["info_icon"]}
              >
                <i className="fas fa-info-circle"></i>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className={styles["cards_container"]}>
          {profession.map((obj) => {
            return (
              <DropdownCard
                data={obj}
                isCardSelected={
                  selectedCard === "All Rounder"
                    ? true
                    : selectedCard?.title === obj.title
                }
                selectCardHandler={selectCardHandler}
                professionHandler={professionHandler}
              />
            );
          })}
        </div> */}
    </div>
  );
}
