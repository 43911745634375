import { useCommunityContext } from "../../../../store/context/community-context";
import styles from "./ProfilePictureRound.module.css";
import { useUserContext } from "../../../../store/context/user-context";

const ProfilePictureRound = ({ profilePictureLink, userId }: any) => {
  const communityCtx = useCommunityContext();
  const { avatarDispatch, avatarState } = communityCtx;

  const userCtx = useUserContext();
  const { user, isAvatarSelected } = userCtx;

  return (
    <img
      className={styles["profile_picture"]}
      src={`${profilePictureLink}`}
      alt="user-avatar"
      onError={(e: any) => {
        e.target.src = "/assets/community/people.png";
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (!isAvatarSelected && user.id === userId) {
          avatarDispatch({ type: "SHOW_AVATAR_SELECTION" });
        }
      }}
    />
  );
};

export default ProfilePictureRound;
