import ReactConfetti from "react-confetti";
import styles from "./CouponSuccessModal.module.css";

const CouponSuccessModal = ({
  hideCoupon,
  couponData,
  couponData: { couponCode, couponType, couponAmount },
}: any) => {


  
  return (
    <div onClick={hideCoupon} className={styles["coupon_applied_container"]}>
      <ReactConfetti
        height={window.innerHeight}
        width={window.innerWidth}
        confettiSource={{
          x: 0.5 * window.innerWidth,
          y: 0.5 * window.innerHeight,
          w: 0,
          h: 30,
        }}
        recycle={false}
      />
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles["coupon_wrapper"]}
      >
        <img
          src="./assets/parentLanding/discount2.png"
          alt="giftbox"
          className={styles["giftbox_image"]}
        ></img>
        <p className={styles["coupon_name"]}>"{couponCode}" APPLIED</p>
        <p className={styles["coupon_amount"]}>
          {couponType === "fixed" ? `₹${couponAmount}/-` : `${couponAmount}%`}
        </p>
        <p className={styles["coupon_heading"]}>Savings with this coupon</p>
        <div className={styles["bar"]}></div>
        <p className={styles["coupon_content"]}>
          YAY! Your coupon is successfully applied
        </p>
        <hr />
        <button onClick={hideCoupon} className={styles["button"]}>
          WooHoo!
        </button>
      </div>
    </div>
  );
};

export default CouponSuccessModal;
