import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Path } from "../../components/Program/Path";
import styles from "./Program.module.css";
import { useUserContext } from "../../store/context/user-context";
import { ZERO_TO_ONE_AVAILABLE_DAYS } from "../../constants/Program/program-constants";
import {
  calculateInBetweenDates,
  diffBetweenToday,
  differenceBetweenDays,
  isPassedTodayDate,
  isSameDay,
} from "../../utils/date-utils";
import { getExerciseData, programAttendance } from "../../utils/service-utils";
import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { detectIsMobileDevice } from "../../utils/general-utils";
import Loader from "../../components/UI/Loader/Loader";
import NavigationDot from "./components/NavigationDot";
import { ScrollLeft, ScrollRight } from "./components/ScrollButtons";
import { generateZeroToOnePath } from "../../utils/activity-utils";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import useIsZeroTwoToOne from "../../hooks/useIsZeroToOne";
import moment from "moment";
import { BACK_BUTTON } from "../../constants/Program/program-constants";

const ProgramZeroToOne = () => {
  // ? TESTING
  // const {
  //   user: { ageGroup, id },
  // } = useUserContext();
  // const childBirthDate = "01-11-2022";
  // const activeSubscriptions = subData;
  // const todaysDate: any = "2023-03-20";
  //CONTEXT
  const {
    user: { childBirthDate, ageGroup, id },
  } = useUserContext();
  const { activeSubscriptions } = useSubscriptionContext();
  //STATE
  const [isLoaded, setIsLoaded] = useState(false);
  const [program, setProgram] = useState<any>([]);
  const [currentProgramDay, setCurrentProgramDay] = useState();
  const [totalExercise, setTotalExercise] = useState(0);
  //REFS
  const finalProgramJsonRef = useRef<any>({});
  const programRef = useRef<any>(null);
  //HOOKS
  const { programName = "BrainCells" }: any = useParams<any>();
  const programHandle = useFullScreenHandle();
  //CUSTOM HOOKS
  const { isZeroToOneTrial, isZeroToOneV2 } = useIsZeroTwoToOne();
  //CONSTANTS
  const todaysDate: any = moment().format("YYYY-MM-DD");
  const showArrowNavigation = program.length > 1;
  const isMobile = detectIsMobileDevice();
  const brainCellsSubscriptions = isZeroToOneTrial
    ? activeSubscriptions.filter((program) => {
        return (
          program.programName === "BrainCells" &&
          program.programType === "TRIAL" &&
          program.version === "v2"
        );
      })
    : activeSubscriptions.filter((program) => {
        return (
          program.programName === "BrainCells" &&
          program.programType !== "TRIAL" &&
          program.version === "v2"
        );
      });
  const addToFinalProgramJson = (
    startProgramDay: any,
    totalDays: any,
    programData: any
  ) => {
    startProgramDay++;
    if (isZeroToOneTrial) {
      for (let i = startProgramDay; i <= startProgramDay + 3; i++) {
        if (programData[i]) {
          finalProgramJsonRef.current[i] = programData[i];
        } else {
          continue;
        }
      }
    } else {
      for (let i = startProgramDay; i <= totalDays; i++) {
        if (programData[i]) {
          finalProgramJsonRef.current[i] = programData[i];
        } else {
          continue;
        }
      }
    }
  };

  const enterFullScreen = () => {
    if (!programHandle.active && isMobile && !document.fullscreenElement) {
      programHandle.enter();
    }
  };

  const scrollToLast = () => {
    programRef.current.scrollTo({
      left: 10000000,
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    programAttendance(id, "BrainCells");
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoaded(false);
      let currentProgramDayAddition = 0;
      let currentPrograms: any = [];
      const { programExercise, userExercise }: any = await getExerciseData(
        "",
        ageGroup,
        programName,
        id,
        isZeroToOneV2
      );
      brainCellsSubscriptions.forEach((program: any) => {
        const startProgramDay = Number(program.startProgramDay);
        const diffBetweenEndAndStart =
          startProgramDay +
          differenceBetweenDays(program.endDate, program.startDate);
        if (isPassedTodayDate(program.endDate, todaysDate)) {
          // console.log("program passed");
          addToFinalProgramJson(
            startProgramDay,
            diffBetweenEndAndStart,
            programExercise
          );
          currentProgramDayAddition += differenceBetweenDays(
            program.endDate,
            program.startDate
          );
        } else if (
          calculateInBetweenDates(
            program.startDate,
            program.endDate,
            todaysDate
          )
        ) {
          // console.log("program in between");
          addToFinalProgramJson(
            startProgramDay,
            diffBetweenEndAndStart,
            programExercise
          );
          currentPrograms.push(program);
        } else if (isSameDay(program.startDate, "DD-MM-YYYY", todaysDate)) {
          // console.log("program same day");
          addToFinalProgramJson(
            startProgramDay,
            diffBetweenEndAndStart,
            programExercise
          );
          currentPrograms.push(program);
        } else if (isSameDay(program.endDate, "DD-MM-YYYY", todaysDate)) {
          // console.log("program same day ended");
          addToFinalProgramJson(
            startProgramDay,
            diffBetweenEndAndStart,
            programExercise
          );
          currentPrograms.push(program);
        } else {
          // console.log("else condition");
        }
      });

      const saveToLocalStorageValidExercise = (
        arr: any,
        currentExercise: any
      ) => {
        localStorage.setItem(
          ZERO_TO_ONE_AVAILABLE_DAYS,
          JSON.stringify({
            lastExercise: currentExercise,
            availableDays: arr.map(
              (day: any) => finalProgramJsonRef.current[day]["exercise-number"]
            ),
          })
        );
      };

      const actualCurrentProgramDay = Math.abs(
        Number(diffBetweenToday(childBirthDate, todaysDate, "DD/MM/YYYY"))
      );

      // const actualCurrentProgramDay = 370;
      const currentProgramDay: any =
        actualCurrentProgramDay <= 363 ? actualCurrentProgramDay + 1 : 364;
      setCurrentProgramDay(currentProgramDay);
      const daysArr = Object.keys(finalProgramJsonRef.current);
      const zeroToOneNavigationArray =
        Number(daysArr[daysArr.length - 1]) >= Number(currentProgramDay)
          ? daysArr.slice(0, daysArr.indexOf(`${currentProgramDay}`) + 1)
          : daysArr;
      saveToLocalStorageValidExercise(
        zeroToOneNavigationArray,
        programExercise[currentProgramDay]["exercise-number"]
      );

      const programData: any = generateZeroToOnePath(
        daysArr,
        programName,
        userExercise,
        programExercise,
        currentProgramDay,
        finalProgramJsonRef
      );

      const programStructure = [];
      let tempArr: any = [];
      //divide the days into weeks of 7
      let exerciseCount = 1;
      //? bcaCount and ocaCount are used to get the correct json for BitCardActivity and OtherCardActivity that are DotCardActivity, WordCardActivity, and NumberCardActivity
      let bcaCount = 1;
      let ocaCount = 1;

      for (let i = 0; i < programData.length; i++) {
        let bca: any = {};
        let oca: any = {};
        if (programData[i]["exercise-number"] >= 127) {
          bca.bcaCount = bcaCount++;
        }
        if (programData[i]["exercise-number"] >= 79) {
          oca.ocaCount = ocaCount++;
        }
        tempArr.push({
          ...programData[i],
          activityShowNumber: programData[i].holiday ? "" : exerciseCount++,
          programDayCount: i + 1,
          ...bca,
          ...oca,
        });
        if (tempArr.length === 7) {
          if (programData[i + 1]?.trophy) {
            tempArr.push(programData[i + 1]);
            i++;
          }
          programStructure.push(tempArr);
          tempArr = [];
        }
      }

      if (tempArr.length !== 0) {
        programStructure.push(tempArr);
      }
      setProgram(programStructure);
      setTotalExercise(finalProgramJsonRef.current.length);
      setTimeout(() => {
        setIsLoaded(true);
      }, 1000);
      scrollToLast();
    })();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <FullScreen handle={programHandle}>
          <div
            onClick={enterFullScreen}
            className={styles["program-page"]}
            ref={programRef}
          >
            <Loader removeLoader={isLoaded} />
            <>
              <NavigationDot
                programRef={programRef}
                unlockedExc={totalExercise}
              />
              {showArrowNavigation && (
                <>
                  <ScrollLeft programRef={programRef} />
                  <ScrollRight programRef={programRef} />
                </>
              )}
              {program.map((week: any, index: any) => {
                return (
                  <Path
                    key={index}
                    week={week}
                    currentProgramDay={currentProgramDay}
                    index={index}
                    currentExercise={{}}
                    programTypeParam={false}
                  />
                );
              })}
            </>

            <IonRouterLink href={`/dashboard`}>
              <div
                style={{
                  position: "fixed",
                  top: "1rem",
                  right: "2rem",
                  width: "6vw",
                  cursor: "pointer",
                }}
              >
                <img src={BACK_BUTTON} alt="Go Back" />
              </div>
            </IonRouterLink>
          </div>
        </FullScreen>
      </IonContent>
    </IonPage>
  );
};

export default ProgramZeroToOne;
