import moment from "moment";
import { useSubscriptionContext } from "../../../../store/context/subscription-context";
import { getCountry, getFinalPrice } from "../../../../utils/payment-utils";
import styles from "./PaymentCard.module.css";
const MINIMUM_PRICE = 0;
const PricingCard = ({
  onPaymentClick,
  mostPopular,
  programData,
  paymentDetails,
  showDecimal,
}: any) => {
  const { activeSubscriptions }: any = useSubscriptionContext();

  const sellingPrice: any = showDecimal
    ? Number(programData?.price).toFixed(0)
    : Number(programData?.price).toFixed(2);

  const isSubscribed = activeSubscriptions.some((sub: any) => {
    return (
      sub.programName === programData.name &&
      sub.programType === "GENIUS" &&
      sub.status === "ACTIVE"
    );
  });
  const couponAppliedPrice = getFinalPrice(sellingPrice, paymentDetails);
  const price =
    couponAppliedPrice <= MINIMUM_PRICE ? MINIMUM_PRICE : couponAppliedPrice;
  const discountAmount = sellingPrice - price;
  return mostPopular ? (
    <div className={styles["pricing_card_popular"]}>
      <div className={styles["popular_tag"]}>Most Popular</div>
      <div className={styles["popular_card_inner_wrapper"]}>
        <div className={styles["pricing_head"]}>
          <h1 className={styles["title"]}>{programData.name}</h1>
          <h4 className={styles["subtitle"]}>{programData.description}</h4>
          <div className={styles["price"]}>
            <span className={styles["strikeout_price"]}>
              <i
                className={
                  getCountry() !== "India"
                    ? "fas fa-dollar-sign"
                    : "fas fa-rupee-sign"
                }
              ></i>
              {Number(programData?.originalPrice).toFixed(0)}
            </span>
            <span>
              <i
                className={
                  getCountry() !== "India"
                    ? "fas fa-dollar-sign"
                    : "fas fa-rupee-sign"
                }
              ></i>
              {price}
            </span>
          </div>
        </div>
        <div className={styles["pricing_body"]}>
          <button
            onClick={(e) =>
              onPaymentClick(e, programData, price, discountAmount)
            }
            className={styles["price_button"]}
          >
            Subscribe Now
          </button>
          <div className={styles["points_wrapper"]}>
            {programData.specifications.split(",").map((item: any) => {
              return (
                <div key={item} className={styles["point_container"]}>
                  <i className="fas fa-check-circle"></i>
                  <p className={styles["pricing_text"]}>{item}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles["pricing_foot"]}></div>
      </div>
    </div>
  ) : (
    <div className={styles["pricing_card"]}>
      <div className={styles["card_inner_wrapper"]}>
        <div className={styles["pricing_head"]}>
          <h1 className={styles["title"]}>{programData.name}</h1>
          <h4 className={styles["subtitle"]}>{programData.description}</h4>
          <div className={styles["price"]}>
            <span className={styles["strikeout_price"]}>
              <i
                className={
                  getCountry() !== "India"
                    ? "fas fa-dollar-sign"
                    : "fas fa-rupee-sign"
                }
              ></i>
              {showDecimal
                ? Number(programData?.originalPrice).toFixed(0)
                : Number(programData?.originalPrice).toFixed(2)}
            </span>
            <span
              style={{
                textDecoration: paymentDetails.isCouponApplied
                  ? "line-through"
                  : "",
              }}
            >
              <i
                className={
                  getCountry() !== "India"
                    ? "fas fa-dollar-sign"
                    : "fas fa-rupee-sign"
                }
              ></i>
              {sellingPrice}
            </span>
            {paymentDetails.isCouponApplied && (
              <span>
                <i
                  className={
                    getCountry() !== "India"
                      ? "fas fa-dollar-sign"
                      : "fas fa-rupee-sign"
                  }
                ></i>
                {price}
              </span>
            )}
          </div>
        </div>
        <div className={styles["pricing_body"]}>
          <button
            disabled={isSubscribed}
            onClick={(e) =>
              onPaymentClick(e, programData, price, discountAmount)
            }
            className={styles["price_button"]}
          >
            {isSubscribed ? "Already Subscribed" : "Subscribe Now"}
          </button>
          <div className={styles["points_wrapper"]}>
            {programData.specifications.split(",").map((item: any) => {
              return (
                <div key={item} className={styles["point_container"]}>
                  <i className="fas fa-check-circle"></i>
                  <p className={styles["pricing_text"]}>{item}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles["pricing_foot"]}></div>
      </div>
    </div>
  );
};

const BrainCellsPricingCards = ({
  onPaymentClick,
  programData,
  crrId,
  paymentDetails,
  ageGroup,
  showDecimal,
}: any) => {
  const { activeSubscriptions }: any = useSubscriptionContext();
  const braincellsActiveSub = activeSubscriptions.filter(
    (obj: any) => obj.status === "ACTIVE" && obj.programName === "BrainCells"
  )[0];

  const today = new Date();
  const todayDatetime = moment(today).format("YYYY-MM-DD");

  const sellingPrice: any = showDecimal
    ? Number(programData[crrId]?.price).toFixed(0)
    : Number(programData[crrId]?.price).toFixed(2);

  const sellingPackagePrice: any = showDecimal
    ? Number(programData[crrId]?.packagePrice).toFixed(0)
    : Number(programData[crrId]?.packagePrice).toFixed(2);

  const sellingBoxPrice: any = showDecimal
    ? Number(programData[crrId]?.boxPrice).toFixed(0)
    : Number(programData[crrId]?.boxPrice).toFixed(2);

  const braincellsEndDate = moment(
    braincellsActiveSub?.endDate,
    "DD-MM-YYYY"
  ).format("YYYY-MM-DD");

  const isBraincellsSubExpired =
    moment(todayDatetime).isAfter(braincellsEndDate);

  const couponAppliedPrice = getFinalPrice(sellingPrice, paymentDetails);
  const couponAppliedPackagePrice = getFinalPrice(
    showDecimal
      ? Number(programData[crrId]?.packagePrice).toFixed(0)
      : Number(programData[crrId]?.packagePrice).toFixed(2),
    paymentDetails
  );
  const couponAppliedBoxPrice = getFinalPrice(
    showDecimal
      ? Number(programData[crrId]?.boxPrice).toFixed(0)
      : Number(programData[crrId]?.boxPrice).toFixed(2),
    paymentDetails
  );

  const price =
    couponAppliedPrice <= MINIMUM_PRICE ? MINIMUM_PRICE : couponAppliedPrice;
  const packagePrice =
    couponAppliedPackagePrice <= MINIMUM_PRICE
      ? MINIMUM_PRICE
      : couponAppliedPackagePrice;
  const boxPrice =
    couponAppliedBoxPrice <= MINIMUM_PRICE
      ? MINIMUM_PRICE
      : couponAppliedBoxPrice;

  const discountAmountPrice = sellingPrice - price;
  const discountAmountPackagePrice = sellingPackagePrice - packagePrice;
  const discountAmountBoxPrice = sellingBoxPrice - boxPrice;
  const previouslySubscribed = activeSubscriptions.some((sub: any) => {
    return sub.programType === "GENIUS" && sub.status === "ACTIVE";
  });
  const isZeroToOne = ageGroup === "0-1";
  // !isZeroToOne && styles["temp"]
  // LINE 232

  //remove temp class below if more than one cards
  return (
    <div className={`${styles["pricing_wrapper"]} ${true && styles["temp"]}`}>
      <div className={styles["pricing_card"]}>
        {braincellsActiveSub?.endDate && (
          <div
            className={
              isBraincellsSubExpired
                ? styles["expired_on"]
                : styles["valid_until"]
            }
          >
            {`Renewal due on : ${braincellsActiveSub?.endDate}`}
          </div>
        )}
        <div className={styles["card_inner_wrapper"]}>
          <div className={styles["pricing_head"]}>
            <h1 className={styles["title"]}>BrainCells Program</h1>
            <h4 className={styles["subtitle"]}>
              {programData[crrId].description}
            </h4>
            <div className={styles["price"]}>
              <span className={styles["strikeout_price"]}>
                <i
                  className={
                    getCountry() !== "India"
                      ? "fas fa-dollar-sign"
                      : "fas fa-rupee-sign"
                  }
                ></i>
                {showDecimal
                  ? Number(programData[crrId]?.originalPrice).toFixed(0)
                  : Number(programData[crrId]?.originalPrice).toFixed(2)}
              </span>{" "}
              <span
                style={{
                  textDecoration: paymentDetails.isCouponApplied
                    ? "line-through"
                    : "",
                }}
              >
                <i
                  className={
                    getCountry() !== "India"
                      ? "fas fa-dollar-sign"
                      : "fas fa-rupee-sign"
                  }
                ></i>
                {showDecimal
                  ? Number(programData[crrId]?.price).toFixed(0)
                  : Number(programData[crrId]?.price).toFixed(2)}
              </span>
              {paymentDetails.isCouponApplied && (
                <span>
                  <i
                    className={
                      getCountry() !== "India"
                        ? "fas fa-dollar-sign"
                        : "fas fa-rupee-sign"
                    }
                  ></i>
                  {price}
                </span>
              )}
            </div>
          </div>
          <div className={styles["pricing_body"]}>
            <button
              onClick={(e) =>
                onPaymentClick(
                  e,
                  programData[crrId],
                  price,
                  discountAmountPrice
                )
              }
              className={styles["price_button"]}
            >
              {previouslySubscribed ? "Re-subscribe" : "Subscribe Now"}
            </button>
            <div className={styles["points_wrapper"]}>
              {programData[crrId].specifications.split(",").map((item: any) => {
                return (
                  <div key={item} className={styles["point_container"]}>
                    <i className="fas fa-check-circle"></i>
                    <p className={styles["pricing_text"]}>{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles["pricing_foot"]}></div>
        </div>
      </div>
      {/* {isZeroToOne && (
        <div className={styles["pricing_card_popular"]}>
          <div className={styles["popular_tag"]}>Most Popular</div>
          <div className={styles["popular_card_inner_wrapper"]}>
            <div className={styles["pricing_head"]}>
              <h1 className={styles["title"]}>BrainCells Program + Box</h1>
              <h4 className={styles["subtitle"]}>
                {programData[crrId].description}
              </h4>
              <p className={styles["price"]}>
                <span className={styles["strikeout_price"]}>
                  <i
                    className={
                      getCountry() !== "India"
                        ? "fas fa-dollar-sign"
                        : "fas fa-rupee-sign"
                    }
                  ></i>
                  {showDecimal
                    ? Number(programData[crrId]?.packageOrignalPrice).toFixed(0)
                    : Number(programData[crrId]?.packageOrignalPrice).toFixed(
                        2
                      )}
                </span>
                <span
                  style={{
                    textDecoration: paymentDetails.isCouponApplied
                      ? "line-through"
                      : "",
                  }}
                >
                  <i
                    className={
                      getCountry() !== "India"
                        ? "fas fa-dollar-sign"
                        : "fas fa-rupee-sign"
                    }
                  ></i>
                  {showDecimal
                    ? Number(programData[crrId]?.packagePrice).toFixed(0)
                    : Number(programData[crrId]?.packagePrice).toFixed(2)}
                </span>
                {paymentDetails.isCouponApplied && (
                  <span>
                    <i
                      className={
                        getCountry() !== "India"
                          ? "fas fa-dollar-sign"
                          : "fas fa-rupee-sign"
                      }
                    ></i>
                    {packagePrice}
                  </span>
                )}
              </p>
            </div>
            <div className={styles["pricing_body"]}>
              <button
                onClick={(e) =>
                  onPaymentClick(
                    e,
                    programData[crrId],
                    packagePrice,
                    discountAmountPackagePrice
                  )
                }
                className={styles["price_button"]}
              >
                {previouslySubscribed ? "Re-subscribe" : "Subscribe Now"}
              </button>
              <div className={styles["points_wrapper"]}>
                {programData[crrId].specifications
                  .split(",")
                  .map((item: any) => {
                    return (
                      <div key={item} className={styles["point_container"]}>
                        <i className="fas fa-check-circle"></i>
                        <p className={styles["pricing_text"]}>{item}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles["pricing_foot"]}></div>
          </div>
        </div>
      )}

      {isZeroToOne && (
        <div className={styles["pricing_card"]}>
          <div className={styles["card_inner_wrapper"]}>
            <div className={styles["pricing_head"]}>
              <h1 className={styles["title"]}>BrainCells Box</h1>
              <h4 className={styles["subtitle"]}>
                {programData[crrId].description}
              </h4>
              <p className={styles["price"]}>
                <span className={styles["strikeout_price"]}>
                  <i
                    className={
                      getCountry() !== "India"
                        ? "fas fa-dollar-sign"
                        : "fas fa-rupee-sign"
                    }
                  ></i>
                  {showDecimal
                    ? Number(programData[crrId]?.boxOrignalPrice).toFixed(0)
                    : Number(programData[crrId]?.boxOrignalPrice).toFixed(2)}
                </span>
                <span
                  style={{
                    textDecoration: paymentDetails.isCouponApplied
                      ? "line-through"
                      : "",
                  }}
                >
                  <i
                    className={
                      getCountry() !== "India"
                        ? "fas fa-dollar-sign"
                        : "fas fa-rupee-sign"
                    }
                  ></i>
                  {showDecimal
                    ? Number(programData[crrId]?.boxPrice).toFixed(0)
                    : Number(programData[crrId]?.boxPrice).toFixed(2)}
                </span>
                {paymentDetails.isCouponApplied && (
                  <span>
                    <i
                      className={
                        getCountry() !== "India"
                          ? "fas fa-dollar-sign"
                          : "fas fa-rupee-sign"
                      }
                    ></i>
                    {boxPrice}
                  </span>
                )}
              </p>
            </div>
            <div className={styles["pricing_body"]}>
              <button
                onClick={(e) =>
                  onPaymentClick(
                    e,
                    programData[crrId],
                    boxPrice,
                    discountAmountBoxPrice
                  )
                }
                className={styles["price_button"]}
              >
                {previouslySubscribed ? "Re-subscribe" : "Subscribe Now"}{" "}
              </button>
              <div className={styles["points_wrapper"]}>
                {programData[crrId].specifications
                  .split(",")
                  .map((item: any) => {
                    return (
                      <div key={item} className={styles["point_container"]}>
                        <i className="fas fa-check-circle"></i>
                        <p className={styles["pricing_text"]}>{item}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles["pricing_foot"]}></div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export { PricingCard, BrainCellsPricingCards };
