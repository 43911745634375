import { IonModal } from "@ionic/react";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { useSubscriptionContext } from "../../../store/context/subscription-context";
import { postHttpData } from "../../../utils/http-util";
import styles from "./EndSubscriptionDialogBox.module.css";
import { useUserContext } from "../../../store/context/user-context";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";

function EndSubscriptionDialogBox({ location }: any) {
  const [isRecurring, setRecurring] = useState(false);
  const [isNormalPayment, setIsNormalPayment] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { activeSubscriptions } = useSubscriptionContext();

  const subscriptionCtx = useSubscriptionContext();
  const { loadSubscriptionContext } = subscriptionCtx;

  const userCtx = useUserContext();
  const user = userCtx.user;

  const braincellsActiveSubscription = activeSubscriptions.filter(
    (sub: any) =>
      sub.status === "ACTIVE" &&
      sub.programName === "BrainCells" &&
      sub.programType === "GENIUS"
  );

  const onEndSubscriptionClick = () => {
    setShowConfirmModal(true);
  };
  const endSubscriptionClick = async () => {
    try {
      const res = await postHttpData(
        // "https://staging.braincells.in:8080/cancel_razorpay_subscription",
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/cancel_razorpay_subscription`,
        {
          razorpaySubId: braincellsActiveSubscription[0].razorpaySubId,
          subId: braincellsActiveSubscription[0].id,
        }
      );

      loadSubscriptionContext(user.id, "");
      setShowConfirmModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (braincellsActiveSubscription.length > 0) {
      if (braincellsActiveSubscription[0]?.razorpaySubId) {
        //RECURRING PAYMENT WAS ON
        if (
          braincellsActiveSubscription[0]?.recurringPaymentStatus === "LIVE"
        ) {
          //RECURRING PAYMENT IS ON
          setRecurring(true);
          setIsNormalPayment(false);
        } else {
          //RECURRING PAYMENT IS CANCELLED
          setRecurring(false);
          setIsNormalPayment(true);
        }
      } else {
        //ONE TIME SUBSCRIPTION
        setRecurring(false);
        setIsNormalPayment(true);
      }
    } else {
      //TRIAL ACTIVITY
      setRecurring(false);
      setIsNormalPayment(false);
    }
  }, []);

  return (
    <div
      style={{
        width: location === "profile-settings" ? "100%" : "fit-content",
      }}
      className={styles["end_subscription_wrapper"]}
    >
      {isRecurring ? (
        <div
          className={
            location === "pricing-page"
              ? styles["subscription_end_details_container_pricing"]
              : styles["subscription_end_details_container_settings"]
          }
        >
          <h2 className={styles["endsubscription_text"]}>
            Your subscription will renew on{" "}
            {braincellsActiveSubscription[0]?.endDate}.
          </h2>
          <button
            onClick={(e) => {
              e.preventDefault();
              onEndSubscriptionClick();
            }}
            style={{
              width: location === "pricing-page" ? "100%" : "10rem",
            }}
            className={styles["end_subscription_button"]}
          >
            Stop automatic payments
          </button>
        </div>
      ) : isNormalPayment ? (
        `Your subscription will end on ${braincellsActiveSubscription[0].endDate}`
      ) : (
        ""
      )}
      <IonModal isOpen={showConfirmModal}>
        <div className={styles["dialog_box_container"]}>
          <div className={styles["dialog_box_close"]}>
            <i onClick={()=>setShowConfirmModal(false)} className="fas fa-times-circle"></i>
          </div>
          <p className={styles["dialog_box_text"]}>
            Do you want to cancel your automatic payments?
          </p>
          <p className={styles["dialog_box_text"]}>
            Your current subscription will be valid until{" "}
            {braincellsActiveSubscription[0]?.endDate}.
          </p>
          <div className={styles["dialog_box_button_container"]}>
            <button
              onClick={() => setShowConfirmModal(false)}
              className={styles["button_confirm"]}
            >
              Keep Plan
            </button>

            <button
              onClick={endSubscriptionClick}
              className={styles["button_cancel"]}
            >
              Cancel Subscription
            </button>
          </div>
        </div>
      </IonModal>
    </div>
  );
}

const ModalWrapper = ({ children }: any) => {
  console.log("wrapper");
  return <div className={styles["modal_wrapper"]}>{children}</div>;
};

export default EndSubscriptionDialogBox;
