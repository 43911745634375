import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { useSubscriptionContext } from "../../../../store/context/subscription-context";
import { useUserContext } from "../../../../store/context/user-context";
import { postHttpData, putHttpData } from "../../../../utils/http-util";
import { DropDown } from "./DropDown";
import styles from "./QuestionsModal.module.css";
import FormSteps from "./FormSteps/FormSteps";
import { IonPage, IonContent } from "@ionic/react";
import DatePicker from "./DatePicker/DatePicker";

export function QuestionsModal({ setIsOpen, setShowProgressBar }: any) {
  const { loadSubscriptionContext } = useSubscriptionContext();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedProfessionName, setSelectedProfessionName] = useState("");
  const [answers, SetAnswers] = useState(0);
  const userCtx = useUserContext();
  const { user } = userCtx;
  const {
    user: { id, childBirthDate, childName, ageGroup },
    updateUserData,
  } = userCtx;
  const [data, setData] = useState({
    name: "",
    dob: "",
  });
  const [dob, setDob] = useState("");
  const [mcqAnswer, setMcqAnswer] = useState();
  const [otherInput, setOtherInput] = useState(false);
  const [formSteps, setFormSteps] = useState({
    currentStep: 1,
    totalSteps: 4,
  });
  const postToMongo = async () => {
    try {
      const response = await postHttpData(
        `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/userInfo`,
        {
          userId: `${id}`,
          dataType: "parents-question",
          childName: data.name ? data.name : childName,
          childBirthDate: data.dob ? data.dob : childBirthDate,
          childExpectation: selectedProfessionName,
          braincellsExpectation: mcqAnswer,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const showOtherInput = () => {
    setOtherInput(true);
  };
  const updateUserProfile = async (payload: any) => {
    try {
      const response = await putHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/user?id=${id}`,
        {
          ...payload,
        }
      );

      localStorage.setItem(
        "USER_DATA",
        JSON.stringify({ ...user, ...payload })
      );
    } catch (err) {
      console.log(err);
    }
  };
  const nextHandler = () => {
    setCurrentQuestion((prev) => prev + 1);
    SetAnswers((prev) => prev + 1);
    setFormSteps({
      ...formSteps,
      currentStep: formSteps.currentStep + 1,
    });
    if (currentQuestion > modalQuestions.length - 2) {
      postToMongo();
      // Put request for updating the user birthdate
      if (data.dob.length > 0 || data.name.length > 0) {
        let payload = {};
        if (data.dob.length) {
          payload = { ...payload, childBirthDate: data["dob"] };
        }
        if (data.name.length) {
          payload = { ...payload, childName: data["name"] };
        }
        updateUserProfile(payload);
      }
      if (ageGroup === "0-1") {
        updateSubscription(childBirthDate || data.dob);
      }
      setShowProgressBar(true);
      setIsOpen(false);
      setShowProgressBar(true);
    }
  };
  const changeHandler = (e: any) => {
    if (e.target.type === "date") {
      if (e.target.name === "dob") {
        setData({
          ...data,
          [e.target.name]: moment(e.target.value, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          ),
        });
        setDob(e.target.value);
      } else {
        setData({ ...data, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "mcq") {
      setMcqAnswer(e.target.value);
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const dateChangeHandler = (dateDDMMYYYY: string, dateYYYYMMDD: string) => {
    setData({
      ...data,
      dob: dateDDMMYYYY,
    });
    setDob(dateYYYYMMDD);
  };

  const questionOne = (
    <label
      className={`${styles["question_one"]} animate__animated animate__slideInRight`}
      htmlFor="name"
    >
      <p className={`${styles["modalQuestions"]}`}>
        <strong>Enter Child's Name</strong>
      </p>
      <input
        type="text"
        id="name"
        className={`${styles["modalInput"]}`}
        value={data.name}
        name="name"
        onChange={changeHandler}
        placeholder="Type Your Answer Here"
      />
    </label>
  );

  const questionTwo = (
    // <label
    //   className={` ${styles["question_two"]} animate__animated animate__slideInRight`}
    //   htmlFor="date"
    // >
    //   <p className={`${styles["modalQuestions"]}`}>
    //     <strong>Enter Child's BirthDate</strong>
    //   </p>
    //   <input
    //     type="date"
    //     id="date"
    //     className={`${styles["modalInput"]}`}
    //     value={dob}
    //     name="dob"
    //     onChange={changeHandler}
    //     placeholder="dd/mm/yyyy"
    //   />
    // </label>

    <DatePicker dateChangeHandler={dateChangeHandler} />
  );

  const questionFour = (
    <div
      className={`${styles["mcq_wrapper"]} animate__animated animate__slideInRight`}
    >
      <label htmlFor="mcq">
        <p className={`${styles["modalQuestions"]}`}>
          <strong>What are you looking out of BrainCells Program?</strong>
        </p>
        <div className={`${styles["mcq_answers"]}`}>
          <div className={`${styles["radio_button_wrapper"]}`}>
            <input
              type="radio"
              // className={`${styles["modalInput"]}`}
              value={"Holistic development for my child"}
              name="mcq"
              onChange={changeHandler}
              id="holistic_development"
            />
            <label htmlFor="holistic_development">
              Holistic development for my child
            </label>
          </div>
          <div className={styles["radio_button_wrapper"]}>
            <input
              type="radio"
              // className={`${styles["modalInput"]}`}
              value={"Brain / IQ development for my child"}
              name="mcq"
              onChange={changeHandler}
              id="brain_development"
            />
            <label htmlFor="brain_development">
              Brain / IQ development for my child
            </label>
          </div>
          <div className={styles["radio_button_wrapper"]}>
            <input
              type="radio"
              // className={`${styles["modalInput"]}`}
              value={"I want my child to be a step ahead"}
              name="mcq"
              onChange={changeHandler}
              id="step_ahead"
            />
            <label htmlFor="step_ahead">
              I want my child to be a step ahead
            </label>
          </div>
          <div className={styles["radio_button_wrapper"]}>
            <input
              type="radio"
              // className={`${styles["modalInput"]}`}
              value={"I want to enhance my parenting skills sets"}
              name="mcq"
              onChange={changeHandler}
              id="parenting_skills"
            />
            <label htmlFor="parenting_skills">
              I want to enhance my parenting skills sets
            </label>
          </div>
          <div className={styles["radio_button_wrapper"]}>
            <input
              type="radio"
              // className={`${styles["modalInput"]}`}
              value={"answer 5"}
              name="mcq"
              onChange={showOtherInput}
            />
            {otherInput ? (
              <input
                type="text"
                className={`${styles["modalInput"]} ${styles["mcq_modal_input"]}`}
                name="mcq"
                onChange={changeHandler}
                placeholder="Type Your Answer Here"
              />
            ) : (
              <label>Other</label>
            )}
          </div>
        </div>
      </label>
    </div>
  );

  const questionThree = (
    <DropDown
      setSelectedProfessionName={setSelectedProfessionName}
      selectedProfessionName={selectedProfessionName}
    />
  );

  const modalQuestions = [
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
  ];

  const questions = [data.name, data.dob, selectedProfessionName, mcqAnswer];

  useEffect(() => {
    if (childName) {
      if (childBirthDate) {
        setCurrentQuestion((prev) => prev + 2);
        SetAnswers((prev) => prev + 2);
        setFormSteps({
          currentStep: 1,
          totalSteps: 2,
        });
      }
      if (!childBirthDate) {
        setCurrentQuestion((prev) => prev + 1);
        SetAnswers((prev) => prev + 1);
        setFormSteps({
          currentStep: 1,
          totalSteps: 3,
        });
      }
    }
    if (!childName) {
      if (childBirthDate) {
        setCurrentQuestion(0);
        SetAnswers(0);
        setFormSteps({
          currentStep: 1,
          totalSteps: 3,
        });
      }
    }
  }, [childName, childBirthDate]);

  function isValueSelected(currentValue: any) {
    if (currentValue) return true;
  }

  const updateSubscription = async (childBirthDate: any) => {
    try {
      if (ageGroup === "0-1") {
        const res = await axios.put(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/modify_subscription?userId=${id}`,
          {
            childBirthDate: childBirthDate,
          }
        );
        loadSubscriptionContext(id, "");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles["modalContent"]}>
      <FormSteps
        currentStep={formSteps.currentStep}
        totalSteps={formSteps.totalSteps}
      />
      {modalQuestions[currentQuestion]}
      {isValueSelected(questions[answers]) ? (
        <button className={styles["modalBtn"]} onClick={nextHandler}>
          Next
        </button>
      ) : (
        <button
          className={styles["modalBtn"]}
          style={{ background: "gray", cursor: "auto" }}
        >
          Next
        </button>
      )}
    </div>
  );
}
