import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useLocation, useParams } from "react-router";
import OrientationChecker from "../../components/generalComponents/OrientationChecker";
import { Path } from "../../components/Program/Path";
import Loader from "../../components/UI/Loader/Loader";

import {
  BACK_BUTTON,
  PATH_MIDDLE,
} from "../../constants/Program/program-constants";
import useCurrentProgramDate from "../../hooks/useCurrentProgramDay";
import useIsZeroTwoToOne from "../../hooks/useIsZeroToOne";
import { useGeneratePath } from "../../hooks/usePathGenerator";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import { useUserContext } from "../../store/context/user-context";
import { lowestValueFromArray } from "../../utils/array-utils";
import { detectIsMobileDevice } from "../../utils/general-utils";
import { getExerciseData, programAttendance } from "../../utils/service-utils";
import NavigationDot from "./components/NavigationDot";
import { ScrollLeft, ScrollRight } from "./components/ScrollButtons";
import styles from "./Program.module.css";

const Program = () => {
  //STATES
  const [program, setProgram] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  //REFS
  const programRef = useRef<any>(null);
  const currentExerciseRef: any = useRef();
  //CONTEXT
  const { user } = useUserContext();
  //CUSTOM HOOKS
  const { programName } = useParams<any>();
  const programHandle = useFullScreenHandle();
  const search = useLocation().search;
  //USER CUSTOM HOOKS
  const { activeSubscriptions } = useSubscriptionContext();
  const { generatePathData } = useGeneratePath();
  const { isSubscribedZeroToOne: isZeroToOne } = useIsZeroTwoToOne();
  const {
    currentProgramDay,
    daysSinceBirth,
    todaysDate,
    refactoredEndDate,
    refactoredStartDate,
  } = useCurrentProgramDate(programName);
  //CONSTANTS
  const earliestStartProgramDay = lowestValueFromArray(
    activeSubscriptions,
    "startProgramDay"
  );
  const isMobile = detectIsMobileDevice();
  const showArrowNavigation = program.length > 1;

  const programTypeResponse = new URLSearchParams(search).get("s");
  const programTypeParam =
    programTypeResponse !== null ? `_${programTypeResponse}` : "";
  const currentProgram = activeSubscriptions.find(
    (program) =>
      program.programName === programName && program.status === "ACTIVE"
  );
  const { startProgramDay, ageGroup, userId } = currentProgram;
  const enterFullScreen = () => {
    if (!programHandle.active && isMobile && !document.fullscreenElement) {
      programHandle.enter();
    }
  };

  const scrollToLast = () => {
    programRef.current.scrollTo({
      left: 10000000,
      top: 0,
      behavior: "instant",
    });
  };

  const initializePathCreation = async () => {
    const { programExercise, userExercise }: any = await getExerciseData(
      programTypeParam,
      ageGroup,
      programName,
      userId
    );
    currentExerciseRef.current = isZeroToOne
      ? programExercise[currentProgramDay + daysSinceBirth]
      : programExercise[currentProgramDay] || {
          "exercise-number": programExercise.length,
        };
    const programStructure = generatePathData(
      programExercise,
      userExercise,
      currentProgramDay,
      earliestStartProgramDay,
      programName,
      programTypeResponse ? true : false,
      refactoredStartDate,
      refactoredEndDate,
      startProgramDay,
      todaysDate
    );
    setProgram(programStructure);
    setTimeout(() => {
      setRemoveLoader(true);
      scrollToLast();
    }, 1000);
  };

  useEffect(() => {
    const img = new Image();
    img.src = PATH_MIDDLE;
    programAttendance(userId, programName);
    setShowLoader(true);
  }, []);

  useEffect(() => {
    initializePathCreation();
  }, [ageGroup, programName]);

  return (
    <IonPage>
      <IonContent>
        <FullScreen handle={programHandle}>
          <OrientationChecker />
          <div
            onClick={enterFullScreen}
            className={styles["program-page"]}
            ref={programRef}
          >
            {showLoader && <Loader removeLoader={removeLoader} />}
            <>
              <NavigationDot
                programRef={programRef}
                unlockedExc={currentProgramDay}
              />
              {showArrowNavigation && (
                <>
                  <ScrollLeft programRef={programRef} />
                  <ScrollRight programRef={programRef} />
                </>
              )}
              {program.length > 0 &&
                program.map((week, index) => {
                  return (
                    <Path
                      key={index}
                      week={week}
                      currentProgramDay={currentProgramDay}
                      index={index}
                      currentExercise={
                        currentExerciseRef.current["exercise-number"]
                      }
                      programTypeParam={programTypeResponse}
                    />
                  );
                })}
              <IonRouterLink href={`/dashboard`}>
                <div
                  style={{
                    position: "fixed",
                    top: "1rem",
                    right: "2rem",
                    width: "6vw",
                    cursor: "pointer",
                  }}
                >
                  <img src={BACK_BUTTON} alt="Go Back" />
                </div>
              </IonRouterLink>
            </>
          </div>
        </FullScreen>
      </IonContent>
    </IonPage>
  );
};

export { Program };
