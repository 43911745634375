import styles from "./CommunityFeed.module.css";
import CommunityFooter from "../components/CommunityFooter/CommunityFooter";
import CommunityNavbar from "../components/CommunityNavbar/CommunityNavbar";
import SinglePost from "../components/SinglePost/SinglePost";
import { useEffect, useRef } from "react";
import { useCommunityContext } from "../../../store/context/community-context";
import AvatarSelection from "../components/AvatarSelection/AvatarSelection";
import { CommunityLoader } from "../../../components/generalComponents/loaders/CommunityLoader/CommunityLoader";
import { useHistory } from "react-router";

const CommunityFeed = () => {
  const communityCtx = useCommunityContext();
  const feedPostRef = useRef<any>(null);
  const { communityPosts, getCommunityPosts, avatarState } = communityCtx;

  const history = useHistory();

  const fetchMorePosts = () => {
    getCommunityPosts();
  };

  useEffect(() => {
    getCommunityPosts();
  }, []);

  const onScrollHandler = () => {
    const { scrollTop, scrollHeight, clientHeight } = feedPostRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      fetchMorePosts();
    }
  };

  return (
    <div className={styles["community_feed"]}>
      <div>
        <CommunityNavbar heading={"Community Canvas"} />
      </div>
      <div
        onScroll={onScrollHandler}
        ref={feedPostRef}
        className={styles["community_feed__posts"]}
      >
        {[...communityPosts].map((post, index) => {
          return (
            <SinglePost
              postData={post}
              onPostClick={(e: any) => {
                history.push(`/community/post/${post["_id"]["$oid"]}`);

                e.target.reload();
              }}
            />
          );
        })}
        <CommunityLoader />
      </div>
      <div>
        <CommunityFooter activeTab={"community"} />
      </div>
      <div>{avatarState ? <AvatarSelection /> : null}</div>
    </div>
  );
};

export default CommunityFeed;
