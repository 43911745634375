// API Server
// export const MAIN_REST_API_SERVER = "https://api.braincells.in";
// export const ACTIVITY_TRACKING_SERVER = "https://api.braincells.in";
export const MAIN_REST_API_SERVER = process.env.REACT_APP_MAIN_REST_API_SERVER;
export const ACTIVITY_TRACKING_SERVER =
  process.env.REACT_APP_ACTIVITY_TRACKING_SERVER;
// // API Server
// export const MAIN_REST_API_SERVER = "http://52.78.137.248";
// export const ACTIVITY_TRACKING_SERVER = "http://3.38.153.221";

// // NEW API Server
// export const MAIN_REST_API_SERVER = "http://3.0.57.193";
// export const ACTIVITY_TRACKING_SERVER = "http://54.179.189.250";

// Server Ports
export const MAIN_REST_PORT = 8080;
export const GENIE_PORT = 8081;
export const ACTIVITY_PORT = 8082;
// export const ACTIVITY_PORT = 8080;

// Root Endpoints
export const ROOT_ENDPOINT = "";
