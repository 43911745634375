import {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
} from "react";
import {
  ADD_BOOKING_DATA,
  ADD_TO_CART,
  REMOVE_BOOKING_DATA,
  REMOVE_FROM_CART,
  SET_TEACHER_DATA,
  REMOVE_TEACHER_DATA,
  EMPTY_CART,
  EMPTY_CONTEXT,
  FIRST_BOOKING,
} from "../../constants/utility/utility-constants";
const rttOnlineData: any = require("./rtt-online-context-filter.json");
// create react context with types
export const RttOnlineContext = createContext<any>(null);

const rttUserData = {
  topicId: 1,
  bookingData: {},
};
const RttOnlineContextProvider = ({ children }: any) => {
  const [rttData, setRttData] = useState({});

  const getRttData = async () => {};
  const getUserRttData = async () => {};
  const initialRttData = {
    selectedTopics: [],
    selectedTeacher: "",
    isFirstBooking: false,
    rttCouponData: {},
    // selectedTopics: [...rttOnlineData],
  };

  const rttDataReducer = (state: any, action: any) => {
    const newState = { ...state };
    switch (action.type) {
      case ADD_TO_CART:
        return {
          ...state,
          selectedTopics: [...state.selectedTopics, action.payload],
        };
      case REMOVE_FROM_CART:
        newState.selectedTopics = newState.selectedTopics.filter(
          (topic: any) => topic.id !== action.payload
        );
        return { ...newState };

      case ADD_BOOKING_DATA:
        return {
          ...newState,
          bookingData: [...newState.bookingData, action.payload],
        };

      case SET_TEACHER_DATA:
        return {
          ...newState,
          selectedTeacher: { ...newState.selectedTeacher, ...action.payload },
        };

      case EMPTY_CART:
        return { ...newState, selectedTopics: [] };

      case REMOVE_TEACHER_DATA:
        return { ...newState, selectedTeacher: "" };

      case EMPTY_CONTEXT:
        return {
          selectedTopics: [],
          selectedTeacher: "",
        };

      case FIRST_BOOKING:
        return { ...newState, isFirstBooking: true };
      case "ADD_COUPON":
        return { ...newState, rttCouponData: action.payload };

      default:
        return state;
    }
  };

  const [rttDataState, rttDataDispatch] = useReducer(
    rttDataReducer,
    initialRttData
  );

  const getAllRttData = async () => {
    try {
      const response = await Promise.all([getRttData(), getUserRttData()]);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {});

  const rttOnlineContextValue = {
    rttData: [],
    getAllRttData,
    rttDataState,
    rttDataDispatch,
  };

  return (
    <RttOnlineContext.Provider value={rttOnlineContextValue}>
      {children}
    </RttOnlineContext.Provider>
  );
};

const useRttOnlineContext = () => useContext(RttOnlineContext);

export { RttOnlineContextProvider, useRttOnlineContext };
