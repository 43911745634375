import { IonButton } from "@ionic/react";
import styles from "./DashCard.module.css";

export const DashCard = (props: any) => {
  return (
    <IonButton
      fill="clear"
      href={props.href}
      className={`${styles.dashCardBtn} `}
    >
      <img src={props.img} alt={props.subText} />
    </IonButton>
  );
};
