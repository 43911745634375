import { useEffect, useState } from "react";
import styles from "./SinglePostPage.module.css";
import { getHttpData } from "../../../utils/http-util";
import { useLocation } from "react-router";
import { IonPage } from "@ionic/react";
import CommunityFooter from "../components/CommunityFooter/CommunityFooter";
import CommunityNavbar from "../components/CommunityNavbar/CommunityNavbar";
import SinglePost from "../components/SinglePost/SinglePost";
import { communityGetLink } from "../../../constants/Services/services-constants";

const SinglePostPage = (props: any) => {
  const location = useLocation();
  const postId = location["pathname"].split("/")[3];

  const [postData, setPostData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getHttpData(`${communityGetLink}?_id=${postId}`);
        console.log("aaaaaa");
        console.log(response);
        setPostData(response["data"][0]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <IonPage>
      <div className={styles["single_post_page_container"]}>
        <div>
          <CommunityNavbar heading={"My Post"} />
        </div>

        <div className={styles["single_post_page"]}>
          {postData !== null && <SinglePost postData={postData} />}
        </div>

        <div>
          <CommunityFooter activeTab={"community"} />
        </div>
        {/* <div>{avatarState ? <AvatarSelection /> : null}</div> */}
      </div>
    </IonPage>
  );
};

export default SinglePostPage;
