import { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import styles from "./StoryBook.module.css";
import useWebAudioApi from "../../../../../../hooks/useWebAudioApi";
import StoryBookPage from "../StoryBookPage/StoryBookPage";
import { language } from "ionicons/icons";
import { detectIsAndroidDevice } from "../../../../../../utils/general-utils";
import { imagesLink } from "../../../../../../constants/Services/services-constants";

const SUBTITLE_SPEED = 115;

const StoryBook = ({
  activityJson,
  selectedLanguage,
  setShowMenu,
  startNewStory,
}: any) => {
  //!DEVELOPMENT
  const activityData = activityJson;
  //STATE
  const [bookArea, setBookArea] = useState<any>({
    gotDimensions: false,
    height: null,
    width: null,
  });
  const [isPlaying, setIsPlaying] = useState<any>({
    pageNumber: 0,
    dialogue: false,
  });
  const [audioPlayVisible, setAudioPlayVisible] = useState<any>(true);
  const [crrPage, setCrrPage] = useState<any>(0);
  const [isTurning, setIsTurning] = useState<any>(false);
  const [showBook, setShowBook] = useState<boolean>(false);
  //CUSTOM HOOK
  const { createWebAudioBuffer, createAudioObject } = useWebAudioApi();
  //REFS
  const activityRef: any = useRef();
  const bookRef: any = useRef();
  const isAndroidRef = useRef(detectIsAndroidDevice());
  //CONSTANTS
  const {
    bookTitleSpeakText,
    pageList,
    bookCoverVideo,
    backCoverSpeakText,
    availableLanguages,
    backCoverImage,
  } = activityData;

  const isLanguageSelected = selectedLanguage.length > 0;

  const finalPageList = [
    {
      pageVideo: bookCoverVideo,
      englishSpeakText: activityData[`englishSpeakText`],
      hindiSpeakText: activityData[`hindiSpeakText`],
      hardcover: true,
    },
    ...pageList,
    {
      speakText: backCoverSpeakText,
      hardcover: true,
      pageImage: backCoverImage,
      backCover: true,
    },
  ];
  //USE EFFECT
  useEffect(() => {
    setTimeout(() => {
      setBookArea(
        getActivityDimensions(activityRef.current.getBoundingClientRect())
      );
      setTimeout(() => {
        setShowBook(true);
      }, 500);
    }, 1500);
  }, []);
  //FUNCTIONS
  const onFlip = (e: any) => {
    setCrrPage(e.data);
  };

  const subtitleSpeed = activityData.subtitleSpeed;

  useEffect(() => {
    if (bookRef.current) {
      setCrrPage(0);
      setIsPlaying(false);
      setIsTurning({
        pageNumber: 0,
        dialogue: false,
      });
      bookRef.current.pageFlip().turnToPage(0);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (!isPlaying.dialogue) {
      setAudioPlayVisible(true);
    } else {
      setAudioPlayVisible(false);
    }
  }, [isPlaying]);

  const getActivityDimensions = (dimensions: any) => {
    const { top, bottom, left, right } = dimensions;
    const areaWidth = right - left;
    const areaHeight = bottom - top;
    const width = areaWidth * 0.49;
    const tempHeight = areaHeight * 0.7;
    return { gotDimensions: true, width, height: tempHeight };
  };

  const nextPage = () => {
    bookRef.current.pageFlip().flipNext();
  };
  const prevPage = () => {
    bookRef.current.pageFlip().flipPrev();
  };
  const onBookTouch = (e: any) => {
    if (e.data === "user_fold") {
      setIsTurning(true);
    } else {
      setIsTurning(false);
    }
  };
  const colorArr = ["#ff465d", "#0072ca", "#00b761", "#a43da5"];

  const applyBlackTint = () => {
    const { dialogue, pageNumber } = isPlaying;
    return dialogue && pageNumber > 0
      ? pageNumber % 2 === 0
        ? pageNumber - 1
        : pageNumber + 1
      : -1;
  };

  return (
    <div
      ref={activityRef}
      style={{
        backgroundImage: `url(${imagesLink}course/Library/activity-files/encyclo-book/assets/backgroundImage.png)`,
      }}
      className={`${styles["activity_wrapper"]} `}
    >
      <div
        style={{
          backgroundImage: `url(${imagesLink}course/Library/activity-files/encyclo-book/assets/backgroundImage.png)`,
          visibility: bookArea.gotDimensions && showBook ? "visible" : "hidden",
        }}
        className={styles["book_wrapper"]}
      >
        {bookArea.gotDimensions && (
          <HTMLFlipBook
            style={{}}
            width={bookArea.width}
            height={bookArea.height}
            className={styles["storybook"]}
            startPage={0}
            size={"fixed"}
            minWidth={bookArea.width}
            maxWidth={bookArea.width}
            minHeight={bookArea.height}
            maxHeight={bookArea.height}
            drawShadow={false}
            flippingTime={1000}
            usePortrait={false}
            startZIndex={0}
            autoSize={true}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            clickEventForward={false}
            useMouseEvents={true}
            swipeDistance={30}
            showPageCorners={true}
            disableFlipByClick={true}
            onFlip={onFlip}
            ref={bookRef}
            onChangeState={onBookTouch}
          >
            {finalPageList.map((page: any, index: any) => {
              return (
                <StoryBookPage
                  key={index}
                  index={index}
                  page={page}
                  bookArea={bookArea}
                  crrPage={crrPage}
                  setCrrPage={setCrrPage}
                  setIsPlaying={setIsPlaying}
                  isPlaying={isPlaying}
                  createWebAudioBuffer={createWebAudioBuffer}
                  createAudioObject={createAudioObject}
                  isTurning={isTurning}
                  selectedLanguage={selectedLanguage}
                  autoplay={index === 0}
                  startNewStory={startNewStory}
                  audioPlayVisible={audioPlayVisible}
                  isAndroid={isAndroidRef.current}
                  applyBlackTint={applyBlackTint()}
                />
              );
            })}
          </HTMLFlipBook>
        )}
        {isPlaying.pageNumber > 0 && (
          <SubtitleGenerator
            subtitleSpeed={subtitleSpeed || SUBTITLE_SPEED}
            setShowMenu={setShowMenu}
            isPlaying={isPlaying}
            crrPage={crrPage}
            subtitle={
              finalPageList[isPlaying["pageNumber"]][
                `${selectedLanguage}SpeakText`
              ]
            }
          />
        )}

        {isLanguageSelected && !isPlaying.dialogue && (
          <div className={styles["navigation_wrapper"]}>
            <i
              onClick={prevPage}
              className={`fas fa-arrow-left ${styles["left_arrow"]}`}
            ></i>
            <i
              onClick={nextPage}
              className={`fas fa-arrow-right ${styles["right_arrow"]}`}
            ></i>
          </div>
        )}
      </div>
    </div>
  );
};

const SubtitleGenerator = ({
  subtitle = [],
  isPlaying,
  crrPage,
  setShowMenu,
  subtitleSpeed,
}: any) => {
  const [subtitleText, setSubtitleText] = useState<any>("");

  useEffect(() => {
    if (isPlaying.dialogue) {
      startSubtitle();
    }
  }, [isPlaying]);

  useEffect(() => {
    setSubtitleText("");
  }, [crrPage]);

  const startSubtitle = () => {
    let subtitleText = "";
    let i = 0;
    let lineCount = 0;
    setShowMenu(false);
    const intervalId = setInterval(() => {
      const subtitleLine = subtitle[lineCount];
      if (lineCount < subtitle.length) {
        if (i < subtitleLine.length) {
          subtitleText = subtitleText.concat(`${subtitleLine[i]}`);
          setSubtitleText(subtitleText);
        } else {
          i = 0;
          lineCount++;
          if (lineCount >= subtitle.length) {
            clearInterval(intervalId);
            setSubtitleText("");
            setShowMenu(true);
          } else {
            subtitleText = `${subtitle[lineCount][i]}`;
            setSubtitleText(subtitleText);
          }
        }
        i++;
      } else {
        clearInterval(intervalId);
        setSubtitleText("");
        setShowMenu(true);
      }
    }, subtitleSpeed);
  };

  return subtitleText.length > 0 ? (
    <div className={styles["subtitle"]}>{subtitleText}</div>
  ) : null;
};

export default StoryBook;
