import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router";
import OrientationChecker from "../../../components/generalComponents/OrientationChecker";
import { ActivityStarter } from "../../../components/Program/ActivityStarter";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import {
  BACKGROUND_DESIGN,
  BACKGROUND_IMG,
} from "../../../constants/Excercise/excercise-constants";
import useActivityStarter from "../../../hooks/useActivityStarter";
import { useRttOnlineContext } from "../../../store/context/rtt-online-context";
import { useUserContext } from "../../../store/context/user-context";
import { crrActivityJson } from "../../../utils/activity-utils";
import { getRandomInt } from "../../../utils/general-utils";
import {
  giveIconClass,
  giveIconClassRTTOnline,
} from "../../../utils/giveIconClass";
import { getHttpData } from "../../../utils/http-util";
import { randomColor } from "../../../utils/ui-functions-utils";
import ExerciseLoader from "../../Excercise/components/ExerciseLoader";
import { ActivityCard } from "../../Excercise/Excercise";
import styles from "./RttOnlineExercise.module.css";
import { log } from "console";
import { dateTimeFromUTCtoLocal } from "../../../utils/date-utils";
import Loader from "../../../components/UI/Loader/Loader";
const RttOnlineExercise = () => {
  const BUTTONJSON = require("../../Excercise/color-data.json");
  const { buttonData } = BUTTONJSON;
  const DOWNLOADABLES = "Downloadables";
  const RTT_ONLINE_ACTIVITY = "Join-The-Session";
  const queryParams = new URLSearchParams(window.location.search);
  const isTeacher = queryParams.get("t");
  const activities = isTeacher
    ? [DOWNLOADABLES]
    : [DOWNLOADABLES, RTT_ONLINE_ACTIVITY];
  const { eventId } = useParams<any>();
  //CUSTOM HOOKS
  const {
    activityJsonRef,
    setStartActivity,
    startActivity,
    activityHandle,
    toggleActivity,
    endActivity,
    enterFullScreenInsideActivity,
    exerciseHandle,
    enterFullscreen,
    activityStartFullscreen,
  } = useActivityStarter();
  //STATE
  const [finalActivityList, setFinalActivityList] = useState<any>([]);
  const [completedActivity, setCompletedActivity] = useState<any>([]);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [colorTheme, setColorTheme] = useState<any>(0);
  const [currentActivity, setCurrentActivity] = useState<any>({});
  const [activityList, setActivityList] = useState<any>([]);
  const [showExerciseIntro, setExerciseIntro] = useState<any>(true);
  const [bookingData, setBookingData] = useState<any>({}); //API CALL TO GET BOOKING DATA
  const { exerciseId } = useParams<any>();
  //CONTEXT
  const {
    rttDataState,
    rttDataState: { selectedTopics, selectedTeacher },
    rttDataDispatch,
  } = useRttOnlineContext();
  const {
    user: { id },
  } = useUserContext();
  const changeColor = () => {
    setColorTheme((prev: any) => {
      return getRandomInt(Object.keys(buttonData).length - 1);
    });
  };
  const selectActivityClick = async (currentActivity: any) => {
    setCurrentActivity(currentActivity);
    changeColor();
    if (currentActivity.name === RTT_ONLINE_ACTIVITY) {
      activityJsonRef.current = crrActivityJson(
        createActivityData(bookingData),
        RTT_ONLINE_ACTIVITY,
        "Rumble-Tumble-Time-Online",
        eventId
      );
    } else {
      await getActivityData(currentActivity.name);
    }
  };

  const onActivityStartClick = () => {
    activityStartFullscreen();
  };
  // ${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}
  const getActivityList = async () => {
    const data: any = await getHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_exercise?exerciseId=${eventId}&programName=${"Rumble-Tumble-Time-Online"}&exerciseType=Exercise`
    );
    setActivityList(data.data);
  };

  const getBookingData = async () => {
    const res = await getHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineBooking?userId=${id}&eventId=${eventId}`
    );
    setBookingData(res.data[0]);
  };
  const getActivityData = async (activityName: string) => {
    if (activityName) {
      // const activityJsonLink = `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_activity?programName=Rumble-Tumble-Online&exerciseId=${eventId}&activityName=${activityName}`;
      const activityJsonLink = `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_activity?programName=Rumble-Tumble-Time-Online&exerciseId=${eventId}&activityName=${activityName}&exerciseType=Exercise`;
      setLoadingActivity(true);
      const data: any = await getHttpData(activityJsonLink);
      activityJsonRef.current = crrActivityJson(
        data.data,
        activityName,
        "Rumble-Tumble-Time-Online",
        eventId
      );
    }
    setLoadingActivity(false);
  };

  useEffect(() => {
    //API CALL TO GET ACTIVITY JSON
    getBookingData();
    getActivityList();
  }, []);

  useEffect(() => {
    const tempActivityList = activityList;
    if (!isTeacher) {
      tempActivityList.push(RTT_ONLINE_ACTIVITY);
    }
    const newActivityList = tempActivityList
      .map((activity: any) => {
        const activityIconData = giveIconClassRTTOnline(activity);
        return { name: activity, ...activityIconData };
      })
      .sort((a: any, b: any) => a.priority - b.priority);
    setFinalActivityList(newActivityList);
    selectActivityClick(newActivityList[0]);
  }, [activityList]);

  const createActivityData = (data: any) => {
    return {
      meetingLink: data.meetLink,
      meetingDate: data.eventDate,
      meetingTime: data.eventTime,
    };
  };

  const showEventTime: any = !isTeacher
    ? dateTimeFromUTCtoLocal(bookingData.eventDate, bookingData.eventTime)
    : {};

  return (
    <IonPage>
      <IonContent>
        <FullScreen handle={exerciseHandle}>
          <Loader crrExe={"Please Wait"} showLoaderFunc={() => {}} />
          <FullScreen handle={activityHandle}>
            {startActivity && (
              <ActivityStarter
                activityJSON={activityJsonRef.current}
                startActivity={startActivity}
                toggleActivity={toggleActivity}
                endActivity={endActivity}
                enterFullScreenInsideActivity={enterFullScreenInsideActivity}
              />
            )}
          </FullScreen>
          <OrientationChecker />
          <div onClick={enterFullscreen} className={styles["excercise-page"]}>
            <img
              className={styles["bg-img"]}
              src={BACKGROUND_IMG}
              alt="Background"
            />
            <div className={styles["excercise-main"]}>
              <div className={styles["activity-list"]}>
                <div className={styles["fade-effect-down"]}></div>
                <div className={styles["fade-effect-up"]}></div>
                {finalActivityList.map((activity: any) => {
                  const isCompleted = completedActivity.some(
                    (el: any) => el.activityName === activity.name
                  );
                  return (
                    <div
                      style={{
                        transform: `${
                          currentActivity.name === activity.name
                            ? "scale(1.25)"
                            : ""
                        }`,
                      }}
                      key={activity.name}
                      className={styles["activity-button"]}
                      onClick={() => {
                        selectActivityClick(activity);
                      }}
                    >
                      <ActivityCard
                        activity={activity}
                        currentActivity={currentActivity}
                        isCompleted={isCompleted}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={styles["activity"]}>
                {!isTeacher && (
                  <h1
                    style={{ color: buttonData[colorTheme]["color_code"] }}
                    className={styles["theme-name"]}
                  >
                    {bookingData?.themeName} | {showEventTime?.readableTime}
                  </h1>
                )}
                <h1
                  style={{ color: buttonData[colorTheme]["color_code"] }}
                  className={styles["activity-name"]}
                >
                  {currentActivity?.name}
                </h1>
                <img
                  src={BACKGROUND_DESIGN}
                  alt="Background Design"
                  className={styles["activity-bg"]}
                />
                {loadingActivity ? (
                  <ExerciseLoader
                    colorCode={buttonData[colorTheme]["color_code"]}
                  />
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      backgroundColor: "green",
                    }}
                    className="animate__animated animate__bounceIn"
                  >
                    <img
                      onClick={onActivityStartClick}
                      src={buttonData[colorTheme]["PLAY_BUTTON"]}
                      alt="Start Button"
                      className={`${styles["activity-start-btn"]}`}
                    />
                  </div>
                )}
              </div>
            </div>
            <IonRouterLink href={`/rtt-online?b=2d`}>
              <div
                style={{
                  position: "fixed",
                  top: "1rem",
                  right: "2rem",
                  width: "6vw",
                  cursor: "pointer",
                }}
              >
                <img
                  src={buttonData[colorTheme]["BACK_BUTTON"]}
                  alt="Go Back"
                />
              </div>
            </IonRouterLink>
          </div>
        </FullScreen>
      </IonContent>
    </IonPage>
  );
};

export default RttOnlineExercise;
