import axios from "axios";
import {
  GENIE_PORT,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../configs/ServerConfig";

export const tts = (text: string, lang: string) => {
  const api = axios.create({
    baseURL: `${MAIN_REST_API_SERVER}:${GENIE_PORT}`,
  });
  let data = { text: text, lang: lang };

  const soundPromise = api.get("/tts", {
    params: { text: text, lang: lang },
  });

  return soundPromise;
};

export const bulktts = (textArray: Array<string>, lang: string) => {
  const api = axios.create({
    baseURL: `${MAIN_REST_API_SERVER}:${GENIE_PORT}`,
  });
  let data = { textArray: textArray, lang: lang };

  const soundPromise = api.get("/bulktts", {
    params: { textArray: textArray.toString(), lang: lang },
  });

  return soundPromise;
};
