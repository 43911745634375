import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButtons,IonButton, IonBackButton, IonCard, IonImg} from '@ionic/react';
import styles from "./TrophyCase.module.css"
import { logoGithub, trophy } from 'ionicons/icons';
export const TrophyCase = (props:any) => {
  // let trophyImg = "https://media.istockphoto.com/vectors/gold-trophy-with-the-name-plate-of-the-winner-of-the-competition-vector-id1168757141?k=20&m=1168757141&s=612x612&w=0&h=_jia0PPMGux63K2gqp-o0OzRcHbd6bvjVQJ70rz3nF8=" 
  let trophyImg = "https://www.freeiconspng.com/uploads/gold-trophy-transparent-1.png" 
  let data=[
    {
      name:"t1",
      imageURL:trophyImg,
      activity:"Bit Card"
    },
    {
      name:"t2",
      imageURL:trophyImg,
      activity:"Sorto"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    },
    {
      name:"t3",
      imageURL:trophyImg,
      activity:"Weigh Me In"
    }
]

  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
      <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>Trophy Case</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>
      <div className={styles.mainContainer} >

        <div className={styles.trophyContainer} >
                {
                  data.map((tx:any,i)=>{
                    const isEven = i%2==0;
                    const position = (isEven)? {justifyContent:'flex-start'}:{justifyContent:'flex-end'}
                    return(
                        <Trophy key={i} position={position} imageURL={tx['imageURL']} name={tx['name']} activity={tx['activity']} />
                    )
                  })
                }
        </div>
      </div>
    </IonContent>
  </IonPage>
  )
}

function Trophy(props:any){
  const {key,imageURL,name,activity,position} = props
  const endpoint = "/activity/" + activity.replaceAll(' ', '-')
  
  return(
    <div className={styles.innerTrophyContainer} style={position} >
      <div className={styles.trophy} >
        <img src={imageURL} />
        <IonButton href = {endpoint} color="primary" size='small' > Load Bonus Activity </IonButton>
      </div>
    </div>
    
  )
}
