import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { ActivityStarter } from "../../components/Program/ActivityStarter";
import {
  BACKGROUND_DESIGN,
  BACKGROUND_IMG,
} from "../../constants/Excercise/excercise-constants";
import { useUserContext } from "../../store/context/user-context";
import { giveIconClass, giveIconClassRTT } from "../../utils/giveIconClass";
import { getHttpData, postHttpData } from "../../utils/http-util";
import styles from "./Excercise.module.css";
import { crrActivityJson } from "../../utils/activity-utils";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../configs/ServerConfig";
import {
  detectIsIOSDevice,
  detectIsMobileDevice,
  getRandomInt,
  isRttTeller,
} from "../../utils/general-utils";
import TrialCoupon from "./components/TrialCoupon";
import { ExerciseNavigation } from "./components/ExerciseNavigation";
import Loader from "../../components/UI/Loader/Loader";
import ExerciseLoader from "./components/ExerciseLoader";
import OrientationChecker from "../../components/generalComponents/OrientationChecker";
import { testLink } from "../../constants/Services/services-constants";
import { useImgPrefetch } from "../../hooks/useImgPrefetch";
import {
  RUMBLE_TUMBLE_GUIDE,
  ZERO_TO_ONE_AVAILABLE_DAYS,
} from "../../constants/Program/program-constants";
import { ActivityCard } from "./components/ActivityCard";
import { countIndividualElements } from "../../utils/array-utils";
import ProgressBar from "@ramonak/react-progress-bar";
import ActivityProgressbar from "./components/ActivityProgressbar/ActivityProgressbar";
import useIsZeroTwoToOne from "../../hooks/useIsZeroToOne";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import TutorialVideo from "./components/TutorialVideo";

const ZERO_TO_ONE_EXCEPTION_ACTIVITY: any = require("../../assets/activityJson/exception-activity-zero-to-one.json");

const Excercise = ({}: any) => {
  //CONTEXT
  const { user } = useUserContext();
  const { activeSubscriptions } = useSubscriptionContext();
  const isBraincellsActiveGeniusSub = () => {
    let flag = false;

    activeSubscriptions.map((sub: any) => {
      if (
        sub.programName === "BrainCells" &&
        sub.programType === "GENIUS" &&
        sub.status === "ACTIVE"
      ) {
        flag = true;
      }
    });
    return flag;
  };
  //STATE
  const [activityList, setActivityList] = useState<any>([]);
  const [currentActivity, setCurrentActivity] = useState<any>("");
  const [finalActivityList, setFinalActivityList] = useState<any>([]);
  const [completedActivity, setCompletedActivity] = useState<any>([]);
  const [startActivity, setStartActivity] = useState<boolean>(false);
  const [activityJSON, setActivityJSON] = useState<any>({});
  const [colorTheme, setColorTheme] = useState<any>(0);
  const [showExerciseIntro, setExerciseIntro] = useState<any>(true);
  const [loadingActivity, setLoadingActivity] = useState<any>(false);
  const [hideFadeEffect, setHideFadeEffect] = useState<any>("top");
  const [audio] = useState(new Audio("./assets/sound/intro.mp3"));
  //REFS
  const [showLoader, setShowLoader] = useState<any>(false);
  const showLoaderFunc = () => {
    setShowLoader(true);
  };
  const scrollDivisionRef: any = useRef();
  //HOOKS
  const history = useHistory();
  const activityHandle = useFullScreenHandle();
  const location = useLocation();
  const { programId, excerciseId } = useParams<any>();
  const search = useLocation().search;
  //CUSTOM HOOKS
  const {
    isAgeZeroToOne,
    isZeroToOneV2,
    isSubscribedZeroToOne: isZeroToOne,
  }: any = useIsZeroTwoToOne();
  //CONSTANTS
  const BUTTONJSON = require("./color-data.json");
  const isMobile = detectIsMobileDevice();
  const isRTT: any = isRttTeller(programId);
  const zeroToOneExeptionActivities = Object.keys(
    ZERO_TO_ONE_EXCEPTION_ACTIVITY
  );
  const zeroToOneV2Addition = isZeroToOneV2 ? "&ageGroup=0-1_v2" : "";

  const currentExerciseType: any = excerciseId.split("-")[0];
  const { buttonData } = BUTTONJSON;
  const crrExeNumber = Number(excerciseId.split("_")[0].split("-")[1]);
  const queryParams = new URLSearchParams(window.location.search);
  // const crrExercisePathIndex: any = Number(queryParams.get("crr") || 0);
  const bcaCount: any = Number(queryParams.get("bca") || 0);
  const ocaCount: any = Number(queryParams.get("oca") || 0);
  const programTypeResponse = new URLSearchParams(search).get("s");
  const newProgramId =
    programTypeResponse !== null ? `${programId}_TRIAL` : programId;
  const programTypeParam =
    programTypeResponse !== null ? `_${programTypeResponse}` : "";
  const getProgramDayFromCrrExe = (crrExeNumber: any) => {
    return Math.trunc(crrExeNumber * (7 / 6));
  };
  // const programStartDay = Number(
  //   activeSubscriptions.reduce((acc, sub, index) => {
  //     if (
  //       sub.programType === "GENIUS" &&
  //       sub.ageGroup === "0-1" &&
  //       sub.programName === "BrainCells" &&
  //       sub.version === "v2"
  //     ) {
  //       if (acc <= 0) {
  //         return sub.startProgramDay;
  //       } else {
  //         const startProgramDay = Number(sub.startProgramDay);
  //         return acc - startProgramDay >= 0 ? startProgramDay : acc;
  //       }
  //     } else {
  //       return acc;
  //     }
  //   }, 0)
  // );

  const getExerciseFromProgram = (crrProgramDay: any) => {
    const remainder = Math.trunc(crrProgramDay / 7);
    return crrProgramDay - remainder;
  };

  const crrProgramDay: any = getProgramDayFromCrrExe(crrExeNumber);
  const crrExercise: any = getExerciseFromProgram(crrProgramDay);

  let completedActivityCount: any = 0;
  // const isZeroToOne = user.ageGroup === "0-1";
  completedActivityCount = countIndividualElements(
    completedActivity,
    "activityName"
  );
  // FILTERING ACTIVITIES BASED ON DAYS SINCE BIRTH FOR ZERO TO ONE
  // const shouldFilterActivityZeroToOne = (data: any) => {
  //   const activityNeedingFiltering = data.filter((activity: any) => {
  //     if (zeroToOneExeptionActivities.includes(activity)) {
  //       if (
  //         ZERO_TO_ONE_EXCEPTION_ACTIVITY[activity].showDaysAfter <=
  //         crrProgramDay
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       return true;
  //     }
  //   });
  //   return activityNeedingFiltering;
  // };

  // ? IF TEST ENV IS TRUE, FETCH JSON FROM THE LOCAL HOST
  const testingActivity = process.env.REACT_APP_TEST_ACTIVITY === "true";
  const activityInitializerPresent = activityJSON?.initializer?.componentName
    ? true
    : false;

  // ? CHECKING FOR THE UNAUTHORIZED ACCESS THROUGH CHANGING URL
  const checkUnauthorizedAccess = () => {
    const parsedValue = JSON.parse(
      localStorage.getItem(ZERO_TO_ONE_AVAILABLE_DAYS) ||
        `{"availableDays": []}`
    );
    const zeroToOneLastExercise = parsedValue["lastExercise"];
    if (crrExeNumber > Number(zeroToOneLastExercise)) {
      if (isZeroToOne) {
        history.push(`/program/BrainCells/zeroToOne`);
      } else {
        history.push(`/program/${programId}${programTypeParam && "?s=TRIAL"}`);
      }
    }
  };

  // ? FETCH ACTIVITY IMAGES IF THE PROGRAM TYPE IS RTT-GUIDE
  let rttImages: any = [];
  if (programId === RUMBLE_TUMBLE_GUIDE) {
    // ? FETCHING THE ACTIVITY IMAGES
  } else {
    // ? DON'T FETCH THE ACTIVITY IMAGES
  }
  useImgPrefetch(rttImages);

  const exerciseAttendance = async () => {
    const res = await postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/activity_tracking`,
      {
        type: "EXERCISE_LOADED",
        userId: String(user.id),
        timestamp: moment.utc().valueOf(),
        //temporary fix for typo excercise, it should be exercise
        data: { programId, exerciseId: excerciseId },
      }
    );
  };

  const activityAttendance = async (activity: string) => {
    const res = await postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/activity_tracking`,
      {
        type: "ACTIVITY",
        userId: String(user.id),
        timestamp: moment.utc().valueOf(),
        //temporary fix for typo excercise, it should be exercise
        data: {
          programName: newProgramId,
          exerciseId: excerciseId,
          activityName: activity,
          activityStartTime: moment.utc().valueOf(),
        },
      }
    );
  };
  const getCompletedActivities = async () => {
    const res: any = await getHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/activity?userId=${user.id}&exerciseId=${excerciseId}&programName=${newProgramId}`
    );
    setCompletedActivity(res.data);
  };

  const getActivityList = async () => {
    const data: any = await getHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_exercise?exerciseId=${excerciseId}&programName=${programId}${programTypeParam}${zeroToOneV2Addition}&exerciseType=${currentExerciseType}`
    );
    setActivityList(data.data);
  };

  const getProgressPercentage = () => {
    const totalActivities = activityList.length;
    const completedActivities = completedActivity.length;

    if (completedActivities >= 9) {
      return 100;
    }

    const percentage = Math.round((completedActivities / 9) * 100);
    return percentage;
  };

  const randomColor: any = (crrNum: any = -1) => {
    let randomNumber = getRandomInt(Object.keys(buttonData).length - 1);
    if (randomNumber === crrNum) {
      return randomColor(crrNum);
    } else {
      return randomNumber;
    }
  };
  const changeColor = () => {
    setColorTheme((prev: any) => {
      return randomColor(buttonData, prev);
    });
  };
  const getActivityData = async (activityName: string) => {
    if (activityName) {
      if (zeroToOneExeptionActivities.includes(activityName) && isZeroToOne) {
        let exerciseNumberJSON = crrExeNumber;
        if (activityName === "Bit-Card-Activity") {
          if (bcaCount) {
            exerciseNumberJSON = 127 + bcaCount - 1;
          }
        }
        if (
          activityName === "Word-Card-Activity" ||
          activityName === "Dot-Card-Activity"
        ) {
          if (ocaCount) {
            exerciseNumberJSON = 79 + ocaCount - 1;
          }
        }

        const exceptionActivityThreshold: any =
          ZERO_TO_ONE_EXCEPTION_ACTIVITY[activityName]["showDaysAfter"];
        const activityJsonLink = testingActivity
          ? testLink
          : `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_activity?programName=${newProgramId}&exerciseId=Exercise-${
              // exceptionActivityThreshold +
              exerciseNumberJSON
              //- exceptionActivityThreshold
            }_Age_0-1_v2&activityName=${activityName}${zeroToOneV2Addition}`;
        setLoadingActivity(true);
        const data: any = await getHttpData(activityJsonLink);
        setActivityJSON(
          crrActivityJson(
            data.data,
            activityName,
            programId,
            excerciseId,
            isZeroToOneV2
          )
        );
      } else {
        const activityJsonLink = testingActivity
          ? testLink
          : `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_activity?programName=${newProgramId}&exerciseId=${excerciseId}&activityName=${activityName}${zeroToOneV2Addition}&exerciseType=${currentExerciseType}`;
        setLoadingActivity(true);
        const data: any = await getHttpData(activityJsonLink);
        setActivityJSON(
          crrActivityJson(
            data.data,
            activityName,
            programId,
            excerciseId,
            isZeroToOneV2
          )
        );
      }
    }
    setLoadingActivity(false);
  };

  const toggleActivity = () => {
    setStartActivity((prevState) => !prevState);
    activityHandle.active && activityHandle.exit();
    getCompletedActivities();
  };

  const endActivity = () => {
    getCompletedActivities();

    if (isMobile && activityInitializerPresent) {
      activityHandle.exit();
    } else if (isMobile) {
      setStartActivity(false);
    } else {
      activityHandle.exit();
    }
  };

  const disableIntroSettings = JSON.parse(
    localStorage.getItem("DISABLE_INTRO") || "false"
  );

  const showIntroBoolean: boolean =
    process.env.REACT_APP_SHOW_INTRO !== "false" &&
    // currentActivity?.name !== "Mindfulness" &&
    currentActivity?.name !== "Custom-Flash-Cards" &&
    !disableIntroSettings &&
    !isAgeZeroToOne;
  const onActivityStartClick = async () => {
    showIntroBoolean && audio.play();
    toggleActivity();
    if (!isMobile && !activityInitializerPresent) activityHandle.enter();
    await activityAttendance(currentActivity.name);
    // getCompletedActivities();
  };

  const enterFullScreenInsideActivity = () => {
    activityHandle.enter();
  };

  const selectActivityClick = async (currentActivity: any) => {
    setCurrentActivity(currentActivity);
    await getActivityData(currentActivity.name);
  };
  const scrollTop = () => {
    scrollDivisionRef.current.scrollTop -= 2000;
  };
  const scrollBottom = () => {
    scrollDivisionRef.current.scrollTop += 2000;
  };

  const removeFade = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 10;
    const top = e.target.scrollTop === 0;
    if (bottom) {
      setHideFadeEffect("bottom");
    } else if (top) {
      setHideFadeEffect("top");
    } else {
      setHideFadeEffect(null);
    }
  };

  useEffect(() => {
    checkUnauthorizedAccess();
    exerciseAttendance();
    getCompletedActivities();
  }, []);

  useEffect(() => {
    (async () => {
      getActivityList();
    })();
  }, [excerciseId, programId]);

  useEffect(() => {
    const newActivityList = activityList.map((activity: any) => {
      const activityIconData = isRTT["isRtt"]
        ? giveIconClassRTT(activity)
        : giveIconClass(activity);

      return { name: activity, ...activityIconData };
    });
    const tempFinalActivityList = [...newActivityList].sort(
      (a: any, b: any) => a.priority - b.priority
    );

    setFinalActivityList(tempFinalActivityList);
    setCurrentActivity(tempFinalActivityList[0]);
    getActivityData(tempFinalActivityList[0]?.name);
    getCompletedActivities();
  }, [activityList]);

  useEffect(() => {
    if (!activityHandle.active) {
      setStartActivity(false);
    }
  }, [activityHandle.active]);

  useEffect(() => {
    setTimeout(() => {
      scrollBottom();
      setTimeout(() => {
        scrollTop();
      }, 1000);
    }, 2000);
  }, []);

  const exerciseHandle = useFullScreenHandle();
  const enterFullscreen = () => {
    if (!exerciseHandle.active && isMobile && !document.fullscreenElement)
      exerciseHandle.enter();
  };

  return (
    <IonPage>
      <IonContent>
        <FullScreen handle={exerciseHandle}>
          {showExerciseIntro && (
            <Loader
              loaderText={currentExerciseType}
              crrExe={isZeroToOne ? "Please Wait" : crrExeNumber}
              showLoaderFunc={showLoaderFunc}
            />
          )}
          <TrialCoupon
            completedActivities={completedActivity}
            // completedActivities={countDemo}
            totalNumberOfActivity={activityList.length}
            // totalNumberOfActivity={3}
            crrExeNumber={crrExeNumber}
          />
          <FullScreen handle={activityHandle}>
            {startActivity && (
              <ActivityStarter
                activityJSON={activityJSON}
                startActivity={startActivity}
                toggleActivity={toggleActivity}
                endActivity={endActivity}
                enterFullScreenInsideActivity={enterFullScreenInsideActivity}
                completedActivityCount={completedActivityCount}
              />
            )}
          </FullScreen>
          <OrientationChecker />
          <div onClick={enterFullscreen} className={styles["excercise-page"]}>
            {/* <img className={styles["bg-img"]} src={BACKGROUND_IMG} alt="" /> */}
            <img
              className={styles["bg-img"]}
              src="/assets/excercise/background.png"
              alt=""
            />
            <div className={styles["excercise-main"]}>
              <div
                ref={scrollDivisionRef}
                onScroll={(e) => removeFade(e)}
                className={styles["activity-list"]}
              >
                <div
                  style={{
                    display: `${hideFadeEffect === "top" ? "none" : ""}`,
                  }}
                  className={styles["fade-effect-down"]}
                ></div>
                <div
                  style={{
                    display: `${hideFadeEffect === "bottom" ? "none" : ""}`,
                  }}
                  className={styles["fade-effect-up"]}
                ></div>
                {finalActivityList.map((activity: any) => {
                  const isCompleted = completedActivity.some(
                    (el: any) => el.activityName === activity.name
                  );
                  return (
                    <div
                      style={{
                        transform: `${
                          currentActivity.name === activity.name
                            ? "scale(1.25)"
                            : ""
                        }`,
                      }}
                      key={activity.name}
                      className={styles["activity-button"]}
                      onClick={() => {
                        changeColor();
                        selectActivityClick(activity);
                      }}
                    >
                      <ActivityCard
                        activity={activity}
                        currentActivity={currentActivity}
                        isCompleted={isCompleted}
                      />
                    </div>
                  );
                })}
              </div>

              <ActivityProgressbar
                progressPercentage={getProgressPercentage()}
                completedActivity={completedActivity}
              />

              <div className={styles["activity"]}>
                <h1
                  style={{
                    color: buttonData[colorTheme]["color_code"],
                    animation: detectIsIOSDevice() ? "none" : "",
                  }}
                  className={styles["activity-name"]}
                >
                  {currentActivity?.name &&
                    currentActivity?.name.replace(/-/g, " ")}
                </h1>
                <img
                  src={BACKGROUND_DESIGN}
                  alt="Background Design"
                  className={styles["activity-bg"]}
                />
                {loadingActivity ? (
                  <ExerciseLoader
                    colorCode={buttonData[colorTheme]["color_code"]}
                  />
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      backgroundColor: "green",
                    }}
                    className="animate__animated animate__bounceIn"
                  >
                    <img
                      style={{ animation: detectIsIOSDevice() ? "none" : "" }}
                      onClick={onActivityStartClick}
                      src={buttonData[colorTheme]["PLAY_BUTTON"]}
                      alt="Start Button"
                      className={`${styles["activity-start-btn"]}`}
                    />
                  </div>
                )}
              </div>
            </div>
            <IonRouterLink
              href={
                isZeroToOne
                  ? `/program/BrainCells/zeroToOne`
                  : programTypeParam
                  ? `/program/${programId}?s=${programTypeResponse}`
                  : `/program/${programId}`
              }
            >
              <div
                style={{
                  position: "fixed",
                  top: "1rem",
                  right: "2rem",
                  width: "6vw",
                  cursor: "pointer",
                }}
              >
                <img
                  src={buttonData[colorTheme]["BACK_BUTTON"]}
                  alt="Go Back"
                />
              </div>
            </IonRouterLink>
            <ExerciseNavigation
              programName={programId}
              exerciseId={excerciseId}
              colorCode={buttonData[colorTheme]["color_code"]}
              programTypeParam={programTypeParam}
              showNavigationArrows={currentExerciseType !== "Trophy"}
            />
            {!isBraincellsActiveGeniusSub() && (
              <div
                className={`${styles["subscribe_btn_container"]} animate__animated animate__slideInRight animate__delay-4s`}
              >
                <a
                  className={styles["subscribe_btn"]}
                  href="/parents/subscriptions"
                >
                  <i className="fas fa-shopping-cart"></i> Subscribe Now
                </a>
              </div>
            )}

            {showLoader && (
              <TutorialVideo selectedActivity={currentActivity?.name} />
            )}
          </div>
        </FullScreen>
      </IonContent>
    </IonPage>
  );
};

export { Excercise, ActivityCard };
