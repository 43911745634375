const BootStrapTable = ({
  tableHeadings,
  tableContent,
  showIndex = true,
}: any) => {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {tableHeadings.map((heading: any) => {
            return <th scope="col">{heading}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {tableContent.map((row: any, index: number) => {
          return (
            <BootStrapTableRow
              showIndex={showIndex}
              rowData={row}
              count={index}
            />
          );
        })}
        {/* <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>Larry the Bird</td>
        </tr> */}
      </tbody>
    </table>
  );
};

const BootStrapTableRow = ({ rowData, count, showIndex }: any) => {
  return (
    <tr>
      {showIndex && <th scope="row">{count + 1}</th>}
      {rowData.map((data: any) => {
        return <td>{data}</td>;
      })}
    </tr>
  );
};

export default BootStrapTable;
