import {
  IonContent,
  IonCard,
  IonAccordion,
  IonLabel,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonItem,
  IonAccordionGroup,
} from "@ionic/react";
import styles from "./ClubHouse.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { detectIsMobileDevice } from "../../../utils/general-utils";

export const Clubhouse = () => {
  const [currSlideNumber, setCurrSlideNumber] = useState(1);
  const clubHouseHandle = useFullScreenHandle();
  const enterFullScreen = () => {
    if (!clubHouseHandle.active && detectIsMobileDevice())
      clubHouseHandle.enter();
  };
  return (
    <FullScreen handle={clubHouseHandle}>
      <IonPage>
        <IonHeader>
          <IonToolbar color="secondary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/parents" />
            </IonButtons>
            <IonTitle>Clubhouse</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
            onClick={enterFullScreen}
            style={{
              backgroundImage: `url("/assets/newPortalMsg/whos-the-guest-bg.jpeg")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className={styles.clubhouse}
          >
            <div className={styles.container}>
              <p className={styles.currSlideNumber}> {currSlideNumber} / 8 </p>
              <Swiper
                onActiveIndexChange={(e) => {
                  console.log(e);
                  setCurrSlideNumber(e.activeIndex + 1);
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                spaceBetween={10}
                slidesPerView={1}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      src="https://www.youtube.com/embed/VGitlUik23E"
                      width="80%"
                      height="100%"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/FkwsQVbD8hM"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/qhov6ab8mB8"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/kuC4hsfKTY0"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/v5KSUJ5QJvM"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/7nGqt6kXuW8"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/Tp4MupxnqSI"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.youtubeIframeContainer}>
                    <iframe
                      width="80%"
                      height="100%"
                      src="https://www.youtube.com/embed/dVjh5bOtbhg"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </FullScreen>
  );
};
