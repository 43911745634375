import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import styles from "./TNC.module.css";

export const TNC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar color="secondary">
            <IonTitle>Terms & Conditions</IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/parents" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div
          style={{
            backgroundImage: `url("/assets/newPortalMsg/whos-the-guest-bg.jpeg")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className={styles.tncContainer}
        >
          <div className={styles.accordianContainer}>
            <IonAccordionGroup multiple={true} value={["About Us"]}>
              <IonAccordion value="About Us">
                <IonItem slot="header" color="secondary">
                  <IonLabel>About Us</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. With an experience of over 12 years in Preschool,
                      Thought River EduTech Private Limited has brought the
                      early learing program of BrainCells for children in age
                      group 0-5 years
                    </li>
                    <li>
                      2. We have worked with over 500 children and their parents
                      for their childs holistic development
                    </li>
                    <li>
                      3. BrainCells is choice of Parents from countries - India,
                      USA, UK, Germany, France, Singapore, Dubai, Saudi Arabia,
                      Nepal and have been following BrainCells practices
                    </li>
                  </ul>
                </div>
              </IonAccordion>

              <IonAccordion value="Terms & Conditions">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Terms & Conditions</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      These Terms & Conditions (“Terms”) of (a) use of our
                      website braincells.in (“Website”), our applications
                      (“Application”) or any products or services in connection
                      with the Application/, Website/products (“Services”) or
                      (b) any modes of registrations or usage of products,
                      including through SD cards, tablets or other
                      storage/transmitting device are between Thought River
                      EduTech Private Limited (“Company/We/Us/Our”) and its
                      users (“User/You/Your”).
                    </li>
                    <li>
                      1. These Terms constitute an electronic record in
                      accordance with the provisions of the Information
                      Technology Act, 2000 and the Information Technology
                      (Intermediaries guidelines) Rules, 2011 thereunder, as
                      amended from time to time.
                    </li>
                    <li>
                      2. Please read the Terms and the privacy policy of the
                      Company (“Privacy Policy”) with respect to registration
                      with us, the use of the Application, Website, Services and
                      products carefully before using the Application, Website,
                      Services or products. In the event of any discrepancy
                      between the Terms and any other policies with respect to
                      the Application or Website or Services or products, the
                      provisions of the Terms shall prevail.
                    </li>
                    <li>
                      3. Your use/access/browsing of the Application or Website
                      or the Services or products or registration (with or
                      without payment/with or without subscription) through any
                      means shall signify Your acceptance of the Terms and Your
                      agreement to be legally bound by the same.
                    </li>
                    <li>
                      4. If you do not agree with the Terms or the Privacy
                      Policy, please do not use the Application or Website or
                      avail the Services or products. Any access to our
                      Services/Application/products through
                      registrations/subscription is non transferable.
                    </li>
                    <li>
                      5. Except as mentioned below, all information, content,
                      material, trademarks, services marks, trade names, and
                      trade secrets including but not limited to the software,
                      text, images, graphics, video, script and audio, contained
                      in the Application, Website, Services and products are
                      proprietary property of the Company (“Proprietary
                      Information”). No Proprietary Information may be copied,
                      downloaded, reproduced, modified, republished, uploaded,
                      posted, transmitted or distributed in any way without
                      obtaining prior written permission from the Company and
                      nothing on this Application or Website or Services shall
                      be or products deemed to confer a license of or any other
                      right, interest or title to or in any of the intellectual
                      property rights belonging to the Company, to the User. You
                      may own the medium on which the information, content or
                      materials resides, but the Company shall at all times
                      retain full and complete title to the information, content
                      or materials and all intellectual property rights inserted
                      by the Company on such medium. Certain contents on the
                      Website may belong to third parties. Such contents have
                      been reproduced after taking prior consent from said party
                      and all rights relating to such content will remain with
                      such third party. Further, you recognize and acknoweldge
                      that the ownership of all trademarks, copyright, logos,
                      service marks and other intellectual property owned by any
                      third party shall continue to vest with such party and You
                      are not permitted to use the same without the consent of
                      the respective third party.
                    </li>
                    <li>
                      6. Your use of our products, Website, Application and
                      Services is solely for Your personal and non-commercial
                      use. Any use of the Application, Website, Services or
                      products or their contents other than for personal
                      purposes is prohibited. Your personal and non-commercial
                      use of this Application, Website, products and / or our
                      Services shall be subjected to the following restrictions:
                    </li>
                    <li>
                      7. You may not decompile, reverse engineer, or disassemble
                      the contents of the Application and / or our Website
                      and/or Services/ products or modify, copy, distribute,
                      transmit, display, perform, reproduce, publish, license,
                      create derivative works from, transfer, or sell any
                      information or software obtained from the Application and
                      / or our Website and/or Services/products, or remove any
                      copyright, trademark registration, or other proprietary
                      notices from the contents of the Application and / or and
                      / or our Website and/or Services/products.
                    </li>
                    <li>
                      8. You will not (a) use this Application and / or our
                      Website and/or any of our product/s or Service/s for
                      commercial purposes of any kind, or (b) advertise or sell
                      the Application or any products, Services or domain names
                      or otherwise (whether or not for profit), or solicit
                      others (including, without limitation, solicitations for
                      contributions or donations) or use any public forum for
                      commercial purposes of any kind, or (c) use the
                      Application and / or Website/our products and Services in
                      any way that is unlawful, or harms the Company or any
                      other person or entity as determined by the Company.
                    </li>
                    <li>
                      9. No User shall be permitted to perform any of the
                      following prohibited activities while availing our
                      Services:
                    </li>
                    <li>
                      10. Making available any content that is misleading,
                      unlawful, harmful, threatening, abusive, tortious,
                      defamatory, libelous, vulgar, obscene, child-pornographic,
                      lewd, lascivious, profane, invasive of another's privacy,
                      hateful, or racially, ethnically or otherwise
                      objectionable;
                    </li>
                    <li>
                      11. Stalking, intimidating and/or harassing another and/or
                      inciting other to commit violence;
                    </li>
                    <li>
                      12. Transmitting material that encourages anyone to commit
                      a criminal offence, that results in civil liability or
                      otherwise breaches any relevant laws, regulations or code
                      of practice;
                    </li>
                    <li>
                      13. Interfering with any other person's use or enjoyment
                      of the Application/Website/Services;
                    </li>
                    <li>
                      14. Making, transmitting or storing electronic copies of
                      materials protected by copyright without the permission of
                      the owner, committing any act that amounts to the
                      infringement of intellectual property or making available
                      any material that infringes any intellectual property
                      rights or other proprietary rights of anyone else;
                    </li>
                    <li>
                      15. Make available any content or material that You do not
                      have a right to make available under any law or
                      contractual or fiduciary relationship, unless You own or
                      control the rights thereto or have received all necessary
                      consents for such use of the content;
                    </li>
                    <li>
                      16. Impersonate any person or entity, or falsely state or
                      otherwise misrepresent Your affiliation with a person or
                      entity;
                    </li>
                    <li>
                      17. Post, transmit or make available any material that
                      contains viruses, trojan horses, worms, spyware, time
                      bombs, cancelbots, or other computer programming routines,
                      code, files or such other programs that may harm the
                      Application/services, interests or rights of other users
                      or limit the functionality of any computer software,
                      hardware or telecommunications, or that may harvest or
                      collect any data or personal information about other Users
                      without their consent;
                    </li>
                    <li>
                      18. Access or use the
                      Application/Website/Services/products in any manner that
                      could damage, disable, overburden or impair any of the
                      Application's/Website's servers or the networks connected
                      to any of the servers on which the Application/Website is
                      hosted;
                    </li>
                    <li>19.</li>
                    <li>
                      20. Use deep-links, page-scrape, robot, spider or other
                      automatic device, program, algorithm or methodology, or
                      any similar or equivalent manual process, to increase
                      traffic to the Application/Website/Services/products, to
                      access, acquire, copy or monitor any portion of the
                      Application /Website/Services/products, or in any way
                      reproduce or circumvent the navigational structure or
                      presentation of the Application, or any content, to obtain
                      or attempt to obtain any content, documents or information
                      through any means not specifically made available through
                      the Application/ Website/Services/products; Alter or
                      modify any part of the Services; Use the Services for
                      purposes that are not permitted by: (i) these Terms; and
                      (ii) any applicable law, regulation or generally accepted
                      practices or guidelines in the relevant jurisdiction; or
                      Violate any of the terms specified under the Terms for the
                      use of the Application /Website/Services/products. By
                      submitting content on or through the Services (your
                      “Material”), you grant us a worldwide, non-exclusive,
                      royalty-free license (with the right to sublicense) to
                      use, copy, reproduce, process, adapt, modify, publish,
                      transmit, display and distribute such Material in any and
                      all media or distribution methods (now known or later
                      developed) and to associate your Material with you, except
                      as described below. You agree that others may use Your
                      Material in the same way as any other content available
                      through the Services. Other users of the Services may
                      fork, tweak and repurpose your Material in accordance with
                      these Terms. If you delete your user account your Material
                      and name may remain available through the Services In the
                      preparation of the Application/Website/Services/products
                      and contents therein, every effort has been made to offer
                      the most current, correct, and clearly expressed
                      information possible. Nevertheless, inadvertent errors may
                      occur. In particular, but without limiting anything here,
                      the Company disclaims any responsibility for any errors
                      and accuracy of the information that may be contained in
                      the Application. Any feedback from User is most welcome to
                      make the Application and contents thereof error free and
                      user friendly. Company also reserves the right and
                      discretion to make any changes/corrections or withdraw/add
                      contents at any time without notice. Neither the Company
                      nor any third parties provide any warranty or guarantee as
                      to the accuracy, timeliness, performance, completeness or
                      suitability of the information and materials found or
                      offered on Application/Website/Services/products for any
                      particular purpose. You acknowledge that such information
                      and materials may contain inaccuracies or errors and we
                      expressly exclude liability for any such inaccuracies or
                      errors to the fullest extent permitted by law. Our Website
                      provides Users with access to compiled educational
                      information and related sources. Such information is
                      provided on an As Is basis and We assume no liability for
                      the accuracy or completeness or use or non obsolescence of
                      such information. We shall not be liable to update or
                      ensure continuity of such information contained on the
                      Website. We would not be responsible for any errors, which
                      might appear in such information, which is compiled from
                      third party sources or for any unavailability of such
                      information. From time to time the Website may also
                      include links to other websites. These links are provided
                      for your convenience to provide further information. They
                      do not signify that we endorse the website(s). We have no
                      responsibility for the content of the linked website(s).
                      You may not create a link to the Website from another
                      website or document without the Company's prior written
                      consent. The contents of the Application/Services/products
                      are developed on the concepts covered in the structured
                      curriculum syllabus prescribed for students of various
                      courses. The usage of the Application/Services/products is
                      not endorsed as a substitution to the curriculum based
                      education provided by the educational institutions but is
                      intended to supplement the same by explaining and
                      presenting the concepts in a manner enabling easy
                      understanding. The basic definitions and formulae of the
                      subject matter would remain the same. The Company
                      acknowledges that there are various means of delivering
                      structured curriculum pedagogy and inclusion of methods in
                      the Application/Services/products does not imply
                      endorsement of any particular method nor exclusion imply
                      disapproval. Subscription to the Application or usage of
                      our Services/Website/products does not in any manner
                      guarantee admission to any educational institutions or
                      passing of any exams or achievement of any specified
                      percentage of marks in any examinations. Certain contents
                      in the Application/Services/Website/products (in
                      particular relating to assistance in preparations for
                      administrative services) may contain opinions and views.
                      The Company shall not be responsible for such opinions or
                      any claims resulting from them. Further, the Company makes
                      no warranties or representations whatsoever regarding the
                      quality, content, completeness, or adequacy of such
                      information and data. Some parts of the Services are
                      interactive, and we encourage contributions by Users,
                      which may or may not be subject to editorial control prior
                      to being posted. The Company accepts no responsibility or
                      liability for any material communicated by third parties
                      in this way. The Company reserves the right at its sole
                      discretion to remove, review, edit or delete any content.
                      Similarly, We will not be responsible or liable for any
                      content uploaded by Users directly on the Website,
                      irrespective of whether We have certified any answer
                      uploaded by the User. We would not be responsible to
                      verify whether such questions/answers or contents placed
                      by any User contain infringing materials or not. The
                      Company (including but not limited to its
                      subsidiaries/affiliates) may, based on any form of access
                      to the Application (including free download/trials) or
                      Services or Website or registrations through any source
                      whatsoever, contact the User through sms, email and call,
                      to give information about their offerings and products as
                      well as notifications on various important updates and/or
                      to seek permission for demonstration of its products. The
                      User expressly grants such permission to contact him/her
                      through telephone, SMS, e-mail and holds the Company
                      (including but not limited to its subsidiaries/affiliates)
                      indemnified against any liabilities including financial
                      penalties, damages, expenses in case the User's mobile
                      number is registered with Do not Call (DNC) database. By
                      registering yourself, you agree to make your contact
                      details available to Our employees, associates,
                      subsidiaries,affiliates and partners so that you may be
                      contacted for education information, offerings and
                      promotions through telephone, SMS, email etc. While the
                      Company may, based on the User's confirmation, facilitate
                      the demonstration of its products at the location sought
                      by the User, the User acknowledges that he/she has not
                      been induced by any statements or representations of any
                      person with respect to the quality or conditions of the
                      products and that User has relied solely on the
                      investigations, examinations and inspections as the User
                      has chosen to make and that the Company has afforded the
                      User the opportunity for full and complete investigations,
                      examinations and inspections. Upon registration through
                      any means whatsoever, the Company may contact You through
                      the registered mobile number or e-mail or any other mobile
                      number or contact number or email provided by You to
                      enable effective provision of Services. The User expressly
                      permits the Company to contact him/her and the student
                      utilising the Services, through the above mentioned means
                      at any time post registration. .-. Further, the Company
                      shall have the right to monitor the download and usage of
                      the Application/Services/products and the contents thereof
                      by the User/student, to analyze such usage and discuss the
                      same with the User/student to enable effective and
                      efficient usage of the Services. The User expressly
                      permits the Company to clear the doubts of the student
                      using the Application/Services/online portal by answering
                      the questions placed before it, providing study plans,
                      informing of the progress, providing feedback,
                      communicating with the student and mentoring the student
                      through telephone or e-mail on express consent of the
                      legal guardian/parent of the User or through any other
                      forum. While the Company has made efforts to train the
                      personnel engaged in the sales and services relating to
                      its products to enable quality control, it makes no
                      warranties or representations whatsoever regarding the
                      quality and competence of such personnel and would not be
                      responsible for any deviant behaviour of any such
                      personnel. Any feedback from User relating to the same is
                      most welcome and Company reserves the right and discretion
                      to take any action in this regard. Access to certain
                      elements of the Services including doubt clearance,
                      mentoring services etc may be subject to separate terms,
                      conditions and fair usage policy. The Company reserves the
                      right to determine the criteria for provision of various
                      elements of Services to the different categories of Users
                      based on its policies. Hence, subscription to the
                      Application/products or registrations do not automatically
                      entitle the User to any and all elements of Services
                      provided by the Company and the Company shall be entitled
                      to exercise its discretion while providing access to and
                      determining continuity of certain elements of Services. We
                      reserve the right to extend, cancel, discontinue,
                      prematurely withdraw or modify any of Our Services at Our
                      discretion. The Company's products and / or Services,
                      including the Application and content, are compatible only
                      with certain devices/tablets/instruments/hardware . The
                      Company shall not be obligated to provide workable
                      products and or services for any instruments that are not
                      recognized by the Company or those instruments that may be
                      purchased from any third party which are not compatible
                      with the Companys products and Services The company
                      reserves the right to upgrade the table type of compatible
                      devices as required from time to time The Company shall
                      have no responsibility for any loss or damage caused to
                      tablet or any other hardware and / or software and/or
                      instrument, including loss of data or effect on the
                      processing speed, resulting from Your use of our products
                      and Services. You have to specify the address to which the
                      shipment has to be made at the time of purchase. All
                      product(s) shall be delivered directly to the address as
                      specified at the point of ordering and You cannot, under
                      any circumstances whatsoever, change the address after the
                      order is processed. In case of any change in the address,
                      You need to specify the same to us in writing well in
                      advance to the shipping date. Any inconsistencies in name
                      or address will result in non-delivery of the product(s).
                      You acknowledge that the Company is not the manufacturer
                      of the instrument/medium/hardware and hence, any defect
                      relating to the same shall be reported to the manufacturer
                      whose details shall be specifed on the packaging and the
                      Company shall not be in any manner responsible for the
                      same. The Company does not provide any guarantee or
                      warranty relating to the instrument/medium/hardware In
                      order to access the Services and to avail the use of the
                      Application/products, You shall be required to register
                      yourself with the Application/Services/products, and
                      maintain an account with the
                      Application/Services/products. You will be required to
                      furnish certain information and details, including Your
                      name, mobile number, e-mail address, residential address,
                      grade/class of the student, school name, payment
                      information (credit/debit card details) if required, and
                      any other information deemed necessary by the Application.
                      With respect to the provision of information, the
                      following may be noted:- It is Your sole responsibility to
                      ensure that the account information provided by You is
                      accurate, complete and latest. You shall be responsible
                      for maintaining the confidentiality of the account
                      information and for all activities that occur under Your
                      account. You agree to (a) ensure that You successfully log
                      out from Your account at the end of each session; and (b)
                      immediately notify the Company of any unauthorized use of
                      Your account. If there is reason to believe that there is
                      likely to be a breach of security or misuse of Your
                      account, we may request You to change the password or we
                      may suspend Your account without any liability to the
                      Company, for such period of time as we deem appropriate in
                      the circumstances. We shall not be liable for any loss or
                      damage arising from Your failure to comply with this
                      provision. You acknowledge that Your ability to use Your
                      account is dependent upon external factors such as
                      internet service providers and internet network
                      availability and the Company cannot guarantee
                      accessibility to the Application at all times. In addition
                      to the disclaimers set forth in the Terms, the Company
                      shall not be liable to You for any damages arising from
                      Your inability to log into Your account and access the
                      services of the Application at any time. Persons who are
                      "competent/capable" of contracting within the meaning of
                      the Indian Contract Act, 1872 shall be eligible to
                      register for the Application and all Our products or
                      Services. Persons who are minors, un-discharged insolvents
                      etc. are not eligible to register for Our products or
                      Services. As a minor if You wish to use Our products or
                      Services, such use shall be made available to You by Your
                      legal guardian or parents, who has agreed to these Terms.
                      In the event a minor utilizes the
                      Application/Website/Services, it is assumed that he/she
                      has obtained the consent of the legal guardian or parents
                      and such use is made available by the legal guardian or
                      parents. The Company will not be responsible for any
                      consequence that arises as a result of misuse of any kind
                      of Our Application or any of Our products or Services that
                      may occur by virtue of any person including a minor
                      registering for the Services/products provided. By using
                      the products or Services You warrant that all the data
                      provided by You is accurate and complete and that student
                      using the Application has obtained the consent of
                      parent/legal guardian (in case of minors). The Company
                      reserves the right to terminate Your subscription and / or
                      refuse to provide You with access to the products or
                      Services if it is discovered that You are under the age of
                      18 (eighteen) years and the consent to use the products or
                      Services is not made by Your parent/legal guardian or any
                      information provided by You is inaccurate. You acknowledge
                      that the Company does not have the responsibility to
                      ensure that You conform to the aforesaid eligibility
                      criteria. It shall be Your sole responsibility to ensure
                      that You meet the required qualification. Any persons
                      under the age of 18 (eighteen) should seek the consent of
                      their parents/legal guardians before providing any
                      Information about themselves or their parents and other
                      family members on the Application. You agree to defend,
                      indemnify and hold harmless the Company, its officers,
                      directors, employees and agents, from and against any and
                      all claims, damages, obligations, losses, liabilities,
                      costs or debt, and expenses (including but not limited to
                      attorney's fees) arising from: (i) Your use of and access
                      of the Application/Website/Services; (ii) Your violation
                      of any term of these Terms or any other policy of the
                      Company; (iii) Your violation of any third party right,
                      including without limitation, any copyright, property, or
                      privacy right; or (iv) any claim that Your use of the
                      Application/Website/Services has caused damage to a third
                      party. This defense and indemnification obligation will
                      survive these Terms. In no event shall the Company, its
                      officers, directors, employees, partners or agents be
                      liable to You or any third party for any special,
                      incidental, indirect, consequential or punitive damages
                      whatsoever, including those resulting from loss of use,
                      data or profits or any other claim arising out, of or in
                      connection with, Your use of, or access to, the
                      Application. In the event of Your breach of these Terms,
                      You agree that the Company will be irreparably harmed and
                      may not have an adequate remedy in money or damages. The
                      Company therefore, shall be entitled in such event to
                      obtain an injunction against such a breach from any court
                      of competent jurisdiction. The Company's right to obtain
                      such relief shall not limit its right to obtain other
                      remedies. Any violation by You of the terms of this Clause
                      may result in immediate suspension or termination of Your
                      Accounts apart from any legal remedy that the Company can
                      avail. In such instances, the Company may also disclose
                      Your Account Information if required by any Governmental
                      or legal authority. You understand that the violation of
                      these Terms could also result in civil or criminal
                      liability under applicable laws. The Terms shall be
                      governed by and construed in accordance with the laws of
                      India, without regard to conflict of law principles.
                      Further, the Terms shall be subject to the exclusive
                      jurisdiction of the competent courts located in Bangalore
                      and You hereby accede to and accept the jurisdiction of
                      such courts. The Company has the right to change modify,
                      suspend, or discontinue and/or eliminate any aspect(s),
                      features or functionality of the Application or the
                      Services as it deems fit at any time without notice.
                      Further, the Company has the right to amend these Terms
                      from time to time without prior notice to you. The Company
                      makes no commitment, express or implied, to maintain or
                      continue any aspect of the Application. You agree that the
                      Company shall not be liable to You or any third party for
                      any modification, suspension or discontinuance of the
                      Application/Services. All prices are subject to change
                      without notice. DISCLAIMER: THIS WEBSITE, THE APPLICATION
                      AND THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS WITH ALL
                      FAULTS AND WITHOUT ANY WARRANTY OF ANY KIND. THE COMPANY
                      HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD
                      TO THE WEBSITE, APPLICATION/PRODUCTS AND THE SERVICES,
                      INCLUDING WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND
                      CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, TITLE, ACCURACY, TIMELINESS. PERFORMANCE,
                      COMPLETENESS, SUITABILITY AND NON-INFRINGEMENT.
                      ADDITIONALLY, THE COMPANY SHALL NOT BE LIABLE FOR ANY
                      DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR
                      PERFORMANCE OF THIS SITE, OR THE APPLICATION OR THE
                      SERVICES. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS
                      WEBSITE/APPLICATION/SERVICES/PRODUCTS IS ENTIRELY AT YOUR
                      OWN RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT SHALL BE
                      YOUR OWN RESPONSIBILITY TO ENSURE THAT SERVICES PROVIDED
                      BY US MEET YOUR SPECIFIC REQUIREMENTS. Any feedback You
                      provide with respect to the Application shall be deemed to
                      be non-confidential. The Application shall be free to use
                      such information on an unrestricted basis. Further, by
                      submitting the feedback, You represent and warrant that
                      (i) Your feedback does not contain confidential or
                      proprietary information of You or of third parties; (ii)
                      the Company is not under any obligation of
                      confidentiality, express or implied, with respect to the
                      feedback; (iii) the Application may have something similar
                      to the feedback already under consideration or in
                      development; and (iv) You are not entitled to any
                      compensation or reimbursement of any kind from the Company
                      for the feedback under any circumstances, unless
                      specified. Under no circumstances shall the Company be
                      held responsible in any manner for any content provided by
                      other users even such content is offensive, hurtful or
                      offensive. Please exercise caution while accessing the
                      Application. Customer Care: We make all best endeavors to
                      provide You with a pleasant experience. In the unlikely
                      event that You face any issues, please contact us at
                      braincells.tre@gmail.com.
                    </li>
                  </ul>
                </div>
              </IonAccordion>

              <IonAccordion value="Privacy">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Privacy</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      We value your trust. In order to honour that trust,
                      BrainCells & Thought River EduTech Private Limited adheres
                      to ethical standards in gathering, using, and safeguarding
                      any information you provide. Thought River Edu Group
                      (operating under the brandname BrainCells), is a leading
                      edtech company, incorporated in India, for imparting
                      learning. This privacy policy governs your use of the
                      application BrainCells ('Application'),
                      https://braincells.in ('Website') and the other associated
                      applications, products, websites and services managed by
                      the Company. Please read this privacy policy ('Policy')
                      carefully before using the Application, Website, our
                      services and products, along with the Terms of Use ('ToU')
                      provided on the Application and the Website. Your use of
                      the Website, Application, or services in connection with
                      the Application, Website or products ('Services'), or
                      registrations with us through any modes or usage of any
                      products including through SD cards, tablets or other
                      storage/transmitting device shall signify your acceptance
                      of this Policy and your agreement to be legally bound by
                      the same. If you do not agree with the terms of this
                      Policy, do not use the Website, Application our products
                      or avail any of our Services. User Provided Information
                      The Application/Website/Services/products obtains the
                      information you provide when you download and register for
                      the Application or Services or products. When you register
                      with us, you generally provide (a) your name, age, email
                      address, location, phone number, password and your ward's
                      educational interests; (b) transaction-related
                      information, such as when you make purchases, respond to
                      any offers, or download or use applications from us; (c)
                      information you provide us when you contact us for help;
                      (d) information you enter into our system when using the
                      Application/Services/products, such as while asking
                      doubts, participating in discussions and taking tests. The
                      said information collected from the users could be
                      categorized as “Personal Information”, “Sensitive Personal
                      Information” and “Associated Information”. Personal
                      Information, Sensitive Personal Information and Associated
                      Information (each as individually defined under this
                      Information Technology (Reasonable security practices and
                      procedures and sensitive personal data or information)
                      Rules, 2011 (the “Data Protection Rules”)) shall
                      collectively be referred to as 'Information' in this
                      Policy. We may use the Information to contact you from
                      time to time, to provide you with the Services, important
                      information, required notices and marketing promotions. We
                      will ask you when we need more information that personally
                      identifies you (personal information) or allows us to
                      contact you. We will not differentiate between who is
                      using the device to access the Application, Website or
                      Services or products, so long as the log in/access
                      credentials match with yours. In order to make the best
                      use of the Application/Website/Services/products and
                      enable your Information to be captured accurately on the
                      Application/Website/Services/products, it is essential
                      that you have logged in using your own credentials. We
                      will, at all times, provide the option to you to not
                      provide the Personal Information or Sensitive Personal
                      Information, which we seek from you. Further, you shall,
                      at any time while using the Application/Services/products,
                      also have an option to withdraw your consent given earlier
                      to us to use such Personal Information or Sensitive
                      Personal Information. Such withdrawal of the consent is
                      required to be sent in writing to us at the contact
                      details provided in this Policy below. In such event,
                      however, the Company fully reserves the right not to allow
                      further usage of the Application or provide any
                      Services/products thereunder to you. Automatically
                      Collected Information In addition, the
                      Application/products/Services may collect certain
                      information automatically, including, but not limited to,
                      the type of mobile device you use, your mobile devices
                      unique device ID, the IP address of your mobile device,
                      your mobile operating system, the type of mobile Internet
                      browsers you use, and information about the way you use
                      the Application/Services/products. As is true of most
                      Mobile applications, we also collect other relevant
                      information as per the permissions that you provide. We
                      use an outside credit card processing company to bill you
                      for goods and services. These companies do not retain,
                      share, store or use personally identifiable information
                      for any other purpose.
                    </li>
                  </ul>
                </div>
              </IonAccordion>

              <IonAccordion value="Cancellations & Refunds">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Cancellations & Refunds</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      1. We can cancel your subscriptoin order if a request is
                      made within 24 hours of placing the subscription order and
                      making the payment. The request has to be made at
                      braincells.tre@gmail.com with your email ID and Phone
                      number used during registration
                    </li>
                    <li>
                      2. Cancellation charges of INR 1000.00 shall be applicable
                      for such cancellations and refunds
                    </li>
                    <li>
                      3. Such cancellations reported within 24 hours of
                      subscription order shall be refunded and amount after
                      deducting cancellation charges of INR 1000 will be
                      credited to your bank account within 5-7 working days
                    </li>
                  </ul>
                </div>
              </IonAccordion>

              <IonAccordion value="Contact Us">
                <IonItem slot="header" color="secondary">
                  <IonLabel>Contact Us</IonLabel>
                </IonItem>
                <div
                  className={`ion-padding ${styles.accordianContent}`}
                  slot="content"
                >
                  <ul style={{ listStyleType: "disc" }}>
                    <li>You can Reach us at</li>
                    <li>1. THOUGHT RIVER EDUTECH PRIVATE LIMITED</li>
                    <li>2. Phone: +91-84596 28238</li>
                    <li>3. Email : braincells.tre@gmail.com</li>
                    <li>
                      4. Corporate Address: F1, Runwal Maestro, Bhusari Colony,
                      Paud road, Kothrud, Pune, India 411038
                    </li>
                  </ul>
                </div>
              </IonAccordion>
            </IonAccordionGroup>

            <p
              style={{
                textAlign: "center",
                marginTop: "1rem",
                fontSize: "1rem",
              }}
            >
              We are always there to help you. Call us on +91 84596 28238 if you
              need any specific help
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
