import { useEffect, useState } from "react";
import styles from "./TutorialVideo.module.css";
import ExerciseLoader from "../../../components/generalComponents/loaders/ExerciseLoader/ExerciseLoader";
import { CommunityLoader } from "../../../components/generalComponents/loaders/CommunityLoader/CommunityLoader";
const TUTORIAL_HIDDEN = "";

const TutorialVideo = ({ selectedActivity }: any) => {
  const activityFileJson = require("../../../assets/activityJson/activity-file.json");

  const [showIframe, setShowIframe] = useState<boolean>(false);
  const [hideTutorial, setHideTutorial] = useState<boolean>(false);
  const videoLink = activityFileJson[selectedActivity]?.tutorialVideo;

  const handleInfoClick = (e: any) => {
    e.stopPropagation();
    showTutorial();
  };
  const showTutorial = () => {
    setHideTutorial(false);
    changeActivityTutorialStatus(selectedActivity, "show");
  };
  const onShowIframeClick = (e: any) => {
    e.stopPropagation();
    console.log("show iframe clicked");
    setShowIframe(true);
  };
  const onClickHideIframe = () => {
    setShowIframe(false);
  };
  const handleShowIframe = () => {};
  const checkActivityTutorialHidden = (activityName: any) => {
    const tutorialHidden = JSON.parse(
      localStorage.getItem(TUTORIAL_HIDDEN) || "{}"
    );
    return tutorialHidden[activityName];
  };
  const changeActivityTutorialStatus = (activityName: any, status: any) => {
    const tutorialHidden = JSON.parse(
      localStorage.getItem(TUTORIAL_HIDDEN) || "{}"
    );
    tutorialHidden[activityName] = status === "hide" ? true : false;
    localStorage.setItem(TUTORIAL_HIDDEN, JSON.stringify(tutorialHidden));
  };

  const handleHideTutorial = (e: any) => {
    e.stopPropagation();
    console.log("hide tutorial clicked");
    changeActivityTutorialStatus(selectedActivity, "hide");
    setHideTutorial(true);
  };

  useEffect(() => {
    if (selectedActivity) {
      const isHidden = checkActivityTutorialHidden(selectedActivity);
      if (isHidden) {
        setHideTutorial(true);
      } else {
        setHideTutorial(false);
      }
    }
  }, [selectedActivity]);

  const infoIcon = (
    <div
      className={styles["exe-number-wrapper"]}
      onClick={(e) => handleInfoClick(e)}
    >
      <div className={styles["current-exe-number"]}>i</div>
    </div>
  );
  const iFrameVideo = (
    <>
      <div className={styles["tutorial_video"]}>
        <div
          onClick={(e) => onShowIframeClick(e)}
          className={`${styles["tutorial_video_container"]} animate__animated animate__slideInRight `}
        >
          <div
            onClick={(e) => handleHideTutorial(e)}
            className={styles["closeBtn"]}
          >
            <i className="fas fa-times-circle"></i>
          </div>
          <div className={styles["play_button_container"]}>
            <i className="fas fa-play-circle"></i>
          </div>
          <div className={styles["tutorial_text_container"]}>
            <p className={styles["tutorial_title"]}>
              {selectedActivity && selectedActivity.replace(/-/g, " ")}
            </p>
            <p className={styles["tutorial_text"]}>Tutorial</p>
          </div>
        </div>
      </div>
      {showIframe && (
        <YouTubeVideo videoLink={videoLink} hideIframe={onClickHideIframe} />
      )}
    </>
  );

  return videoLink !== undefined
    ? hideTutorial
      ? infoIcon
      : iFrameVideo
    : null;
};

const YouTubeVideo = ({ videoLink, hideIframe }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onIframeLoad = () => {
    console.log("iframe loaded");
    setIsLoading(false);
  };

  const onVideoEnded = () => {
    console.log("video ended");
    hideIframe();
  };

  return (
    <div className={styles["video_wrapper"]}>
      {isLoading && (
        <div
          className={`${styles["loading_container"]} animate__animated animate__fadeInBottomRight `}
        >
          <CommunityLoader color="#0d8ecc" />
        </div>
      )}
      <div className={styles["video_container"]}>
        <iframe
          style={{
            borderRadius: "25px",
            visibility: isLoading ? "hidden" : "visible",
          }}
          width="100%"
          height="100%"
          src={`${videoLink}?enablejsapi=1&origin=http://localhost:8100/`}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          onLoad={onIframeLoad}
        ></iframe>
      </div>

      <button className={styles["video_close_button"]} onClick={hideIframe}>
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
};

export default TutorialVideo;
