import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { useHistory } from "react-router";
import Countdown from "../../../components/generalComponents/CountDown/CountDown";
import { useCountdown } from "../../../hooks/useCountdown";
import { useSubscriptionContext } from "../../../store/context/subscription-context";
import { useUserContext } from "../../../store/context/user-context";
import { postHttpData } from "../../../utils/http-util";
import styles from "./TrialCoupon.module.css";
import { v4 as uuid } from "uuid";
import moment from "moment";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";

const TrialCoupon = ({
  completedActivities,
  totalNumberOfActivity,
  crrExeNumber,
}: any) => {
  // ? SHOW THE TRIAL EXERCISE COUPON
  const { activeSubscriptions } = useSubscriptionContext();
  const {
    user: { id, name },
  } = useUserContext();
  const [totalCompletedActivities, setTotalCompletedActivities] =
    React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const { couponDay, discountPercentage, startCount } = useCountdown();
  const countStartedRef: any = useRef(false);
  const history = useHistory();
  const showCouponInitialValue = () => {
    const isAnyProgramSubscribed =
      activeSubscriptions.filter((sub: any) => {
        return sub.programType !== "TRIAL";
      }).length > 0;
    if (!isAnyProgramSubscribed && crrExeNumber === couponDay + 1) {
      if (localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`)) {
        if (
          localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`) === "true"
        ) {
          if (!countStartedRef.current) {
            startCount("");
            countStartedRef.current = true;
          }
          return true;
        } else if (
          localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`) ===
          "completed"
        ) {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const [showCoupon, setShowCoupon] = React.useState(showCouponInitialValue());
  const createNewCoupon = async () => {
    const now = moment();
    const coupon = postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon`,
      {
        applicabilityCriteria: "",
        code: `WELCOME-${discountPercentage}`,
        createdAt: `${now.format("YYYY-MM-DDTHH:mm:ss")}`,
        description: "Welcome coupon for completing trial activities",
        discountAmount: "0",
        discountPercent: `${discountPercentage}`,
        id: `${uuid()}`,
        name: `WELCOME-${discountPercentage}`,
        programId: "",
        status: "ASSIGNED",
        usageType: "ONETIME",
        userId: `${id}`,
        userName: `${name}`,
        validUntil: `${now.add(3, "days").format("YYYY-MM-DD")}`,
      }
    );
  };

  const countCompletedActivities = () => {
    const activityName: any = [];
    completedActivities.forEach((activity: any) => {
      if (!activityName.includes(activity["activityName"])) {
        activityName.push(activity["activityName"]);
      }
    });
    // setTotalCompletedActivities(activityName.length);
    setTotalCompletedActivities(completedActivities.length);
  };

  const toggleCounterModal = () => {
    if (
      totalCompletedActivities >= totalNumberOfActivity &&
      totalCompletedActivities !== 0
    )
      setShowCoupon(false);
  };

  const onRedeemClick = () => {
    history.push(
      `/parents/subscriptions/coupon/${`WELCOME-${discountPercentage}`}?ct=code`
    );
  };
  useEffect(() => {
    // console.log(localStorage.getItem(`TRIAL_COUPON_COUNT_${couponDay}`));
    if (
      totalCompletedActivities === totalNumberOfActivity &&
      totalCompletedActivities !== 0 &&
      totalNumberOfActivity !== 0
      // &&
      // !localStorage.getItem(`TRIAL_COUPON_COUNT_${couponDay}`)
    ) {
      // DO A POST REQUEST TO THE SERVER TO CREATE THE COUPON
      if (discountPercentage > 0) {
        createNewCoupon();
      }
      setTimeout(() => {
        setShowModal(true);
        startCount("trial_coupon");
      }, 1000);
    }
  }, [totalCompletedActivities]);

  React.useEffect(() => {
    countCompletedActivities();
  }, [completedActivities]);

  const allActivitiesCompleted =
    totalCompletedActivities >= totalNumberOfActivity;

  return showCoupon ? (
    <>
      {showModal ? (
        <Modal
          discountPercentage={discountPercentage}
          hideModal={() => {
            setShowModal(false);
          }}
          hideCoupon={() => {
            setShowCoupon(false);
          }}
          onRedeemClick={onRedeemClick}
        />
      ) : (
        <div
          onClick={() => {
            if (
              localStorage.getItem(`TRIAL_COUPON_COUNT_${couponDay}`) &&
              totalCompletedActivities >= totalNumberOfActivity
            ) {
              setShowModal(true);
            }
          }}
          className={styles["trial_coupon_wrapper"]}
          style={{
            backgroundColor: allActivitiesCompleted ? "#fdf0dc" : "#f59b19",
          }}
        >
          {allActivitiesCompleted ? (
            <img
              className={styles["trophy"]}
              src="./assets/excercise/award.png"
              alt="trophy"
            ></img>
          ) : (
            <p className={styles["trial_coupon_text"]}>
              Complete <br />
              {totalCompletedActivities > 0 ? (
                <span className={styles["trial_coupon_text_span"]}>
                  {`${totalNumberOfActivity - totalCompletedActivities} more`}
                </span>
              ) : (
                "all the"
              )}{" "}
              <br />
              activities to unlock {discountPercentage}% discount.
              {/* Completed {`${totalCompletedActivities}`} activities out of{" "} */}
              {/* {`${totalNumberOfActivity}`}{" "} */}
            </p>
          )}
        </div>
      )}
    </>
  ) : null;
};

const Modal = ({
  discountPercentage,
  hideModal,
  hideCoupon,
  onRedeemClick,
}: any) => {
  return (
    <div className={styles["modal_wrapper"]}>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
      />
      <div className={styles["modal"]}>
        <div className={styles["modal_close"]}>
          <i onClick={hideModal} className="fas fa-times"></i>
        </div>
        <div className={styles["modal_header"]}>
          <p>
            Congratulations you have earned a
            <span className={styles["discount_text"]}>
              {" "}
              {discountPercentage}% discount{" "}
            </span>
            coupon.
          </p>
        </div>
        <div className={styles["modal_body"]}>
          <Countdown func={hideCoupon} showUntilExpires={false} />
          <button onClick={onRedeemClick} className={styles["redeem_button"]}>
            <i className="fas fa-ticket-alt"></i> Redeem Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrialCoupon;
