import { useLocation } from "react-router";
import styles from "./CommunityNavbar.module.css";
import ProfilePictureRound from "../ProfilePictureRound/ProfilePictureRound";
import {
  cloudinaryAvatarLink,
  communityGetLink,
} from "../../../../constants/Services/services-constants";
import { useUserContext } from "../../../../store/context/user-context";
import { NOTIFICATION_LAST } from "../../../../constants/Program/program-constants";
import { useEffect, useState } from "react";
import { getHttpData } from "../../../../utils/http-util";

const CommunityNavbar = ({ heading }: any) => {
  const location = useLocation();
  const { user } = useUserContext();
  const [userNotificationNumber, setUserNotificationNumber] = useState<any>([]);

  const lastNotificationNumber = Number(
    localStorage.getItem(NOTIFICATION_LAST)
  );
  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const response = await getHttpData(
          `${communityGetLink}?userId=${user.id}`
        );
        const notificationArr: any = [];
        response.data.forEach((element: any) => {
          element["comments"].forEach((comment: any) => {
            comment["type"] = "comment";
            notificationArr.push(comment);
          });
          element["likes"].forEach((like: any) => {
            like["type"] = "like";
            notificationArr.push(like);
          });
        });

        setUserNotificationNumber(
          notificationArr.length - lastNotificationNumber
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserPosts();
  }, []);

  return (
    <div className={styles["community_navbar"]}>
      <div className={styles["community_navbar__heading_container"]}>
        <p className={styles["community_navbar__heading"]}>{heading}</p>
      </div>
      <div className={styles["notification_container"]}>
        <a href="/community/notification">
          <div className={styles["community_navbar_notification_wrapper"]}>
            <i className="far fa-bell"></i>
            {userNotificationNumber > 0 && (
              <div className={styles["notification_number_wrapper"]}>
                <p className={styles["notification_number"]}>
                  {userNotificationNumber}
                </p>
              </div>
            )}
          </div>
        </a>
        <div className={styles["notification_container__profile_picture"]}>
          <ProfilePictureRound
            profilePictureLink={`${cloudinaryAvatarLink}${user.id}`}
            userId={user.id}
          />
        </div>
      </div>
    </div>
  );
};

export default CommunityNavbar;
