import styles from "./CircleLoader.module.css";

const CircleLoader = () => {
  return (
    <div className={styles["loadingio-spinner-rolling-9vl12ajybri"]}>
      <div className={styles["ldio-wsemtpe4w1o"]}>
        <div></div>
      </div>
    </div>
  );
};

export default CircleLoader;
