/** @format */

import React from "react";
import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonAccordion,
  IonButton,
  IonLabel,
  IonSelect,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRouterLink,
  IonButtons,
  IonBackButton,
  IonItem,
  IonSelectOption,
  IonAccordionGroup,
} from "@ionic/react";
import styles from "./BrainFunction.module.css";
import BarDataRender from "./Components/BarDataRender";
import BarChart from "./Components/BarChart";
import BubbleChart from "./Components/BubbleChart";

export const BrainFunction = () => {
  const data = [
    { value: "90%", color: "green" },
    { color: "red", value: "90%" },
    { color: "blue", value: "20%" },
    { color: "pink", value: "50%" },
    { color: "yellow", value: "60%" },
    { color: "brown", value: "100%" },
  ];
  const colors = [
    "red",
    "green",
    "blue",
    "yellow",
    "pink",
    "purple",
    "brown",
    "skyblue",
  ];
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>Brain Function</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.mainContainer}>
          <BarDataRender />
          {/* <BubbleChart /> */}
          {/* <IonCard className={styles.barGraph}>
            {data.map((item: any, i) => {
              return (
                <BarCompenent
                  size={item["value"]}
                  label={item["color"]}
                  color={colors[i % colors.length]}
                />
              );
            })}
          </IonCard> */}
        </div>
      </IonContent>
    </IonPage>
  );
};

function BarCompenent(props: any) {
  const { size, label, color } = props;

  return (
    <div className={styles.barCompenent}>
      <div className={styles.bar}>
        <div
          style={{ height: size, width: "100%", backgroundColor: color }}></div>
        <h3> {label} </h3>
      </div>
    </div>
  );
}
