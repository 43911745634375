import moment from "moment";
export const convertUtcDayToLocal = (weekDay: any, time: any, format: any) => {
  const weekDayInUTC = weekDay.toUpperCase();
  const formatType = format === 12 ? "hh:mm a" : "HH:mm";
  // const myTime = moment
  //   .utc(`${weekDayInUTC} ${time}`, "dddd HH:mm")
  //   .format("dddd HH:mm");
  const localWeekDay = moment
    .utc(`${weekDayInUTC} ${time}`, "dddd HH:mm")
    .local()
    .format("dddd");
  const localTime = moment
    .utc(`${weekDayInUTC} ${time}`, "dddd HH:mm")
    .local()
    .format(formatType);
  return { localWeekDay, localTime };
};

export const dateTimeFromUTCtoLocal = (date: any, time: any) => {
  if (date && time) {
    const formattedDate = moment.utc(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    const formattedTime = moment.utc(time, "HH:mm").format("HH:mm");
    const dateTime = moment.utc(`${formattedDate} ${formattedTime}`);
    const localDate = dateTime.local().format("DD-MM-YYYY");
    const localTime = dateTime.local().format("hh:mm a");
    const localTime24 = dateTime.local().format("HH:mm");
    const localDay = dateTime.local().format("dddd");
    // const readableTime = dateTime.local().format("MMMM, dddd Do, YYYY h:mm A");
    const readableTime = dateTime.local().format("MMMM, Do, YYYY, h:mmA");
    return { localDate, localTime, localDay, localTime24, readableTime };
  } else {
    return {
      localDate: "",
      localTime: "",
      localDay: "",
      localTime24: "",
      readableTime: "",
    };
  }
};

export const currentUtcTimeDate = () => {
  return moment.utc().format();
};
export const currentUtcDate = () => {
  const currentDate = moment.utc().format("YYYY-MM-DD");
  return currentDate;
};
export const filterByUpcoming = (
  arr: any,
  key: any = "eventDate",
  weekday: any = null
) => {
  // const todaysDate = "2023-04-28";
  const todaysDate = moment().format("YYYY-MM-DD");
  const weekdayNumber = weekday ? moment(weekday, "dddd").weekday() : 0;

  const upcomingBookedTopics = arr.filter((bookedTopicData: any) => {
    const date = bookedTopicData[key];
    const time = bookedTopicData.eventTime || "00:00";
    const { localDate } = dateTimeFromUTCtoLocal(date, time);
    const localDateUpdatedFormat = moment(localDate, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    if (weekday) {
      //SUBTRACT N, WHERE N IS, 7 - WEEK DAY NUMBER, WEEK DAY INDEXED FROM 0, SO 1 MEANS SATURDAY
      const subtractedDate = moment(localDate, "DD-MM-YYYY")
        .subtract(7 - weekdayNumber, "days")
        .format("YYYY-MM-DD");
      return moment(subtractedDate).isSameOrAfter(todaysDate);
    } else {
      return moment(localDateUpdatedFormat).isSameOrAfter(todaysDate);
    }
  });
  return upcomingBookedTopics;
};
export const differenceBetweenDays = (date: any, dateTwo: any) => {
  const refactoredStartDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  const secondDate = moment(dateTwo, "DD-MM-YYYY").format("YYYY-MM-DD");
  // console.log(moment(refactoredStartDate).diff(secondDate, "days"));
  return Number(moment(refactoredStartDate).diff(secondDate, "days"));
};

export const calculateInBetweenDates = (
  date: any,
  dateTwo: any,
  todayDate: any
) => {
  const refactoredStartDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  const secondDate = moment(dateTwo, "DD-MM-YYYY").format("YYYY-MM-DD");
  return moment(todayDate).isBetween(refactoredStartDate, secondDate);
};

export const isPassedTodayDate = (date: any, todaysDate: any) => {
  const refactoredEndDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  return moment(todaysDate).isAfter(refactoredEndDate);
};

export const isBeforeTodayDate = (date: any, todaysDate: any) => {
  const refactoredStartDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  return moment(todaysDate).isBefore(refactoredStartDate);
};

export const diffBetweenToday = (
  date: any,
  todaysDate: any,
  format: any = "DD-MM-YYYY"
) => {
  const refactoredStartDate = moment(date, format).format("YYYY-MM-DD");
  return moment(refactoredStartDate).diff(todaysDate, "days");
};

export const isSameDay = (date: any, format: any, todaysDate: any) => {
  const refactoredStartDate = moment(date, format).format("YYYY-MM-DD");
  return moment(refactoredStartDate).isSame(todaysDate);
};
