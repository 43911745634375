import { IonButton, IonContent, IonPage } from "@ionic/react";
import styles from "./RttOnlineProgram.module.css";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import { useState, useEffect } from "react";
import CircleLoader from "../../Parents/Subscription/components/CircleLoader";
import TopicCard from "../components/TopicCard/TopicCard";
import ViewDetailsModal from "../components/ViewDetailsModal/ViewDetailsModal";
import { useRttOnlineContext } from "../../../store/context/rtt-online-context";
import TeacherSelectionModal from "./components/TeacherSelectionModal/TeacherSelectionModal";
import { useHistory } from "react-router";
import { getHttpData } from "../../../utils/http-util";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import { useUserContext } from "../../../store/context/user-context";
import { filterByUpcoming } from "../../../utils/date-utils";
import RttDemoVideoModal from "./components/RttDemoVideoModal/RttDemoVideoModal";
import { FIRST_BOOKING } from "../../../constants/utility/utility-constants";
import CouponApplicationModal from "../../../components/generalComponents/CouponApplicationModal/CouponApplicationModal";

type RttOnlineData = {
  id: String;
  weekNumber: String;
  weekTheme: String;
  weekStartDate: String;
  weekEndDate: String;
  weekAnnualTenure: String;
  effectiveStartWeek: String;
  batchType: String;
  imagePath: String;
  teachers: {
    id: String;
    name: String;
    email: String;
    timeSlots: { date: String; timeZone: String[] }[];
    link: String;
  }[];
};

type IsTeacherModalOpen = {
  showModal: boolean;
  data: Object;
};

type DetailsModalData = {
  showModal: boolean;
  data: any;
};

export type TeacherData = {
  _id: {
    $oid: string;
  };
  id: string;
  name: string;
  email: string;
  phone: number;
  description: string;
  dob: string;
  active: boolean;
  profilePicture: string;
  skills: Array<String>;
  experience: number;
  meetingLink: string;
  meetingAccount: string;
  availability: Array<{
    weekDay: string;
    startTime: string;
    endTime: string;
  }>;
};

const RttOnlineProgram = () => {
  // ! Remove after development
  const jsonData = require("../RttOnline.json");
  // ! Remove after development
  //CONTEXT
  const {
    user: { id, ageGroup },
  } = useUserContext();
  const { rttData, getAllRttData, rttDataState, rttDataDispatch } =
    useRttOnlineContext();
  //HOOKS
  const history = useHistory();
  //STATE
  const [rttOnlineData, setRttOnlineData] = useState<RttOnlineData[]>([]);
  const [bookedTopicsData, setBookedTopicsData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [themeData, setThemeData] = useState([]);
  const [showCouponApplyModal, setShowCouponApplyModal] =
    useState<boolean>(false);
  const [isTeacher, setIsTeacher] = useState<any>(false);
  const [wobblePrice, setWobblePrice] = useState<any>(false);
  const [isTeacherModalOpen, setIsTeacherModalOpen] =
    useState<IsTeacherModalOpen>({
      showModal: false,
      data: {},
    });
  const [couponInfo, setCouponInfo] = useState<any>({});
  const [detailsModalData, setDetailsModalData] = useState({
    showModal: false,
    data: {},
  });
  const [showRttDemoModal, setShowRttDemoModal] = useState<boolean>(false);
  const [isShowingAnimation, setIsShowingAnimation] = useState<boolean>(false);
  const [rttTeachersData, setRttTeachersData] = useState<TeacherData[]>([]);
  //CONSTANTS
  const isCouponApplied = Object.keys(couponInfo).length > 0;
  const showAnimation = () => {
    setIsShowingAnimation(true);
    setTimeout(() => {
      setIsShowingAnimation(false);
    }, 2000);
  };

  const closeRttDemoModal = () => {
    setShowRttDemoModal(false);
  };

  const bookedTabs =
    bookedTopicsData.length === 0 ? "Rtt-Demo-Video" : "Selected-Topics";

  const queryParams = new URLSearchParams(window.location.search);
  const tabsArray = isTeacher
    ? ["all", "Booking Info"]
    : ["1", "4", "12", bookedTabs];
  const selectedTabQuery: any = queryParams.get("st");
  const selectedTabFinal = isTeacher
    ? "all"
    : selectedTabQuery === "booked"
    ? "Selected-Topics"
    : selectedTabQuery === "demo"
    ? "Rtt-Demo-Video"
    : "4";

  const [selectedTab, setSelectedTab] = useState<any>(selectedTabFinal);

  useEffect(() => {
    showAnimation();
  }, [selectedTab]);

  const {
    isFirstBooking,
    rttCouponData: { couponType, couponAmount },
  } = rttDataState;
  const selectedTopics = rttDataState.selectedTopics;

  const getRttOnlineData = async () => {
    const res = await getHttpData(
      // `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineData/`
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/course_program?ageGroup=${ageGroup}&programName=Rumble-Tumble-Time-Online`
    );
    const dataArr: any = Object.keys(res.data).map((key: any) => {
      return res.data[key];
    });
    setThemeData(dataArr);
    const upcomingTopics = filterByUpcoming(dataArr, "weekEndDate", "Saturday");
    return upcomingTopics;
    // setRttOnlineData(filterByUpcoming(jsonData, "weekStartDate"));
    // setRttOnlineData(jsonData);
  };

  const filterByNotBought = (dataArr: any, comparisonArr: any) => {
    const eventIdArr = comparisonArr.map(
      (bookedTopics: any) => bookedTopics.eventId
    );
    return dataArr.filter((topic: any) => {
      return !eventIdArr.includes(topic.id);
    });
  };

  const getBookedTopicsData = async () => {
    const res = await getHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineBooking?userId=${id}`
    );
    // const todaysDate = moment().format("YYYY-MM-DD");
    const bookingsData = res.data;
    //! REMOVE TOMORROW
    if (bookingsData.length <= 0) {
      //! DISPATCH TO UPDATE CONTEXT
      // rttDataDispatch({
      //   type: FIRST_BOOKING,
      // });
    }
    // const upcomingBookedTopics = filterByUpcoming(bookingsData);
    const upcomingBookedTopics = bookingsData;

    const sortedUpcomingTopics = upcomingBookedTopics.sort((a: any, b: any) => {
      const exerciseNo = Number(a.eventId.split("-")[1].split("")[0]);
      const exerciseNoB = Number(b.eventId.split("-")[1].split("")[0]);
      return exerciseNo - exerciseNoB;
    });
    // sortedUpcomingTopics.length <= 0 && setSelectedTab("Rtt-Demo-Video");
    return { sortedUpcomingTopics, bookingsData };
  };
  const getRttOnlinePageData = async () => {
    setLoading(true);
    try {
      const response = await Promise.all([
        getRttOnlineData(),
        getBookedTopicsData(),
      ]);
      const rttTopicsData = response[0];
      const { sortedUpcomingTopics, bookingsData } = response[1];
      const notBoughtTopics = filterByNotBought(rttTopicsData, bookingsData);
      setBookedTopicsData(sortedUpcomingTopics);
      setRttOnlineData(notBoughtTopics);
      setLoading(false);
      return response;
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const dispatchSelectedTopicsData = () => {
    if (selectedTab !== bookedTabs) {
      for (let i = 0; i < Number(selectedTab); i++) {
        rttDataDispatch({
          type: "ADD_TO_CART",
          payload: rttOnlineData[i],
        });
      }
    }
  };

  const isTeacherSelected = rttDataState.selectedTeacher !== "" ? true : false;

  const proceedButtonHandler = (): void => {
    if (isTeacherSelected) {
      setIsTeacherModalOpen({ showModal: false, data: {} });

      history.push("/rtt-online/booking");
    } else {
      dispatchSelectedTopicsData();

      setIsTeacherModalOpen({
        ...isTeacherModalOpen,
        showModal: true,
      });
    }
  };

  const getRttOnlineTeacherData = async () => {
    try {
      const response = await getHttpData(
        `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineTeacherData`
      );
      const activeTeachers = response.data.filter(
        (teacher: any) => teacher.active === true
      );
      const isCurrentUserTeacher = activeTeachers.filter((teacherData: any) => {
        return teacherData.accountId.includes(`${id}`);
      });
      if (isCurrentUserTeacher.length > 0) {
        setSelectedTab("all");
        setIsTeacher(isCurrentUserTeacher[0]);
      }
      const ageGroupTeachers = activeTeachers.filter((teacher: any) => {
        return teacher.ageGroup.includes(ageGroup);
      });
      setRttTeachersData(ageGroupTeachers);
    } catch (error) {
      console.log(error);
    }
  };

  const showCouponApplyClick = () => {
    setShowCouponApplyModal(true);
  };

  useEffect(() => {
    //To fetch the RttOnline data and subscribed data
    getRttOnlinePageData();
    getRttOnlineTeacherData();
  }, []);

  useEffect(() => {
    getBookedTopicsData();
    setSelectedTab(selectedTabFinal);
  }, [selectedTabQuery]);

  useEffect(() => {
    if (selectedTab !== bookedTabs) {
      rttDataDispatch({ type: "REMOVE_TEACHER_DATA" });
    }
  }, [selectedTab]);

  useEffect(() => {
    if (isCouponApplied) {
      rttDataDispatch({
        type: "ADD_COUPON",
        payload: couponInfo,
      });
    }
  }, [couponInfo]);
  const initialAmount = Number(selectedTab) * 300;
  const payableAmount = isCouponApplied
    ? couponType === "percent"
      ? //PERCENT DISCOUNT
        Number((initialAmount * (1 - couponAmount / 100)).toFixed(0))
      : initialAmount - couponAmount >= 0
      ? //AMOUNT DISCOUNT
        initialAmount - couponAmount
      : 0
    : initialAmount;

  useEffect(() => {
    if (!showCouponApplyModal && isCouponApplied) {
      setWobblePrice(true);
    }
  }, [showCouponApplyModal]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Rumble Tumble Time Online</IonTitle>
          <IonButtons slot="start">
            {/* <button onClick={() => history.push("/dashboard")}>back</button> */}
            <IonBackButton defaultHref="/" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showCouponApplyModal && !isTeacher && (
          <CouponApplicationModal
            setCouponInfo={setCouponInfo}
            couponInfo={couponInfo}
            closeApplicationModal={() => setShowCouponApplyModal(false)}
          />
        )}
        <div className={styles["rtt_online_program"]}>
          {isTeacherModalOpen.showModal && (
            <TeacherSelectionModal
              teacherModalDetails={[isTeacherModalOpen, setIsTeacherModalOpen]}
              teacherData={rttTeachersData}
              showAnimation={showAnimation}
            />
          )}
          {showRttDemoModal && (
            <RttDemoVideoModal closeRttDemoModal={closeRttDemoModal} />
          )}
          {loading && <CircleLoader />}
          {detailsModalData.showModal && (
            <ViewDetailsModal
              modalDetails={[detailsModalData, setDetailsModalData]}
              selectedTab={selectedTab}
            />
          )}
          <div className={styles["tabs_wrapper"]}>
            <div className={styles["tabs_container"]}>
              {tabsArray.map((data: String, index: number) => {
                /// if data is selected topics then show the selected topics
                if (data === "Selected-Topics") {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedTab(data)}
                      className={`${
                        selectedTab === data && styles["selected_tab"]
                      } ${styles["tabs"]}`}
                    >
                      <p className={styles["tabs_para_book"]}>Booked </p>
                      <p className={styles["tabs_para"]}>
                        <i className="fas fa-chalkboard-teacher"></i>
                      </p>
                      <p className={styles["tabs_para_book"]}>Topics </p>
                    </div>
                  );
                } else if (data === "Rtt-Demo-Video") {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedTab(data)}
                      className={`${
                        selectedTab === data && styles["selected_tab"]
                      } ${styles["tabs"]}`}
                    >
                      <p className={styles["tabs_para_book"]}>Demo </p>
                      <p className={styles["tabs_para"]}>
                        <i className="fas fa-play"></i>
                      </p>
                      <p className={styles["tabs_para_book"]}>Video</p>
                    </div>
                  );
                } else if (data === "all") {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedTab(data)}
                      className={`${
                        selectedTab === data && styles["selected_tab"]
                      } ${styles["tabs"]}`}
                    >
                      <p className={styles["tabs_para_book"]}>Available</p>
                      <p className={styles["tabs_para"]}>
                        <i className="fas fa-chalkboard"></i>
                      </p>
                      <p className={styles["tabs_para_book"]}>Topics</p>
                    </div>
                  );
                } else if (data === "Booking Info") {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedTab(data)}
                      className={`${
                        selectedTab === data && styles["selected_tab"]
                      } ${styles["tabs"]}`}
                    >
                      <p className={styles["tabs_para_book"]}>Booking</p>
                      <p className={styles["tabs_para"]}>
                        <i className="fas fa-clipboard-list"></i>{" "}
                      </p>
                      <p className={styles["tabs_para_book"]}>Info</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedTab(data)}
                      className={`${
                        selectedTab === data && styles["selected_tab"]
                      } ${styles["tabs"]}`}
                    >
                      <p className={styles["tabs_para_book"]}>Book</p>
                      <p className={styles["tabs_para"]}>{data}</p>
                      <p className={styles["tabs_para_book"]}>
                        {" "}
                        {data === "1" ? "Session" : "Sessions"}{" "}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
            <div className={styles["coupon_box"]}>
              <button
                onClick={showCouponApplyClick}
                className={styles["coupon_button"]}
              >
                Apply Coupon
              </button>
            </div>
          </div>

          {selectedTab === "1" && (
            <div className={styles["topic_cards_container_one"]}>
              {rttOnlineData.slice(0, 1).map((topic: RttOnlineData) => (
                <TopicCard
                  key={topic.id}
                  topic={topic}
                  //isBooked={bookedTopicsData.includes(topic)}
                  isBooked={false}
                  modalData={[detailsModalData, setDetailsModalData]}
                  size="large"
                />
              ))}
            </div>
          )}
          {selectedTab === "4" && (
            <div className={styles["topic_cards_container_four"]}>
              {rttOnlineData.slice(0, 4).map((topic: RttOnlineData) => (
                <TopicCard
                  key={topic.id}
                  topic={topic}
                  //isBooked={bookedTopicsData.includes(topic)}
                  isBooked={false}
                  modalData={[detailsModalData, setDetailsModalData]}
                  size="medium"
                />
              ))}
            </div>
          )}
          {selectedTab === "12" && (
            <div className={styles["topic_cards_container_twelve"]}>
              {rttOnlineData.map((topic: RttOnlineData) => (
                <TopicCard
                  key={topic.id}
                  topic={topic}
                  //isBooked={bookedTopicsData.includes(topic)}
                  isBooked={false}
                  modalData={[detailsModalData, setDetailsModalData]}
                  size="small"
                />
              ))}
            </div>
          )}
          {selectedTab === "Selected-Topics" && (
            <div className={styles["topic_cards_container_twelve"]}>
              {bookedTopicsData.map((topic: RttOnlineData) => {
                return (
                  <TopicCard
                    key={topic.id}
                    topic={topic}
                    //isBooked={bookedTopicsData.includes(topic)}
                    isBooked={false}
                    modalData={[detailsModalData, setDetailsModalData]}
                    size="small"
                    goToExercise={true}
                  />
                );
              })}
            </div>
          )}
          {selectedTab === "all" && (
            <div className={styles["topic_cards_container_twelve"]}>
              {themeData.map((topic: RttOnlineData) => {
                return (
                  <TopicCard
                    key={topic.id}
                    topic={topic}
                    //isBooked={bookedTopicsData.includes(topic)}
                    isBooked={false}
                    modalData={[detailsModalData, setDetailsModalData]}
                    size="small"
                    goToExercise={true}
                    isTeacher={isTeacher}
                  />
                );
              })}
            </div>
          )}
          {selectedTab === "Booking Info" && (
            <div className={styles["button_container"]}>
              <IonButton
                href={`rtt-online/teacher/${isTeacher["id"]}`}
                shape="round"
              >
                View Bookings
              </IonButton>
            </div>
          )}
          {selectedTab === "Rtt-Demo-Video" && (
            <div
              className={`animate__animated animate__bounceIn ${styles["rtt_demo_video_container"]}`}
            >
              <img
                onClick={() => setShowRttDemoModal(true)}
                src="/assets/excercise/blue start activity button.svg"
                alt="Start Button"
                className={`${styles["activity_start_btn"]}`}
              />
            </div>
          )}
          {selectedTab !== "Selected-Topics" &&
            selectedTab !== "Rtt-Demo-Video" &&
            !isTeacher && (
              <footer className={styles["footer_tab_bar"]}>
                <div
                  className={`${styles["footer_total_selected_program"]} ${
                    wobblePrice && "animate__animated animate__heartBeat"
                  }`}
                >
                  <p className={styles["footer_total_selected_number"]}>
                    {selectedTab}{" "}
                    {Number(selectedTab) === 1 ? "Session" : "Sessions"}
                  </p>
                  <div className={styles["footer_price_container"]}>
                    <p className={styles["footer_total_price"]}>
                      ₹{Number(selectedTab) * 1000}{" "}
                    </p>
                    <p
                      className={`${styles["footer_total_price"]} ${
                        isFirstBooking &&
                        Number(selectedTab) === 1 &&
                        styles["line_through"]
                      } ${isCouponApplied && styles["line_through"]}`}
                    >
                      ₹{initialAmount}
                    </p>
                    {isFirstBooking && Number(selectedTab) === 1 ? (
                      <p className={styles["footer_total_price"]}>FREE</p>
                    ) : isCouponApplied ? (
                      <p className={styles["footer_total_price"]}>
                        ₹{payableAmount}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* {rttDataState.selectedTeacher !== undefined && (
                <div className={styles["footer_selected_teacher"]}>
                  <p className={styles["footer_selected_teacher_text"]}>
                    Selected Teacher
                  </p>
                  <p className={styles["footer_selected_teacher_name"]}>
                    {rttDataState.selectedTeacher.teacherName}
                  </p>
                </div>
              )} */}

                {isTeacherSelected && (
                  <button
                    onClick={() => proceedButtonHandler()}
                    className={`${styles["footer_payment_button"]} ${
                      isShowingAnimation
                        ? "animate__animated animate__heartBeat animate__delay-1s"
                        : ""
                    }`}
                  >
                    Proceed <i className="fas fa-caret-right"></i>
                  </button>
                )}

                {!isTeacherSelected && !isTeacherModalOpen.showModal && (
                  <button
                    onClick={() => proceedButtonHandler()}
                    className={`${styles["footer_payment_button"]} ${
                      isShowingAnimation
                        ? "animate__animated animate__heartBeat animate__delay-1s"
                        : ""
                    } `}
                  >
                    Select Teacher <i className="fas fa-caret-right"></i>
                  </button>
                )}
              </footer>
            )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RttOnlineProgram;
