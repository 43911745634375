import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import styles from "./Community.module.css";

const socialMediaData = [
  {
    img: "/assets/community/instagram.svg",
    link: "https://www.instagram.com/braincells06/",
  },
  {
    img: "/assets/community/facebook.svg",
    link: "https://www.facebook.com/braincells.tre/",
  },
  {
    img: "/assets/community/whatsapp.svg",
    link: "https://wa.me/918459628238",
  },
  {
    img: "/assets/community/youtube.svg",
    link: "https://www.youtube.com/channel/UCUSTO8aDfwj0mVOTf1VoXWA/featured",
  },
];

export const Community = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/parents" />
          </IonButtons>
          <IonTitle>Community</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div
          className={styles.communityContainer}
          style={{
            backgroundImage: `url("/assets/community/background-cropped.svg")`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        >
          <div className={styles.socialButtonContainer}>
            {socialMediaData.map((obj) => {
              return (
                <div className={styles.socialButtons}>
                  <a href={obj.link} target="_blank">
                    <img src={obj.img} alt="" />
                  </a>
                </div>
              );
            })}
          </div>

          <div className={styles.blogsBtnContainer}>
            <a href="https://blog.braincells.in/" target="_blank">
              <img src="/assets/community/blogs.svg" alt="" />
            </a>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
