import { useEffect, useState } from "react";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
} from "../../../../configs/ServerConfig";
import { useUserContext } from "../../../../store/context/user-context";
import { getHttpData } from "../../../../utils/http-util";
import Progressbar from "./Progressbar";
import { QuestionsModal } from "./QuestionsModal";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";

const SurveyModal = () => {
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const {
    user: { id, childBirthDate },
  } = useUserContext();

  const history = useHistory();
  const [showIonButton, setShowIonButton] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const res = await getHttpData(
            `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/userInfo?dataType=parents-question&userId=${id}`,
            {
              headers: {
                "Cache-Control": "no-cache",
                Pragma: "no-cache",
                Expires: "0",
              },
            }
          );
          if (res.data.length > 0) {
            setIsOpen(false);
            history.push("/dashboard");
            //! This is for testing purposes, remove after testing
            // setIsOpen(true);
          } else {
            setIsOpen(true);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [id]);

  return (
    <IonPage>
      <IonContent>
        <div>
          {isOpen && (
            <QuestionsModal
              setIsOpen={setIsOpen}
              setShowProgressBar={setShowProgressBar}
            />
          )}
          {showProgressBar && (
            <Progressbar
              setShowProgressbar={setShowProgressBar}
              showIonButton={() => {
                setShowIonButton(true);
              }}
            />
          )}
          {showIonButton && (
            <IonButton
              style={{
                position: "absolute",
                bottom: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={() => {
                history.goBack();
              }}
              size="default"
            >
              Click here to Continue
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurveyModal;
