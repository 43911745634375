import { useEffect } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import styles from "./SingleActivityStarter.module.css";
import StoryBookActivity from "../../Activity/Activities/StoryBook/StoryBookActivity";

const SingleActivityStarter = ({
  activityName,
  activityJson = {},
  startActivity,
  closeActivity,
  activityProps,
}: any) => {
  const activityFullscreenHandle = useFullScreenHandle();

  useEffect(() => {
    if (startActivity) {
      setTimeout(() => {
        activityFullscreenHandle.enter();
      }, 0);
    } else {
    }
  }, [startActivity]);

  const onActivityEnd = () => {
    activityFullscreenHandle.exit();
    closeActivity();
  };

  return startActivity ? (
    <FullScreen handle={activityFullscreenHandle}>
      <div className={`${styles["activity_container"]}`}>
        <div className={styles["activity_wrapper"]}>
          <div onClick={onActivityEnd} className={styles["activity_cross"]}>
            <i className="fas fa-times-circle"></i>
          </div>
          <StoryBookActivity
            closeActivity={closeActivity}
            {...activityProps}
            // activityJson={activityJson}
            // closeActivity={onActivityEnd}
            // showNextComp={() => {}}
          />
        </div>
      </div>
    </FullScreen>
  ) : null;
};

export default SingleActivityStarter;
