import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Program } from "./pages/Program/Program";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { LogoAnimation } from "./pages/SplashScreen/SplashScreen";
import { ProfileCreation } from "./pages/ProfileCreation/ProfileCreation";
import { TrophyCase } from "./pages/TrophyCase/TrophyCase";
import { ParentsHome } from "./pages/Parents/Home/ParentsHome";
import { Attendence } from "./pages/Parents/Reports/Attendence/Attendence";
import { ReportsHome } from "./pages/Parents/Reports/ReportsHome";
import { SignIn } from "./pages/Auth/SignIn";
import { MasterClassRegistration } from "./pages/Parents/MasterClass/MasterClassRegistration";
import { Clubhouse } from "./pages/Parents/Clubhouse/Clubhouse";
import { Community } from "./pages/Parents/Community/Community";
import { Events } from "./pages/Parents/Events/Events";
import { ParentingRewards } from "./pages/Parents/ParentingRewards/ParentingRewards";
import { FAQ } from "./pages/Parents/FAQ/FAQ";
import { TNC } from "./pages/Parents/TNC/TNC";
import { DeviceAccess } from "./pages/Parents/DeviceAccess/DeviceAccess";
import { ProfileSettings } from "./pages/Parents/ProfileSettings/ProfileSettings";
import { BrainFunction } from "./pages/Parents/Reports/BrainFunction/BrainFunction";
import { ChildInterest } from "./pages/Parents/Reports/ChildInterest/ChildInterest";
import { NotFound404 } from "./pages/NotFound/NotFound404";
import { useUserContext } from "./store/context/user-context";
import { ProtectedRoute } from "./components/ProtectedRoute";
import SetName from "./pages/ProfileCreation/SetName/SetName";
import SetBirthDay from "./pages/ProfileCreation/SetBirthDate/SetBirthDate";
import SetAvatar from "./pages/ProfileCreation/SetAvatar/SetAvatar";
import SetSkills from "./pages/ProfileCreation/SetSkills/SetSkills";
import SetDevice from "./pages/ProfileCreation/SetDevice/SetDevice";
import { SignUp } from "./pages/Auth/SignUp";
import { Excercise } from "./pages/Excercise/Excercise";
import ReferFriend from "./pages/Parents/ReferFriend/ReferFriend";
import PatternMatch from "./components/Activity/Activities/PatternMatch/PatternMatch";
import WeighMeIn from "./components/Activity/Activities/WeighMeIn/WeighMeIn";
import Authenticator from "./components/Authenticator/Authenticator";
import PricingPage from "./pages/Parents/Subscription/PricingPage";
import PaymentSuccessModal from "./pages/Parents/Subscription/components/PaymentSuccess";
import { RttLandingPage } from "./pages/RttLandingPage/RttLandingPage";
import { Intro } from "./pages/Home/Intro";
import SinglePostPage from "./pages/CommunityCanvas/SinglePostPage/SinglePostPage";
import CommunityFeed from "./pages/CommunityCanvas/CommunityFeed/CommunityFeed";
import ProfilePage from "./pages/CommunityCanvas/ProfilePage/ProfilePage";
import NotificationPage from "./pages/CommunityCanvas/NotificationPage/NotificationPage";
import AdminPage from "./pages/Admin/AdminStats/AdminPage";
import RttOnlineProgram from "./pages/RttOnline/RttOnlineProgramPage/RttOnlineProgram";
import RttOnlineBookingPage from "./pages/RttOnline/RttOnlineBookingPage/RttOnlineBookingPage";
import RttOnlineExercise from "./pages/RttOnline/RttOnlineExercisePage/RttOnlineExercise";
import RttOnlineTeacherDashboard from "./pages/RttOnline/RttOnlineTeacherDashboard/RttOnlineTeacherDashboard";
import ProgramZeroToOne from "./pages/Program/ProgramZeroToOne";
import SurveyModal from "./pages/Parents/Home/Components/SurveyModal";
import Tawk from "./components/generalComponents/Tawk/Tawk";
import Invoice from "./pages/Admin/Invoice/Invoice";
import InvoiceList from "./pages/Admin/InvoiceList/InvoiceList";
import FreeDownloadProgramPage from "./pages/Program/FreePrograms/FreeDownloadProgram/FreeDownloadProgramPage";
import FreeProgramPage from "./pages/Program/FreePrograms/FreeProgramPage/FreeProgramPage";
import StorytellingProgramPage from "./pages/Program/FreePrograms/StorytellingProgram/StorytellingProgramPage";

export const AppRoutes = () => {
  const userCtx = useUserContext();
  const isLoggedIn = userCtx.isUserLoggedIn;

  //TEMPORARY PLAYBACK SPEED FIX
  if (localStorage.getItem("PLAYBACK_SPEED") === null) {
    localStorage.setItem("PLAYBACK_SPEED", "1.2");
  }
  //TEMPORARY DOT CARD TYPE
  if (localStorage.getItem("DOTCARD_TYPE") === null) {
    localStorage.setItem("DOTCARD_TYPE", "dot");
  }

  return (
    <div style={{ zIndex: 1 }}>
      <IonReactRouter>
        <IonRouterOutlet id="main">
          <Route exact path="/">
            {!userCtx.isUserLoggedIn ? (
              <SignIn />
            ) : (
              <Redirect exact from="/" to="/dashboard" />
            )}
          </Route>
          {/* <Route exact path="rumble-tumble-time">
            <RttLandingPage />
          </Route> */}
          <ProtectedRoute
            exact
            path="/rumble-tumble-time"
            component={RttLandingPage}
            portraitMode={true}
          />
          <Route exact path="/signin">
            {!userCtx.isUserLoggedIn ? (
              <>
                <SignIn />
                <Tawk />
              </>
            ) : (
              <Redirect exact from="/signin" to="/dashboard" />
            )}
          </Route>
          <Route exact path="/signup">
            {!userCtx.isUserLoggedIn ? (
              <SignUp />
            ) : (
              <Redirect exact from="/signup" to="/dashboard" />
            )}
          </Route>
          <Route exact path="/rumble-tumble-time">
            {!userCtx.isUserLoggedIn ? (
              <RttLandingPage />
            ) : (
              <Redirect exact from="/rumble-tumble-time" to="/dashboard" />
            )}
          </Route>
          {/* Temporary Route */}
          <Route path="/authenticate">
            {!userCtx.isUserLoggedIn ? (
              <Authenticator />
            ) : (
              <Redirect from="/authenticate" to="/dashboard" />
            )}
          </Route>
          <Route exact path="/authenticate" component={Authenticator} />

          <Route path="/parents/subscriptions/login">
            <Authenticator />
          </Route>
          <Route path="/login">
            <Authenticator />
          </Route>
          <ProtectedRoute
            exact
            path="/admin/statistics"
            isLoggedIn={isLoggedIn}
            component={AdminPage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/admin/invoice"
            isLoggedIn={isLoggedIn}
            component={InvoiceList}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/admin/invoice/:invoiceId"
            isLoggedIn={isLoggedIn}
            component={Invoice}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/dashboard"
            isLoggedIn={isLoggedIn}
            component={Dashboard}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/child-survey"
            isLoggedIn={isLoggedIn}
            component={SurveyModal}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/program/:programName"
            isLoggedIn={isLoggedIn}
            component={Program}
          />
          {/* Temporary Route */}
          <ProtectedRoute
            exact
            path="/program/BrainCells/zeroToOne"
            isLoggedIn={isLoggedIn}
            component={ProgramZeroToOne}
          />
          <ProtectedRoute
            exact
            path="/excercise/:programId/:excerciseId"
            isLoggedIn={isLoggedIn}
            component={Excercise}
          />
          {/* Temporary Route for Testing */}
          <ProtectedRoute
            exact
            path="/activity/patternMatch"
            isLoggedIn={isLoggedIn}
            component={PatternMatch}
          />
          {/* Temporary Route for Testing */}
          <ProtectedRoute
            exact
            path="/activity/weighMeIn"
            isLoggedIn={isLoggedIn}
            component={WeighMeIn}
          />
          {/* Temporary Route for Testing */}
          <ProtectedRoute
            exact
            path="/intro"
            isLoggedIn={isLoggedIn}
            component={Intro}
          />
          <ProtectedRoute
            exact
            path="/logo-animation"
            isLoggedIn={isLoggedIn}
            component={LogoAnimation}
          />
          <ProtectedRoute
            exact
            path="/profile-creation"
            isLoggedIn={isLoggedIn}
            component={ProfileCreation}
          />
          <ProtectedRoute
            exact
            path="/profile-creation/set-name"
            isLoggedIn={isLoggedIn}
            component={SetName}
          />
          <ProtectedRoute
            exact
            path="/profile-creation/set-birth-date"
            isLoggedIn={isLoggedIn}
            component={SetBirthDay}
          />
          <ProtectedRoute
            exact
            path="/profile-creation/set-avatar"
            isLoggedIn={isLoggedIn}
            component={SetAvatar}
          />
          <ProtectedRoute
            exact
            path="/profile-creation/set-skills"
            isLoggedIn={isLoggedIn}
            component={SetSkills}
          />
          <ProtectedRoute
            exact
            path="/profile-creation/set-device"
            isLoggedIn={isLoggedIn}
            component={SetDevice}
          />
          {/* <ProtectedRoute
            exact
            path="/profile-creation/build-profile"
            isLoggedIn={isLoggedIn}
            component={BuildProfile}
          /> */}
          <ProtectedRoute
            exact
            path="/trophy-case"
            isLoggedIn={isLoggedIn}
            component={TrophyCase}
          />
          <ProtectedRoute
            exact
            path="/parents"
            isLoggedIn={isLoggedIn}
            component={ParentsHome}
          />
          <ProtectedRoute
            exact
            path="/parents/masterclass-registration"
            isLoggedIn={isLoggedIn}
            component={MasterClassRegistration}
          />
          <ProtectedRoute
            exact
            path="/parents/clubhouse"
            isLoggedIn={isLoggedIn}
            component={Clubhouse}
          />
          <ProtectedRoute
            exact
            path="/parents/refer-friend"
            isLoggedIn={isLoggedIn}
            component={ReferFriend}
          />
          <ProtectedRoute
            exact
            path="/parents/subscriptions"
            isLoggedIn={isLoggedIn}
            component={PricingPage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/parents/subscriptions/paymentsuccess"
            isLoggedIn={isLoggedIn}
            component={PaymentSuccessModal}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/parents/subscriptions/coupon/:couponCode"
            isLoggedIn={isLoggedIn}
            component={PricingPage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/parents/community"
            isLoggedIn={isLoggedIn}
            component={Community}
          />
          <ProtectedRoute
            exact
            path="/parents/device-access"
            isLoggedIn={isLoggedIn}
            component={DeviceAccess}
          />
          <ProtectedRoute
            exact
            path="/parents/events"
            isLoggedIn={isLoggedIn}
            component={Events}
          />
          <ProtectedRoute
            exact
            path="/parents/parenting-rewards"
            isLoggedIn={isLoggedIn}
            component={ParentingRewards}
          />
          <ProtectedRoute
            exact
            path="/parents/parenting-rewards"
            isLoggedIn={isLoggedIn}
            component={ParentingRewards}
          />
          <ProtectedRoute
            exact
            path="/parents/reports"
            isLoggedIn={isLoggedIn}
            component={ReportsHome}
          />
          <ProtectedRoute
            exact
            path="/parents/reports/attendence"
            isLoggedIn={isLoggedIn}
            component={Attendence}
          />
          <ProtectedRoute
            exact
            path="/parents/reports/brain-function"
            isLoggedIn={isLoggedIn}
            component={BrainFunction}
          />
          <ProtectedRoute
            exact
            path="/parents/reports/child-interest"
            isLoggedIn={isLoggedIn}
            component={ChildInterest}
          />
          <ProtectedRoute
            exact
            path="/parents/profile-settings"
            isLoggedIn={isLoggedIn}
            component={ProfileSettings}
          />
          <ProtectedRoute
            exact
            path="/parents/FAQ"
            isLoggedIn={isLoggedIn}
            component={FAQ}
          />
          <ProtectedRoute
            path="/community"
            isLoggedIn={isLoggedIn}
            component={CommunityFeed}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/community/my-canvas"
            isLoggedIn={isLoggedIn}
            component={ProfilePage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/community/notification"
            isLoggedIn={isLoggedIn}
            component={NotificationPage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/community/post/:postId"
            isLoggedIn={isLoggedIn}
            component={SinglePostPage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/rtt-online"
            isLoggedIn={isLoggedIn}
            component={RttOnlineProgram}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/rtt-online/booking"
            isLoggedIn={isLoggedIn}
            component={RttOnlineBookingPage}
            portraitMode={true}
          />
          <ProtectedRoute
            exact
            path="/rtt-online/exercise/:eventId"
            isLoggedIn={isLoggedIn}
            component={RttOnlineExercise}
            portraitMode={false}
          />
          <ProtectedRoute
            exact
            path="/rtt-online/teacher/:teacherId"
            isLoggedIn={isLoggedIn}
            portraitMode={true}
            component={RttOnlineTeacherDashboard}
          />

          {/* Free Programs */}

          <ProtectedRoute
            exact
            path="/free-programs/:programId"
            isLoggedIn={isLoggedIn}
            portraitMode={true}
            component={FreeProgramPage}
          />
          <Route
            exact
            path="/free-programs/free_downloadable/:downloadId"
            component={FreeDownloadProgramPage}
          />
          <Route
            exact
            path="/free-programs/story_telling/:storyId"
            component={StorytellingProgramPage}
          />

          <Route exact path="/parents/TNC" component={TNC} />
          <Route path="/pagenotfound" component={NotFound404} />
          <Redirect to="/pagenotfound" />
        </IonRouterOutlet>
      </IonReactRouter>
    </div>
  );
};
