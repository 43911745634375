import moment from "moment";
import React, { lazy, useEffect, useRef, useState } from "react";
import {
  communityGetLink,
  imagesLink,
} from "../../constants/Services/services-constants";
import { usePostCreator } from "../../hooks/usePostDataCreator";
import { useUserContext } from "../../store/context/user-context";
import { imgFetchArrCreator } from "../../utils/activity-utils";
import { getRandomInt } from "../../utils/general-utils";
import { getHttpData } from "../../utils/http-util";
import { ActivityWrapper } from "../Activity/ActivityWrapper/ActivityWrapper";
import OrientationChecker from "../generalComponents/OrientationChecker";
const POST_AFTER_ACTIVITY_COUNT = 0;

const Loading = () => {
  return <></>;
};

const ActivityStarter = ({
  activityJSON,
  startActivity,
  toggleActivity,
  endActivity,
  enterFullScreenInsideActivity,
  completedActivityCount,
}: any) => {
  const [currentComponent, setCurrentComponent] = useState<any>();
  const [showComp, setShowComp] = useState(0);
  const { postCreator } = usePostCreator();
  const componentsViewed: any = { pre: false, post: false, activity: false };
  const componentKeys = Object.keys(activityJSON);
  const activityStartTime = useRef<any>();
  const { user } = useUserContext();
  const isPosted: any = useRef(false);

  useEffect(() => {
    activityStartTime.current = moment().unix();
  }, []);

  useEffect(() => {
    if (componentKeys[showComp] === "exit") {
      const timeSpent = moment().unix() - activityStartTime.current;
      postToCanvas(timeSpent);
      isPosted.current = true;
    }
  }, [showComp]);

  useEffect(() => {
    return () => {
      if (!isPosted.current) {
        const timeSpent = moment().unix() - activityStartTime.current;
        postToCanvas(timeSpent);
      }
    };
  }, []);

  const getTodayPost = async () => {
    const res: any = await getHttpData(`${communityGetLink}?userId=${user.id}`);
    const todayPost = res.data.filter((post: any) => {
      const today = moment(moment().unix());
      const postDate = moment(post.createdAt);
      if (today.isSame(postDate, "day")) {
        return true;
      }
    });
    return false;
  };
  const postToCanvasAPI = (timeSpent: any) => {
    const activityName = activityJSON["intro"]["props"]["currentActivity"];
    const imgArr = imgFetchArrCreator(
      activityJSON["activity"]["props"]["activityData"],
      activityName
    );
    if (activityName === "Mandala") {
      const randomImage = imgArr[0];
      if (randomImage) {
        postCreator(`${imagesLink}${randomImage}`, activityName, timeSpent, [
          `${imagesLink}${imgArr[1]}`,
        ]);
      }
    } else {
      const randomImage = imgArr[getRandomInt(imgArr.length)];
      if (randomImage) {
        postCreator(`${imagesLink}${randomImage}`, activityName, timeSpent);
      }
    }

    return {};
  };

  const postToCanvas = async (timeSpent: any) => {
    if (completedActivityCount >= POST_AFTER_ACTIVITY_COUNT) {
      // const alreadyPosted = await getTodayPost();
      if (process.env.REACT_APP_COMMUNITY_POST === "true") {
        const canvasPost = await postToCanvasAPI(timeSpent);
        if (canvasPost) {
        }
      }
    }
  };

  useEffect(() => {
    const viewComp = activityJSON[componentKeys[showComp]];
    if (!viewComp.componentName) {
      showNextComp();
    }
  }, []);

  const exitInitializers = () => {
    toggleActivity();
  };
  const importComponent = (
    comp: any,
    category: any,
    extra: any = "",
    nestedComp: any = ""
  ) =>
    lazy(() => {
      if (category === "Initializers") {
        return import(`../Activity/${category}/${comp}/${comp}`).catch();
      } else if (extra && nestedComp)
        return import(`../Activity/${category}/${comp}/${extra}/${nestedComp}`);
      else if (extra) {
        return import(`../Activity/${category}/${comp}/${extra}`);
      } else return import(`../Activity/${category}/${comp}/${comp}`).catch();
    });

  const showNextComp = () => {
    const viewComp = activityJSON[componentKeys[showComp]];
    if (activityJSON["initializer"].componentName && showComp === 0) {
      enterFullScreenInsideActivity();
    }
    if (showComp < componentKeys.length - 1) {
      if (viewComp.category === "Activities") {
        if (
          viewComp.pre &&
          componentsViewed.pre &&
          !componentsViewed.activity
        ) {
          loadComponent();
        } else if (componentsViewed.activity) {
          if (viewComp.post && !componentsViewed.post) loadComponent();
          else setShowComp((prevState) => prevState + 1);
        } else if (componentsViewed.post) {
          setShowComp((prevState) => prevState + 1);
        }
      } else {
        setShowComp((prevState) => prevState + 1);
      }
    } else {
      toggleActivity();
    }
  };

  const setView = async (
    viewComp: any,
    extra: any = null,
    nestedComp: any = null
  ) => {
    const View = await importComponent(
      viewComp.componentName,
      viewComp.category,
      extra,
      nestedComp
    );
    if (viewComp.category === "Activities") {
      setCurrentComponent(
        <ActivityWrapper coverFullScreen={false} endActivity={endActivity}>
          <View showNextComp={showNextComp} {...viewComp.props} />
        </ActivityWrapper>
      );
    } else {
      setCurrentComponent(
        <View
          showNextComp={showNextComp}
          {...viewComp.props}
          exitInitializers={exitInitializers}
        />
      );
    }
  };
  const loadComponent = async () => {
    const viewComp = activityJSON[componentKeys[showComp]];
    if (viewComp.category === "Activities") {
      if (viewComp.pre && !componentsViewed.pre) {
        componentsViewed.pre = true;
        setView(viewComp, `pre`, `${viewComp.pre}`);
      } else if (!componentsViewed.activity) {
        componentsViewed.activity = true;
        setView(viewComp, `${viewComp.componentName}`);
      } else if (viewComp.post && !componentsViewed.post) {
        componentsViewed.post = true;
        setView(viewComp, `post`, `${viewComp.post}`);
      }
    } else {
      setView(viewComp);
    }
  };

  useEffect(() => {
    loadComponent();
  }, [showComp]);

  return (
    <>
      <OrientationChecker />
      <React.Suspense fallback={<Loading />}>
        <div style={{ position: "absolute", zIndex: 8 }}>
          {startActivity && currentComponent}
        </div>
      </React.Suspense>
    </>
  );
};

export { ActivityStarter };
