import { IonPage, IonRouterLink } from "@ionic/react";
import React, { useEffect } from "react";
import styles from "./NotFound404.module.css";

export const NotFound404 = () => {
  return (
    <div className={styles["page_wrapper"]}>
      <div className={styles["content_wrapper"]}>
        <img src="./assets/404Error.svg" alt="error" />
        <IonRouterLink href="/dashboard" color="danger">
          <button className={styles["link_button"]}>Go to Dashboard</button>
        </IonRouterLink>{" "}
      </div>
    </div>
  );
};
