import { IonRouterLink } from "@ionic/react";
import { useState } from "react";
import { ZERO_TO_ONE_AVAILABLE_DAYS } from "../../../constants/Program/program-constants";
import useCurrentProgramDate from "../../../hooks/useCurrentProgramDay";
import useIsZeroTwoToOne from "../../../hooks/useIsZeroToOne";
import { useUserContext } from "../../../store/context/user-context";
import { useSubscriptionContext } from "../../../store/context/subscription-context";
import styles from "./ExerciseNavigation.module.css";
const ExerciseNavigationLeft = ({
  programName,
  exerciseId,
  color,
  programTypeParam,
  previousForZeroToOne,
  isZeroToOne,
  bca = null,
  oca = null,
}: any) => {
  const crrExeNumber = Number(exerciseId.split("_")[0].split("-")[1]);
  const splitArr = exerciseId.split("_");
  let finalLink = "";
  if (isZeroToOne) {
    if (previousForZeroToOne) {
      finalLink = `/excercise/BrainCells/Exercise-${previousForZeroToOne}_Age_0-1_v2?${
        oca !== null ? `&oca=${oca - 1}` : ""
      }${bca !== null ? `&bca=${bca - 1}` : ""}`;
    } else {
      finalLink = `/program/BrainCells/zeroToOne`;
    }
  } else {
    finalLink = previousForZeroToOne
      ? `/excercise/${programName}/Exercise-${previousForZeroToOne}_Age_${
          splitArr[splitArr.length - 1]
        }?${programTypeParam && "s=TRIAL"}`
      : `/program/${programName}${programTypeParam && "?s=TRIAL"}`;
  }
  return (
    <div
      className={`${styles["exercise_navigation"]} ${styles["exercise_navigation_left"]}`}
    >
      <IonRouterLink className={styles["prev_exercise"]} href={finalLink}>
        <button className={styles["button"]}>
          <i style={{ color: color }} className="fas fa-chevron-left"></i>
        </button>
      </IonRouterLink>
    </div>
  );
};

const ExerciseNavigationRight = ({
  programName,
  exerciseId,
  color,
  programTypeParam,
  nextForZeroToOne,
  isZeroToOne,
  bca = null,
  oca = null,
}: any) => {
  const crrExeNumber = Number(exerciseId.split("_")[0].split("-")[1]);
  const splitArr = exerciseId.split("_");

  const nextExe = `Exercise-${nextForZeroToOne}_Age_${
    splitArr[splitArr.length - 1]
  }`;

  let finalLink = "";

  if (isZeroToOne) {
    if (nextForZeroToOne) {
      finalLink = `/excercise/BrainCells/Exercise-${nextForZeroToOne}_Age_0-1_v2?${
        oca ? `&oca=${oca + 1}` : ""
      }${bca ? `&bca=${bca + 1}` : ""}`;
    } else {
      finalLink = `/program/BrainCells/zeroToOne`;
    }
  } else {
    finalLink = nextForZeroToOne
      ? `/excercise/${programName}/${nextExe}?${programTypeParam && "&s=TRIAL"}`
      : `/program/${programName}${programTypeParam && "?s=TRIAL"}`;
  }

  return (
    <div
      className={`${styles["exercise_navigation"]} ${styles["exercise_navigation_right"]}`}
    >
      <IonRouterLink className={styles["next_exercise"]} href={finalLink}>
        <button className={styles["button"]}>
          <i style={{ color: color }} className="fas fa-chevron-right"></i>
        </button>
      </IonRouterLink>
    </div>
  );
};
const ExerciseNavigation = ({
  exerciseId,
  colorCode,
  programName,
  programTypeParam,
  showNavigationArrows = true,
}: any) => {
  const [showNavigation, setShowNavigation] = useState<boolean>(false);
  const tempCrrExeNumber = Number(exerciseId.split("_")[0].split("-")[1]);
  const { isSubscribedZeroToOne: isZeroToOne } = useIsZeroTwoToOne();
  const parsedValue = JSON.parse(
    localStorage.getItem(ZERO_TO_ONE_AVAILABLE_DAYS) || `{"availableDays": []}`
  );
  const { activeSubscriptions } = useSubscriptionContext();
  const isAnyProgramSubscribed =
    activeSubscriptions.filter((sub: any) => {
      return sub.programType !== "TRIAL";
    }).length > 0;

  const clickHandle = () => {
    if (isAnyProgramSubscribed) {
      setShowNavigation((prev: any) => !prev);
    }
  };
  const queryParams = new URLSearchParams(window.location.search);
  const oca: any =
    queryParams.get("oca") !== null ? Number(queryParams.get("oca")) : null;
  const bca: any =
    queryParams.get("bca") !== null ? Number(queryParams.get("bca")) : null;
  const zeroToOneAvailableDays = parsedValue["availableDays"].map((day: any) =>
    Number(day)
  );
  const zeroToOneLastExercise = parsedValue["lastExercise"];
  let navigationObj: any = {
    shouldNavigate: true,
    previous: null,
    next: null,
    current: null,
  };

  const findElementsOfArray = (element: any, findArray: any) => {
    const index = findArray.indexOf(element);
    let navigationValue = {};

    if (index > -1) {
      if (index === findArray.length) {
        navigationValue = {
          previous: findArray[index - 1],
          current: findArray[index],
        };
      } else if (index === 0) {
        navigationValue = {
          current: findArray[index],
          next: findArray[index + 1],
        };
      } else {
        navigationValue = {
          previous: findArray[index - 1],
          current: findArray[index],
          next: findArray[index + 1],
        };
      }
    } else {
    }
    if (zeroToOneLastExercise === element) {
      navigationValue = {
        ...navigationValue,
        next: null,
      };
    }
    return navigationValue;
  };
  if (zeroToOneAvailableDays.includes(tempCrrExeNumber)) {
    if (
      tempCrrExeNumber <= Math.max(...zeroToOneAvailableDays) &&
      tempCrrExeNumber >= Math.min(...zeroToOneAvailableDays)
    ) {
      const result = findElementsOfArray(
        tempCrrExeNumber,
        zeroToOneAvailableDays.filter((day: any) => day > 0)
      );
      navigationObj = { ...navigationObj, ...result };
      // if (tempCrrExeNumber === Math.max(zeroToOneAvailableDays)) {
      // } else if (tempCrrExeNumber === Math.min(zeroToOneAvailableDays)) {
      // } else {
      // }
    } else {
    }
  }

  const crrExeNumber = isZeroToOne
    ? zeroToOneAvailableDays
        .filter((day: any) => day > 0)
        .indexOf(tempCrrExeNumber) + 1
    : tempCrrExeNumber;

  return (
    <div
      style={{
        border: `none`,
        backgroundColor: `transparent`,
      }}
      className={styles["outer_wrapper"]}
    >
      {/* <div
          style={{ color: colorCode }}
          className={styles["current-exe-number"]}
        >
          <span className={styles["ex-span"]}>Ex</span>{" "}
          <span>{crrExeNumber}</span>
        </div> */}
      <div
        onClick={clickHandle}
        style={{
          borderColor: `${colorCode}`,
        }}
        className={styles["exe-number-wrapper"]}
      >
        <div
          style={{ color: colorCode }}
          className={styles["current-exe-number"]}
        >
          {crrExeNumber}
        </div>
      </div>
      {showNavigation && (
        <div className={styles["container"]}>
          <ExerciseNavigationLeft
            exerciseId={exerciseId}
            programName={programName}
            color={colorCode}
            programTypeParam={programTypeParam}
            previousForZeroToOne={navigationObj.previous}
            isZeroToOne={isZeroToOne}
            oca={oca}
            bca={bca}
          />
          <ExerciseNavigationRight
            exerciseId={exerciseId}
            programName={programName}
            color={colorCode}
            programTypeParam={programTypeParam}
            nextForZeroToOne={navigationObj.next}
            isZeroToOne={isZeroToOne}
            oca={oca}
            bca={bca}
          />
        </div>
      )}
    </div>
  );
};

export { ExerciseNavigationRight, ExerciseNavigationLeft, ExerciseNavigation };
