import styles from "./ToggleSwitch.module.css";

const ToggleSwitch = ({ value, onToggleTrue, onToggleFalse }: any) => {
  return (
    <label className={styles["switch"]}>
      <input
        checked={value}
        onChange={(e) => (e.target.checked ? onToggleTrue() : onToggleFalse())}
        type="checkbox"
      />
      <span className={`${styles["slider"]} ${styles["round"]} `}></span>
    </label>
  );
};

export default ToggleSwitch;
