export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADD_BOOKING_DATA = "ADD_BOOKING_DATA";
export const REMOVE_BOOKING_DATA = "REMOVE_BOOKING_DATA";
export const REMOVE_TEACHER_DATA = "REMOVE_TEACHER_DATA";
export const EMPTY_CART = "EMPTY_CART";
export const SET_TEACHER_DATA = "SET_TEACHER_DATA";
export const EMPTY_CONTEXT = "EMPTY_CONTEXT";
export const FIRST_BOOKING = "FIRST_BOOKING";
export const FREE_PROGRAM_UNLOCK_COUNT = 10;
export const INITIAL_FREE_DOWNLOADABLE_VISIBLE = 3;
