import React, { useEffect, useRef, useState } from "react";
import styles from "./LockedTopicModal.module.css";
import { useHistory } from "react-router";
import { getHttpData, postHttpData } from "../../../../../../utils/http-util";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../../../configs/ServerConfig";
import { useUserContext } from "../../../../../../store/context/user-context";
import moment from "moment";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const LockedTopicModal = ({
  closeLockedModal,
  referClickHandler,
  programType,
}: any) => {
  const tncModalRef = useRef(null);
  const {
    user: { id, name },
  } = useUserContext();
  const history = useHistory();

  const [referFriendData, setReferFriendData] = useState<any>({
    name: "",
    phone: "",
    childName: "",
  });

  const [error, setError] = useState({
    showError: false,
    message: "",
  });

  const postReferredUserData = async (data: any) => {
    const postData = await postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/temp_user`,
      {
        ...data,
      }
    );
  };

  const messageReferredUser = async (data: any) => {
    const res = await postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/events`,
      {
        type: "USER-REFERRAL",
        userId: String(id),
        timestamp: moment.utc().valueOf(),
        data: { ...data },
      }
    );
  };

  const checkReferredUserData = async () => {
    const response = await getHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/temp_user?phone=%2B${referFriendData[
        "phone"
      ].slice(1)}`
    );
    console.log(response.data.length > 0);
    return response.data.length > 0;
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    if (await checkReferredUserData()) {
      setError({
        showError: true,
        message: "Account already exists with the given number",
      });
    } else {
      const referralData = {
        ...referFriendData,
        status: "Created",
        program: programType,
      };

      referClickHandler(e);
      postReferredUserData(referralData);
      messageReferredUser({ ...referralData, referee: name });
    }
  };

  return (
    <div className={styles.tncModalBackground}>
      <div className={styles.tncModalContainer} ref={tncModalRef}>
        <div onClick={() => closeLockedModal()} className={styles["closeBtn"]}>
          <i className="fas fa-times-circle"></i>
        </div>

        <div className={styles["subscribe_container"]}>
          <p className={styles["subscribe_text"]}>
            Subscribe BrainCells to unlock all downloadables
          </p>

          <button
            onClick={() => history.push("/parents/subscriptions")}
            className={styles["action_btn"]}
          >
            Subscribe
          </button>
        </div>

        <div className={styles["or_text"]}>OR</div>

        <div className={styles["refer_friend_container"]}>
          <p className={styles["refer_text"]}>
            Refer a friend to unlock next 10 downloadables.
          </p>

          <form
            onSubmit={(e) => onFormSubmit(e)}
            className={styles["form_container"]}
            action=""
          >
            <input
              type="text"
              name="friends_name"
              placeholder="Friend's Name"
              className={styles["form_input"]}
              onChange={(e) =>
                setReferFriendData({ ...referFriendData, name: e.target.value })
              }
              value={referFriendData.name}
              required
            />

            <IntlTelInput
              preferredCountries={["in", "sg", "de", "us", "co"]}
              inputClassName={styles["form_input"]}
              onPhoneNumberChange={(b, n, c, number) =>
                setReferFriendData({
                  ...referFriendData,
                  phone: number,
                })
              }
              style={{ width: "100%" }}
              placeholder="Friend's Phone Number"
            />
            <input
              type="text"
              name="child_name"
              placeholder="Child's name"
              className={styles["form_input"]}
              onChange={(e) =>
                setReferFriendData({
                  ...referFriendData,
                  childName: e.target.value,
                })
              }
              value={referFriendData.childName}
            />

            {error.showError && (
              <p className={styles["error_text"]}>{error.message}</p>
            )}

            <button type="submit" className={styles["action_btn"]}>
              Refer Friend
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LockedTopicModal;
