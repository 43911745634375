import {
  IonContent,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonRouterLink,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./Intro.css";
import classes from "./IntroSub.module.css";
import brainCellsLogo from "../../assets/img/brainCells-logo.png";
import peopleButtonImg from "../../assets/img/people-button.png";
import wizard from "../../assets/img/wizard.png";
import samar from "../../assets/img/samar-video-youtube.png";
import icon6 from "../../assets/img/icon6.png";
import icon8 from "../../assets/img/icon8.png";
import icon9 from "../../assets/img/icon9.png";
import icon10 from "../../assets/img/icon10.png";
import icon15 from "../../assets/img/icon15.png";
import tejasa from "../../assets/img/tejasa-potnis-profile.jpeg";
import closeBtn from "../../assets/img/lightbox-close.png";
import { nextDate } from "../../utils/general-utils";
import { SignIn } from "../Auth/SignIn";
import { ModalExample } from "../../components/UI/ModalExample";
import { IonModal, IonButton } from "@ionic/react";
import { SignUp } from "../Auth/SignUp";

export const Intro = () => {
  // useEffect(() => {
  //   const wow = new WOW.WOW({live: false, offset: 0});
  //   wow.init();
  // }, []);
  const nextSaturday = nextDate(6).toLocaleDateString();

  const [showModal, setShowModal] = useState(false);

  return (
    <IonContent fullscreen>
      <div className={classes.mainSectionExt + " main-section"}>
        <header>
          <div className="container">
            <div className="header-content d-flex flex-wrap align-items-center">
              <div className={classes.alignCenter + " logo animated zoomIn "}>
                <a href="#" title="">
                  <img src={`/assets/logo/brainCells-logo-small.png`} alt="" />
                  <p className={classes.logoText + " login-box-msg"}>
                    BrainCells
                  </p>
                </a>
              </div>
            </div>
          </div>
        </header>
        <section className="main-banner">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6 col-md-6">
                <div className={classes.alignCenter + " banner-text"}>
                  <h2 className={classes.alignCenter + " animated zoomIn "}>
                    A Lifetime of Confidence <span>Starts Here</span>
                  </h2>
                  <br />
                  <h1 className={classes.subText}>
                    {" "}
                    Revolutionary Right Brain Education & Holistic Development
                    Program with Activities for kids for Age 0-5 Years
                  </h1>
                  <br />
                  <h1
                    className={classes.subText}
                    style={{ fontSize: "1.25rem" }}
                  >
                    Cognitive Neuroscience based Program
                  </h1>
                  <br></br>
                  <IonRouterLink href="/#braincells">
                    <div className={classes.alignCenter}>
                      <button className="btn btn-primary animated bounceIn learnMore">
                        Learn More{" "}
                      </button>

                      {/* <Genie/> */}

                      <img
                        className="animated zoomIn image"
                        width="75%"
                        src={peopleButtonImg}
                      />
                    </div>
                  </IonRouterLink>
                  <ul className="social-links ml-auto">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/braincells.tre/"
                        title=""
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/72152804"
                        title=""
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/braincells06"
                        title=""
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                  <br />
                </div>
              </div>
              <div className="col-lg-6 col-md-6" id="signup">
                <SignUp />
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        id="braincells"
        className="course-section benifit-section about-us-section about-page-content"
      >
        <div className="container">
          <div
            className="section-title text-center wow bounceIn"
            data-wow-duration="1000ms"
          >
            <img width="150px" src={wizard} />

            <h2>
              We are a Team of Educationalists, Technologists &{" "}
              <span>Harvard Alumnus</span>
            </h2>

            {/* <h1 className={classes.secondSectionTitle}>Bring out the Genius in your Child !</h1> */}

            <h1 className={classes.secondSectionSubTitle}>
              Over 10 years of experience in early learning
            </h1>
            <br />

            <IonModal isOpen={showModal}>
              <IonButtons
                slot="end"
                className="absolute right-0 top-0 mr-4  md:mr-8 md:mt-3 end"
              >
                <img
                  style={{ float: "right" }}
                  onClick={() => setShowModal(false)}
                  src={closeBtn}
                />
              </IonButtons>
              <p>
                <IonHeader className="bg-white">
                  <IonToolbar>
                    <IonTitle className="text-center">
                      <div
                        className={
                          classes.signupBannerGrey +
                          " login-box-msg absolute right-0 top-0 mt-4 mr-4  md:mr-8 md:mt-3"
                        }
                      >
                        Every child is Intelligent !
                      </div>
                    </IonTitle>
                  </IonToolbar>
                </IonHeader>
                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=s6QGSu0ga0M"
                >
                  <img className="p-50" src={samar} />
                </a>
              </p>
            </IonModal>

            <div className="row justify-content-center">
              <button
                style={{ width: "90%" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className={classes.mainActionButton + " btn btn-primary"}
              >
                <i className="fas fa-play-circle"></i> Watch Story of BrainCells
                Founders Son - Samar
              </button>
            </div>
          </div>
          <br />
          <div className="about-sec">
            <div className="container align-items-center text-center">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="abt-col wow fadeInUp"
                    data-wow-duration="1000ms"
                  >
                    <img src={icon6} alt="" />
                    <h1 className={classes.blackTxtColor}>
                      Algorithmically programmed <br />
                      Online Learning App
                    </h1>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="abt-col wow fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="200ms"
                  >
                    <img src={icon8} alt="" />
                    <h1 className={classes.blackTxtColor}>
                      No Online classes. <br /> Self Performable learning
                      activities for kids
                    </h1>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="abt-col wow fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="400ms"
                  >
                    <img src={icon15} alt="" />
                    <h1 className={classes.blackTxtColor}>
                      No long screen times. <br /> Only 5 - 10 mins of your
                      child&apos;s Daily time
                    </h1>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="abt-col wow fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="600ms"
                  >
                    <img src={icon9} alt="" />
                    <h1 className={classes.blackTxtColor}>
                      Incredible 12 RBE Techniques <br /> Super Fast Learning
                    </h1>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-lg-8">
              <div className=" className-sidebar position-static">
                <div className="widget widget-information">
                  <ul className="clt">
                    <li>
                      <div className="clt-info">
                        <h1 className={classes.techInfoTitle}>
                          Next BrainCells Program Batch{" "}
                        </h1>
                      </div>
                    </li>
                    <li>
                      <div className="clt-info">
                        <h3 className={classes.techInfoTitle}>
                          {nextSaturday} <span>Saturday</span>
                        </h3>
                      </div>
                    </li>
                  </ul>
                  <div className="tech-info">
                    <h2 className={classes.techInfoTitle}>Program Mentor</h2>
                    <div className="tech-tble">
                      <img src={tejasa} alt="" />
                      <div className="tch-info">
                        <h3>Tejasa Potnis</h3>
                        <span>BrainCells Founder</span>
                      </div>
                    </div>
                    <a href="#signup" title="" className="btn-default">
                      Signup Now to Enroll
                      <i className="fa fa-long-arrow-alt-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" className-sidebar position-static">
                <div className="widget widget-information">
                  <ul className="clt">
                    <li>
                      <div className="clt-info">
                        <h3 className={classes.programSchedule}>
                          Program Schedule
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="clt-info">
                        <h3 className={classes.clubhouseSchedule}>
                          First Live Clubhouse{" "}
                          <span>
                            {nextSaturday} <br /> 9AM to 10AM (India Time)
                          </span>
                        </h3>
                      </div>
                    </li>
                  </ul>
                  <div className="tech-info">
                    <div className="tech-tble">
                      <div className="tch-info">
                        <h3>Monday - Friday</h3>
                        <span>
                          In App self performable Daily Exercises with 5 - 10
                          RBE Activities for your child
                        </span>
                      </div>
                      <div className="tch-info">
                        <h3>Saturday</h3>
                        <span>
                          In App Recorded Clubhouse Session Video for you
                          (parent) for first 3 months
                        </span>
                      </div>
                      <div className="tch-info">
                        <h3>Sunday</h3>
                        <span>Holiday</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </IonContent>
  );
};
