import moment from "moment";
import { useUserContext } from "../store/context/user-context";
import { useEffect, useState } from "react";
import { useSubscriptionContext } from "../store/context/subscription-context";

const useCountdown = (func = () => {}) => {
  const COUPON_COUNTDOWN_TIME = 300;
  const {
    user: { createdAt },
  } = useUserContext();
  const { activeSubscriptions } = useSubscriptionContext();
  const isAnyProgramSubscribed =
    activeSubscriptions.filter((sub: any) => {
      return sub.programType !== "TRIAL";
    }).length > 0;

  if (isAnyProgramSubscribed) {
    for (let i = 0; i < 3; i++) {
      localStorage.setItem(`COUPON_COUNT_ACTIVE_${i}`, "completed");
    }
  }
  //TEST
  // const todaysDate: any = moment("17-03-2023", "DD-MM-YYYY").format(
  //   "YYYY-MM-DD"
  // );

  const todaysDate: any = moment().format("YYYY-MM-DD");
  const createdDate = createdAt.split("T")[0];
  const diffDate = Number(moment(todaysDate).diff(createdDate, "days"));
  let discountPercentage = 0;
  if (diffDate !== null && diffDate !== undefined) {
    if (diffDate === 0) {
      discountPercentage = 10;
    } else if (diffDate === 1) {
      discountPercentage = 15;
    } else if (diffDate === 2) {
      discountPercentage = 20;
    }
  }

  const couponDay = diffDate < 3 ? diffDate : 3;

  const [count, setCount] = useState<any>(
    localStorage.getItem(`TRIAL_COUPON_COUNT_${couponDay}`) ||
      COUPON_COUNTDOWN_TIME
  );

  // console.log(
  //   localStorage.getItem(`TRIAL_COUPON_COUNT_${couponDay}`) ||
  //     COUPON_COUNTDOWN_TIME
  // );

  const countFinished = () => {
    localStorage.setItem(`COUPON_COUNT_ACTIVE_${couponDay}`, "completed");
    func();
  };

  const startCount = (page: any) => {
    reduceCount(count);
    localStorage.setItem(`COUPON_COUNT_ACTIVE_${couponDay}`, "true");
  };

  useEffect(() => {
    localStorage.setItem(`TRIAL_COUPON_COUNT_${couponDay}`, count);
  }, [count]);

  const reduceCount = (count: any) => {
    if (count > 0) {
      const updatedCount: any = count - 1;
      localStorage.setItem(`TRIAL_COUPON_COUNT_${couponDay}`, updatedCount);
      setCount(localStorage.getItem(`TRIAL_COUPON_COUNT_${couponDay}`));
      setTimeout(() => {
        reduceCount(count - 1);
      }, 1000);
    } else {
      countFinished();
    }
  };

  const secCount = count % 60;
  const secCountString = `${count % 60}`.split("");
  const minTwo = Math.floor(count / 60);
  const secOne = secCount > 9 ? secCountString[0] : 0;
  const secTwo = secCount > 9 ? secCountString[1] : secCountString[0];

  return {
    minTwo,
    secOne,
    secTwo,
    discountPercentage,
    couponDay,
    startCount,
    isAnyProgramSubscribed,
    diffDate,
  };
};

export { useCountdown };
