/** @format */

import React, { useState, useEffect } from "react";
import { useUserContext } from "../../../../store/context/user-context";
import styles from "./Progressbar.module.css";
import { useHistory } from "react-router";

export default function Progressbar({
  setShowProgressbar,
  showIonButton,
}: any) {
  const history = useHistory();
  const {
    user: { childName },
  } = useUserContext();
  const [filled, setFilled] = useState(0);

  const [isRunning, setIsRunning] = useState(false);
  const [coverFilled, setCoverFilled] = useState(300);

  useEffect(() => {
    return () => {
      // showIonButton();
      //Using history.goBack 2 times to make it work
      history.goBack();
    };
  }, []);
  useEffect(() => {
    if (filled === 100) {
      setShowProgressbar(false);
      //Using history.goBack 2 times to make it work
      history.goBack();
    }
    if (filled < 100 && isRunning) {
      setTimeout(() => setFilled((prev) => (prev += 2)), 200);
    }
  }, [filled, isRunning, setShowProgressbar]);

  useEffect(() => {
    if (coverFilled > 0 && isRunning) {
      setTimeout(() => setCoverFilled((prev) => (prev -= 3)), 100);
    }
  }, [coverFilled, isRunning]);

  useEffect(() => {
    setIsRunning(true);
  }, []);
  return (
    <div className={styles["loader-contents-container"]}>
      <div className={styles["progressbar"]}>
        <div className={styles["loader-container"]}>
          <div className={styles["box"]}>
            <div className={styles["loader"]}></div>
          </div>
          <div
            className={styles["loader-child"]}
            style={{
              width: `${coverFilled}px`,
            }}
          ></div>
        </div>
        <div
          className={styles["loader-content-container"]}
          style={{ marginTop: "-4.2rem" }}
        >
          <div className={styles["loader_text_container"]}>
            <p className={styles["loader-text"]}>
              Customizing program for {childName ? childName : "your child"}
            </p>
          </div>
          <span className={styles["loader-text-percentage"]}>{filled}%</span>
        </div>
      </div>
    </div>
  );
}
