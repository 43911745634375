import { useState } from "react";
import { useHistory } from "react-router";
import styles from "./TopicCard.module.css";
import { useRttOnlineContext } from "../../../../store/context/rtt-online-context";
import { detectIsMobileDevice } from "../../../../utils/general-utils";
import { dateTimeFromUTCtoLocal } from "../../../../utils/date-utils";
const TopicCard = ({
  topic,
  isBooked,
  modalData,
  size = "small",
  goToExercise = false,
  isTeacher,
}: any) => {
  const { boxColor, boxBorderColor } = topic;
  const themeId = topic.eventId || topic.id;
  const [detailsModalData, setDetailsModalData] = modalData;

  const { rttData, getAllRttData, rttDataState, rttDataDispatch } =
    useRttOnlineContext();

  const selectedTopics = rttDataState.selectedTopics;

  const topicCardWidthWeb =
    size === "small" ? "12rem" : size === "medium" ? "16rem" : "20rem";

  const topicCardWidthMobile =
    size === "small" ? "6rem" : size === "medium" ? "11rem" : "13rem";

  const [showInfoPopUp, setShowInfoPopUp] = useState<boolean>(false);
  const history = useHistory();
  const toggleInfoPopup = () => {
    setShowInfoPopUp(!showInfoPopUp);
  };

  const showInfo = () => {
    toggleInfoPopup();
  };

  const onBookClick = () => {
    if (!goToExercise) {
      if (!isBooked) {
        rttDataDispatch({
          type: "ADD_TO_CART",
          payload: topic,
        });
      } else {
        openRttOnlineActivity();
      }
      if (!isBooked) {
        rttDataDispatch({
          type: "ADD_TO_CART",
          payload: topic,
        });
      } else {
        openRttOnlineActivity();
      }
    }
  };
  const openRttOnlineActivity = () => {
    // Navigate to the activity page
    history.push(
      `/rtt-online/exercise/${themeId}${isTeacher ? "?t=teacher" : ""}`
    );
  };

  const onCardClick = (topic: any) => {
    if (!goToExercise) {
      setDetailsModalData({
        showModal: true,
        data: topic,
      });
    }
  };

  const goToExercisePage = () => {
    if (goToExercise) openRttOnlineActivity();
  };

  const { localDate, localTime } = dateTimeFromUTCtoLocal(
    topic.eventDate,
    topic.eventTime
  );

  return (
    <>
      {/* {showPopUp && <TopicCardPopUp togglePopup={togglePopup} />} */}
      {/* {showInfoPopUp && <TopicCardInfoPopUp togglePopup={toggleInfoPopup} />} */}
      <div
        onClick={goToExercisePage}
        style={{
          width: detectIsMobileDevice()
            ? topicCardWidthMobile
            : topicCardWidthWeb,
        }}
        className={`${styles["topic_card"]} animate__animated animate__zoomIn`}
      >
        <div
          onClick={() => onCardClick(topic)}
          className={styles["topic_card_head"]}
        >
          <img
            className={styles["topic_card_image"]}
            src={`https://braincells.in${topic.imagePath}`}
            alt={topic.imagePath}
          />
        </div>
        {!isTeacher && (
          <div
            style={{
              border: `6.6px solid ${boxBorderColor}`,
              backgroundColor: boxColor,
            }}
            onClick={onBookClick}
            className={styles["topic_card_body"]}
          >
            {/* <div className={styles["topic_card_date"]}> */}
            {goToExercise
              ? `${localDate} at ${localTime}`
              : `${topic.weekStartDate} to ${topic.weekEndDate}`}
            {/* </div> */}
          </div>
        )}
      </div>
    </>
  );
};

const TopicCardPopUp = ({ topic, togglePopup }: any) => {
  const closeModal = () => {
    togglePopup();
  };

  return (
    <div onClick={closeModal} className={styles["topic_card_popup_wrapper"]}>
      <div
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        className={styles["topic_card_popup"]}
      >
        <div className={styles["close_popup"]}>
          <button onClick={togglePopup}>X</button>
        </div>
        <h1>Hello</h1>
      </div>
    </div>
  );
};

const TopicCardInfoPopUp = ({ toggleInfoPopup }: any) => {
  return (
    <div
      onClick={toggleInfoPopup}
      className={styles["topic_card_popup_wrapper"]}
    >
      <div
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        className={styles["topic_card_popup"]}
      >
        <div className={styles["close_popup"]}>
          <button onClick={toggleInfoPopup}>X</button>
        </div>
        <h1>Hello</h1>
      </div>
    </div>
  );
};

export { TopicCard as default };
