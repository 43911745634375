import { useRef } from "react";
import styles from "./ScrollButtons.module.css";
const ScrollRight = ({ programRef }: any) => {
  const scrollForwardRef = useRef<any>(null);

  const scrollForward = () => {
    scrollForwardRef.current = setInterval(() => {
      programRef.current.scrollLeft += 100;
    }, 100);
  };
  const stopScrollForward = () => {
    clearInterval(scrollForwardRef.current);
  };
  return (
    <div
      onTouchStart={scrollForward}
      onTouchEnd={stopScrollForward}
      onMouseDown={scrollForward}
      onMouseUp={stopScrollForward}
      className={styles["forward_button_wrapper"]}
    >
      <button className={styles["nav_button"]}>
        <i className="fas fa-angle-right"></i>
      </button>
    </div>
  );
};

const ScrollLeft = ({ programRef }: any) => {
  const scrollBackRef = useRef<any>(null);

  const scrollBack = () => {
    scrollBackRef.current = setInterval(() => {
      programRef.current.scrollLeft -= 100;
    }, 100);
  };
  const stopScrollBack = () => {
    clearInterval(scrollBackRef.current);
  };
  return (
    <div
      onTouchStart={scrollBack}
      onTouchEnd={stopScrollBack}
      onMouseDown={scrollBack}
      onMouseUp={stopScrollBack}
      className={styles["backward_button_wrapper"]}
    >
      <button className={styles["nav_button"]}>
        <i className="fas fa-angle-left"></i>
      </button>
    </div>
  );
};

export { ScrollRight, ScrollLeft };
