import React from 'react'
import { IonContent, IonHeader, IonRadioGroup,IonLabel,IonRadio,IonItem,IonPage, IonTitle, IonDatetime, IonToolbar,IonButton,IonButtons, IonBackButton, IonCard, IonImg, IonCardTitle, IonInput} from '@ionic/react';
import { useState } from 'react';

import styles from './SetAvatar.module.css';

function SetAvatar(props:any) {
    
    const [avatarImageURL,setAvatar] = useState();
    const [selected, setSelected] = useState<string>('biff');
    let urlList = ['https://png.pngtree.com/png-vector/20200614/ourmid/pngtree-businessman-user-avatar-character-vector-illustration-png-image_2242909.jpg','https://clipart-best.com/img/mario/mario-clip-art-5.png','https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png','https://www.kindpng.com/picc/m/150-1503949_computer-icons-user-profile-male-profile-icon-png.png']
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
      <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>Profile Creation</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      
      <div className={styles.mainContainer} >

        <div className={styles.creationContainer}>
          <h1> Select Avatar </h1>

          {/* <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}> */}
            <div className={styles.avatarContainer}>
            {
            urlList.map((url,i)=>{
              return(
              <div>
                <input name='avatar' id={url} type='radio' value={url} />
                <label className={styles.avatarLabel} htmlFor={url} > 
                  <IonCard className={styles.avatarComponent} >
                     <img  src={url} style={{height:'10rem',width:'10rem'}} />
                     
                  </IonCard>
                </label>
              </div>
              )
            })
            }
          </div>
            
          {/* </IonRadioGroup> */}


          
            <IonButton href = '/profile-creation/set-skills' color="primary" style={{fontSize:'150%'}} size='large' > Next </IonButton>
        </div>
        
      </div>
      
    </IonContent>
  </IonPage>
  )
}




function AvatarComponent(props:any){
  const {url,key} = props
    return(
      <IonCard className={styles.avatarComponent} >
        <img  src={url} style={{height:'100%',width:'100%'}} />
      </IonCard>
    )
}


export default SetAvatar