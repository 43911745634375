import { useRef } from "react";
import { getHttpData, getTTSAudio } from "../utils/http-util";
import { GENIE_PORT, MAIN_REST_API_SERVER } from "../configs/ServerConfig";

const useWebAudioApi = () => {
  const audioContext = useRef<any>();
  audioContext.current = new AudioContext();

  const createWebAudioBuffer = async (mediaLink: string) => {
    const audioBuffer = await fetchAudioData(audioContext.current, mediaLink);
    return audioBuffer;
  };

  const createAudioObject = async (audioBuffer: any) => {
    const sampleSource = await audioContext.current.createBufferSource();
    sampleSource.buffer = audioBuffer;
    sampleSource.connect(audioContext.current.destination);
    return sampleSource;
  };

  const playWAA = async (audioBuffer: any, func: any) => {
    const audioObject = createAudioObject(audioBuffer);
    audioBuffer.start();
    audioBuffer.onEnded = func;
  };

  const createWebAudioApiAudio = async (mediaLink: string) => {
    const audioBuffer = await fetchAudioData(audioContext.current, mediaLink);
    const audioObject = createAudioObject(audioBuffer);
    return audioObject;
  };

  const getTTsWebApiAudio = async (text: string, lang: string = "ENGLISH") => {
    let audioData = await getHttpData(
      `${MAIN_REST_API_SERVER}:${GENIE_PORT}/tts?lang=${lang}&text=${text}`
    );
    let prefix = "data:audio/wav;base64,";
    let base64Audio = prefix + audioData.data;
    // const ttsAudioElement = await getTTSAudio(text);
    const audioBuffer = await audioContext.current.decodeAudioData(
      base64ToArrayBuffer(base64Audio)
    );
    return audioBuffer;
  };

  return {
    audioContext,
    createWebAudioBuffer,
    createAudioObject,
    playWAA,
    createWebAudioApiAudio,
    getTTsWebApiAudio,
  };
};

const fetchAudioData = async (audioContext: any, mediaLink: string) => {
  const res = await fetch(mediaLink);
  const arrBuffer = await res.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrBuffer);
  return audioBuffer;
};

const base64ToArrayBuffer = (base64: any) => {
  const binaryString = window.atob(base64.split(",")[1]);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export default useWebAudioApi;
