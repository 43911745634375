import moment from "moment";
import { useSubscriptionContext } from "../store/context/subscription-context";
import { useUserContext } from "../store/context/user-context";

const useCurrentProgramDate = (programName: any = "BrainCells") => {
  const { activeSubscriptions } = useSubscriptionContext();
  const { user } = useUserContext();
  const { childBirthDate } = user;
  //TEST
  // const todaysDate: any = moment("18-12-2023", "DD-MM-YYYY").format(
  //   "YYYY-MM-DD"
  // );
  // const refactoredStartDate = moment("01-10-2022", "DD-MM-YYYY").format("YYYY-MM-DD");
  // const refactoredEndDate = moment("29-04-2023", "DD-MM-YYYY").format(
  //   "YYYY-MM-DD"
  // );

  //PRODUCTION
  const currentProgram = activeSubscriptions.find(
    (program) =>
      program.programName === programName && program.status === "ACTIVE"
  );
  const { startDate, endDate, startProgramDay, ageGroup, userId } =
    currentProgram || {};
  const refactoredStartDate = moment(startDate, "DD-MM-YYYY").format(
    "YYYY-MM-DD"
  );
  const refactoredEndDate = moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  const todaysDate: any = moment().format("YYYY-MM-DD");
  // const brainCellsJoinDate: any = activeSubscriptions.filter((program) => {
  //   return program["programName"] === "BrainCells";
  // });
  // const filtered = brainCellsJoinDate.reduce((acc: any, curr: any) => {},
  // brainCellsJoinDate[0]["startDate"]);
  const currentProgramDayCalculate = (
    startDate: string,
    endDate: string,
    startProgramDay: number
  ) => {
    let diffDays = 0;
    if (Number(startProgramDay) !== 1) {
      diffDays = Number(startProgramDay) - 1;
    }
    if (moment(endDate).isBefore(todaysDate)) {
      return (
        Number(diffDays) + Number(moment(endDate).diff(startDate, "days")) + 1
      );
    } else {
      return (
        Number(diffDays) +
        Number(moment(todaysDate).diff(startDate, "days")) +
        1
      );
    }
  };

  const daysSinceBirthGenerator = () => {
    // const testBirthDate = moment("20-01-2023", "DD-MM-YYYY").format(
    //   "DD/MM/YYYY"
    // );
    const formattedBirthDate = moment(childBirthDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    return moment(todaysDate).diff(formattedBirthDate, "days");
  };

  const daysSinceBirth = Number(daysSinceBirthGenerator());
  const tempCurrentProgramDay = currentProgramDayCalculate(
    refactoredStartDate,
    refactoredEndDate,
    startProgramDay
  );

  const currentProgramDay = tempCurrentProgramDay;

  return {
    currentProgramDay,
    todaysDate,
    ageGroup,
    userId,
    daysSinceBirth,
    refactoredEndDate,
    refactoredStartDate,
  };
};

export default useCurrentProgramDate;
