import { useRef } from "react";
import styles from "./MedalModal.module.css";
import Confetti from "react-confetti";

const MedalModal = ({ medalModal, closeMedalModal }: any) => {
  const { showMedalModal, medalData, medalType } = medalModal;
  console.log("medalData", medalData);

  return (
    <div className={styles.tncModalBackground}>
      <Confetti numberOfPieces={400} recycle={false} />
      <div
        className={`${styles.tncModalContainer} animate__animated animate__slideInUp`}
      >
        <div onClick={closeMedalModal} className={styles["close_btn"]}>
          <i className="fas fa-times-circle "></i>
        </div>

        <div className={styles["image_container"]}>
          <img
            className={styles["achivement_medal"]}
            src={`/assets/community/${medalType}.png`}
            alt=""
          />
          {medalType === "bronze-medal" ? (
            <p className={styles["medal_name"]}>Bronze Medal</p>
          ) : medalType === "silver-medal" ? (
            <p className={styles["medal_name"]}>Silver Medal</p>
          ) : (
            <p className={styles["medal_name"]}>Gold Medal</p>
          )}
        </div>

        <div>
          {medalType === "bronze-medal" ? (
            <p className={styles["medal_name"]}>
              Bronze Medal Awarded as {medalData.childName} completed more than
              3 activities
            </p>
          ) : medalType === "silver-medal" ? (
            <p className={styles["medal_name"]}>
              Bronze Medal Awarded as {medalData.childName} completed more than
              6 activities
            </p>
          ) : (
            <p className={styles["medal_name"]}>
              Bronze Medal Awarded as {medalData.childName} completed more than
              9 activities
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedalModal;
