/** @format */

import { IonPage, IonImg } from "@ionic/react";
import { useUserContext } from "../../../store/context/user-context";
import styles from "./ParentHome.module.css";
import { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  detectIsIOSDevice,
  detectIsMobileDevice,
} from "../../../utils/general-utils";
import { NpsModal } from "./Components/NpsModal";

export const ParentsHome = (props: any) => {
  const userCtx = useUserContext();
  const { user } = userCtx;
  const { id } = user;

  const [modalDetails, setModalDetails] = useState({
    showModal: true,
  });
  const logoutHandler = () => {
    userCtx.logoutUser();
  };

  const parentsFullscreenHandle = useFullScreenHandle();

  const enterFullScreen = () => {
    if (!parentsFullscreenHandle.active && detectIsMobileDevice())
      parentsFullscreenHandle.enter();
  };

  return (
    <FullScreen handle={parentsFullscreenHandle}>
      <IonPage>
        <div
          onClick={enterFullScreen}
          style={{
            backgroundImage: `url("/assets/parentLanding/background.svg")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
          className={styles.parentHomeContainer}
        >
          <NpsModal />
          <div className={styles.parentActivitiesDiv}>
            <ParentActivities />
          </div>

          <div className={styles.tabBarDiv}>
            <ParentTabBar />
          </div>
        </div>
      </IonPage>
    </FullScreen>
  );
};

function ParentTabBar() {
  return (
    <div
      style={{
        backgroundImage: `url("/assets/parentLanding/background-bar.svg")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%",
      }}
      className={detectIsIOSDevice() ? styles.tabBarIos : styles.tabBar}
    >
      <a className={styles.navIonBtn} href="/dashboard">
        <img
          className={styles.navigationBtn}
          src="/assets/parentLanding/back-button.svg"
          alt=""
        />
      </a>

      <a className={styles.navIonBtn} href="/parents/FAQ">
        <img
          className={styles.navigationBtn}
          src="/assets/parentLanding/help-button.svg"
          alt=""
        />
      </a>

      <a className={styles.navIonBtn} href="/parents/TNC">
        <img
          className={styles.navigationBtn}
          src="/assets/parentLanding/tandc-button.svg"
          alt=""
        />
      </a>

      <a className={styles.navIonBtn} href="/parents/profile-settings">
        <img
          className={styles.navigationBtn}
          src="/assets/parentLanding/settings-button.svg"
          alt=""
        />
      </a>
    </div>
  );
}
function ParentActivities() {
  let data = [
    // {
    //   url: "/parents/masterclass-registration",
    //   imgSrc: "/assets/parentLanding/masterclass-registration-button.svg",
    //   name: "MasterClass Registration",
    // },
    {
      url: "/parents/clubhouse",
      imgSrc: "/assets/parentLanding/clubhouse-button.svg",
      name: "Clubhouse",
    },
    {
      url: "/parents/subscriptions",
      imgSrc: "/assets/parentLanding/subscription-and-payments-button.svg",
      name: "Subscriptions and Payments",
    },
    {
      url: "/parents/refer-friend",
      imgSrc: "/assets/parentLanding/refer-friend-button.svg",
      name: "Refer a Friend",
    },
    // {
    //   url: "/parents/reports",
    //   imgSrc: "/assets/parentLanding/braincells-report.svg",
    //   name: "Reports",
    // },
    {
      url: "/parents/events",
      imgSrc: "/assets/parentLanding/events-and-meetups-button.svg",
      name: "Events and Meetups",
    },
    // {
    //   url: "/parents/parenting-rewards",
    //   imgSrc: "/assets/parentLanding/parenting-rewards-button.svg",
    //   name: "Parenting Rewards",
    // },
    {
      url: "/parents/community",
      imgSrc: "/assets/parentLanding/community.svg",
      name: "Blogs and Social Media",
    },
    {
      url: "/parents/device-access",
      imgSrc: "/assets/parentLanding/other-devices-button.svg",
      name: "Other Devices",
    },
  ];

  return (
    <div className={styles.parentContainer}>
      {data.map((e, i) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            key={i}
          >
            <ParentFunctionComponent
              url={e["url"]}
              imgSrc={e["imgSrc"]}
              name={e["name"]}
            />
          </div>
        );
      })}
    </div>
  );
}

function ParentFunctionComponent(props: any) {
  const { key, url, name, imgSrc } = props;

  return (
    <a href={url}>
      <IonImg className={styles.dashboardSingleCard} src={imgSrc}></IonImg>
    </a>
  );
}
