/** @format */

import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { getHttpData } from "../../../../../utils/http-util";
import { useState } from "react";
import BarChart from "./BarChart";
import { useUserContext } from "../../../../../store/context/user-context";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const users = [
  { name: "👍", activitiesSolvedPerDay: 150, totalActivitiesSolved: 100 },
  { name: "ayush", activitiesSolvedPerDay: 70, totalActivitiesSolved: 200 },
  { name: "Aryan", activitiesSolvedPerDay: 95, totalActivitiesSolved: 300 },
  { name: "pablo", activitiesSolvedPerDay: 125, totalActivitiesSolved: 90 },
  { name: "Krish", activitiesSolvedPerDay: 100, totalActivitiesSolved: 150 },
];

function BarDataRender() {
  const [key, setKey] = useState<any>([]);
  const userCtx = useUserContext();
  const { user } = userCtx;
  const { id } = user;

  const [value, setValue] = useState<any>([]);

  const userDataChart1 = {
    labels: key.slice(0, 5)?.map((data: any) => data),
    datasets: [
      {
        label: "Performance Details",
        data: value.slice(0, 5)?.map((data: any) => data),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };

  const userDataChart2 = {
    labels: key.slice(6, 11)?.map((data: any) => data),
    datasets: [
      {
        label: "Performance Details",
        data: value.slice(6, 11)?.map((data: any) => data),
        backgroundColor: [
          "#DB3A34",
          "#6A994E",
          "#BC6C25",
          "#CDB4DB",
          "#F4A261",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    (async () => {
      const response = await getHttpData(
        `https://staging2.braincells.in:8082/reporting/brain_functions?userId=${id}`
      );
      console.log(response.data);

      setKey(Object.keys(response.data));

      console.log(key);

      //const value = Object.values(response.data);

      setValue(Object.values(response.data));
    })();
  }, []);

  return (
    <div>
      {" "}
      <div>
        <BarChart userData1={userDataChart1} userData2={userDataChart2} />
      </div>
    </div>
  );
}

export default BarDataRender;
