import { useEffect } from "react";
import { preFetchImages } from "../utils/general-utils";

export const useImgPrefetch = (imgArr: any, mode: any = "activity") => {
  return useEffect(() => {
    const stopPrefetch = preFetchImages(imgArr);
    return () => {
      stopPrefetch();
    };
  }, []);
};
