import { useEffect } from "react";
import { getRandomInt } from "./general-utils";

const useOnClickOutside = (ref: any, callbackFunc: any) => {
  useEffect(() => {
    const eventListener = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFunc();
      }
    };

    document.addEventListener("click", eventListener);

    return () => {
      document.removeEventListener("click", eventListener);
    };
  }, [ref, callbackFunc]);
};

export const randomColor: any = (buttonData: any, crrNum: any = -1) => {
  let randomNumber = getRandomInt(Object.keys(buttonData).length - 1);
  if (randomNumber === crrNum) {
    return randomColor(crrNum);
  } else {
    return randomNumber;
  }
};

export { useOnClickOutside };
