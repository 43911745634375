import { useEffect, useRef, useState } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { detectIsMobileDevice } from "../utils/general-utils";

const useActivityStarter = () => {
  const [startActivity, setStartActivity] = useState<boolean>(false);
  const activityJsonRef = useRef<any>({});
  //   const [activityJSON, setActivityJSON] = useState<any>({});

  const isMobile = detectIsMobileDevice();
  const activityInitializerPresent: any = activityJsonRef.current?.initializer
    ?.componentName
    ? true
    : false;
  const activityHandle = useFullScreenHandle();
  const exerciseHandle = useFullScreenHandle();

  const toggleActivity = () => {
    setStartActivity((prevState) => !prevState);
    activityHandle.active && activityHandle.exit();
  };

  const endActivity = () => {
    if (isMobile && activityInitializerPresent) {
      activityHandle.exit();
    } else if (isMobile) {
      setStartActivity(false);
    } else {
      activityHandle.exit();
    }
  };

  const enterFullScreenInsideActivity = () => {
    activityHandle.enter();
  };

  const enterFullscreen = () => {
    if (!exerciseHandle.active && isMobile && !document.fullscreenElement)
      exerciseHandle.enter();
  };

  const activityStartFullscreen = () => {
    toggleActivity();
    if (!isMobile && !activityInitializerPresent) activityHandle.enter();
  };

  useEffect(() => {
    if (!activityHandle.active) {
      setStartActivity(false);
    }
  }, [activityHandle.active]);

  return {
    activityJsonRef,
    startActivity,
    toggleActivity,
    endActivity,
    enterFullScreenInsideActivity,
    setStartActivity,
    activityHandle,
    exerciseHandle,
    enterFullscreen,
    activityStartFullscreen,
  };
};

export default useActivityStarter;
