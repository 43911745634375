import { useEffect, useState } from "react";
import { getHttpData } from "../../../utils/http-util";
import { IonContent, IonPage } from "@ionic/react";
import InvoiceListItem from "./components/InvoiceListItem";
import styles from "./InvoiceList.module.css";
import CircleLoader from "../../Parents/Subscription/components/CircleLoader";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import BootStrapTable from "../../../components/generalComponents/Tables/BootStrapTable";
import moment from "moment";

const InvoiceList = () => {
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const todayDate = moment().format("YYYY-MM-DD");
  const yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  const [dateInput, setDateInput] = useState<any>({
    dateOne: yesterdayDate,
    dateTwo: todayDate,
  });

  const getInvoiceList = async (dateOne: any, dateTwo: any) => {
    const dateTwoTime = `${dateTwo}T23:59:59`;
    const dateOneTime = `${dateOne}T00:00:00`;
    setIsLoading(true);
    const response = await getHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/payment_history?start_timestamp=${dateOneTime}&end_timestamp=${dateTwoTime}`
    );
    setInvoiceList(response.data);
    setIsLoading(false);
  };
  console.log(invoiceList);
  const createdAtToDateTime = (createdAt: any) => {
    const dateTimeArr = createdAt.split("T");
    const createdDate = dateTimeArr[0];
    const createdTime = dateTimeArr[1];
    return { createdDate, createdTime };
  };

  useEffect(() => {
    getInvoiceList(dateInput.dateOne, dateInput.dateTwo);
  }, []);

  const TABLE_HEADINGS = [
    "Invoice Id",
    "Parent name",
    "Paid amount",
    "Date",
    "View Invoice",
  ];

  const contentArray: any = [];

  invoiceList.map((invoice: any) => {
    const { createdDate, createdTime } = createdAtToDateTime(invoice.createdAt);
    contentArray.push([
      `TREPL00${invoice.id}`,
      invoice.userName,
      invoice.paidAmount,
      createdDate,
      <a
        className="btn btn-primary"
        href={`/admin/invoice/${invoice.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        View
      </a>,
    ]);
  });

  const searchClick = () => {
    getInvoiceList(dateInput.dateOne, dateInput.dateTwo);
  };

  return (
    <IonPage>
      <IonContent>
        {isLoading && <CircleLoader />}
        <div className={styles["invoice_list_container"]}>
          <h1 className={styles["invoice_list_heading"]}>Invoice List</h1>
          <div className={styles["date_filter_container"]}>
            <p className={styles["filter_date_text"]}>Filter Date:</p>
            From:
            <input
              onChange={({ target: { value } }) =>
                setDateInput((prev: any) => {
                  return { ...prev, dateOne: value };
                })
              }
              type="date"
              value={dateInput.dateOne}
            />
            To:
            <input
              onChange={({ target: { value } }) =>
                setDateInput((prev: any) => {
                  return { ...prev, dateTwo: value };
                })
              }
              type="date"
              value={dateInput.dateTwo}
            />
            <button
              onClick={searchClick}
              type="button"
              className="btn btn-primary"
            >
              Search
            </button>
          </div>

          <BootStrapTable
            showIndex={false}
            tableHeadings={TABLE_HEADINGS}
            tableContent={contentArray}
          />

          {/* {invoiceList.map((invoice: any) => {
            const { createdDate, createdTime } = createdAtToDateTime(
              invoice.createdAt
            );
            return (
              <InvoiceListItem
                key={invoice.id}
                invoice={invoice}
                createdDate={createdDate}
                createdTime={createdTime}
              />
            );
          })} */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default InvoiceList;
