import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import styles from "./DeviceAccess.module.css";

const deviceData = [
  {
    img: "/assets/otherDevices/tablet.svg",
    textImg: "/assets/otherDevices/next-preffered.svg",
  },
  {
    img: "/assets/otherDevices/laptop.svg",
    textImg: "/assets/otherDevices/most-preffered.svg",
  },
  {
    img: "/assets/otherDevices/mobile.svg",
    textImg: "/assets/otherDevices/last-preffered.svg",
  },
];
export const DeviceAccess = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/parents" />
          </IonButtons>
          <IonTitle>Other Devices</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div
          style={{
            backgroundImage: `url("/assets/parentLanding/background-cropped.svg")`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
          className={styles.deviceAccessContainer}
        >
          {deviceData.map((obj) => {
            return (
              <div className={styles.singleDeviceContainer}>
                <img className={styles.deviceImage} src={obj.img} alt="" />
                <img className={styles.textImage} src={obj.textImg} alt="" />
              </div>
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};
