/** @format */

import React, { useState } from "react";
import styles from "./BarChart.module.css";
import { Bar } from "react-chartjs-2";
import {
  FaBookReader,
  FaBrain,
  FaCamera,
  FaFlask,
  FaLightbulb,
  FaMusic,
  FaPuzzlePiece,
  FaSpa,
  FaWalking,
} from "react-icons/fa";
import { TbLink } from "react-icons/tb";

function BarChart(props: any) {
  console.log(props.userData1);

  const [options] = useState({
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    },
  });
  return (
    <div className={styles["main-div-container"]}>
      <div className={styles["center-bg"]}></div>
      <div className={styles["center"]}>
        {" "}
        <div className={styles["bar-chart1"]}>
          {" "}
          <Bar data={props.userData1} options={options} />
        </div>
        <div className={styles["middle-div"]}>
          <div className={styles["m-div1"]}>
            <FaBookReader />
            <TbLink />
          </div>
          <div className={styles["m-div2"]}>
            <FaCamera />
            <FaSpa />
          </div>
          <div className={styles["m-div3"]}>
            <FaLightbulb />
            <FaBrain />
          </div>{" "}
          <div className={styles["m-div4"]}>
            <FaWalking />
            <FaFlask />
          </div>{" "}
          <div className={styles["m-div5"]}>
            <FaPuzzlePiece />
            <FaMusic />
          </div>{" "}
        </div>
        <div className={styles["bar-chart2"]}>
          {" "}
          <Bar data={props.userData2} options={options} />
        </div>
      </div>{" "}
      <div className={styles["div2-container"]}>
        <div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              {" "}
              <FaBookReader />
            </div>
            Photographic Memory
          </div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              <TbLink />
            </div>
            Working Memory
          </div>
        </div>
        <div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              {" "}
              <FaCamera />
            </div>
            Spatial Memory
          </div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              {" "}
              <FaSpa />
            </div>
            Attention
          </div>
        </div>
        <div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              {" "}
              <FaLightbulb />
            </div>
            Sensory Stimulation
          </div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              <FaBrain />
            </div>
            Processing Speed
          </div>
        </div>
        <div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              {" "}
              <FaWalking />
            </div>
            Math Ability
          </div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              <FaFlask />
            </div>
            Pattern Matching
          </div>
        </div>
        <div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              <FaPuzzlePiece />
            </div>
            Logic & Analytical Skills
          </div>
          <div className={styles["div2-container-content"]}>
            <div className={styles["div2-content-margin"]}>
              {" "}
              <FaMusic />
            </div>
            Language Ability
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarChart;
