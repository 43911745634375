import { IonApp, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "bootstrap/dist/css/bootstrap.min.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AppRoutes } from "./AppRoutes";

///////

import { UserContextProvider } from "./store/context/user-context";
import { SubscriptionContextProvider } from "./store/context/subscription-context";

import "./App.css";
import { CommunityContextProvider } from "./store/context/community-context";
import { RttOnlineContextProvider } from "./store/context/rtt-online-context";

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <UserContextProvider>
        <SubscriptionContextProvider>
          <CommunityContextProvider>
            <RttOnlineContextProvider>
              <AppRoutes />
            </RttOnlineContextProvider>
          </CommunityContextProvider>
        </SubscriptionContextProvider>
      </UserContextProvider>
    </IonApp>
  );
};

export default App;
