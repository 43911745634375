import { useSubscriptionContext } from "../store/context/subscription-context";
import { useUserContext } from "../store/context/user-context";

const useIsZeroTwoToOne = () => {
  const { activeSubscriptions } = useSubscriptionContext();
  const { user } = useUserContext();
  const nonTrialSub = activeSubscriptions.filter(
    (sub: any) =>
      sub.programType !== "TRIAL" &&
      sub.ageGroup === "0-1" &&
      sub.programName === "BrainCells" &&
      sub.version === "v2"
  );
  const isZeroToOneV2 =
    activeSubscriptions.filter((sub: any) => {
      return (
        sub.programName === "BrainCells" &&
        sub.status === "ACTIVE" &&
        sub.ageGroup === "0-1" &&
        sub.version === "v2" &&
        sub.programType !== "TRIAL"
      );
    }).length > 0;
  // const isZeroToOneV2 = true;
  const isSubscribedZeroToOne = isZeroToOneV2 ? nonTrialSub.length > 0 : false;
  const isZeroToOneTrial = isZeroToOneV2 ? nonTrialSub.length === 0 : false;
  const isAgeZeroToOne = user.ageGroup === "0-1";
  return {
    isSubscribedZeroToOne,
    isZeroToOneTrial,
    isAgeZeroToOne,
    isZeroToOneV2,
  };
};

export default useIsZeroTwoToOne;
