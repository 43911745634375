import { createContext, useContext, useReducer, useRef, useState } from "react";
import { communityPostLink } from "../../constants/Services/services-constants";
import { getHttpData } from "../../utils/http-util";

// create community context
export const CommunityContext = createContext({
  communityPosts: [],
  getCommunityPosts: () => {},
  avatarSelectionReducer: (avatarState: any, action: any) => {},
  avatarDispatch: (action: any) => {},
  avatarState: false,
});

const avatarSelectionReducerFunc = (avatarState: any, action: any) => {
  switch (action.type) {
    case "SHOW_AVATAR_SELECTION":
      return true;
    case "HIDE_AVATAR_SELECTION":
      return false;
    default:
      return false;
  }
};

const CommunityContextProvider = (props: any) => {
  const [communityPosts, setCommunityPosts] = useState([]);
  const pageNumber = useRef<any>(1);
  const isLoading: any = useRef(false);

  const [avatarState, avatarDispatch] = useReducer(
    avatarSelectionReducerFunc,
    false
  );

  const getCommunityPosts = async () => {
    if (!isLoading.current) {
      isLoading.current = true;
      try {
        const response = await getHttpData(
          `${communityPostLink}?page=${pageNumber.current}&limit=7`
        );
        setCommunityPosts((prevPosts): any => {
          return [...prevPosts, ...response.data];
        });
        pageNumber.current++;
        if (response.data.length > 0) isLoading.current = false;
        return response.data;
      } catch (error) {
        isLoading.current = false;
        console.log(error);
      }
    }
  };

  const communityContext = {
    communityPosts: communityPosts,
    getCommunityPosts: getCommunityPosts,
    avatarSelectionReducer: avatarSelectionReducerFunc,
    avatarDispatch: avatarDispatch,
    avatarState: avatarState,
  };

  return (
    <CommunityContext.Provider value={communityContext}>
      {props.children}
    </CommunityContext.Provider>
  );
};

const useCommunityContext = () => useContext(CommunityContext);

export { CommunityContextProvider, useCommunityContext };
