import React from "react";
import styles from "./UsersLikesCircle.module.css";

const UsersLikeCircle = ({ likesArray }: any) => {
  const likes = likesArray.slice(0, 3);

  return (
    <div className={styles["users_likes_circle"]}>
      <div>
        {likes.map((likeObj: any, index: number) => {
          return (
            <img
              key={index}
              className={styles["profile_picture"]}
              src={`https://res.cloudinary.com/lucidui-cl-a/image/upload/avatar/${likeObj["userId"]}`}
              alt=""
              onError={(e: any) => {
                e.target.src = "https://picsum.photos/200";
              }}
            />
          );
        })}
      </div>
      {likesArray.length !== 0 ? (
        <p className={styles["total_likes"]}>
          {likesArray.length} gave claps 👏
        </p>
      ) : (
        <p className={styles["total_likes"]}>Be the first one to clap 👏</p>
      )}
    </div>
  );
};

export default UsersLikeCircle;
