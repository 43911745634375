import moment from "moment";
import { ZERO_TO_ONE_AVAILABLE_DAYS } from "../constants/Program/program-constants";
import { useUserContext } from "../store/context/user-context";
import { isExerciseCompleted } from "../utils/array-utils";
import {
  isRttTeller,
  programDayToExerciseNumber,
} from "../utils/general-utils";
import useIsZeroTwoToOne from "./useIsZeroToOne";
const RTT_SKIP_CURRENT_DAY = `RTT_SKIP_CURRENT_DAY`;
const BEFORE_CURRENT_PROGRAM_DAY = `BEFORE_CURRENT_PROGRAM_DAY`;
const COMPLETED = `COMPLETED`;
const NOT_COMPLETED = `NOT_COMPLETED`;
const UPCOMING_PROGRAM_DAY = `UPCOMING_PROGRAM_DAY`;
const UPCOMING_DAY = `UPCOMING_DAY`;
const UPCOMING_DAY_HOLIDAY = `UPCOMING_DAY_HOLIDAY`;
const PROGRAM_DAY_CURRENT_DAY = `PROGRAM_DAY_CURRENT_DAY`;
const END_DATE_BEFORE_TODAY = `END_DATE_BEFORE_TODAY`;
const END_DATE_ON_WEEKEND = `END_DATE_ON_WEEKEND`;
const END_DATE_ON_WEEKDAY = `END_DATE_ON_WEEKDAY`;
const CURRENT_PROGRAM_DAY = `CURRENT_PROGRAM_DAY`;
const CURRENT_DAY_HOLIDAY = `CURRENT_DAY_HOLIDAY`;
const SUBSCRIPTION_END_ON_HOLIDAY = `SUBSCRIPTION_END_ON_HOLIDAY`;
const PASSED_HOLIDAY = `PASSED_HOLIDAY`;

export const useGeneratePath = () => {
  const generatePathData = (
    programExercise: any,
    userExercise: any,
    currentProgramDay: any,
    earliestStartProgramDay: any,
    programName: any,
    isTrial: boolean,
    refactoredStartDate: any,
    refactoredEndDate: any,
    startProgramDay: any,
    todaysDate: any
  ) => {
    const isRTT = isRttTeller(programName);
    const programData: any[] = [];
    let completedExerciseCount = 0;
    const programDaysArr = Object.keys(programExercise);
    const lastProgramDay = programDayToExerciseNumber(currentProgramDay);
    const accessibleExeArr = [];
    for (let dayCount = 0; dayCount < programDaysArr.length; dayCount++) {
      let showTrophy = false;
      const programDay = programDaysArr[dayCount];
      const exerciseCompleted = isExerciseCompleted(
        programName,
        userExercise,
        programExercise,
        programDay
      );

      //Count completed exercises to show trophy
      exerciseCompleted && completedExerciseCount++;
      //COMPLETED EXERCISE GREATER THAN 6 THEN SHOW TROPHY
      const hasTrophyExercise = programExercise[programDay].trophy;
      showTrophy = completedExerciseCount >= 6;
      const trophyExerciseData = {
        ...programExercise[programDay],
        showTrophy,
        completionStatus: exerciseCompleted ? "opened" : "closed",
      };

      if (
        Number(programExercise[programDay]["exercise-number"]) &&
        Number(programDay) <= currentProgramDay
      ) {
        accessibleExeArr.push(
          Number(programExercise[programDay]["exercise-number"])
        );
      }
      if (Number(programDay) < earliestStartProgramDay && isRTT["isRtt"]) {
        continue;
      }
      //When week ends, next img does not get loaded
      if (
        programExercise[programDay].holiday === true &&
        Number(programDay) === currentProgramDay
      ) {
        //Current Program day is holiday 7th day of week
        //if subscription ends on trophy day the bubble is shown
        if (
          moment(
            moment(refactoredStartDate)
              .add(currentProgramDay - startProgramDay, "days")
              .format("YYYY-MM-DD")
          ).isSame(refactoredEndDate)
        ) {
          programData.push(
            hasTrophyExercise ? trophyExerciseData : programExercise[programDay]
          );
          programData.push({
            hasEnded: true,
          });
          break;
        } else {
          programData.push(
            hasTrophyExercise ? trophyExerciseData : programExercise[programDay]
          );
          break;
        }
      }
      if (programExercise[programDay].holiday === true) {
        if (Number(programDay) > currentProgramDay) {
          programData.push(
            hasTrophyExercise ? trophyExerciseData : programExercise[programDay]
          );
          break;
        } else {
          programData.push(
            hasTrophyExercise ? trophyExerciseData : programExercise[programDay]
          );
          completedExerciseCount = 0;
          continue;
        }
      }
      //Program day already passed and completed
      if (Number(programDay) < currentProgramDay && exerciseCompleted) {
        programData.push({
          ...programExercise[programDay],

          completionStatus: "completed",
        });
      }
      //Program day passed and pending
      else if (Number(programDay) < currentProgramDay && !exerciseCompleted) {
        programData.push({
          ...programExercise[programDay],
          completionStatus: "pending",
        });
      }
      //Program day current
      else if (Number(programDay) === currentProgramDay) {
        //If the subscription has ended then
        if (moment(refactoredEndDate).isBefore(todaysDate)) {
          //if subscription ends on 6 or 7th day the day so trophy should be visible
          if (programExercise[programDay].holiday) {
            programData.push(
              hasTrophyExercise
                ? trophyExerciseData
                : programExercise[programDay]
            );
            programData.push({
              hasEnded: true,
            });
          } else {
            programData.push(
              {
                ...programExercise[programDay],
                completionStatus: "completed",
              },
              {
                hasEnded: true,
              }
            );
          }
          break;
        }
        //Subscription has not ended
        else {
          programData.push({
            ...programExercise[programDay],
            completionStatus: "current",
          });
        }
      }
      //program day up-coming
      else {
        programData.push({
          ...programExercise[programDay],
          completionStatus: "upcoming",
        });
      }
      //Reset completed exercise count if seven days over
      // if (Number(programDay) % 7 === 6) {
      //   completedExerciseCount = 0;
      // }
      if (Number(programDay) % 7 === 0) {
        completedExerciseCount = 0;
      }
    }
    const programStructure = [];
    let tempArr: any = [];
    localStorage.setItem(
      ZERO_TO_ONE_AVAILABLE_DAYS,
      JSON.stringify({
        lastExercise: accessibleExeArr[accessibleExeArr.length - 1],
        availableDays: accessibleExeArr,
      })
    );
    //divide the days into weeks of 7
    for (let i = 0; i < programData.length; i++) {
      tempArr.push({
        ...programData[i],
        activityShowNumber: i + 1,
      });
      if (tempArr.length === 7) {
        if (programData[i + 1]?.trophy) {
          tempArr.push(programData[i + 1]);
          i++;
        }
        programStructure.push(tempArr);
        tempArr = [];
      }
    }

    if (tempArr.length !== 0) {
      programStructure.push(tempArr);
    }
    //if Trial, then here add { has ended } to first array, to show subscribe now
    if (isTrial) {
      programStructure[0].push({ hasEnded: true });
    }
    return programStructure;
  };

  // const decideCondition = (
  //   programDay: any,
  //   earliestStartProgramDay: any,
  //   isRTT: any,
  //   currentProgramDay: any,
  //   programExercise: any,
  //   exerciseCompleted: any,
  //   refactoredStartDate: any,
  //   startProgramDay: any,
  //   refactoredEndDate: any,
  //   todaysDate: any
  // ) => {
  //   const decisionArr = [];
  //   if (Number(programDay) < earliestStartProgramDay && isRTT["isRtt"]) {
  //     // THIS CONDITION IS FOR RTT, TO SKIP THE DAYS BEFORE THE EARLIEST START DAY
  //     decisionArr.push(RTT_SKIP_CURRENT_DAY);
  //   } else {
  //     // THIS CONDITION IS FOR OTHER PROGRAMS AND RTT AFTER THE EARLIEST START DAY
  //     if (Number(programDay) < currentProgramDay) {
  //       //THIS CONDITION IS FOR DAYS BEFORE THE CURRENT PROGRAM DAY
  //       decisionArr.push(BEFORE_CURRENT_PROGRAM_DAY);
  //       if (programExercise[programDay].holiday === true) {
  //         // HOLIDAY PASSED
  //         decisionArr.push(PASSED_HOLIDAY);
  //       } else if (exerciseCompleted) {
  //         //THIS CONDITION IS FOR DAYS BEFORE THE CURRENT PROGRAM DAY AND EXERCISE IS COMPLETED
  //         decisionArr.push(COMPLETED);
  //       } else {
  //         //THIS CONDITION IS FOR DAYS BEFORE THE CURRENT PROGRAM DAY AND EXERCISE IS NOT COMPLETED
  //         decisionArr.push(NOT_COMPLETED);
  //       }
  //     } else if (Number(programDay) === currentProgramDay) {
  //       // THIS CONDITION IS IF PROGRAM DAY IS EQUAL TO THE CURRENT PROGRAM DAY
  //       decisionArr.push(PROGRAM_DAY_CURRENT_DAY);
  //       // IF THE SUBSCRIPTION HAS ENDED
  //       if (programExercise[programDay].holiday === true) {
  //         // CURRENT PROGRAM DAY IS HOLIDAY
  //         decisionArr.push(CURRENT_DAY_HOLIDAY);
  //         if (
  //           moment(
  //             moment(refactoredStartDate)
  //               .add(currentProgramDay - startProgramDay, "days")
  //               .format("YYYY-MM-DD")
  //           ).isSame(refactoredEndDate)
  //         ) {
  //           decisionArr.push(SUBSCRIPTION_END_ON_HOLIDAY);
  //         }
  //       } else if (moment(refactoredEndDate).isBefore(todaysDate)) {
  //         // IF SUBSCRIPTION ENDED BEFORE TODAY
  //         decisionArr.push(END_DATE_BEFORE_TODAY);
  //         if (programExercise[programDay].trophy) {
  //           // IF SUBSCRIPTION ENDS ON 6TH OR 7TH DAY SO THE TROPHY SHOULD BE VISIBLE
  //           decisionArr.push(END_DATE_ON_WEEKEND);
  //         } else {
  //           // IF SUBSCRIPTION ENDS BEFORE TODAY ON ANY OTHER WEEKDAY
  //           decisionArr.push(END_DATE_ON_WEEKDAY);
  //         }
  //       } else {
  //         // SUBSCRIPTION NOT ENDED
  //         decisionArr.push(CURRENT_PROGRAM_DAY);
  //       }
  //     } else {
  //       // UPCOMING PROGRAM DAYS
  //       decisionArr.push(UPCOMING_PROGRAM_DAY);
  //       if (programExercise[programDay].holiday === true) {
  //         decisionArr.push(UPCOMING_DAY_HOLIDAY);
  //       } else {
  //         decisionArr.push(UPCOMING_DAY);
  //       }
  //     }
  //   }
  //   return decisionArr;
  // };

  return { generatePathData };
};
