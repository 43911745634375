import moment from "moment";
import { useUserContext } from "../../../../store/context/user-context";
import { useState, useEffect } from "react";
import { NetPromoterScore } from "./NetPromoterScore";
import { getHttpData } from "../../../../utils/http-util";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
} from "../../../../configs/ServerConfig";

export function NpsModal() {
  const SHOW_AFTER_DAYS = 15;
  const {
    user: { createdAt, id },
  } = useUserContext();

  const [npsModal, SetNpsModal] = useState(false);
  const date = moment(createdAt);
  const accountCreationDate = date.format("YYYY/MM/DD").split("T")[0];
  const modalOpeningDate = date
    .add(SHOW_AFTER_DAYS, "days")
    .format("YYYY/MM/DD");
  const currentDate = moment(new Date()).format("YYYY/MM/DD");

  useEffect(() => {
    (async () => {
      try {
        const res = await getHttpData(
          `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/userInfo?data-type=nps-score&userId=${id}`
        );

        if (res.data.length > 0) SetNpsModal(false);
        if (res.data.length === 0) {
          if (!moment(accountCreationDate).isSame(currentDate)) {
            if (
              modalOpeningDate === currentDate ||
              moment(currentDate).isAfter(modalOpeningDate)
            ) {
              SetNpsModal(true);
            }
          }
        }
      } catch (err) {
        console.log(false);
      }
    })();
  }, []);

  return (
    <>
      {
        // npsModal
        false && <NetPromoterScore SetNpsModal={SetNpsModal} />
      }
    </>
  );
}
