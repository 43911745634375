/** @format */

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bubble } from "react-chartjs-2";
import { getHttpData } from "../../../../../utils/http-util";
import styles from "./BubbleChart.module.css";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        display: false,
      },
      grid: {
        drawTicks: false,
        drawBorder: false,
      },
    },
    x: {
      beginAtZero: true,
      ticks: {
        display: false,
      },
      grid: {
        drawTicks: false,
        drawBorder: false,
      },
    },
  },
};

function BubbleChart() {
  const [labels, setlabels] = useState<any>([]);

  const [values, setValues] = useState<any>([]);

  const data = {
    datasets: [
      {
        label: labels[0],
        data: Array.from({ length: 1 }, () => ({
          x: 50,
          y: 45,
          r: values[0],
        })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: labels[1],
        data: Array.from({ length: 1 }, () => ({
          x: 5,
          y: 39,
          r: values[1],
        })),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: labels[2],
        data: Array.from({ length: 1 }, () => ({
          x: 45,
          y: 15,
          r: values[2],
        })),
        backgroundColor: "#FFFDD0",
      },
      {
        label: labels[3],
        data: Array.from({ length: 1 }, () => ({
          x: 15,
          y: 29,
          r: values[3],
        })),
        backgroundColor: "rgba(103, 128, 159, 1)",
      },
      {
        label: labels[4],
        data: Array.from({ length: 1 }, () => ({
          x: 35,
          y: 29,
          r: values[4],
        })),
        backgroundColor: "red",
      },
    ],
  };

  useEffect(() => {
    (async () => {
      const response = await getHttpData(
        `https://staging2.braincells.in:8082/reporting/early_interests`
      );

      var myArray = Object.entries(response.data);

      myArray.sort(function (a: any, b: any) {
        return a[1] - b[1];
      });

      var sortedObject = Object.fromEntries(myArray);

      const objectData = JSON.parse(JSON.stringify(sortedObject));
      setlabels(Object.keys(objectData));
      setValues(Object.values(objectData));
    })();
  }, []);
  return (
    <div className={styles["bubble-container"]}>
      <div className={styles["bubble-chart-container"]}>
        <Bubble options={options} data={data} />
      </div>
    </div>
  );
}

export default BubbleChart;
