import styles from "./ActivityProgressbar.module.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useLocation } from "react-router-dom";

const ActivityProgressbar = (props: any) => {
  const { progressPercentage, completedActivity } = props;

  const location = useLocation();

  return (
    <div className={styles["activity_progress_bar_container"]}>
      <div className={styles["activity_progress_bar"]}>
        <ProgressBar
          completed={`${completedActivity.length}`}
          maxCompleted={9}
          // bgColor="#8DD54F"
          bgColor="#9b2ece"
        />
        {progressPercentage >= 32 ? (
          <img
            className={styles["bronze-medal"]}
            src="/assets/community/bronze-medal.png"
            alt="bronze-medal"
          />
        ) : (
          <img
            className={styles["bronze-medal"]}
            src="/assets/community/bronze-outline.png"
            alt="bronze-medal"
          />
        )}

        {progressPercentage >= 66 ? (
          <img
            className={styles["silver-medal"]}
            src="/assets/community/silver-medal.png"
            alt="silver-medal"
          />
        ) : (
          <img
            className={styles["silver-medal"]}
            src="/assets/community/silver-outline.png"
            alt="silver-medal"
          />
        )}
        {progressPercentage >= 98 ? (
          <img
            className={styles["gold-medal"]}
            src="/assets/community/gold-medal.png"
            alt="gold-medal"
          />
        ) : (
          <img
            className={styles["gold-medal"]}
            src="/assets/community/gold-outline.png"
            alt="gold-medal"
          />
        )}
      </div>

      <a
        onClick={() =>
          sessionStorage.setItem(
            "PROGRESSBAR_LINK",
            JSON.stringify(location.pathname)
          )
        }
        className={styles["community_button"]}
        href="/community"
      >
        Canvas
      </a>
    </div>
  );
};

export default ActivityProgressbar;
