import moment from "moment";
import { useEffect, useState } from "react";
import { CommunityLoader } from "../../../../../components/generalComponents/loaders/CommunityLoader/CommunityLoader";
import useImageUpload from "../../../../../hooks/useImgUpload";
import { usePostCreator } from "../../../../../hooks/usePostDataCreator";
import styles from "./EditPostModal.module.css";

const EditPostModal = ({ post, onClose, addCustomPostImage }: any) => {
  const [title, setTitle] = useState<any>();
  const { addCustomImage } = usePostCreator();
  const { selectImage, uploadMultipleImages } = useImageUpload();
  const [inputFileSelected, setInputFileSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitClick = async () => {
    if (inputFileSelected) {
      setLoading(true);
      try {
        const cloudinaryLinkArr = await uploadMultipleImages();
        const res = await addCustomImage(
          post["_id"]["$oid"],
          cloudinaryLinkArr,
          post["customImages"]
        );
        const finalImageArr = cloudinaryLinkArr.map((url: any) => {
          return {
            imageUrl: url,
            createdAt: moment().unix(),
          };
        });
        addCustomPostImage(finalImageArr);
        onClose();
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);

  useEffect(() => {
    return () => {
      setTitle("");
      setInputFileSelected(false);
    };
  }, []);

  return (
    <div className={styles["edit_post_modal_wrapper"]}>
      {loading ? (
        <CommunityLoader />
      ) : (
        <div className={styles["edit_post_modal"]}>
          <div className={styles["edit_post_modal_header"]}>
            <h3>Add to the Post</h3>
            <div className={styles["edit_post_modal_close"]}>
              <i onClick={onClose} className="fas fa-times"></i>
            </div>
          </div>
          <div className={styles["edit_post_modal_body"]}>
            {/* <div className={styles["edit_post_modal_input"]}>
              <label>Post Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div> */}
            <div className={styles["edit_post_modal_file"]}>
              <label className={styles["edit_post_modal_file_label"]}>
                Select Image(s) to post
              </label>
              <input
                type="file"
                onChange={(e) => {
                  setInputFileSelected(true);
                  selectImage(e);
                }}
                accept="image/*"
                multiple
              />
              {showError && (
                <p className={styles["edit_post_modal_error"]}>
                  Please enter valid data
                </p>
              )}{" "}
              <button onClick={submitClick}>Submit</button>
            </div>
          </div>
        </div>
      )}
      ;
    </div>
  );
};

export { EditPostModal };
