import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import styles from "./Events.module.css";
import ChildBirthdayCard from "./components/ChildBirthdayCard/ChildBirthdayCard";

export const Events = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/parents" />
          </IonButtons>
          <IonTitle>Events</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            backgroundImage: `url("/assets/parentLanding/background-cropped.svg")`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
          className={styles.mainContainer}
        >
          <img
            className={styles.comingSoonImg}
            src="/assets/comingSoon/comingSoon.png"
            alt=""
          />

          {/* <ChildBirthdayCard /> */}
        </div>
      </IonContent>
    </IonPage>
  );
};
