import {
  IonContent,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonRouterLink,
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import "../Home/Intro.css";
import classes from "./RttLandingPage.module.css";
import peopleButtonImg from "../../assets/img/people-button.png";
import wizard from "../../assets/img/wizard.png";
import rttLandingImg from "/assets/rttLanding/rumbleTumbleTimeLandingImg.png";
import samar from "../../assets/img/samar-video-youtube.png";
import icon6 from "../../assets/img/icon6.png";
import icon8 from "../../assets/img/icon8.png";
import icon9 from "../../assets/img/icon9.png";
import icon10 from "../../assets/img/icon10.png";
import icon15 from "../../assets/img/icon15.png";
import tejasa from "../../assets/img/tejasa-potnis-profile.jpeg";
import closeBtn from "../../assets/img/lightbox-close.png";
import { nextDate } from "../../utils/general-utils";
import { SignIn } from "../Auth/SignIn";
import { ModalExample } from "../../components/UI/ModalExample";
import { IonModal, IonButton } from "@ionic/react";
import { RttSignup } from "../Auth/RttSignup/RttSignup";
import { PANDA } from "../../constants/Program/program-constants";

export const RttLandingPage = () => {
  // useEffect(() => {
  //   const wow = new WOW.WOW({live: false, offset: 0});
  //   wow.init();
  // }, []);
  const nextSaturday = nextDate(6).toLocaleDateString();

  const [showModal, setShowModal] = useState(false);

  const scrollToSignupRef: any = useRef(null);

  const rttColor = "#1360a0";
  const rttLightColor = "#3b8ed1";

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={classes.mainSectionExt + " main-section"}>
          <header>
            <div className="container">
              <div className="header-content d-flex flex-wrap align-items-center">
                <div
                  style={{ width: "1rem" }}
                  className={classes.alignCenter + " logo animated zoomIn "}
                >
                  <a href="#" title="">
                    <img
                      style={{ width: "6rem" }}
                      src={`/assets/logo/brainCells-logo-png.png`}
                      alt="braincells-logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </header>
          <section className="main-banner">
            <div className="container">
              <div className="row ">
                <div
                  ref={scrollToSignupRef}
                  className="col-lg-6 col-md-6"
                  id="signup"
                >
                  <RttSignup />
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className={classes.alignCenter + " banner-text"}>
                    <div>
                      <p
                        className={classes.rttSmallText}
                        style={{
                          fontSize: "1.2rem",
                          margin: "1rem",
                          color: "#1360a0",
                        }}
                      >
                        Presenting All New
                      </p>
                      <p
                        className={classes.rttHeading}
                        style={{
                          color: "#1360a0",
                          margin: "2rem 0",
                          fontSize: "2rem",
                        }}
                      >
                        Rumble Tumble Time
                      </p>
                    </div>
                    <h2 className={classes.alignCenter + " animated zoomIn "}>
                      In-School Extracurricular class for
                      <p
                        style={{
                          color: rttColor,
                          textDecoration: "underline",
                          textDecorationLine: "yellow",
                          fontSize: "4rem",
                          marginTop: "1rem",
                        }}
                      >
                        Age 1.5-6y
                      </p>
                    </h2>
                    <h1 className={classes.subText}>
                      Teacher lead brain development extracurricular class at
                      your nearest school
                    </h1>
                    <h1 className={classes.subTextList}>
                      Physical & Cognitive Neuroscience based activities
                    </h1>
                    <hr />
                    <h1 className={classes.subTextList}>Neurobics</h1>
                    <h1 className={classes.subTextList}>Music</h1>
                    <h1 className={classes.subTextList}>Foreign Language</h1>
                    <h1 className={classes.subTextList}>
                      Collaboration & Group Thinking
                    </h1>
                    <h1 className={classes.subTextList}>
                      Photographic Memory Building
                    </h1>
                    <h1 className={classes.subTextList}>
                      & many more activities
                    </h1>

                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          id="braincells"
          className="course-section benifit-section about-us-section about-page-content"
        >
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="container"
          >
            <img
              className={classes.rttLandingImg}
              src="/assets/rttLanding/rumbleTumbleTimeLandingImg.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="act-inffo wow fadeInRight"
                  data-wow-duration="1000ms"
                >
                  <img width="150px" src={PANDA} alt="panda" />
                  <h2>What is Rumble Tumble Time?</h2>
                  <ul style={{ textAlign: "left" }}>
                    <li>Specially curated online learning program</li>
                    <li>120+ Activities of Brain Development</li>
                    <li>
                      Every day new Right Brain Education & Whole Brain
                      Development Activities
                    </li>
                    <li>No preparation required by parents</li>
                    <li>
                      3000+ categories. Library of 3,00,000+ Knowledge bits
                    </li>
                  </ul>

                  <h2 style={{ color: "gray" }}>Age wise program contents</h2>
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-3 ageGroup ${classes.linearGradient}`}
                    >
                      <span>Age</span>
                      <br />
                      0-1
                    </div>

                    <div className="col-lg-6 ageGroupDesc">
                      <ul style={{ textAlign: "left" }}>
                        <li>Step-wise Exercise Instructions deliverd in App</li>
                        <li>Downloadable & Printable Cards</li>- OR -
                        <li>Ready Subscription box available at extra cost</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="row  align-items-center"
                    style={{ marginTop: "15px" }}
                  >
                    <div
                      className={`col-lg-3 ageGroup ${classes.linearGradient}`}
                    >
                      <span>Age</span>
                      <br />
                      1-2
                      <br />
                      2-4
                      <br />
                      4-Plus
                    </div>
                    <div className="col-lg-6">
                      <ul style={{ textAlign: "left" }}>
                        <li>
                          Online Activities structured on 12 techniques. No
                          physical cards
                        </li>
                        <li>
                          Algorithmically set activities according to age group
                        </li>
                        <li>
                          As the program progresses Complexity of activities,
                          puzzles etc increases
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="act-inffo about-us-section p-0">
                  <h2 style={{ textAlign: "center" }}>
                    Develop these <br />
                    10 Life Skills
                    <br /> in your child
                  </h2>
                  <div className="about-sec">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#3372ab" }}
                            className="fas fa-book-reader"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#3372ab",
                              float: "right",
                            }}
                          >
                            1
                          </span>
                          <h2 style={{ color: "black" }}>
                            Photographic Memory Building
                          </h2>
                          <br />
                          <p>
                            Build your childs razorsharp memory <br />
                            <br />
                            Bit Cards (Image)
                          </p>
                          <p>Dot Cards (Quantity / Maths) </p>
                          <p> Word Cards (Vocab)</p>
                          <p> Spaced Repition or Revision of Flash cards</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#3372ab" }}
                            className="fa fa-camera"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#3372ab",
                              float: "right",
                            }}
                          >
                            2
                          </span>
                          <h2 style={{ color: "black" }}>Spatial Sense</h2>
                          <br />
                          <p>
                            Build amazing direction sense and love for geography
                            <br /> <br />
                            Mandala
                          </p>
                          <p>Photo Play</p>
                          <p> Memory grids and more</p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="600ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#f79435" }}
                            className="fa fa-music"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#f79435",
                              float: "right",
                            }}
                          >
                            3
                          </span>
                          <h2 style={{ color: "black" }}>Music</h2>
                          <br />
                          <p>
                            Build the short term memory for your child <br />
                            <br /> Activities to build connection between Bit,
                            Dot & Words and more
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="200ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#f79435" }}
                            className="fas fa-hand-paper"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#f79435",
                              float: "right",
                            }}
                          >
                            4
                          </span>
                          <h2 style={{ color: "black" }}>
                            Cognitive Creativity
                          </h2>
                          <br />
                          <p>
                            Build your childs ability to innovate and create new
                            world <br />
                            <br /> Activities to nurture and stimulate
                            creativity in children
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="400ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#3372ab" }}
                            className="fas fa-spa"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#3372ab",
                              float: "right",
                            }}
                          >
                            5
                          </span>
                          <h2 style={{ color: "black" }}>
                            Patience, Attention and focus
                          </h2>
                          <br />
                          <p>Build Focus, Mindfulness, alert and sharp mind</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="600ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#3372ab" }}
                            className="fas fa-lightbulb"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#3372ab",
                              float: "right",
                            }}
                          >
                            6
                          </span>
                          <h2 style={{ color: "black" }}>Neurobics</h2>
                          <br />
                          <p>
                            Activities to stimulate 5 senses - Taste, Hearing,
                            Smell, Vision, Touch
                          </p>
                          <br />
                          <p>
                            Music as a thrapy Indian Classical, Western
                            classical, Instrumental and more
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="600ms"
                        >
                          {/* <i
                            style={{ fontSize: "42px", color: "#f79435" }}
                            className="fas fa-walking"
                          ></i> */}
                          <i
                            style={{ fontSize: "42px", color: "#f79435" }}
                            className="fas fa-handshake"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#f79435",
                              float: "right",
                            }}
                          >
                            7
                          </span>
                          <h2 style={{ color: "black" }}>Collaboration</h2>
                          <br />
                          <p>
                            Build love for maths with amazing math based
                            activities
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="800ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#f79435" }}
                            className="fa fa-language"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#f79435",
                              float: "right",
                            }}
                          >
                            8
                          </span>
                          <h2 style={{ color: "black" }}>
                            Foreign language introduction
                          </h2>
                          <br />
                          <p>
                            Activities for children to grasp the language faster
                            and efficiently
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="600ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#3372ab" }}
                            className="fas fa-child"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#3372ab",
                              float: "right",
                            }}
                          >
                            9
                          </span>
                          <h2 style={{ color: "black" }}>
                            Group Thinking and Problem Solving
                          </h2>
                          <br />
                          <p>
                            Activities to develop this left brain function which
                            is the most essential in todays world
                          </p>
                          <p>STEM Experiments</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          className="abt-col wow fadeInRight"
                          data-wow-duration="1000ms"
                          data-wow-delay="600ms"
                        >
                          <i
                            style={{ fontSize: "42px", color: "#3372ab" }}
                            className="fas fa-brain"
                          ></i>
                          <span
                            style={{
                              fontFamily: "sensei",
                              fontSize: "36px",
                              color: "#3372ab",
                              float: "right",
                            }}
                          >
                            10
                          </span>
                          <h2 style={{ color: "black" }}>Processing Speed</h2>
                          <br />
                          <p>
                            Activities to Maintain and enhance the high
                            processing speed of the grasped knowledge
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <section id="program-plans" className="classes-section">
              <div className="container">
                <div className="sec-title">
                  <h2>Rumble Tumble Time</h2>
                </div>
                <img width="100px" src={PANDA} alt="" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="classes-sec">
                    <div
                      style={{
                        margin: "1rem",
                      }}
                    >
                      <div
                        className="classes-col wow fadeInUp"
                        data-wow-duration="1000ms"
                      >
                        <div className="class-thumb">
                          <div className="w-100"></div>
                          <a href="/#signup" title="" className="crt-btn">
                            <img
                              src="/assets/rttLanding/icon10.png"
                              alt="icon10"
                            />
                          </a>
                        </div>
                        <div className="class-info">
                          <h3>Rumble Tumble Time 3 month</h3>
                          <span
                            style={{ backgroundColor: rttColor }}
                            className="duration"
                          >
                            3 month
                          </span>
                          <br />
                          <span>4 clubhouse sessions once every week</span>
                          <br />
                          <span>
                            30 Daily Exercises x 5-10 activities daily
                          </span>
                          <br />
                          <span>25 Categories of flash cards</span> <br />
                          <span> &#8377; 1500 per month </span> <br />
                          <div className="d-flex flex-wrap align-items-center">
                            <button
                              onClick={() =>
                                scrollToSignupRef.current.scrollIntoView({
                                  behavior: "smooth",
                                })
                              }
                              style={{
                                backgroundColor: rttLightColor,
                                color: "white",
                                border: "none",
                              }}
                              className="btn btn-warning"
                            >
                              Subscribe
                            </button>
                            <div className={classes.priceContainer}>
                              <strong
                                style={{ fontSize: "1.6rem" }}
                                className="price"
                              >
                                &#8377; 4500/-
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="classes-sec">
                    <div
                      style={{
                        margin: "1rem",
                      }}
                    >
                      <div
                        className="classes-col wow fadeInUp"
                        data-wow-duration="1000ms"
                      >
                        <div className="class-thumb">
                          <div className="w-100"></div>
                          <a href="/#signup" title="" className="crt-btn">
                            <img
                              src="/assets/rttLanding/icon10.png"
                              alt="icon10"
                            />
                          </a>
                        </div>
                        <div className="class-info">
                          <h3>Rumble Tumble Time 1 Year</h3>
                          <span
                            style={{ backgroundColor: rttColor }}
                            className="duration"
                          >
                            1 Year
                          </span>
                          <br />
                          <span>4 clubhouse sessions once every week</span>
                          <br />
                          <span>
                            30 Daily Exercises x 5-10 activities daily
                          </span>
                          <br />
                          <span>25 Categories of flash cards</span> <br />
                          <span> &#8377; 1500 per month </span> <br />
                          <div className="d-flex flex-wrap align-items-center">
                            <button
                              onClick={() =>
                                scrollToSignupRef.current.scrollIntoView({
                                  behavior: "smooth",
                                })
                              }
                              style={{
                                backgroundColor: rttLightColor,
                                color: "white",
                                border: "none",
                              }}
                              className="btn btn-warning"
                            >
                              Subscribe
                            </button>
                            <div className={classes.priceContainer}>
                              <strong
                                style={{ fontSize: "1.6rem" }}
                                className="price"
                              >
                                &#8377; 18,000/-
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <footer>
          <div className="container">
            <div className="top-footer">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget-about">
                    <img
                      width="45%"
                      src="/assets/logo/brainCells-logo-png.png"
                      alt="logo"
                    />
                    <p>Thought River EduTech Private Limited</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget-contact" id="contact">
                    <ul className="contact-add">
                      <li>
                        <div className="contact-info">
                          <div className="contact-tt">
                            <h4>Call</h4>
                            <span>+91 84596 28238</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="contact-info">
                          <div className="contact-tt">
                            <h4>Support Team</h4>
                            <span>
                              Mon - Sat 9 AM - 9 PM Indian Standard Time
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget-links">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <a href="/tnc" target="_blank" title="">
                          Privacy Policy, Refunds & Cancellations, Term &
                          Conditions, Contact
                        </a>
                      </li>
                      <li>
                        <a href="/#signup" title="">
                          Signup
                        </a>
                      </li>
                      <li>
                        <a href="/login" title="">
                          Login
                        </a>
                      </li>
                      <li>
                        <a href="/" title="">
                          Learn about BrainCells
                        </a>
                      </li>
                      <li>
                        <a href="/#brainCells-children" title="">
                          BrainCells Children
                        </a>
                      </li>
                      <li>
                        <a href="/pricing" title="">
                          BrainCells Pricing
                        </a>
                      </li>
                      <li>
                        <a href="/testimonials" title="">
                          Parents say
                        </a>
                      </li>
                      <li>
                        <a href="https://blog.braincells.in" title="">
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget-iframe">
                    <img
                      width="100%"
                      src={"/assets/rttLanding/kids-signup-footer.png"}
                      alt="footer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-footer">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <strong>
                    Copyright &copy; 2022 BrainCells | Thought River EduTech
                    Private Limited
                  </strong>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <ul className="social-links ml-auto">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/braincells06"
                      title=""
                    >
                      <img
                        width="120px"
                        src="/assets/rttLanding/follow-us-on-instagram-button.png"
                        alt="insta"
                      />
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </IonContent>
    </IonPage>
  );
};
