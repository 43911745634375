import axios from "axios";
import { useEffect, useState } from "react";
import classes from "./RttSignup.module.css";
import { decodeToken, isExpired } from "react-jwt";
import { useUserContext } from "../../../store/context/user-context";
import { useHistory } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useSubscriptionContext } from "../../../store/context/subscription-context";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
  ROOT_ENDPOINT,
} from "../../../configs/ServerConfig";
import { getHttpData } from "../../../utils/http-util";

export const RttSignup = () => {
  const [rttSignupData, setRttSignupData] = useState({
    cohort: "RTT",
    ageGroup: "",
    partnerId: "",
    email: "",
    password: "",
    phone: "",
    name: "",
    childName: "",
    childBirthDate: "",
  });

  console.log(rttSignupData);

  const [showSignupError, setshowSignupError] = useState({
    showError: false,
    errorMessage: "",
  });

  const [googleCaptcha, setGoogleCaptcha] = useState({
    showCaptcha: false,
    captchaValue: null,
  });

  const [preschoolData, setPreschoolData] = useState([]);

  const userCtx = useUserContext();
  const subscriptionCtx = useSubscriptionContext();

  const history = useHistory();

  const googleCaptchaHandler = (value: any) => {
    setGoogleCaptcha({ ...googleCaptcha, captchaValue: value });
  };
  const [dropdownValue, setDropdownValue] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const response = await getHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/partner?type=Preschool`
        );
        setPreschoolData(
          response.data.sort((a: any, b: any) => {
            return a.userName.localeCompare(b.userName);
          })
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const signupHandler = (e: any) => {
    e.preventDefault();
    setRttSignupData({
      cohort: "DEFAULT",
      ageGroup: "",
      partnerId: "",
      email: "",
      password: "",
      phone: "",
      name: "",
      childName: "",
      childBirthDate: "",
    });

    const createNewUser = async () => {
      try {
        const response = await axios.post(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/signup`,
          rttSignupData
        );
        const myJwtToken = response.data.jwt;

        const decodedToken: any = decodeToken(myJwtToken);
        const isTokenExpired = isExpired(myJwtToken);

        if (!isTokenExpired && googleCaptcha.captchaValue !== null) {
          localStorage.setItem("USER_TOKEN", myJwtToken);
          localStorage.setItem("USER_DATA", JSON.stringify(decodedToken.sub));

          userCtx.loginUser(decodedToken.sub);
          subscriptionCtx.loadSubscriptionContext(
            decodedToken.sub.id,
            myJwtToken
          );
          history.push("/parents/subscriptions");
        } else {
          setshowSignupError({
            showError: true,
            errorMessage: "Complete the Captcha to Create a new account",
          });
        }
      } catch (err: any) {
        console.log(err);
        console.log(typeof err.response.status, err.response.status);

        if (err.response.status === 406) {
          setshowSignupError({
            showError: true,
            errorMessage: "Account already Exists",
          });
        }
      }
    };

    if (rttSignupData.phone === "") {
      setshowSignupError({
        showError: true,
        errorMessage: "Enter Phone Number",
      });
    } else {
      createNewUser();
    }
  };
  return (
    <div className="animated zoomIn card card-widget widget-user shadow">
      <div
        style={{ borderRadius: "2rem" }}
        className={"card-body register-card-body " + classes.signUpDiv}
      >
        <p className={classes.signupBanner + " login-box-msg"}>
          Rumble Tumble Time Enrollment
        </p>

        <form
          onSubmit={(e) => signupHandler(e)}
          style={{ fontFamily: "Poppins" }}
        >
          <input type="hidden" name="is_register" value="1" />
          <input type="hidden" name="referralId" />
          <input type="hidden" name="partnerId" />
          <div className="input-group mb-3">
            <input
              onChange={(e) => {
                setRttSignupData({ ...rttSignupData, name: e.target.value });
                if (e.target.value.length === 0) {
                  setGoogleCaptcha({
                    ...googleCaptcha,
                    showCaptcha: false,
                  });
                } else {
                  setGoogleCaptcha({
                    ...googleCaptcha,
                    showCaptcha: true,
                  });
                }
              }}
              value={rttSignupData.name}
              minLength={2}
              type="text"
              name="name"
              className="form-control"
              placeholder="Parent's Full Name"
              required
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user"></span>
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              onChange={(e) =>
                setRttSignupData({ ...rttSignupData, email: e.target.value })
              }
              value={rttSignupData.email}
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              required
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <IntlTelInput
              preferredCountries={["in", "sg", "de", "us", "co"]}
              inputClassName="form-control"
              onPhoneNumberChange={(b, n, c, number) => {
                setRttSignupData({ ...rttSignupData, phone: number });
              }}
              style={{ width: "100%" }}
              placeholder="Mobile (provide WhatsApp number for better help & support)"
            />
          </div>

          <div className="input-group mb-3">
            <input
              onChange={(e) =>
                setRttSignupData({
                  ...rttSignupData,
                  childName: e.target.value,
                })
              }
              value={rttSignupData.childName}
              minLength={2}
              type="text"
              name="childName"
              className="form-control"
              placeholder="Child's First Name only"
              required
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user"></span>
              </div>
            </div>
          </div>

          <div className="input-group mb-3">
            <select
              onChange={(e) =>
                setRttSignupData({
                  ...rttSignupData,
                  partnerId: e.target.value,
                })
              }
              value={rttSignupData.partnerId}
              id="schoolSelect"
              style={{ color: "#6c757d" }}
              name="school"
              className="form-control"
              required
            >
              <option disabled selected hidden value="">
                Select School
              </option>

              {preschoolData.map((preschool: any, index: number) => {
                return (
                  <option key={index} value={preschool.id}>
                    {preschool.userName} - {preschool.address} -{" "}
                    {preschool.city}
                  </option>
                );
              })}

              {/* <option>Little Millenium Balewadi</option>
              <option>Tiger Cubs Baner</option>
              <option>Kidzee - Viman Nagar</option>
              <option>Angels Paradise Amanora</option> */}
            </select>
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-chevron-down"></span>
              </div>
            </div>
          </div>

          <div className="input-group mb-3">
            <select
              onChange={(e) => {
                setDropdownValue(e.target.value);
                setRttSignupData({
                  ...rttSignupData,
                  ageGroup:
                    e.target.value === "playgroup" ||
                    e.target.value === "nursery"
                      ? "2-4"
                      : "4-Plus",
                });
              }}
              value={dropdownValue}
              id="ageGroupSelect"
              style={{ color: "#6c757d" }}
              name="ageGroup"
              className="form-control"
              required
            >
              <option disabled selected hidden value="">
                Select Batch
              </option>

              <option value="playgroup">Playgroup</option>
              <option value="nursery">Nursery</option>
              <option value="k1">Jr. KG/K1</option>
              <option value="k2">Sr. KG/K2</option>
            </select>
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-chevron-down"></span>
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              onChange={(e) =>
                setRttSignupData({ ...rttSignupData, password: e.target.value })
              }
              value={rttSignupData.password}
              minLength={6}
              name="password"
              type="password"
              className="form-control"
              placeholder="Create New Password (Minimum 6 Characters)"
              required
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock"></span>
              </div>
            </div>
          </div>

          <div className=" input-group mb-3">
            {googleCaptcha.showCaptcha ? (
              <div id="reCaptchaEl" className="g-recaptcha">
                <ReCAPTCHA
                  sitekey="6Le9UGgcAAAAAKyu7_FSKRYsC3ymdVpKca4hwdIu"
                  onChange={googleCaptchaHandler}
                />
              </div>
            ) : null}
          </div>

          <div>
            {showSignupError.showError ? (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  border: "1px solid red",
                  fontWeight: "bolder",
                  margin: "1rem",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {showSignupError.errorMessage}
              </p>
            ) : null}
          </div>

          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                style={{
                  fontFamily: "sensei",
                  fontSize: "36px",
                  borderRadius: "50px",
                  background: "#f37335",
                }}
                className="btn btn-primary btn-block"
              >
                Enroll Now
              </button>
            </div>
          </div>
        </form>
        <div style={{ textAlign: "center" }}>
          <br />
          <hr />
          <a href="/signin" style={{ color: "white" }} className="text-center">
            I am already a BrainCells Parent
          </a>
          <br />
          <br />
          <a href="/signin" style={{ color: "white" }} className="text-center">
            <button
              style={{ color: "#1360a0" }}
              className={
                classes.loginNowBtn +
                " btn btn-primary animated bounceIn shadow"
              }
            >
              Login Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
