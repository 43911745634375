import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  ACTIVITY_PORT,
  ACTIVITY_TRACKING_SERVER,
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../configs/ServerConfig";
import { useUserContext } from "../store/context/user-context";
import { postHttpData } from "../utils/http-util";
export const ACTIVITY_COP_DATA = "ACTIVITY_COP_DATA";
export const ACTIVITY_START = "ACTIVITY_START";
export const ACTIVITY_END = "ACTIVITY_END";
export const ROUND_START = "ROUND_START";
export const ROUND_END = "ROUND_END";
export const ROUND_WRONG_ATTEMPT = "ROUND_WRONG_ATTEMPT";
export const ROUND_RIGHT_ATTEMPT = "ROUND_RIGHT_ATTEMPT";
const checkObjEmpty = (obj: any) => {
  return Object.keys(obj).length > 0;
};
const getActivityCopData = () => {
  return JSON.parse(localStorage.getItem(ACTIVITY_COP_DATA) || "{}");
};
const setActivityCopData = (data: any) => {
  localStorage.setItem(ACTIVITY_COP_DATA, JSON.stringify({ ...data }));
};
const useActivityTracker = (
  activityId: any = "PROVIDE ID FOR THIS ACTIVITY"
) => {
  const roundRef: any = useRef(0);
  const roundStartTimeRef: any = useRef(0);
  const activityStartTimeRef: any = useRef(0);
  const roundDataRef: any = useRef({});
  const activityName = activityId.split("_")[activityId.split("_").length - 1];
  const exerciseId = activityId.split("_")[0];
  const roundAnsSumRef = useRef(0);
  const userCtx = useUserContext();
  const { user } = userCtx;
  const { id } = user;
  const [activityEndingTime, setActivityEndingTime] = useState<any>(0);

  useEffect(() => {
    const getDataFromLocalStorage = JSON.parse(
      localStorage.getItem(ACTIVITY_COP_DATA) || "{}"
    );
    localStorage.removeItem(ACTIVITY_COP_DATA);
    if (checkObjEmpty(getDataFromLocalStorage)) {
      //POST REQUEST
      (async () => {
        const res: any = await postHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/events`,
          {
            userId: id,
            timestamp: moment().unix(),
            data: getActivityCopData(),
            type: "ACTIVITY_INSIGHTS",
          }
        );
      })();
    }
    setActivityCopData({});
    return () => {
      (async () => {
        //AFTER ACTIVITY ENDS POST REQUEST AND CLEAR LOCAL STORAGE
        //BRAIN FUNCTION CALCULATE
        //POST REQUEST
        const res: any = await postHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/events`,
          {
            userId: id,
            timestamp: moment().unix(),
            data: getActivityCopData(),
            type: "ACTIVITY_INSIGHTS",
          }
        );
        setActivityCopData({});
      })();
    };
  }, []);

  const activityTracker = (activityCondition: any) => {
    const activityData = getActivityCopData();
    switch (activityCondition) {
      case ACTIVITY_START:
        const activityStartTime = moment().unix();
        activityStartTimeRef.current = activityStartTime;
        activityData["activityStartTime"] = activityStartTime;
        activityData["activityName"] = activityName;
        activityData["userId"] = id;
        activityData["exerciseId"] = exerciseId;
        activityData["rounds"] = [];
        setActivityCopData(activityData);
        break;
      case ACTIVITY_END:
        const activityEndTime = moment().unix();
        activityData["activityEndTime"] = activityEndTime;
        activityData["activityScore"] = roundAnsSumRef.current;
        setActivityCopData(activityData);
        setActivityEndingTime(activityEndTime - activityStartTimeRef.current);
        break;
      case ROUND_START:
        roundRef.current += 1;
        roundDataRef.current = {};
        const roundStartTime = moment().unix();
        roundStartTimeRef.current = roundStartTime;
        const roundAttempt: any = [];
        roundDataRef.current = {
          roundNumber: roundRef.current,
          roundStartTime:
            roundStartTimeRef.current - activityStartTimeRef.current,
          roundAttempt,
          roundEndTime: "",
        };
        setActivityCopData(activityData);
        break;
      case ROUND_END:
        const roundEndTime = moment().unix() - activityStartTimeRef.current;
        roundDataRef.current["roundEndTime"] = roundEndTime;
        roundDataRef.current["roundScore"] = Number(
          (1 / roundDataRef.current["roundAttempt"].length) *
            (1 / Math.ceil(roundEndTime / 5))
        );
        roundAnsSumRef.current += roundDataRef.current["roundScore"];
        activityData["rounds"].push(roundDataRef.current);
        setActivityCopData(activityData);

        break;
      case ROUND_WRONG_ATTEMPT:
        const roundWrongAttempt = moment().unix();
        roundDataRef.current["roundAttempt"].push(
          -(roundWrongAttempt - roundStartTimeRef.current)
        );
        setActivityCopData(activityData);
        break;
      case ROUND_RIGHT_ATTEMPT:
        const roundAnswerAttempt = moment().unix();
        roundDataRef.current["roundAttempt"].push(
          roundAnswerAttempt - roundStartTimeRef.current
        );
        setActivityCopData(activityData);
        break;
    }
  };
  return activityTracker;
};

export { useActivityTracker };
