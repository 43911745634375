import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToast, IonToolbar,IonButton,IonButtons, IonBackButton, IonCard, IonImg, IonCardTitle, IonInput,IonRoute, IonRouterLink} from '@ionic/react';
import { useState } from 'react';

import styles from './SetBirthDay.module.css';
function SetBirthDay() {
    
    const [selectedDate, setSelectedDate] = useState<string>();
    const [showToast,setShowToast] = useState<boolean>(false);
    function setDate(val:any){
        setSelectedDate(val.target.value);
    }
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
      <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>Profile Creation</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      
      <div className={styles.mainContainer} >

        <div className={styles.creationContainer}>
            <h1>Enter Child's Birth Date</h1>
            <input type='date' className={styles.dateTimePicker} onChange={setDate} ></input>
            <IonButton href='/profile-creation/set-avatar'  color="primary" style={{fontSize:'150%'}} size='large' > 
              Next
            </IonButton>
        </div>
      </div>
      
    </IonContent>
  </IonPage>
  )
}

export default SetBirthDay
// style={{height:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}