import { IonAlert, useIonToast } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import styles from "./ParentVerificationCaptcha.module.css";
import { useHistory } from "react-router-dom";
import { useOnClickOutside } from "../../utils/ui-functions-utils";

export const ParentVerificationCaptcha = (props: any) => {
  const [showCaptchaAlert, setshowCaptchaAlert] = props.showState;
  const [parentLoginInput, setParentLoginInput] = useState("");
  const [captcha, setCaptcha] = useState({ question: "", answer: NaN });
  const [present, dismiss] = useIonToast();
  const history = useHistory();
  const modalRef = useRef(null);

  function captchaGenerator() {
    const number1: number = Math.ceil(Math.random() * 5);
    const number2: number = Math.ceil(Math.random() * 5);
    const questionString: string = `${number1} × ${number2} = `;
    const answer: number = number1 * number2;
    return { question: questionString, answer: answer };
  }

  useOnClickOutside(modalRef, () => {
    setshowCaptchaAlert(false);
  });

  useEffect(() => {
    setCaptcha(captchaGenerator());
  }, []);

  useEffect(() => {
    if (Number(parentLoginInput) === captcha.answer) {
      setParentLoginInput("");
      setshowCaptchaAlert(false);
      history.push(props.navigateTo);
      window.location.reload();
    }
  }, [parentLoginInput]);

  const parentInputHandler = (num: number) => {
    setParentLoginInput((prevInput) => prevInput + `${num}`);
  };

  return (
    <div className={styles.captchaBackground}>
      <div
        className={`${styles.captchaContainer} animate__animated animate__backInRight ${styles.animate__backInRight}`}
        ref={modalRef}
      >
        <p className={styles.parentLoginHeading}>Parent Login </p>

        <div className={styles.equationContainer}>
          <p>{captcha.question}</p>
          <div
            onChange={() => console.log("onChange", parentLoginInput)}
            className={styles.outputDiv}
          >
            {parentLoginInput}
          </div>
          <img
            onClick={() => setParentLoginInput("")}
            className={styles.refreshIcon}
            src="/assets/icon/refresh-icon.png"
            alt=""
          />
        </div>

        <div className={styles.numberKeypadContainer}>
          <div className={styles.numberKeypadRow}>
            <div
              onClick={() => parentInputHandler(1)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>1</p>
            </div>
            <div
              onClick={() => parentInputHandler(2)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>2</p>
            </div>
            <div
              onClick={() => parentInputHandler(3)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>3</p>
            </div>
          </div>
          <div className={styles.numberKeypadRow}>
            <div
              onClick={() => parentInputHandler(4)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>4</p>
            </div>
            <div
              onClick={() => parentInputHandler(5)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>5</p>
            </div>
            <div
              onClick={() => parentInputHandler(6)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>6</p>
            </div>
          </div>
          <div className={styles.numberKeypadRow}>
            <div
              onClick={() => parentInputHandler(7)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>7</p>
            </div>
            <div
              onClick={() => parentInputHandler(8)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>8</p>
            </div>
            <div
              onClick={() => parentInputHandler(9)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>9</p>
            </div>
          </div>
          <div className={styles.numberKeypadRow}>
            <div
              onClick={() => parentInputHandler(0)}
              className={styles.numberKey}
            >
              <img src="/assets/parentLogin/number-button.svg" alt="" />
              <p className={styles.singleNumberKey}>0</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
