import { useEffect, useState } from "react";
import {
  COMPLETED_EXCERCISE_BUTTON,
  COMPLETE_STAR,
  PANDA,
  PENDING_EXCERCISE_BUTTON,
  TODAYS_EXERCISE_BUTTON,
  UPCOMING_EXCERCISE_BUITTON,
  SUBSCRIBE_TO_CONTINUE,
  HOLIDAY_BUTTON,
  TROPHY_BUTTON,
  TROPHY_BOX_CLOSED,
  TROPHY_BOX_HALF_OPEN,
  TROPHY_BOX_OPEN,
} from "../../constants/Program/program-constants";
import styles from "./Excercise.module.css";
import WOW from "wowjs";
import { useParams } from "react-router";
import { ParentVerificationCaptcha } from "../generalComponents/ParentVerificationCaptcha";
import { getTTSAudio } from "../../utils/http-util";
import { isRttTeller } from "../../utils/general-utils";
import { useUserContext } from "../../store/context/user-context";
import useIsZeroTwoToOne from "../../hooks/useIsZeroToOne";
import { IonRouterLink } from "@ionic/react";

const Excercise = ({
  excercise,
  index,
  animate,
  currentExercise,
  programTypeParam,
  exerciseDisplayName,
  showAnimationModal,
}: any) => {
  const {
    completionStatus,
    exerciseId,
    isTrophy,
    showTrophy = null,
    programDay,
    activityShowNumber,
    programDayCount,
    bcaCount = null,
    ocaCount = null,
    holiday = false,
    trophy = false,
    hasEnded = null,
  } = excercise;
  const {
    user: { ageGroup },
  } = useUserContext();
  const [showCaptchaAlert, setshowCaptchaAlert] = useState(false);
  const { programName = "BrainCells" } = useParams<any>();
  // const [audio] = useState(new Audio("/assets/program/pop.mp3"));
  const [popUpVisible, setPopUpVisible] = useState<any>(false);
  const isRtt = isRttTeller(programName);
  const { isSubscribedZeroToOne } = useIsZeroTwoToOne();
  let buttonImgSrc;
  switch (completionStatus) {
    case "completed":
      buttonImgSrc = COMPLETED_EXCERCISE_BUTTON;
      break;
    case "pending":
      buttonImgSrc = PENDING_EXCERCISE_BUTTON;
      break;
    case "current":
      buttonImgSrc = TODAYS_EXERCISE_BUTTON;
      break;
    case "upcoming":
      buttonImgSrc = UPCOMING_EXCERCISE_BUITTON;
      break;
    default:
      break;
  }

  const excerciseClassName = `${styles["excercise"]} ${
    completionStatus === "current" ? `${styles["excercise-current"]}` : ""
  } ${styles[`excercise-${index + 1}`]} ${animate ? "wow zoomIn" : ""}`;

  const trophyClassName = `${styles["excercise"]} ${
    styles[`excercise-${index + 1}`]
  } ${animate ? "wow zoomIn" : ""}`;

  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();

    setTimeout(() => {
      if (animate) {
        // audio.play();
      }
    }, 1750 + index * 500);
  }, [index, animate]);

  const showPopUp = () => {
    setPopUpVisible(true);
  };

  const HolidayButton = (
    <div
      className={trophyClassName}
      data-wow-duration="1000ms"
      data-wow-delay={`${1500 + index * 500}ms`}
    >
      <img src={HOLIDAY_BUTTON} alt="holiday button" />
    </div>
  );

  const SubscribeButton = (
    <div
      className={`${styles["excercise"]} ${styles[`excercise-${index + 1}`]}  ${
        animate ? "wow zoomIn" : ""
      }`}
      data-wow-duration="1000ms"
      data-wow-delay={`${index > 0 ? 1500 + (index - 1) * 500 : 0}ms`}
      onClick={() => setshowCaptchaAlert(true)}
    >
      <img
        className={styles["end-bubble"]}
        src={SUBSCRIBE_TO_CONTINUE}
        alt="Bubble"
      />
    </div>
  );

  const TrophyButton =
    completionStatus === "closed" ? (
      <div
        onClick={showAnimationModal}
        className={trophyClassName}
        data-wow-duration="1000ms"
        data-wow-delay={`${1500 + index * 500}ms`}
      >
        {showTrophy ? (
          <img
            onClick={showAnimationModal}
            src={TROPHY_BOX_HALF_OPEN}
            alt="Trophy"
          />
        ) : (
          <img src={TROPHY_BOX_CLOSED} alt="Trophy" />
        )}
      </div>
    ) : (
      <IonRouterLink
        href={`/excercise/${programName}/${exerciseId}${
          programTypeParam ? `?s=${programTypeParam}` : ""
        }`}
        className={trophyClassName}
        data-wow-duration="1000ms"
        data-wow-delay={`${1500 + index * 500}ms`}
      >
        <img src={TROPHY_BUTTON} alt="Trophy" />
      </IonRouterLink>
    );

  return (
    <div>
      {holiday ? (
        trophy ? (
          TrophyButton
        ) : (
          HolidayButton
        )
      ) : hasEnded ? (
        SubscribeButton
      ) : (
        <a
          href={
            completionStatus === "upcoming"
              ? void 0
              : programTypeParam
              ? `/excercise/${programName}/${exerciseId}?s=${programTypeParam}`
              : `/excercise/${programName}/${exerciseId}?${
                  bcaCount ? `&bca=${bcaCount}` : ""
                }${ocaCount ? `&oca=${ocaCount}` : ""}`
          }
          onClick={() => {
            if (completionStatus === "upcoming") {
              showPopUp();
            }
          }}
          className={excerciseClassName}
          data-wow-duration="1000ms"
          data-wow-delay={`${index > 0 ? 1500 + (index - 1) * 500 : 0}ms`}
        >
          {popUpVisible && (
            <PopUp
              days={
                programDay - (currentExercise + Math.floor(currentExercise / 7))
              }
            />
          )}
          {completionStatus === "current" && (
            <div
              className={`${styles["current-panda"]} wow bounceInLeft`}
              data-wow-duration="1000ms"
              data-wow-delay="4000ms"
            >
              <img src={PANDA} alt="Wizard Panda" />
            </div>
          )}
          <div style={{ position: "relative" }}>
            <img src={buttonImgSrc} alt="Excercise" />
            <span
              className={
                isRtt["isRtt"]
                  ? styles["excercise-number-rtt"]
                  : styles["excercise-number"]
              }
            >
              {isRtt["isRtt"]
                ? exerciseDisplayName
                : isSubscribedZeroToOne
                ? activityShowNumber
                : excercise["exercise-number"]}
            </span>
          </div>
          {completionStatus === "completed" && (
            <div className={styles["excercise-stars"]}>
              <img
                src={COMPLETE_STAR}
                alt="Complete Star"
                className={`${styles["excercise-star"]} ${styles["excercise-star-one"]}`}
              />
              <img
                src={COMPLETE_STAR}
                alt="Half Star"
                className={`${styles["excercise-star"]} ${styles["excercise-star-two"]}`}
              />
              <img
                src={COMPLETE_STAR}
                alt="Empty Star"
                className={`${styles["excercise-star"]} ${styles["excercise-star-three"]}`}
              />
            </div>
          )}
        </a>
      )}
      {showCaptchaAlert && (
        <ParentVerificationCaptcha
          showState={[showCaptchaAlert, setshowCaptchaAlert]}
          navigateTo={"/parents/subscriptions"}
        />
      )}
    </div>
  );
};

const PopUp = ({ days }: any) => {
  let finalText: any = days;
  switch (days) {
    case 1:
      finalText = "You can access this exercise Tomorrow";
      break;
    case 2:
      finalText = "You can access this exercise Day after Tomorrow";
      break;
    default:
      finalText = `You can access this exercise after ${days} days`;
  }

  useEffect(() => {
    (async () => {
      const audio = await getTTSAudio(finalText);
      audio.play();
    })();
  }, []);

  return <div>{/* <p className={styles["para"]}>{finalText}</p> */}</div>;
};

const Headline = ({ exercise }: any) => {
  return (
    <div
      className={`${
        exercise["headline"] && styles["popup_wrapper"]
      } animate_animated animate__backInDown`}
    >
      {exercise["headline"] && (
        <p className={styles["para"]}>{exercise["headline"]}</p>
      )}
    </div>
  );
};

export { Excercise };
