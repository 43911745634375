import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import styles from "./WeighMeIn.module.css";
import data from "./WeighMeInData.json";
import "animate.css";
// import "./WeighMeInAnimate.css";
import textToSpeech from "../../../../utils/textToSpeech";
import { imagesLink } from "../../../../constants/Services/services-constants";

const WeighMeIn = ({ activityData, showNextComp }: any) => {
  const [weighMeInData, setWeighMeInData] = useState(
    data["weigh-me-in"]["rounds"]
  );
  const [dropZoneCoord, setDropZoneCoord] = useState(Object);
  const [isDragging, setIsDragging] = useState(false);

  const [counter, setCounter] = useState(0);

  let Draggable = require("react-draggable");
  let DraggableCore = Draggable.DraggableCore;

  const dropZoneRef: any = useRef();

  const [selectedOption, setSelectedOption] = useState({
    isSelected: false,
    data: {
      objectImage: "",
      objectName: "",
      objectWeight: 40,
    },
  });

  const [currRound, setCurrRound] = useState(data["weigh-me-in"]["rounds"][0]);

  useEffect(() => {
    textToSpeech(
      "Welcome to the Weigh Me In, select same weight option with respect to given question",
      "ENGLISH",
      () => {}
    );
    setDropZoneCoord(dropZoneRef.current.getBoundingClientRect());
  }, []);

  useEffect(() => {
    if (counter === data["weigh-me-in"]["rounds"].length) {
      window.location.reload();
    } else {
      setTimeout(() => {
        setCurrRound(data["weigh-me-in"]["rounds"][counter]);
        setSelectedOption({
          isSelected: false,
          data: {
            objectImage: "",
            objectName: "",
            objectWeight: 40,
          },
        });
        if (counter !== 0) {
          textToSpeech("SELECT THE CORRECT OPTION", "ENGLISH", () => {});
        }
      }, 3000);
    }
  }, [counter]);

  useEffect(() => {
    if (isDragging) {
      setSelectedOption({
        isSelected: false,
        data: {
          objectImage: "",
          objectName: "",
          objectWeight: 40,
        },
      });
    }
  }, [isDragging]);

  const dragStopHandler = (event: any, option: any) => {
    if (
      event.clientX >= dropZoneCoord.x &&
      event.clientX <= dropZoneCoord.right &&
      event.clientY >= dropZoneCoord.y &&
      event.clientY <= dropZoneCoord.bottom
    ) {
      setSelectedOption({
        isSelected: true,
        data: option,
      });

      // Correct Answer Condition
      if (option.objectWeight === currRound["question-object"].objectWeight) {
        textToSpeech("GOOD WORK, CORRECT ANSWER", "ENGLISH", () => {
          setCounter((prev) => prev + 1);
        });
      } else {
        textToSpeech("Wrong answer", "ENGLISH", () => {});
      }
    } else {
    }

    setIsDragging(false);
  };
  return (
    <IonPage>
      <IonContent>
        <div className={styles.WeighMeIn}>
          <div className={`${styles.weighingContainer}  `}>
            <div
              className={
                selectedOption.isSelected
                  ? `animate__animated animate__swing `
                  : ""
              }
            >
              <img
                className={`${styles.plankImg}`}
                src="/assets/activity/plank1.png"
                alt=""
              />
              <img
                className={`${styles.questionImg} ${styles.upDownAnimate}`}
                src={`${imagesLink}${currRound["question-object"].objectImage}`}
                alt=""
              />
              <div ref={dropZoneRef} className={styles.answerDiv}>
                <img
                  className={
                    selectedOption.isSelected
                      ? styles.answerImg
                      : styles.displayNone
                  }
                  src={`${imagesLink}${selectedOption.data.objectImage}`}
                  alt=""
                />
              </div>
            </div>

            <img
              className={styles.triangleImg}
              src="/assets/activity/triangle1.png"
              alt=""
            />
          </div>
          <div className={styles.optionsContainer}>
            {currRound["option-objects"].map((option, i) => {
              return (
                <Draggable
                  position={{ x: 0, y: 0 }}
                  onDrag={() => setIsDragging(true)}
                  onStop={(event: any) => dragStopHandler(event, option)}
                  onMouseDown={() =>
                    setDropZoneCoord(
                      dropZoneRef.current.getBoundingClientRect()
                    )
                  }
                >
                  <div
                    className={
                      isDragging
                        ? ""
                        : selectedOption.isSelected &&
                          option.objectImage === selectedOption.data.objectImage
                        ? styles.visibilityHidden
                        : styles.optionDragTransition
                    }
                  >
                    <img
                      key={i}
                      draggable={false}
                      className={`${styles.optionImg} animate__bounce`}
                      src={`${imagesLink}${option.objectImage}`}
                      alt=""
                    />
                  </div>
                </Draggable>
              );
            })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export { WeighMeIn as default };
