import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import BootStrapTable from "../../../components/generalComponents/Tables/BootStrapTable";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import { getHttpData } from "../../../utils/http-util";
import CircleLoader from "../../Parents/Subscription/components/CircleLoader";
import styles from "./AdminPage.module.css";
const ADMIN_TABLE_HEADINGS = [
  "#",
  "Subscription Duration",
  "Current Active",
  "Percentage",
];

const AdminPage = () => {
  const [tableData, setTableData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(true);

  const getTableData = async () => {
    const res = await getHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/admin/analytics/subscription_analytics`
    );
    return res.data;
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const tableData = await getTableData();
      const tableRowKeys = Object.keys(tableData["subscriptionDuration"]);
      const totalActiveSubscriptions = tableData["totalActiveSubscriptions"];
      const tableRowData = tableRowKeys.map((key: any) => {
        const value = tableData["subscriptionDuration"][key];
        return [
          key,
          value,
          ((value / totalActiveSubscriptions) * 100).toFixed(2),
        ];
      });
      setTableData({
        tableRowData,
        totalActiveSubscriptions: totalActiveSubscriptions,
      });
      setIsLoading(false);
    })();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className={styles["admin_page_wrapper"]}>
          <div className={styles["page_head"]}>
            <h1 className={styles["head_heading"]}>Current Statistics</h1>
          </div>
          <div className={styles["page_body"]}>
            {isLoading ? (
              <CircleLoader />
            ) : (
              <div className={`${styles["table_wrapper"]} mx-2`}>
                <h2 className="text-center h5 text-success mb-4">
                  {" "}
                  {`Total Active Subscriptions - ${tableData["totalActiveSubscriptions"]}`}
                </h2>
                <BootStrapTable
                  tableHeadings={ADMIN_TABLE_HEADINGS}
                  tableContent={tableData["tableRowData"]}
                />
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export { AdminPage as default };
