import styles from "./TeacherProfileCard.module.css";
import { useRttOnlineContext } from "../../../../../store/context/rtt-online-context";
import { TeacherData } from "../../RttOnlineProgram";
import { convertUtcDayToLocal } from "../../../../../utils/date-utils";
import moment from "moment";
const TeacherProfileCard = (props: any) => {
  const teacher: TeacherData = props.teacher;
  const showAnimation = props.showAnimation;

  const rttOnlineCtx = useRttOnlineContext();

  const { rttDataDispatch, rttDataState } = rttOnlineCtx;

  const isTeacherSelected = rttDataState.selectedTeacher?.id === teacher.id;

  const teacherAvailabilityInLocalStartTimeArray = [
    ...teacher.availability,
  ].map((day: any) => {
    const { localWeekDay, localTime } = convertUtcDayToLocal(
      day.weekDay,
      day.startTime,
      24
    );

    return { weekDay: localWeekDay, startTime: localTime };
  });

  const teacherAvailabilityInLocalEndTimeArray = [...teacher.availability].map(
    (day: any) => {
      const { localWeekDay, localTime } = convertUtcDayToLocal(
        day.weekDay,
        day.endTime,
        24
      );

      return { weekDay: localWeekDay, endTime: localTime };
    }
  );

  const getTeacherAvailableDaysStartTime = (
    teacherAvailabilityInLocalStartTimeArray: any
  ) => {
    const teacherAvailableDays: any = {};
    teacherAvailabilityInLocalStartTimeArray.map((obj: any) => {
      if (teacherAvailableDays[obj.weekDay]) {
        const lastHH = teacherAvailableDays[obj.weekDay].split(":")[0];
        const lastMM = teacherAvailableDays[obj.weekDay].split(":")[1];

        const currentHH = obj.startTime.split(":")[0];
        const currentMM = obj.startTime.split(":")[1];

        if (Number(lastHH) > Number(currentHH)) {
          teacherAvailableDays[obj.weekDay] = obj.startTime;
        }
        if (Number(lastHH) === Number(currentHH)) {
          if (Number(lastMM) > Number(currentMM)) {
            teacherAvailableDays[obj.weekDay] = obj.startTime;
          }
        }
      } else {
        teacherAvailableDays[obj.weekDay] = obj.startTime;
      }
    });

    return teacherAvailableDays;
  };
  const getTeacherAvailableDaysEndTime = (
    teacherAvailabilityInLocalEndTimeArray: any
  ) => {
    const teacherAvailableDays: any = {};
    teacherAvailabilityInLocalEndTimeArray.map((obj: any) => {
      if (teacherAvailableDays[obj.weekDay]) {
        const lastHH = teacherAvailableDays[obj.weekDay].split(":")[0];
        const lastMM = teacherAvailableDays[obj.weekDay].split(":")[1];

        const currentHH = obj.endTime.split(":")[0];
        const currentMM = obj.endTime.split(":")[1];
        if (Number(lastHH) < Number(currentHH)) {
          teacherAvailableDays[obj.weekDay] = obj.endTime;
        }
        if (Number(lastHH) === Number(currentHH)) {
          if (Number(lastMM) < Number(currentMM)) {
            teacherAvailableDays[obj.weekDay] = obj.endTime;
          }
        }
      } else {
        teacherAvailableDays[obj.weekDay] = obj.endTime;
      }
    });

    return teacherAvailableDays;
  };

  const teacherAvailableDaysStartTime = getTeacherAvailableDaysStartTime(
    teacherAvailabilityInLocalStartTimeArray
  );

  const teacherAvailableDaysEndTime = getTeacherAvailableDaysEndTime(
    teacherAvailabilityInLocalEndTimeArray
  );

  const selectTeacherHandler = (e: any, teacher: any) => {
    rttDataDispatch({
      type: "SET_TEACHER_DATA",
      payload: teacher,
    });

    showAnimation();
  };

  return (
    <div
      onClick={(e) => selectTeacherHandler(e, teacher)}
      className={styles["teacher_card"]}
      style={{
        border: isTeacherSelected ? "2px dashed #4c4" : "none",
        scale: isTeacherSelected ? "1.05" : "1",
      }}
    >
      <img
        className={styles["braincells_logo"]}
        src="/assets/logo/brainCells-logo-small.png"
        alt="braincells_logo"
      />
      <div className={styles["teacher_card__image_container"]}>
        <img
          style={{ borderRadius: "50%", width: "100px", height: "100px" }}
          className={styles["teacher_card__image"]}
          src={teacher.profilePicture}
          alt=""
        />
      </div>
      <div className={styles["teacher_card__details_container"]}>
        <div className={styles["teacher_card__name_container"]}>
          <p className={styles["teacher_card__name"]}>{teacher.name}</p>
          <p className={styles["teacher_card__title"]}>Instructor</p>
        </div>

        <div className={styles["teacher_card__description_container"]}>
          <p className={styles["teacher_card__description"]}>
            {teacher.description}
          </p>
        </div>

        <div className={styles["teacher_card__timings"]}>
          <p className={styles["teacher_card__timings__day_title"]}>
            Available Timings
          </p>

          {Object.keys(teacherAvailableDaysStartTime).map(
            (day: any, index: number) => {
              const startTime12HourFormat = moment(
                teacherAvailableDaysStartTime[day],
                "HH:mm"
              ).format("hh:mm A");
              const endTime12HourFormat = moment(
                teacherAvailableDaysEndTime[day],
                "HH:mm"
              ).format("hh:mm A");

              return (
                <p key={index} className={styles["teacher_card__timing"]}>
                  {day} : {startTime12HourFormat} - {endTime12HourFormat}
                </p>
              );
            }
          )}
        </div>

        <div className={styles["teacher_card__radio_toolbar"]}>
          <button
            onClick={(e) => selectTeacherHandler(e, teacher)}
            style={{
              borderColor: isTeacherSelected ? "#4c4" : "#444",
              backgroundColor: isTeacherSelected ? "#bfb" : "#ddd",
            }}
            className={styles["select_teacher_button"]}
          >
            {isTeacherSelected ? "Selected Teacher" : "Select This Teacher"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeacherProfileCard;
