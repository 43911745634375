import { IonButton } from "@ionic/react";
import React, { useRef, useState } from "react";
import Confetti from "react-confetti";

import { useOnClickOutside } from "../../../utils/ui-functions-utils";
import styles from "./NewPortalModal.module.css";

const NewPortalModal = (props: any) => {
  const tncModalRef = useRef(null);
  const [showModal, setShowModal] = props.myModalDetails;

  useOnClickOutside(tncModalRef, () => {
    setShowModal(false);
  });

  setTimeout(() => {
    setShowModal(false);
    localStorage.setItem("SHOW_NEW_PORTAL_MESSAGE", "false");
  }, 5000);

  return (
    <div className={styles.tncModalBackground}>
      <div
        style={{
          backgroundImage: `url("/assets/newPortalMsg/whos-the-guest-bg.jpeg")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className={styles.tncModalContainer}
        ref={tncModalRef}
      >
        <Confetti numberOfPieces={400} recycle={false} />
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <div className={styles.tncContent}>CONGRATULATIONS !</div>
          <div className={styles.tncContent}>
            You are now on New BrainCells Portal
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPortalModal;
