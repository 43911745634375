import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import moment from "moment";
import { createRef, useEffect, useRef, useState } from "react";
import EndSubscriptionDialogBox from "../../../components/generalComponents/EndSubscription/EndSubscriptionDialogBox";
import { planId } from "../../../configs/RazorpayConfig";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";
import { useSubscriptionContext } from "../../../store/context/subscription-context";
import { useUserContext } from "../../../store/context/user-context";
import { getUUID } from "../../../utils/general-utils";
import { getHttpData, postHttpData } from "../../../utils/http-util";
import { getCountry, getFinalPrice } from "../../../utils/payment-utils";
import CircleLoader from "./components/CircleLoader";
import CouponLogic from "./components/CouponLogic";
import { BrainCellsPricingCards, PricingCard } from "./components/PaymentCards";
import TncCheckbox from "./components/TncModal/TncCheckbox";
import { RazorpayLogic } from "./Payments/RazorpayLogic";
import styles from "./PricingPage.module.css";
import PricingInfoModal from "./components/PricingInfoModal/PricingInfoModal";
import TabSwitcher from "./components/TabSwitcher/TabSwitcher";

const PricingPage = () => {
  const restWorldPricing = require("./country_wise_pricing.json");
  const { user }: any = useUserContext();
  const subscriptionCtx = useSubscriptionContext();
  const { activeSubscriptions } = subscriptionCtx;
  const [crrId, setCrrId] = useState(1);
  const [programData, setPageData] = useState<any>(null);
  const [languageData, setLanguageData] = useState<any>([]);
  const [rttData, setRttData] = useState<any>([]);
  const [paymentOpen, setPaymentOpen] = useState<any>(false);
  const [isTncAccepted, setIsTncAccepted] = useState<any>(true);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const recurringPaymentDataRef = useRef<any>(null);
  const programIdRef = useRef<any>(null);
  const [paymentDetails, setPaymentDetails] = useState({
    clientName: "",
    clientNumber: "",
    clientEmail: "",
    progName: "Braincells",
    progPrice: 1000,
    progDuration: 1,
    userId: 0,
    endDate: "",
    subId: "",
    programId: "",
    isCouponApplied: false,
    couponAmount: 0,
    couponType: "",
    couponCode: "",
    couponId: "",
    discountAmount: 0,
  });
  const [modalDetails, setModalDetails] = useState({
    showModal: false,
    modalType: "TNC",
    modalPaymentDetails: {},
  });
  const [pricingInfoModal, setPricingInfoModal] = useState({
    isOpen: false,
    data: {},
  });

  const closePricingInfoModal = () => {
    setPricingInfoModal({
      isOpen: false,
      data: {},
    });
  };

  const openPricingInfoModal = () => {
    setPricingInfoModal({
      isOpen: true,
      data: {},
    });
  };
  const contentRef = createRef<HTMLIonContentElement>();
  const paymentDoneRef: any = useRef(false);
  const country = getCountry();
  const braincellsActiveSubscription = activeSubscriptions.filter(
    (sub: any) =>
      sub.status === "ACTIVE" &&
      sub.programName === "BrainCells" &&
      sub.programType === "GENIUS" &&
      sub.recurringPaymentStatus === "LIVE"
  );
  const isRecurringPaymentLive = braincellsActiveSubscription[0]?.razorpaySubId;
  let razorpayPaymentId: string = "";
  const showRtt = user["cohort"] === "RTT";
  const switchPaymentSuccess = () => {
    setIsPaymentSuccess((prev: any) => !prev);
  };

  const renewBraincellsSubHandler = (
    e: any,
    values: any,
    price: number,
    discountAmount: any
  ) => {
    const braincellsActiveSub = subscriptionCtx.activeSubscriptions.filter(
      (obj) => obj.status === "ACTIVE" && obj.programName === "BrainCells"
    )[0];
    const sanskritFilter = subscriptionCtx.activeSubscriptions.filter(
      (obj) => obj.programName === "Language-Learning-Sanskrit"
    );
    const rttFilter = subscriptionCtx.activeSubscriptions.filter(
      (obj) => obj.programName === "Rumble-Tumble-Time"
    );
    const sanskritSub =
      sanskritFilter.length > 0
        ? {
            isActive: sanskritFilter.length === 0 ? false : true,
            sanskritObj: sanskritFilter[0],
            endDatetime:
              sanskritFilter.length !== 0
                ? moment(sanskritFilter[0].endDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : "",
          }
        : {};
    // RTTSub
    const rttSub =
      rttFilter.length > 0
        ? {
            isActive: rttFilter.length === 0 ? false : true,
            rttObject: rttFilter[0],
            endDatetime:
              rttFilter.length !== 0
                ? moment(rttFilter[0].endDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : "",
          }
        : {};

    // GermanSub
    const germanFilter = subscriptionCtx.activeSubscriptions.filter(
      (obj) => obj.programName === "Language-Learning-German"
    );
    const germanSub =
      germanFilter.length > 0
        ? {
            isActive: germanFilter.length === 0 ? false : true,
            germanObj: germanFilter[0],
            endDatetime: moment(germanFilter[0].endDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            ),
          }
        : {};
    // FrenchSub
    const frenchFilter = subscriptionCtx.activeSubscriptions.filter(
      (obj) => obj.programName === "Language-Learning-French"
    );

    const frenchSub =
      frenchFilter.length > 0
        ? {
            isActive: frenchFilter.length === 0 ? false : true,
            frenchObj: frenchFilter[0],
            endDatetime: moment(frenchFilter[0].endDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            ),
          }
        : {};
    // JapaneseSub
    const japaneseFilter = subscriptionCtx.activeSubscriptions.filter(
      (obj) => obj.programName === "Language-Learning-Japanese"
    );

    const japaneseSub =
      japaneseFilter.length > 0
        ? {
            isActive: japaneseFilter.length === 0 ? false : true,
            japaneseObj: japaneseFilter[0],
            endDatetime: moment(japaneseFilter[0].endDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            ),
          }
        : {};
    if (isTncAccepted === false) {
      contentRef.current?.scrollToTop(500);
      setModalDetails({
        showModal: true,
        modalType: "TNC",
        modalPaymentDetails: {},
      });
    } else {
      showRazorpay(e, {
        clientName: user.name,
        clientNumber: user.phone,
        clientEmail: user.email,
        progName: values.name,
        progPrice: price,
        discountAmount: discountAmount,
        progDuration: values.programDuration,
        userId: user.id,
        endDate:
          values.name === "BrainCells"
            ? braincellsActiveSub.endDate
            : values.name === "Language-Learning-French"
            ? frenchSub.frenchObj.endDate
            : values.name === "Language-Learning-German"
            ? germanSub.germanObj.endDate
            : values.name === "Language-Learning-Japanese"
            ? japaneseSub.japaneseObj.endDate
            : values.name === "Language-Learning-Sanskrit"
            ? sanskritSub.sanskritObj.endDate
            : values.name === "Rumble-Tumble-Time"
            ? moment().format("DD-MM-YYYY")
            : null,

        subId:
          values.name === "BrainCells"
            ? braincellsActiveSub.id
            : values.name === "Language-Learning-French"
            ? frenchSub.frenchObj.id
            : values.name === "Language-Learning-German"
            ? germanSub.germanObj.id
            : values.name === "Language-Learning-Japanese"
            ? japaneseSub.japaneseObj.id
            : values.name === "Language-Learning-Sanskrit"
            ? sanskritSub.sanskritObj.id
            : values.name === "Rumble-Tumble-Time"
            ? getUUID()
            : null,
        programId: values.id,
      });
    }
  };

  const showRazorpay = (
    e: any,
    {
      clientName,
      clientNumber,
      clientEmail,
      progName,
      progPrice,
      progDuration,
      userId,
      endDate,
      subId,
      programId,
      discountAmount,
    }: {
      clientName: string;
      clientNumber: string;
      clientEmail: string;
      progName: string;
      progPrice: number;
      progDuration: number;
      userId: number;
      endDate: "";
      subId: string;
      programId: string;
      discountAmount: number;
    }
  ) => {
    e.preventDefault();
    setPaymentDetails({
      ...paymentDetails,
      clientName: clientName,
      clientNumber: clientNumber,
      clientEmail: clientEmail,
      progName: progName,
      progPrice: progPrice,
      progDuration: progDuration,
      userId: userId,
      endDate: endDate,
      subId: subId,
      programId: programId,
      discountAmount: discountAmount,
    });
    if (isTncAccepted) {
      setPaymentOpen(true);
    }
  };

  const onPaymentClick = async (
    e: any,
    programData: any,
    price: number,
    discountAmount: number
  ) => {
    // if (crrId === 0 && user.ageGroup !== "0-1" &&   programData.name === "BrainCells" )
    //? ENABLE TO START RECURRING PAYMENT
    if (false) {
      setIsLoading(true);
      const razorpaySubData = await recurringSubscriptionData();
      const { id } = razorpaySubData;
      recurringPaymentDataRef.current = {
        total_count: 36,
        quantity: 1,
        plan_id: razorpaySubData["plan_id"],
        subscription_id: id,
      };
      setIsLoading(false);
      renewBraincellsSubHandler(e, programData, price, 0);
    } else {
      recurringPaymentDataRef.current = {};
      renewBraincellsSubHandler(e, programData, price, discountAmount);
    }
  };

  const checkboxClick = () => {
    setIsTncAccepted((prev: any) => !prev);
  };

  const closeModal = () => {
    setModalDetails({
      showModal: false,
      modalType: "TNC",
      modalPaymentDetails: {},
    });
  };

  const getBrainCellsSubscriptionData = async () => {
    setIsLoading(true);
    try {
      if (country === "India") {
        const braincellsProgramResponse: any = await getHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/program?ageGroup=${user.ageGroup}&status=PAID&type=BRAINCELLS`
        );
        braincellsProgramResponse.data.forEach((element: any) => {
          if (element.programDuration === 1) {
            programIdRef.current = element.id;
          }
        });
        setPageData(braincellsProgramResponse.data);
      } else {
        const tempArr =
          restWorldPricing["REST-OF-THE-WORLD"]["BrainCells"][user.ageGroup];

        tempArr.forEach((element: any) => {
          if (element.programDuration === 1) {
            programIdRef.current = element.id;
          }
        });
        setPageData(tempArr);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getLanguageSubscriptionData = async () => {
    try {
      if (country === "India") {
        const languagesProgramResponse: any = await getHttpData(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/program?ageGroup=${user.ageGroup}&status=PAID&type=LANGUAGE`
        );
        setLanguageData(
          languagesProgramResponse.data.filter(
            (item: any) => item.subType !== "TRIAL"
          )
        );
      } else {
        setLanguageData(
          restWorldPricing["REST-OF-THE-WORLD"]["language"][user.ageGroup]
        );
      }
      // setLanguageData(languagesProgramResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getRttSubscriptionData = async () => {
    try {
      const rttProgramResponse: any = await getHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/program?ageGroup=${user.ageGroup}&status=PAID&type=RTT`
      );
      setRttData(rttProgramResponse["data"]);
    } catch {}
  };

  const recurringSubscriptionData = async () => {
    const activeBraincellsSubscription: any =
      subscriptionCtx.activeSubscriptions.filter(
        (sub: any) =>
          sub.programName === "BrainCells" && sub.status === "ACTIVE"
      )[0];
    const razorpaySubData: any = {
      userId: user.id,
      programId: programIdRef.current,
      existingSubId: activeBraincellsSubscription.id,
      planId: planId,
    };
    const res = await postHttpData(
      `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/get_razorpay_sub_id`,
      // "https://api.braincells.in:8080/get_razorpay_sub_id",
      razorpaySubData
    );
    return res;
  };

  useEffect(() => {
    if (!showRtt) {
      getBrainCellsSubscriptionData();
      getLanguageSubscriptionData();
    } else {
      getRttSubscriptionData();
    }
  }, []);

  useEffect(() => {
    // if (crrId === 0)
    if (false) {
      setPaymentDetails((prev: any) => {
        return {
          ...prev,
          isCouponApplied: false,
          couponAmount: 0,
          couponType: "",
          couponCode: "",
          couponId: "",
        };
      });
    }
  }, [crrId]);

  useEffect(() => {
    if (modalDetails.showModal) {
      setTimeout(() => {
        setModalDetails({
          showModal: false,
          modalType: "TNC",
          modalPaymentDetails: {},
        });
      }, 3000);
    }
  }, [modalDetails.showModal]);

  return (
    <IonPage>
      <IonContent ref={contentRef}>
        <IonHeader>
          <IonToolbar color="secondary">
            <IonTitle>Subscription & Payments</IonTitle>
            {!showRtt && (
              <IonButtons slot="start">
                <IonBackButton defaultHref="/parents" />
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
        <div className={styles["outer_wrapper"]}>
          <div
            style={{
              backgroundImage: `url(${"/assets/newPortalMsg/whos-the-guest-bg.jpeg"})`,
            }}
            className={styles["pricing_page_wrapper"]}
          >
            {pricingInfoModal.isOpen && (
              <PricingInfoModal closePricingInfoModal={closePricingInfoModal} />
            )}
            {isLoading && <CircleLoader />}
            {/* {isPaymentSuccess && <PaymentSuccessModal />} */}
            <div className={styles["header_wrapper"]}>
              <TncCheckbox
                modalDetails={modalDetails}
                checkboxClick={checkboxClick}
                isTncAccepted={isTncAccepted}
                closeModal={closeModal}
              />
              <CouponLogic
                paymentDetails={paymentDetails}
                setPaymentDetails={setPaymentDetails}
                // crrId={crrId}
              />
            </div>
            {showRtt && (
              <div>
                <h1 className={styles["headings"]}>Rumble Tumble Time</h1>
                <PricingCarousal
                  onPaymentClick={onPaymentClick}
                  data={rttData}
                  selectedId={1}
                  paymentDetails={paymentDetails}
                  ageGroup={user.ageGroup}
                  showDecimal={country === "India"}
                />
              </div>
            )}

            {programData && (
              <h1 className={styles["headings"]}>BrainCells Program</h1>
            )}

            {programData && !isRecurringPaymentLive && (
              <TabSwitcher
                selectedId={crrId}
                tabSelector={setCrrId}
                programData={programData}
                isZeroToOne={user.ageGroup === "0-1"}
                openPricingInfoModal={openPricingInfoModal}
              />
            )}
            {programData && !isRecurringPaymentLive && (
              <BrainCellsPricingCards
                onPaymentClick={onPaymentClick}
                programData={programData}
                crrId={crrId}
                paymentDetails={paymentDetails}
                ageGroup={user.ageGroup}
                showDecimal={country === "India"}
              />
            )}
            {isRecurringPaymentLive && (
              <EndSubscriptionDialogBox location="pricing-page" />
            )}
            {languageData.length > 0 && (
              <>
                <h1 className={styles["headings"]}>Language Program</h1>
                <PricingCarousal
                  onPaymentClick={onPaymentClick}
                  data={languageData}
                  selectedId={1}
                  paymentDetails={paymentDetails}
                  ageGroup={user.ageGroup}
                  showDecimal={country === "India"}
                />
              </>
            )}
            {paymentOpen && !isLoading && !paymentDoneRef.current && (
              <RazorpayLogic
                val={paymentDetails}
                isRazorpayOpen={[paymentOpen, setPaymentOpen]}
                razorpayPaymentId={razorpayPaymentId}
                // paymentSuccessModal={[modalDetails, setModalDetails]}
                paymentDoneRef={paymentDoneRef}
                showPaymentSuccess={switchPaymentSuccess}
                setIsLoading={setIsLoading}
                currency={country === "India" ? "INR" : "USD"}
                recurringPaymentData={recurringPaymentDataRef.current}
              />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
const PricingCarousal = ({
  paymentDetails,
  data,
  onPaymentClick,
  showDecimal,
  mostPopular = false,
}: any) => {
  return (
    <div className={styles["pricing_wrapper"]}>
      {data.map((programData: any, index: any) => {
        return (
          <PricingCard
            key={index}
            paymentDetails={paymentDetails}
            programData={programData}
            onPaymentClick={onPaymentClick}
            showDecimal={showDecimal}
          />
        );
      })}
    </div>
  );
};

export default PricingPage;
