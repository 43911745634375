import { useParams } from "react-router";
import styles from "./RttOnlineTeacherDashboard.module.css";
import { useEffect, useState } from "react";
import { getHttpData } from "../../../utils/http-util";
import { ACTIVITY_PORT } from "../../../configs/ServerConfig";
import { ACTIVITY_TRACKING_SERVER } from "../../../configs/ServerConfig";
import {
  currentUtcDate,
  dateTimeFromUTCtoLocal,
  filterByUpcoming,
} from "../../../utils/date-utils";
import BootStrapTable from "../../../components/generalComponents/Tables/BootStrapTable";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import CircleLoader from "../../Parents/Subscription/components/CircleLoader";
import moment from "moment";
import { useUserContext } from "../../../store/context/user-context";

const TEACHER_DASHBOARD_TABLE_HEADINGS = [
  "#",
  "Parent's name",
  "Child's name",
  "Contact",
];

const UPCOMING = "Upcoming";
const ALL_BOOKINGS = "All bookings";
const RttOnlineTeacherDashboard = () => {
  //CONTEXT
  const {
    user: { name },
  } = useUserContext();
  //HOOKS
  const { teacherId } = useParams<any>();
  //STATE
  const [bookingData, setBookingData] = useState<any>([]);
  const [bookingDates, setBookingDates] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>({});
  const [selectedButton, setSelectedButton] = useState<any>(UPCOMING);
  //CONSTANTS
  const getTeacherBookings = async () => {
    const res = await getHttpData(
      `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/rttOnlineBooking?teacherId=${teacherId}`
    );
    return res.data;
  };

  const convertDataToTableData = (arr: any) => {
    const tableData: any = {};
    const sortedArr = arr.sort((bookingDataOne: any, bookingDataTwo: any) => {
      const { eventDate: eventDateOne } = bookingDataOne;
      const { eventDate: eventDateTwo } = bookingDataTwo;
      const formattedDate = moment(eventDateOne, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      const formattedDateTwo = moment(eventDateTwo, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      return moment(formattedDate).isAfter(formattedDateTwo) ? 1 : -1;
    });
    sortedArr.forEach((bookingData: any) => {
      const {
        eventDate,
        eventTime,
        themeName,
        meetLink,
        userName,
        childName,
        ageGroup,
        phone,
      } = bookingData;
      const { localDate, localTime, localDay } = dateTimeFromUTCtoLocal(
        eventDate,
        eventTime
      );
      const phoneNumberAnchorTag = (
        <a
          className={styles["contact_anchor"]}
          href={`tel:${phone.split(" ").join("")}`}
        >{`${phone.split(" ").join("")}`}</a>
      );
      const timeSlotAlreadyAvailable = tableData[localDate];
      if (timeSlotAlreadyAvailable) {
        if (timeSlotAlreadyAvailable[localTime]) {
          const timeSlotArr = timeSlotAlreadyAvailable[localTime]["bookings"];
          timeSlotAlreadyAvailable[localTime]["bookings"] = [
            ...timeSlotArr,
            [userName, childName, phoneNumberAnchorTag],
          ];
        } else {
          tableData[localDate][localTime] = {};
          tableData[localDate][localTime]["themeName"] = themeName;
          tableData[localDate][localTime]["ageGroup"] =
            ageGroup === "2-4" ? "Champ" : "Master";
          tableData[localDate][localTime]["bookings"] = [
            [userName, childName, phoneNumberAnchorTag],
          ];
          tableData[localDate][localTime]["meetLink"] = meetLink;
        }
      } else {
        tableData[localDate] = {};
        tableData[localDate][localTime] = {};
        tableData[localDate][localTime]["themeName"] = themeName;
        tableData[localDate][localTime]["ageGroup"] =
          ageGroup === "2-4" ? "Champ" : "Master";
        tableData[localDate][localTime]["bookings"] = [
          [userName, childName, phoneNumberAnchorTag],
        ];
        tableData[localDate][localTime]["meetLink"] = meetLink;
      }
    });
    return tableData;
  };

  const fetchPageData = async () => {
    const teacherBookings = await getTeacherBookings();
    const upcomingBookings =
      selectedButton === UPCOMING
        ? filterByUpcoming(teacherBookings)
        : teacherBookings;
    const bookingDataTableFormat = convertDataToTableData(upcomingBookings);
    setTeacherData({ teacherName: teacherBookings[0]["teacherName"] });
    return bookingDataTableFormat;
  };

  const onButtonClick = (type: any) => {
    setSelectedButton(type);
  };

  const fetchDataCreateTable = async () => {
    setIsLoading(true);
    const bookingData = await fetchPageData();
    const bookingDates = Object.keys(bookingData);
    setBookingData(bookingData);
    setBookingDates(bookingDates);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataCreateTable();
  }, [selectedButton]);

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Teacher dashboard</IonTitle>
            <IonButtons slot="start">
              {/* <button onClick={() => history.push("/dashboard")}>back</button> */}
              <IonBackButton defaultHref="/rtt-online/" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className={styles["page_wrapper"]}>
          <div className={styles["page_header"]}>
            <h1>{name}</h1>
            <div className={styles["button_wrapper"]}>
              <IonButton
                size="small"
                onClick={() => onButtonClick(UPCOMING)}
                fill={selectedButton === UPCOMING ? "solid" : "outline"}
              >
                {UPCOMING}
              </IonButton>
              <IonButton
                size="small"
                onClick={() => onButtonClick(ALL_BOOKINGS)}
                fill={selectedButton === ALL_BOOKINGS ? "solid" : "outline"}
              >
                {ALL_BOOKINGS}
              </IonButton>
            </div>
          </div>
          <div className={styles["table_container"]}>
            {isLoading ? (
              <CircleLoader />
            ) : (
              bookingDates.map((date: any, index: any) => {
                return (
                  <TableGenerator
                    key={index}
                    date={date}
                    dateBookings={bookingData[date]}
                  />
                );
              })
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const TableGenerator = ({ date, dateBookings }: any) => {
  const timings = Object.keys(dateBookings).sort(
    (timeOne: any, timeTwo: any) => {
      return moment(timeOne).isAfter(timeTwo) ? 1 : -1;
    }
  );

  return (
    <div className={`${styles["table_wrapper"]} card`}>
      <h2 className={styles["topic_date"]}>Date - {date}</h2>
      {timings.map((timeSlot: any, index: any) => {
        const { bookings, themeName, ageGroup, meetLink } =
          dateBookings[timeSlot];
        return (
          <div className={styles["topic_wrapper"]}>
            <p className={styles["topic_info"]}>Start time - {timeSlot}</p>
            <p
              className={`${styles["topic_info_name"]} ${styles["topic_info"]}`}
            >
              Theme name - {themeName}
            </p>
            <p className={styles["topic_info"]}>Age group - {ageGroup}</p>
            <button type="button" className="btn btn-primary mb-3">
              <a
                className={styles["topic_link"]}
                href={meetLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click to Join Meet
              </a>{" "}
            </button>
            {/* 
            <button
              className={`${styles["topic_info"]} ${styles["topic_info_button"]}`}
            >
             
            </button> */}

            <BootStrapTable
              key={index}
              tableHeadings={TEACHER_DASHBOARD_TABLE_HEADINGS}
              tableContent={bookings}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RttOnlineTeacherDashboard;
