import { IonButton } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useImgPrefetch } from "../../../../hooks/useImgPrefetch";
import textToSpeech from "../../../../utils/textToSpeech";
import styles from "./PatternMatch.module.css";
import {
  ACTIVITY_END,
  ACTIVITY_START,
  ROUND_END,
  ROUND_RIGHT_ATTEMPT,
  ROUND_START,
  ROUND_WRONG_ATTEMPT,
  useActivityTracker,
} from "../../../../hooks/useActivityTracker";
import { getTTSAudio } from "../../../../utils/http-util";
import { ActivityStartButton } from "../../../UI/Buttons/ActivityStartButton/ActivityStartButton";
import { imagesLink } from "../../../../constants/Services/services-constants";

const PatternMatch = ({ activityData, showNextComp, activityId }: any) => {
  const [isActivityStarted, setIsActivityStarted] = useState(false);
  const [patternMatchData, setPatternMatchData] = useState(
    activityData["pattern-match"]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currQuestion, setCurrQuestion] = useState({
    questionImg: "",
    answerImg: "",
    answerSet: [],
    find: "",
    answerText: "",
    findIndex: 2,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isCorrectAns, setIsCorrectAns] = useState(false);
  const dropZoneRef: any = useRef(null);

  const [dropZoneCoord, setDropZoneCoord] = useState(Object);
  const [counter, setCounter] = useState(0);
  const [score, setScore] = useState(0);
  const activityTracker = useActivityTracker(activityId);

  let Draggable = require("react-draggable");
  let DraggableCore = Draggable.DraggableCore;

  const speechRef: any = useRef(null);

  const correctAnsAudioRef = useRef<any>();
  const wrongAnsAudioRef = useRef<any>();
  const activityCompleteAudioRef = useRef<any>();

  /// Image Prefetching
  const imgArr: string[] = [];
  const imgArrData = [...patternMatchData].map((obj: any) => {
    imgArr.push(obj.questionImg);
    obj.answerSet.map((img: any) => {
      imgArr.push(img);
    });
    imgArr.push(obj.answerImg);
  });
  useImgPrefetch(imgArr);

  useEffect(() => {
    /// ACTIVITY_START
    activityTracker(ACTIVITY_START);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (counter === patternMatchData.length) {
      // textToSpeech("Well Done! Activity Complete", "ENGLISH", () => {});
      activityCompleteAudioRef.current.play();

      setTimeout(() => {
        /// ACTIVITY_END
        activityTracker(ACTIVITY_END);

        showNextComp();
      }, 2000);
    }
  }, [counter]);

  const dragStopHandler = (event: any, img: string) => {
    setDropZoneCoord(dropZoneRef.current.getBoundingClientRect());
    let clientX = 0;
    let clientY = 0;

    if (event.clientX) {
      clientX = event.clientX;
      clientY = event.clientY;
    } else {
      clientX = event.changedTouches[0].clientX;
      clientY = event.changedTouches[0].clientY;
    }

    if (
      clientX >= dropZoneCoord.x &&
      clientX <= dropZoneCoord.right &&
      clientY >= dropZoneCoord.y &&
      clientY <= dropZoneCoord.bottom
    ) {
      if (currQuestion.answerSet[currQuestion.findIndex] === img) {
        /// ROUND_RIGHT_ANSWER
        activityTracker(ROUND_RIGHT_ATTEMPT);

        /// ROUND_END
        activityTracker(ROUND_END);

        setScore((prev) => prev + 1);
        setIsCorrectAns(true);
        if (counter < patternMatchData.length) {
          setCounter((prev) => prev + 1);
        }
        if (counter !== patternMatchData.length - 1) {
          // textToSpeech("Correct Answer", "ENGLISH", () => {});
          correctAnsAudioRef.current.play();

          speechRef.current = 7;

          setTimeout(() => {
            setIsCorrectAns(false);
            if (counter === patternMatchData.length - 1) {
              window.location.reload();
            }
          }, 2000);
        }
      } else {
        /// ROUND_WRONG_ATTEMPT
        activityTracker(ROUND_WRONG_ATTEMPT);

        /// ROUND_END
        activityTracker(ROUND_END);

        // textToSpeech("TRY AGAIN", "ENGLISH", () => {});
        wrongAnsAudioRef.current.play();

        /// ROUND_START
        activityTracker(ROUND_START);
      }
    }
    setIsDragging(false);
  };

  useEffect(() => {
    if (isCorrectAns === false) {
      /// ROUND_START
      activityTracker(ROUND_START);

      setCurrQuestion(patternMatchData[counter]);
    }
  }, [patternMatchData, counter, isCorrectAns]);

  const RoundCount = ({ crrRound, totalRound }: any) => {
    let heartStack: any = [];
    for (let i = 0; i < totalRound; i++) {
      heartStack.push(
        <i
          key={i}
          style={{ color: i < crrRound ? "red" : "#E8E8E8" }}
          className="fas fa-heart"
        ></i>
      );
    }
    return <div className={styles["round_count_wrapper"]}>{heartStack}</div>;
  };

  const activityStartHandler = async () => {
    console.log("activity start handler");
    setIsActivityStarted(true);

    textToSpeech(
      "Drag and drop the current pattern Image",
      "ENGLISH",
      () => {}
    );

    const getAllAudioPromises = await Promise.all([
      getTTSAudio("Correct Answer"),
      getTTSAudio("Try Again"),
      getTTSAudio("Well Done! Activity Complete"),
    ]);

    correctAnsAudioRef.current = getAllAudioPromises[0];
    correctAnsAudioRef.current.play();
    correctAnsAudioRef.current.pause();

    wrongAnsAudioRef.current = getAllAudioPromises[1];
    wrongAnsAudioRef.current.play();
    wrongAnsAudioRef.current.pause();

    activityCompleteAudioRef.current = getAllAudioPromises[2];
    activityCompleteAudioRef.current.play();
    activityCompleteAudioRef.current.pause();

    setIsLoading(false);

    setDropZoneCoord(dropZoneRef.current.getBoundingClientRect());
  };

  const mouseUpDragHandler = () => {
    console.log("mouse up drag handler, speechRef", speechRef.current);
  };

  return (
    // <IonPage>
    //   <IonContent>
    <div className={styles.PatternMatch}>
      {!isActivityStarted ? (
        // <button
        //   className={styles["start_activity_btn"]}
        //   onClick={() => activityStartHandler()}
        //   // onMouseUp={() => mouseUpDragHandler()}
        // >
        //   Start Pattern Match Activity
        // </button>

        <ActivityStartButton
          isLoading={isLoading}
          onClick={activityStartHandler}
        />
      ) : (
        <div>
          <div className={styles.scoreCard}>
            <RoundCount crrRound={score} totalRound={patternMatchData.length} />
          </div>

          <div className={styles.questionContainer}>
            <div
              className={isCorrectAns ? styles.displayNone : styles.question}
            >
              <img
                className={isCorrectAns ? styles.displayNone : ""}
                src={`${imagesLink}${currQuestion.questionImg}`}
                alt=""
              />
            </div>
            <div
              ref={dropZoneRef}
              className={isCorrectAns ? styles.correctAnsDiv : styles.answer}
            >
              <img
                className={
                  isCorrectAns ? styles.correctAnsStyles : styles.displayNone
                }
                src={`${imagesLink}${currQuestion.answerImg}`}
                alt=""
              />
            </div>
          </div>

          <div className={styles.optionsContainer}>
            {!isCorrectAns
              ? currQuestion.answerSet.map((img: string, i: number) => {
                  return (
                    <Draggable
                      position={{ x: 0, y: 0 }}
                      onDrag={() => setIsDragging(true)}
                      onStop={(event: any) => dragStopHandler(event, img)}
                      onMouseDown={() =>
                        setDropZoneCoord(
                          dropZoneRef.current.getBoundingClientRect()
                        )
                      }
                      onMouseUp={() => console.log("mouse up event fired")}
                      key={i}
                    >
                      <div
                        className={
                          isDragging
                            ? ""
                            : styles.optionDragTransition && isCorrectAns
                            ? img ===
                              currQuestion.answerSet[currQuestion.findIndex]
                              ? styles.visibilityHidden
                              : ""
                            : ""
                        }
                      >
                        <img
                          key={i}
                          draggable="false"
                          src={`${imagesLink}${img}`}
                          alt=""
                        />
                      </div>
                    </Draggable>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </div>
    //   </IonContent>
    // </IonPage>
  );
};

export { PatternMatch as default };
