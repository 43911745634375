import { IonButton } from "@ionic/react";
import React, { useRef } from "react";
import styles from "./RadioBtnCardPoput.module.css";

const RadioBtnCardPopup = ({ data, closePopupHandler }: any) => {
  const { title, strengths, characteristics, bgColor } = data;

  return (
    <div className={styles.tncModalBackground}>
      <div className={styles.tncModalContainer}>
        <div className={styles.closeBtn}>
          <i
            onClick={() => closePopupHandler()}
            className="fas fa-times-circle"
          ></i>
        </div>
        <div
          style={{
            backgroundColor: bgColor,
          }}
          className={styles["card"]}
        >
          <div className={styles["card__header"]}>
            <p className={styles["card__title"]}>{title}</p>
          </div>
          <div className={styles["card__body"]}>
            <div className={styles["strengths_container"]}>
              <p className={styles["card__strengths_title"]}>Strengths</p>
              <p className={styles["card__text"]}>{strengths}</p>
            </div>

            <div className={styles["characteristics_container"]}>
              <p className={styles["card__strengths_title"]}>Characteristics</p>
              <ul>
                {characteristics.map((item: string) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioBtnCardPopup;
