import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardHeader,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../../store/context/user-context";
import { getHttpData } from "../../../../utils/http-util";
import styles from "./Attendence.module.css";

export const Attendence = () => {
  const {
    user: { id },
  } = useUserContext();
  const [attendance, SetAttendence] = useState<any>({});

  useEffect(() => {
    (async () => {
      const response = await getHttpData(
        `https://staging2.braincells.in:8082/reporting/attendance?userId=${id}`
      );
      SetAttendence(response.data);
    })();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Attendence</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div
          className={styles.mainContainer}
          style={{
            backgroundImage: `url("/assets/parentLanding/background-cropped.svg")`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        >
          <div className={styles.creationContainer}>
            <Report
              number={attendance.attendance}
              name={Object.keys(attendance)?.[0]}
            />
            <Report
              number={attendance.streak}
              name={Object.keys(attendance)?.[1]}
            />
            <Report
              number={localStorage.getItem("TOTAL_EXERCISE")}
              name="Total Exercise"
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

function Report({ number, name }: any) {
  return (
    <div className={styles.reportContainer}>
      <IonCard className={styles.reportComponent}>
        <IonCardHeader className={styles.reportScore}>{number}</IonCardHeader>
      </IonCard>
      <p className={styles.reportName}> {name} </p>
    </div>
  );
}
