import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../utils/ui-functions-utils";
import styles from "./CouponCountdown.module.css";
import Countdown from "../CountDown/CountDown";
import CountdownSmall from "../CountDownSmall/CountDownSmall";
import {
  UserContextProvider,
  useUserContext,
} from "../../../store/context/user-context";
import { useCountdown } from "../../../hooks/useCountdown";
import { useHistory } from "react-router";
import { useSubscriptionContext } from "../../../store/context/subscription-context";

const CouponCountdown = (props: any) => {
  const tncModalRef = useRef(null);
  const pathname = window.location.pathname.split("/")[1];
  const lastPathNameArr = window.location.pathname.split("/");
  const lastPathName = lastPathNameArr[lastPathNameArr.length - 1];
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const {
    user: { ageGroup },
  } = useUserContext();
  const { couponDay, discountPercentage, diffDate } = useCountdown();
  // SHOW IF TRIAL
  // SHOW IF ACTIVITIES NOT DONE
  // SHOW IF
  // SHOW IF LESS THAN 3 DAYS

  const { activeSubscriptions = [] } = useSubscriptionContext();
  const isAnyProgramSubscribed =
    activeSubscriptions.filter((sub: any) => {
      return sub.programType !== "TRIAL";
    }).length > 0;

  const showHangingBar =
    diffDate < 3 &&
    pathname !== "excercise" &&
    pathname !== "free-programs" &&
    pathname !== "child-survey" &&
    pathname !== "rtt-online" &&
    lastPathName !== "paymentsuccess" &&
    localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`) !== "completed"
      ? !isAnyProgramSubscribed || couponDay < 3
      : false;

  const [hideCountdown, setHideCountdown] = useState(
    pathname === "excercise" ||
      localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`) ===
        "completed" ||
      !localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`)
  );

  useEffect(() => {
    setHideCountdown(
      pathname === "excercise" ||
        localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`) ===
          "completed" ||
        !localStorage.getItem(`COUPON_COUNT_ACTIVE_${couponDay}`)
    );
  }, [pathname]);

  const showCountDownOrHangingBar = () => {};

  const setShowModalTrue = () => {
    setShowModal(true);
  };

  const onRedeemClick = () => {
    history.push(
      `/parents/subscriptions/coupon/${`WELCOME-${discountPercentage}`}?ct=code`
    );
  };

  const checkScreenOrientationPortrait = () => {
    if (window.innerHeight > window.innerWidth) {
      //portrait
      return true;
    } else {
      //landscape
      return false;
    }
  };

  const style = {
    left: checkScreenOrientationPortrait()
      ? showModal
        ? "0%"
        : "50%"
      : showModal
      ? "35%"
      : "50%",
    zIndex: 102,
  };

  return (
    <div style={{ zIndex: 102 }}>
      {showHangingBar &&
        (hideCountdown ? (
          <CountdownFooter
            hrefLink={`/excercise/BrainCells/Exercise-${
              couponDay + 1
            }_Age_${ageGroup}?c=Mw==&s=TRIAL`}
            discountPercentage={discountPercentage}
          />
        ) : (
          <div
            className={`${styles.tncModalContainer} animate__animated animate__slideInUp`}
            ref={tncModalRef}
            style={style}
          >
            {showModal ? (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
                className={`${styles.redeemCountdownModal} ${
                  showModal
                    ? "animate__animated animate__slideInUp"
                    : "animate__animated animate__slideOutUp"
                }`}
              >
                <div
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className={styles.closeBtn}
                >
                  <i className="fas fa-times"></i>
                </div>
                <div>
                  <Countdown func={() => setHideCountdown(true)} />
                </div>
                <div className={styles.tncContent}></div>
                <div className={styles.tncHeaderContainer}>
                  <a
                    href={`/parents/subscriptions/coupon/${`WELCOME-${discountPercentage}`}?ct=code`}
                  >
                    <button
                      // onClick={onRedeemClick}
                      className={styles["redeem_button"]}
                    >
                      Redeem Coupon
                    </button>
                  </a>
                </div>
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
                onClick={() => setShowModal(true)}
                className={styles.smallCountdownModal}
              >
                <CountdownSmall
                  modalData={setShowModalTrue}
                  func={() => setHideCountdown(true)}
                />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

const CountdownFooter = ({ hrefLink, discountPercentage }: any) => {
  return (
    <footer style={{ zIndex: 102 }} className={styles["footer_tab_bar"]}>
      <div className={styles["footer_total_selected_program"]}>
        <p className={styles["footer_total_price"]}>
          Complete today’s exercise activities to unlock {discountPercentage}%
          discount coupon.{" "}
        </p>
      </div>
      <a href={hrefLink}>
        <button className={styles["footer_payment_button"]}>
          Go to Today's Exercise <i className="fas fa-caret-right"></i>
        </button>
      </a>
    </footer>
  );
};

export default CouponCountdown;
