import { useEffect, useState } from "react";
import styles from "./NavigationDot.module.css";

const NavigationDot = ({ programRef, unlockedExc }: any) => {
  const [dotsArray, setDotsArray] = useState<any>([]);
  const numberOfDots = Math.floor(Number(unlockedExc) / 60);
  const skipPath = (num: number) => {
    programRef.current.scrollLeft = `${Number(window.innerWidth) * num}`;
  };

  useEffect(() => {
    const crrArr = [];
    for (let i = 1; i < numberOfDots; i++) {
      crrArr.push(i);
    }
    setDotsArray(crrArr);
  }, [unlockedExc]);

  return (
    <div className={styles["navigation-dot-wrapper"]}>
      <div
        onClick={() => skipPath(0)}
        className={styles["navigation-dot"]}
      ></div>
      {dotsArray.map((i: any) => {
        return (
          <div
            key={i}
            onClick={() => skipPath(i * 10)}
            className={styles["navigation-dot"]}
          ></div>
        );
      })}
      <div
        onClick={() => skipPath(1000)}
        className={styles["navigation-dot"]}
      ></div>
    </div>
  );
};

export default NavigationDot;
