import React, { useEffect, useRef, useState } from "react";
import styles from "./FreeProgramPage.module.css";
import TopicCard from "./components/TopicCard/TopicCard";
import {
  getHttpData,
  postHttpData,
  putHttpData,
} from "../../../../utils/http-util";
import { useUserContext } from "../../../../store/context/user-context";
import { IonContent, IonPage } from "@ionic/react";
import CircleLoader from "../../../Parents/Subscription/components/CircleLoader";
import LockedTopicModal from "./components/LockedTopicModal/LockedTopicModal";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { useSubscriptionContext } from "../../../../store/context/subscription-context";
import { useHistory } from "react-router";
import {
  FREE_PROGRAM_UNLOCK_COUNT,
  INITIAL_FREE_DOWNLOADABLE_VISIBLE,
} from "../../../../constants/utility/utility-constants";
import SingleActivityStarter from "../../../../components/generalComponents/SingleActivityStarter/SingleActivityStarter";
import OrientationChecker from "../../../../components/generalComponents/OrientationChecker";
export const BACK_BUTTON: string = "/assets/program/back button.svg";

const FreeProgramPage = () => {
  //CONTEXT
  const subscriptionCtx = useSubscriptionContext();
  const {
    user: { ageGroup, id },
  } = useUserContext();
  //HOOKS
  const history = useHistory();
  //REF
  const selectedCardRef = useRef<any>();
  const unlockedStoryIdArr = useRef<any>();
  const isActiveSubscriptionIncludes = (subscriptionName: string) => {
    let isSubIncludes: boolean = false;

    const findSub = subscriptionCtx.activeSubscriptions.find(
      (item) =>
        item.programName === subscriptionName &&
        item.programType === "GENIUS" &&
        item.status === "ACTIVE"
    );

    if (findSub !== undefined) {
      isSubIncludes = true;
    }

    return isSubIncludes;
  };

  const BrainCells = "BrainCells";
  const LanguageLearningGerman = "Language-Learning-German";
  const LanguageLearningFrench = "Language-Learning-French";
  const LanguageLearningJapanese = "Language-Learning-Japanese";

  const isSubscriptionActive =
    isActiveSubscriptionIncludes(BrainCells) ||
    isActiveSubscriptionIncludes(LanguageLearningGerman) ||
    isActiveSubscriptionIncludes(LanguageLearningFrench) ||
    isActiveSubscriptionIncludes(LanguageLearningJapanese);

  // get path
  const path = window.location.pathname.split("/")[2];
  let programType = path;

  //STATE
  const [activityStarted, setActivityStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allTopics, setAllTopics] = useState([]);
  const [isLockedModalOpen, setIsLockedModalOpen] = useState(false);
  const [userProgressData, setUserProgressData] = useState<any>({});
  //CONSTANTS
  const tableDataPresent = Object.keys(userProgressData).length > 0;
  const userMapKey =
    programType === "story_telling"
      ? "user_story_telling_map"
      : "user_free_downloadable_map";
  const groupIdKey =
    programType === "story_telling"
      ? "currStoryTellingGroupId"
      : "currFreeDownloadableGroupId";

  const closeLockedModal = () => {
    setIsLockedModalOpen(false);
  };

  const openLockedModal = () => {
    setIsLockedModalOpen(true);
  };
  const startActivity = () => {
    setActivityStarted(true);
  };

  const getAllTopicsData = async () => {
    try {
      const response = await getHttpData(
        programType === "story_telling"
          ? `https://api.braincells.in:8080/story_telling`
          : `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/free_downloadable?ageGroup=${ageGroup}`
        // `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/${programType}?ageGroup=${ageGroup}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserFreeDownloadableData = async () => {
    try {
      const response = await getHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/${userMapKey}?userId=${id}
        `
      );
      return response.data[0];
    } catch (err) {
      console.log(err);
    }
  };

  const referClickHandler = (e: any) => {
    if (tableDataPresent) {
      updateUserFreeDownloadableData(e);
    } else {
      createUserFreeDownloadableData(e);
    }
  };

  const createUserFreeDownloadableData = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/${userMapKey}
        `,
        {
          userId: id,
          [groupIdKey]:
            INITIAL_FREE_DOWNLOADABLE_VISIBLE + FREE_PROGRAM_UNLOCK_COUNT,
          ageGroup: ageGroup,
        }
      );
      setIsLoading(false);
      setIsLockedModalOpen(false);
      window.location.reload();
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserFreeDownloadableData = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await putHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/${userMapKey}?userId=${id}
        `,
        {
          [groupIdKey]:
            userProgressData[groupIdKey] + FREE_PROGRAM_UNLOCK_COUNT,
        }
      );
      setIsLoading(false);
      setIsLockedModalOpen(false);
      window.location.reload();
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const getPageData = async () => {
    const response = await Promise.all([
      getAllTopicsData(),
      getUserFreeDownloadableData(),
    ]);
    const userProgressData = response[1];
    const topicArray = response[0];
    const sortedTopicArray = topicArray.sort((a: any, b: any) => {
      return a.groupId - b.groupId;
    });

    setAllTopics(sortedTopicArray);
    if (userProgressData) {
      setUserProgressData(response[1]);
    }
    if (programType === "story_telling") {
      unlockedStoryIdArr.current = [...sortedTopicArray]
        .slice(
          0,
          userProgressData
            ? userProgressData.currFreeDownloadableGroupId - 1
            : 2
        )
        .map((topicData: any) => topicData.id);
    }
  };

  const isTopicLocked = (topic: any) => {
    if (isSubscriptionActive) {
      return false;
    } else {
      if (tableDataPresent) {
        return topic.groupId > userProgressData[groupIdKey];
      } else {
        return topic.groupId > INITIAL_FREE_DOWNLOADABLE_VISIBLE;
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getPageData();
      setIsLoading(false);
    })();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <OrientationChecker />
        <SingleActivityStarter
          activityName={"StoryBook"}
          startActivity={activityStarted}
          closeActivity={() => {
            setActivityStarted(false);
          }}
          activityProps={{
            storyId: selectedCardRef.current,
            unlockedStoryId: unlockedStoryIdArr.current,
            openLockedModal,
          }}
        />
        <div className={styles["free_program_page"]}>
          {isLoading && <CircleLoader />}
          {isLockedModalOpen && (
            <LockedTopicModal
              closeLockedModal={closeLockedModal}
              referClickHandler={referClickHandler}
              programType={programType}
            />
          )}
          <div
            onClick={history.goBack}
            className={styles["back_button_container"]}
          >
            <img src={BACK_BUTTON} alt="Go Back" />
          </div>
          <div className={styles["free_program_page_head"]}>
            <div className={styles["free_program_page_head_title"]}>
              <h1 className={styles["program_title"]}>
                {programType === "free_downloadable"
                  ? "Free Worksheets"
                  : "Story Telling Program"}
              </h1>
            </div>

            <div className={styles["topic_cards_container"]}>
              {allTopics.map((topic: any) => {
                return (
                  <TopicCard
                    key={topic.id}
                    topic={topic}
                    isLocked={isTopicLocked(topic)}
                    programType={programType}
                    openLockedModal={openLockedModal}
                    closeLockedModal={closeLockedModal}
                    startActivity={startActivity}
                    selectedCardRef={selectedCardRef}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FreeProgramPage;
