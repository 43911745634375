import styles from "./PricingInfoModal.module.css";

const PricingInfoModal = ({ closePricingInfoModal }: any) => {
  return (
    <div className={styles.tncModalBackground}>
      <div className={styles.tncModalContainer}>
        <i
          onClick={() => closePricingInfoModal()}
          className={`${styles["closeBtn"]} fas fa-times-circle`}
        ></i>

        <div>
          <p className={styles["heading"]}>Monthly Recurring</p>
          <hr></hr>
          <p className={styles["text_item"]}>
            Monthly subscription option is automatic monthly payment through
            Debit/Credit Card or UPI
          </p>
          <p className={styles["text_item"]}>
            Monthly fees will be automatically deducted every month on your
            renewal date.
          </p>
          <p className={styles["text_item"]}>
            You can cancel monthly subscription anytime.
          </p>
          <p className={styles["text_item"]}>
            After cancellation, you will keep on receiving exercises for the
            already paid one month tenure i.e. until renewal date.
          </p>
          <p className={styles["text_item"]}>
            You can then re-subscribe any time to continue receiving exercises
          </p>
        </div>

        <div style={{ margin: 0 }}>
          <p className={styles["heading"]}>One-time 3 months & 1 year</p>
          <hr></hr>
          <p className={styles["text_item"]}>
            In one time payment you will receive exercises daily until the end
            of the tenure either 3 months or 1 year.
          </p>
          <p className={styles["text_item"]}>
            You can again make one time payment to keep receiving new exercises
          </p>
        </div>

        <p
          className={`${styles["footer_text"]} text-success

          `}
        >
          *In any of the three modes i.e. monthly recurring or 3 month and 12
          month one-time. You will have lifetime access to all the exercises for
          which you have made the payment.
        </p>
      </div>
    </div>
  );
};

export default PricingInfoModal;
