import React, { useEffect, useState } from "react";
import styles from "./FreeDownloadProgramPage.module.css";
import { IonContent, IonPage } from "@ionic/react";
import { getHttpData } from "../../../../utils/http-util";
import CircleLoader from "../../../Parents/Subscription/components/CircleLoader";
import { useHistory } from "react-router";
import { useUserContext } from "../../../../store/context/user-context";
import axios from "axios";
import { useSubscriptionContext } from "../../../../store/context/subscription-context";
import { FacebookShareButton, FacebookIcon } from "react-share";
import FreeProgramFooter from "../FreeProgramPage/components/FreeProgramFooter/FreeProgramFooter";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { imagesLink } from "../../../../constants/Services/services-constants";
export const BACK_BUTTON: string = "/assets/program/back button.svg";

const FreeDownloadProgramPage = () => {
  const path = window.location.pathname.split("/");
  const history = useHistory();
  const downloadId = path[path.length - 1];
  const userCtx = useUserContext();
  const user = userCtx;

  // states
  const [downloadData, setDownloadData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState<any>(
    downloadData.displayImageMain || ""
  );
  const [isLiked, setIsLiked] = useState(false);

  const NoTokenAxios = axios.create({
    baseURL: "",
    headers: {},
  });

  const getDownloadData = async () => {
    try {
      const response = await NoTokenAxios.get(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/free_downloadable?id=${downloadId}`
      );
      setDownloadData(response.data[0]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const pdfForceDownload = () => {
    fetch(
      "https://cors-anywhere.herokuapp.com/" +
        `https://braincells.in${downloadData.pdfPath}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadData.download);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
      });
  };

  useEffect(() => {
    getDownloadData();
  }, []);

  const inAws: boolean = downloadData?.displayImageMain?.split("//").length > 1;

  return (
    <IonPage>
      <IonContent>
        <div className={styles["free_downloadables_page"]}>
          {isLoading && <CircleLoader />}

          <div
            onClick={history.goBack}
            className={styles["back_button_container"]}
          >
            <img src={BACK_BUTTON} alt="Go Back" />
          </div>

          <div className={styles["images_container"]}>
            <div className={styles["main_image_container"]}>
              <img
                className={styles["main_image"]}
                src={`${inAws ? "" : imagesLink}${
                  mainImage === "" ? downloadData?.displayImageMain : mainImage
                }`}
                alt="main"
              />
            </div>

            <div className={styles["sub_images_container"]}>
              <img
                className={styles["sub_image"]}
                src={`${inAws ? "" : imagesLink}${
                  downloadData?.displayImageMain
                }`}
                alt="sub"
                onClick={() => setMainImage(downloadData?.displayImageMain)}
              />
              <img
                className={styles["sub_image"]}
                src={`${inAws ? "" : imagesLink}${downloadData?.displayImage1}`}
                alt="sub"
                onClick={() => setMainImage(downloadData?.displayImage1)}
              />
              <img
                className={styles["sub_image"]}
                src={`${inAws ? "" : imagesLink}${downloadData?.displayImage2}`}
                alt="sub"
                onClick={() => setMainImage(downloadData?.displayImage2)}
              />
            </div>
          </div>

          <div className={styles["free_downloadables_container"]}>
            <div className={styles["free_downloadables_container_head"]}>
              <h1 className={styles["downloadables_title"]}>
                {downloadData.title}
              </h1>

              <p className={styles["no_of_activities"]}>
                Number of Activities {downloadData.numberOfActivities}
              </p>
            </div>

            <div className={styles["free_downloadables_container_body"]}>
              <p className={styles["downloadables_description"]}>
                {downloadData.description}
              </p>
            </div>
          </div>

          <FreeProgramFooter
            downloadData={downloadData}
            downloadId={downloadId}
            isLiked={isLiked}
            setIsLiked={setIsLiked}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FreeDownloadProgramPage;
