import { useEffect, useState } from "react";
import styles from "./Loader.module.css";

const Loader = ({
  crrExe = "Please Wait",
  toggleExerciseIntro = () => {},
  loaderText = "Exercise",
  showLoaderFunc = () => {},
  removeLoader = null,
}: any) => {
  const [exit, setExit] = useState<boolean>(false);

  useEffect(() => {
    if (removeLoader === null) {
      setTimeout(() => {
        // toggleExerciseIntro();
        setExit(true);
      }, 3500);
    }
    setTimeout(() => {
      showLoaderFunc();
    }, 4500);

    return () => {};
  }, []);

  useEffect(() => {
    if (removeLoader) {
      setExit(true);
    }
  }, [removeLoader]);

  return (
    <div
      style={{
        backgroundImage: `url(${"/assets/parentLanding/background-cropped.svg"})`,
      }}
      className={`${styles["container"]} animate__animated ${
        exit ? "animate__slideOutUp" : "animate__slideInDown"
      }`}
    >
      <div className={`${styles["text_container"]} `}>
        <div
          style={{ animationDelay: "0.3s" }}
          // className="animate__animated animate__fadeInLeftBig  "
        >
          Loading
        </div>
        <div
          style={{ animationDelay: "0.3s" }}
          // className="animate__animated animate__fadeInRightBig "
        >
          {loaderText}
        </div>
        {/* The below div is for jump grid to give space as it is absolutely positioned it's opacity is 0 */}
        <div className={styles["filler"]}>222</div>
        <div
          style={{ animationDelay: "0.3s" }}
          className={`${styles["jump"]} animate__animated animate__fadeInUpBig `}
        >
          {crrExe
            .toString()
            .split("")
            .map((item: any, index: number) => (
              <span key={index}>{item}</span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Loader;
