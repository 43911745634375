import { IonContent, IonPage } from "@ionic/react";
import styles from "./Invoice.module.css";
import { useEffect, useState } from "react";
import CircleLoader from "../../Parents/Subscription/components/CircleLoader";
import { useLocation } from "react-router";
import axios from "axios";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../configs/ServerConfig";

const Invoice = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoiceData, setInvoiceData] = useState(Object);
  const [userData, setUserData] = useState(Object);
  const location = useLocation();
  const invoiceId = location.pathname.split("/")[3];
  console.log(userData);
  const downloadPdfHandler = () => {
    window.print();
  };

  const getInvoiceData = async (invoiceId: any) => {
    try {
      const response =
        await axios.get(`${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/payment_history?id=${invoiceId}
      `);

      setInvoiceData(response.data[0]);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async (userId: any) => {
    try {
      const response = await axios.get(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/user?id=${userId}`
      );
      setUserData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoiceData(invoiceId);
  }, []);

  useEffect(() => {
    if (invoiceData.userId) {
      getUserData(invoiceData.userId);
    }
  }, [invoiceData]);

  const cgst = (invoiceData.gstAmount / 2).toFixed(2);
  const sgst = (invoiceData.gstAmount / 2).toFixed(2);

  return (
    <IonPage>
      <IonContent>
        <div className={styles["invoice_container"]}>
          {isLoading && <CircleLoader />}
          <div
            style={{
              backgroundImage: `url("/assets/invoice/stripe_invoice_bg.png")`,
            }}
            className={styles["invoice_bg_container"]}
          ></div>

          <div className={styles["invoice_main_container"]}>
            <div className={styles["invoice_action_buttons"]}>
              <button
                onClick={() => downloadPdfHandler()}
                className={styles["download_btn"]}
              >
                <i className="fas fa-download"></i> Download Invoice
              </button>
            </div>

            <div className={styles["invoice_header"]}>
              <div className={styles["invoice_header__company"]}>
                <p className={styles["company_name"]}>
                  THOUGHT RIVER EDUTECH PVT LTD
                </p>
                <p className={styles["invoice_header__company_address"]}>
                  <i className="fas fa-map-marker-alt"></i> 4th Floor, Balaji
                  Business Center, Baner, Pune
                </p>
                <p className={styles["invoice_header__company_address"]}>
                  <i className="fas fa-envelope"></i> finance@braincells.in
                </p>
                <p className={styles["invoice_header__company_address"]}>
                  <i className="fas fa-phone-alt"></i> (+91) 8408003509
                </p>
              </div>
              <div className={styles["invoice_header__company_logo"]}>
                <img
                  src="/assets/logo/brainCells-logo-small.png"
                  alt="Company Logo"
                />
              </div>
            </div>
            <hr className={styles["horizontal_line"]} />

            <div className={styles["invoice_body"]}>
              <div className={styles["invoice_body__header"]}>
                <div
                  className={styles["invoice_body__header_details_container"]}
                >
                  <p className={styles["invoice_body__date"]}>
                    Date: 12/12/2021
                  </p>
                  <h3 className={styles["tax_invoice"]}>TAX INVOICE</h3>
                  <p className={styles["invoice_body__invoice_number"]}>
                    Invoice Number: {`TREPL00${invoiceData.id}`}
                  </p>
                  <p className={styles["invoice_body__invoice_number"]}>
                    GSTIN Number: 27AAJCT3897N1ZC
                  </p>
                </div>

                <div
                  className={styles["invoice_body__header_billto_container"]}
                >
                  <p className={styles["billto_text"]}>Bill To</p>
                  <p className={styles["invoice_body__billto_name"]}>
                    {userData.name}
                  </p>
                  <p className={styles["invoice_body__billto_address"]}>
                    {userData.email}
                  </p>
                  <p className={styles["invoice_body__billto_address"]}>
                    {userData.phone}
                  </p>
                </div>
              </div>

              <hr className={styles["horizontal_line"]} />

              <div className={styles["invoice_body__table_container"]}>
                <table className={styles["invoice_body__table"]}>
                  <thead>
                    <tr>
                      <th>Program</th>
                      <th>SAC</th>
                      <th>SPECIFICATIONS/COMMENTS</th>
                      <th>Unit Price</th>
                      <th>Price before tax</th>
                      <th>CGST</th>
                      <th>SGST</th>
                      <th>Line Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{invoiceData.programName}</td>
                      <td>999299</td>
                      <td>{invoiceData.programName} Program</td>

                      <td>₹ {invoiceData.programAmount}</td>
                      <td>₹ {invoiceData.baseAmount}</td>
                      <td>₹ {cgst} (@ 9%)</td>
                      <td>₹ {sgst} (@ 9%)</td>
                      <td>₹ {invoiceData.paidAmount}</td>
                    </tr>
                    <tr className={styles["table_total_tr"]}>
                      <td colSpan={4}></td>

                      <td>TOTAL</td>
                      <td>₹ {cgst}</td>
                      <td>₹ {sgst}</td>
                      <td>₹ {invoiceData.paidAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className={styles["invoice_body__footer"]}>
                <div className={styles["invoice_body__footer_tax_container"]}>
                  <div className={styles["invoice_body__footer_tax"]}>
                    <p className={styles["footer_tax_registration_text"]}>
                      THOUGHT RIVER EDUTECH PVT LTD TAX REGISTRATION
                    </p>
                    <p>GSTIN: 27AAJCT3897N1ZC</p>
                    <p>PAN: AAJCT3897N</p>
                  </div>
                </div>

                <div
                  className={
                    styles["invoice_body__footer_total_table_container"]
                  }
                >
                  <table className={styles["invoice_body__footer_total_table"]}>
                    <tbody>
                      <tr>
                        <td>Total</td>
                        <td>₹ {invoiceData.baseAmount}</td>
                      </tr>
                      <tr>
                        <td>Total Tax</td>
                        <td>₹ {invoiceData.gstAmount}</td>
                      </tr>
                      <tr>
                        <td>GRAND TOTAL</td>
                        <td>₹ {invoiceData.paidAmount}</td>
                      </tr>
                      <tr>
                        <td>Amount Paid</td>
                        <td>₹ {invoiceData.paidAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Invoice;
