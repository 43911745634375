import React from 'react'
import { IonContent, IonHeader,IonItem,IonCheckbox, IonPage, IonRouterLink, IonTitle, IonDatetime, IonToolbar,IonButton,IonButtons, IonBackButton, IonCard, IonImg, IonCardTitle, IonInput, IonRoute, IonLabel} from '@ionic/react';
import { useState } from 'react';

import styles from './SetSkills.module.css';

function SetSkills(props:any) {
    
    const [checkBoxList,setCheckBox]= useState( {
        'Pepperoni': {isChecked: true },
        'Sausage':{ isChecked: false },
     'Mushroom':{ isChecked: false },
     'C++':{ isChecked: false },
     'java':{ isChecked: false },
     'y':{ isChecked: false },
     'rt':{ isChecked: false },
     'gf':{ isChecked: false },
     'gg':{ isChecked: false },
     'ht':{ isChecked: false },
     'yu':{ isChecked: false },
     'rr':{ isChecked: false },
     'ew':{ isChecked: false },
     'ty':{ isChecked: false },
     'ee':{ isChecked: false },
     'ef':{ isChecked: false },
     'eg':{ isChecked: false },
     'eh':{ isChecked: false },
     'ey':{ isChecked: false }
     } );

    function changeCheckBox(val:any){
        let x = checkBoxList;
        let id = val.target.id;
        let checked = val.target.checked
        
        
        // setCheckBox(x);
    }
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
      <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>Profile Creation</IonTitle>
      </IonToolbar> 
    </IonHeader>
    <IonContent fullscreen style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      
      <div className={styles.mainContainer} >

        <div className={styles.creationContainer}>
          <h1> Select Skills </h1>
          <div className={styles.checklist} >
          
              {Object.keys(checkBoxList).map((box:any)=>{
                    return(
                      <div className={styles.checkBox} >
                        <IonCheckbox color='primary' onIonChange={changeCheckBox} id={box} />
                        <IonLabel>{box}</IonLabel>
                      </div>
                       )
              })}
          
          </div>
            <IonButton href = '/profile-creation/build-profile' color="primary" style={{fontSize:'150%'}} size='large' > Build My Child's Personalized Curriculum </IonButton>
        </div>
        
      </div>
      
    </IonContent>
  </IonPage>
  )
}

export default SetSkills





