import { useEffect, useState } from "react";
import styles from "./StorytellingProgramPage.module.css";
import { IonContent, IonPage } from "@ionic/react";
import { getHttpData } from "../../../../utils/http-util";
import CircleLoader from "../../../Parents/Subscription/components/CircleLoader";
import { useHistory } from "react-router";
import StoryBook from "../../../../components/Activity/Activities/StoryBook/Components/StoryBook/StoryBook";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { imagesLink } from "../../../../constants/Services/services-constants";
export const BACK_BUTTON: string = "/assets/program/back button.svg";

const StorytellingProgramPage = () => {
  const path = window.location.pathname.split("/");
  const history = useHistory();
  const storyId = path[path.length - 1];
  // states
  const [downloadData, setDownloadData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState<any>(
    downloadData.displayImageMain || ""
  );

  return (
    <IonPage>
      <IonContent>
        <div className={styles["free_downloadables_page"]}>
          {isLoading && <CircleLoader />}
          <div
            onClick={history.goBack}
            className={styles["back_button_container"]}
          >
            <img src={BACK_BUTTON} alt="Go Back" />
          </div>

          <div className={styles["images_container"]}>
            <div className={styles["main_image_container"]}>
              <img
                className={styles["main_image"]}
                src={`${imagesLink}${
                  mainImage === "" ? downloadData?.displayImageMain : mainImage
                }`}
                alt="main"
              />
            </div>

            <div className={styles["sub_images_container"]}>
              <img
                className={styles["sub_image"]}
                src={`${imagesLink}${downloadData?.displayImageMain}`}
                alt="sub"
                onClick={() => setMainImage(downloadData?.displayImageMain)}
              />
              <img
                className={styles["sub_image"]}
                src={`${imagesLink}${downloadData?.displayImage1}`}
                alt="sub"
                onClick={() => setMainImage(downloadData?.displayImage1)}
              />
              <img
                className={styles["sub_image"]}
                src={`${imagesLink}${downloadData?.displayImage2}`}
                alt="sub"
                onClick={() => setMainImage(downloadData?.displayImage2)}
              />
            </div>
          </div>

          <div className={styles["free_downloadables_container"]}>
            <div className={styles["free_downloadables_container_head"]}>
              <h1 className={styles["downloadables_title"]}>
                {downloadData.title}
              </h1>

              <p className={styles["no_of_activities"]}>
                Number of Activities {downloadData.numberOfActivities}
              </p>
            </div>

            <div className={styles["free_downloadables_container_body"]}>
              <p className={styles["downloadables_description"]}>
                {downloadData.description}
              </p>
            </div>

            <div className={styles["like_container"]}>
              <i className={`fas fa-heart ${styles["like_icon"]}`}></i>
              <p className={styles["like_count"]}>
                {downloadData.numberOfLikes}
              </p>
            </div>

            <div className={styles["action_btn_container"]}>
              <button className={styles["action_btn_download"]}>
                <i className="fas fa-download"></i> Get Stories
              </button>

              <button className={styles["action_btn_share"]}>Share</button>

              <button className={styles["action_btn_like"]}>Like</button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default StorytellingProgramPage;
