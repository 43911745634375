import React, { useEffect } from "react";

const Tawk = () => {
  useEffect(() => {
    const { ageGroup, cohort, name } = JSON.parse(
      localStorage.getItem("USER_DATA") || "{}"
    );
    var Tawk_API: any = Tawk_API || {},
      Tawk_LoadStart = new Date();
    Tawk_API.visitor = {
      name: `${name}- ${cohort}- ${ageGroup}`,
    };
    (function () {
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/60afe3276699c7280da94c15/1f6ngukpf";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.insertAdjacentElement("beforebegin", s1);
    })();
  }, []);

  return <div></div>;
};

export default Tawk;
