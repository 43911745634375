import { forwardRef, useEffect, useRef, useState } from "react";
import { useUserContext } from "../../../../../../store/context/user-context";
import { getTTSAudio } from "../../../../../../utils/http-util";
import styles from "./StoryBookPage.module.css";

const StoryBookPage = forwardRef((props: any, ref: any) => {
  const {
    page,
    page: {
      hardcover,
      pageVideo,
      pageImage,
      backCover,
      pageAction,
      otherStories,
    },
    bookArea: { width, height },
    index,
    crrPage,
    setCrrPage,
    setIsPlaying,
    isPlaying,
    createAudioObject,
    createWebAudioBuffer,
    isTurning,
    selectedLanguage,
    autoplay,
    startNewStory,
    audioPlayVisible,
    isAndroid,
    applyBlackTint,
  } = props;
  //CONTEXT
  const {
    user: { childName },
  } = useUserContext();
  //STATE
  const [dialogueAudio, setDialogueAudio] = useState<any>();
  // const [audioState] = useState<any>(
  //   new Audio(page[`${selectedLanguage}Dialogue`])
  // );
  //! TEST
  // const isAndroid = true;
  //REF
  const videoRef = useRef<any>();
  const audioRef = useRef<any>();
  const pageActionAudioRef = useRef<any>({});
  const dialogueAudioRef = useRef<any>();
  //CONSTANTS
  let playAudioClicked = false;
  //USE EFFECTS
  useEffect(() => {
    if (hardcover) {
      //TTS AUDIO
    } else {
      if (isAndroid) {
        getAndroidDialogueAudio();
      } else {
        getDialogueAudio();
      }
    }
    if (pageAction) {
      getPageActionAudio();
    }
    return () => {
      dialogueAudioRef.current?.pause();
      if (audioRef.current) {
        audioRef?.current?.stop();
      }
    };
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      if (isTurning) {
        if (inRange()) {
          videoRef.current.muted = true;
          videoRef.current.loop = true;
          //   setTimeout(() => {}, 10 * (Math.abs(crrPage - index) + index * 5));
          videoRef.current.play();
        }
      } else {
        if (isPlaying.pageNumber !== index) {
          videoRef.current.pause();
        }
      }
    }
  }, [isTurning]);

  useEffect(() => {
    if (videoRef.current) {
      if (index === 0) {
        if (crrPage === 0) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      } else {
        if (isPlaying.pageNumber !== index) {
          videoRef.current.pause();
        }
      }
    }
  }, [crrPage]);

  //FUNCTIONS
  const getPageActionAudio = async () => {
    const pageAudio = await getTTSAudio(pageAction[selectedLanguage]);
    pageActionAudioRef.current["action"] = pageAudio;
  };

  const getAndroidDialogueAudio = async () => {
    dialogueAudioRef.current = await new Audio(
      page[`${selectedLanguage}Dialogue`]
    );
  };

  const getDialogueAudio = async () => {
    const dialogueAudio = await createWebAudioBuffer(
      page[`${selectedLanguage}Dialogue`]
    );
    setDialogueAudio(dialogueAudio);
  };
  const inRange = () => {
    // return index >= crrPage - 2 && index <= crrPage + 3;
    return crrPage + 1 === index || crrPage + 2 === index;
  };

  const inRangeVideoVisible = () => {
    return index >= crrPage - 2 && index <= crrPage + 3;
  };

  const otherStoryClick = (storyId: any) => {
    startNewStory(storyId);
  };

  const isEven = index % 2 === 0;

  const playAudio = async () => {
    if (hardcover) {
      if (videoRef.current) {
        videoRef.current.play();
      }
      const ttsText = `${
        selectedLanguage === "english"
          ? `Hey ${childName}`
          : `Hello ${childName}`
      } ${page[`${selectedLanguage}SpeakText`].join(" ")}`;

      if (!isPlaying.dialogue) {
        setIsPlaying({ pageNumber: index, dialogue: true });
        const ttsAudio = await getTTSAudio(
          ttsText,
          selectedLanguage.toUpperCase()
        );
        ttsAudio.playbackRate = 0.85;
        ttsAudio.onended = () => {
          setIsPlaying((prev: any) => {
            return { ...prev, dialogue: false };
          });
        };
        ttsAudio.play();
      }
    } else {
      playAudioClicked = true;
      if (isAndroid) {
        dialogueAudioRef.current.onended = () => {
          setIsPlaying((prev: any) => {
            return { ...prev, dialogue: false };
          });
          if (playAudioClicked) {
            setCrrPage(index + 1);
          }
          if (pageVideo) {
            videoRef.current.pause();
          }
        };
        videoRef.current.play();
        dialogueAudioRef.current.play();
      } else {
        audioRef.current = await createAudioObject(dialogueAudio);
        audioRef.current.start();
        audioRef.current.onended = () => {
          setIsPlaying((prev: any) => {
            return { ...prev, dialogue: false };
          });
          if (playAudioClicked) {
            setCrrPage(index + 1);
          }
          if (pageVideo && videoRef.current) {
            videoRef.current.pause();
          }
        };
      }
      if (pageVideo) {
        videoRef.current.currentTime = 0;
        // videoRef.current.volume = 0.2;
        videoRef.current.muted = false;
        // videoRef.current.loop = false;
        videoRef.current.play();
      }

      setIsPlaying({
        dialogue: true,
        pageNumber: index,
      });
      setCrrPage(index);
    }
  };

  const playPageAction = async () => {
    pageActionAudioRef.current.action.currentTime = 0;
    pageActionAudioRef.current.action.onended = () => {
      setIsPlaying((prev: any) => {
        return { ...prev, dialogue: false };
      });
    };
    try {
      await pageActionAudioRef.current.action.play();
      setIsPlaying({ pageNumber: index, dialogue: false });
    } catch (err) {}
  };

  const img = otherStories ? otherStories[0] : "";
  // ${
  //   index % 2 === 0 ? styles["right_page"] : styles["left_page"]
  // }

  return (
    <div ref={ref} className={`${styles["page_wrapper"]} `}>
      {pageImage ? (
        <div className={styles["img_wrapper"]}>
          <img
            className={styles["book_background_img"]}
            // src={`${imagesLink}${page?.pageImage}`}
            src={`${page?.pageImage}`}
            alt=""
          />
          <div className={styles["recommendation_img_wrapper"]}>
            {
              // <img
              //   key={index}
              //   onClick={() => {
              //     otherStoryClick(img.id);
              //   }}
              //   alt="other stories"
              //   className={styles["story_image"]}
              //   src={img.coverImage}
              // />
            }
            {/* {otherStories &&
              otherStories.map((img: any, index: any) => {
                return (
                  <img
                    key={index}
                    onClick={() => {
                      otherStoryClick(img.id);
                    }}
                    alt="other stories"
                    className={styles["story_image"]}
                    src={img.coverImage}
                  />
                );
              })} */}
          </div>
        </div>
      ) : (
        <video
          ref={videoRef}
          className={styles["video"]}
          width={width}
          height={height}
          autoPlay={autoplay}
          playsInline
          loop={index > 0}
          // muted
        >
          {inRangeVideoVisible() && (
            <source src={`${pageVideo}#t=0.1`} type="video/mp4" />
          )}
        </video>
      )}
      {audioPlayVisible && (
        <>
          <div className={styles["interaction_button"]}>
            {!backCover && (
              <i onClick={playAudio} className="fas fa-volume-up"></i>
            )}
          </div>
          {pageAction && (
            <i
              onClick={playPageAction}
              style={isEven ? { left: "5px" } : { right: "5px" }}
              className={`${styles["page_action"]} fas fa-running`}
            ></i>
          )}
        </>
      )}
      {applyBlackTint === index && <div className={styles["page_tint"]}></div>}
    </div>
  );
});

export default StoryBookPage;
