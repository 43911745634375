import { imagesLink } from "../../../../constants/Services/services-constants";
import styles from "./ViewDetailsModal.module.css";

export interface Props {
  modalDetails: [any, Function];
  selectedTab: any;
}

const ViewDetailsModal = (props: Props) => {
  const [detailsModalData, setDetailsModalData] = props.modalDetails;
  const selectedTab = props.selectedTab;

  const data = detailsModalData.data;

  return (
    <div className={styles.tncModalBackground}>
      <div
        onClick={() =>
          setDetailsModalData({ ...detailsModalData, showModal: false })
        }
        className={styles.closeModalBtn}
      >
        <i className="fas fa-times-circle"></i>
      </div>
      <img
        src={
          selectedTab === "Selected-Topics"
            ? `${imagesLink}${data.bookedTopicInfoImage}`
            : `${imagesLink}${data.topicInfoImage}`
        }
        alt={data.topicInfoImage}
      />
      {selectedTab === "Selected-Topics" && (
        <a
          className={styles["start_session_btn"]}
          href={`/rtt-online/exercise/${data.eventId}`}
        >
          Start Session
        </a>
      )}
    </div>
  );
};

export default ViewDetailsModal;
