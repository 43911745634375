/** @format */

import moment from "moment";
import { useUserContext } from "../../../../store/context/user-context";
import ProfilePictureRound from "../ProfilePictureRound/ProfilePictureRound";
import styles from "./SinglePost.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import { usePostCreator } from "../../../../hooks/usePostDataCreator";
import { useRef, useState } from "react";
import { getUUID, timeStampToDate } from "../../../../utils/general-utils";
import { cloudinaryAvatarLink } from "../../../../constants/Services/services-constants";
import { ImCross } from "react-icons/im";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import UsersLikeCircle from "../UsersLikesCircle/UsersLikeCircle";
import { EditPostModal } from "./components/EditPostModal";
import MedalModal from "../MedalModal/MedalModal";

const SinglePost = ({ postData, onPostClick = () => {} }: any) => {
  const {
    userName,
    createdAt,
    title,
    description,
    likes,
    comments,
    activityDetails,
    customImages,
    userId,
    _id: { $oid },
  } = postData;
  const userCtx = useUserContext();
  const user = userCtx.user;
  // const imageArray = postData["customImages"];
  const [showEditPost, setShowEditPost] = useState(false);
  const [customUploadImagesArr, setCustomUploadImagesArr] = useState<any>([
    ...customImages,
  ]);
  const [likesArr, setLikesArr] = useState<any>(likes);
  const [commentsArr, setCommentsArr] = useState<any>(comments);

  const achievement_medal =
    activityDetails.length >= 3 && activityDetails.length < 6
      ? "bronze-medal"
      : activityDetails.length >= 6 && activityDetails.length < 9
      ? "silver-medal"
      : activityDetails.length >= 9
      ? "gold-medal"
      : "no-medal";

  const [medalModal, setMedalModal] = useState({
    showMedalModal: false,
    medalData: postData,
    medalType: achievement_medal,
  });

  const closeMedalModal = () => {
    setMedalModal({
      ...medalModal,
      showMedalModal: false,
    });
  };

  const { postCreator, likePost, unlikePost, addComment } = usePostCreator();
  const [isLiked, setIsLiked] = useState(
    postData.likes.filter((like: any) => {
      return `${user.id}` === like.userId;
    }).length > 0
  );
  const [wasLiked, setWasLiked] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const editable = `${user.id}` === userId;
  const likeClickHandler = (e: any) => {
    e.stopPropagation();

    if (isLiked) {
      // unlikePost(postData);
      // setIsLiked(false);
    } else {
      likePost(`${$oid}`, likes);
      setLikesArr((prev: any) => {
        return [
          {
            userId: `${user.id}`,
            type: `like`,
            userName: user.name,
            createdAt: moment().unix(),
          },
          ...prev,
        ];
      });
      setWasLiked(true);
      setIsLiked(true);
    }
  };

  const addPost = () => {
    setShowEditPost(true);
  };

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const commentClickHandler = (e: any) => {
    e.stopPropagation();

    setShowCommentBox(true);
  };

  // const dateString = moment
  //   .unix(postData["createdAt"])
  //   .format("h:mm A, dddd, MMMM Do, YYYY");

  const dateString = timeStampToDate(postData["createdAt"]);

  const hideCommentBox = () => {
    setShowCommentBox(false);
  };

  const addCustomPostImage = (imagePostData: any) => {
    setCustomUploadImagesArr((prev: any) => {
      return [...prev, ...imagePostData];
    });
  };
  const totalSeconds = activityDetails.reduce((acc: any, curr: any) => {
    return acc + curr.timeSpent;
  }, 0);

  const duration: any = moment.duration(Number(totalSeconds) || 60, "seconds");

  const timeSpent = `${duration.minutes()} min : ${duration.seconds()} sec`;

  const postTitle = `I have completed the ${title} activity on BrainCells App. Check it out!`;

  return (
    <div className={styles["single_post"]}>
      {showCommentBox && (
        <CommentBox
          hideCommentBox={hideCommentBox}
          postId={$oid}
          commentsArr={commentsArr}
          setCommentsArr={setCommentsArr}
          profilePictureLink={`${cloudinaryAvatarLink}${userId}`}
          name={postData["userName"]}
          date={dateString.date}
          time={dateString.time}
        />
      )}
      {medalModal.showMedalModal && (
        <MedalModal
          medalModal={medalModal}
          closeMedalModal={() => closeMedalModal()}
        />
      )}
      <div className={styles["post_header"]}>
        <div className={styles["post_header__profile_picture"]}>
          <ProfilePictureRound
            profilePictureLink={`${cloudinaryAvatarLink}${userId}`}
            userId={userId}
          />
        </div>
        <div className={styles["post_header__content"]}>
          <p className={styles["post_header__content__username"]}>
            {postData["childName"] ? (
              <>
                {`${postData["childName"]}`}
                <p className={styles["post_header_content_username_parent"]}>
                  {`${
                    postData["userName"] && ` child of ${postData["userName"]}`
                  }`}
                </p>
              </>
            ) : (
              postData["userName"]
            )}
          </p>
          <p className={styles["post_header__content__dateTime"]}>
            {`${dateString.date} at ${dateString.time}`}
          </p>
        </div>
        {editable && (
          <div onClick={addPost} className={styles["post_edit_button"]}>
            <i className="fas fa-ellipsis-h"></i>
          </div>
        )}
        {editable && showEditPost && (
          <EditPostModal
            post={postData}
            onClose={() => {
              setShowEditPost(false);
            }}
            addCustomPostImage={addCustomPostImage}
          />
        )}
      </div>
      <div className={styles["activityInfo"]}>
        <div className={styles["activityInfo__activity_stats_container"]}>
          <div className={styles["activityInfo__totalActivities__container"]}>
            <p className={styles["activityInfo__totalActivities"]}>
              Activities
            </p>
            <p className={styles["activityInfo__totalActivities"]}>Performed</p>
            <p className={styles["activityInfo__totalActivities"]}>
              {activityDetails.length}
            </p>
          </div>

          {/* <p className={styles["activityInfo__totalActivities"]}>
            Activities Date and Time:
            <div>January 6, 2023 at 9:00 AM</div>
          </p> */}
        </div>

        <hr className={styles["vertical_line"]} />
        <div className={styles["activityInfo__totalActivities__container"]}>
          <p className={styles["activityInfo__totalActivities"]}>Time Taken</p>
          <p className={styles["activityInfo__totalActivities_time_spent"]}>
            {timeSpent}
          </p>
        </div>

        <hr className={styles["vertical_line"]} />

        <div className={styles["activityInfo__achievement__container"]}>
          <p>Achievements</p>
          {achievement_medal === "no-medal" ? (
            <div className={styles["activityInfo__achievement"]}>
              Complete more activities
            </div>
          ) : (
            <div
              onClick={() =>
                setMedalModal({
                  showMedalModal: true,
                  medalData: postData,
                  medalType: achievement_medal,
                })
              }
              className={styles["activityInfo__achievement"]}
            >
              <img
                className={styles["achivement_medal"]}
                src={`/assets/community/${achievement_medal}.png`}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles["post_description_container"]}>
        {/* <p className={styles["post_description_container__title"]}>{title}</p> */}
        {/* <p className={styles["post_description_container__descrition"]}>
          {description}
        </p> */}
      </div>
      <div className={styles["post_images"]}>
        <Swiper
          navigation={true}
          modules={[Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView={1}
          className="mySwiper"
        >
          {[...customUploadImagesArr, ...activityDetails].map(
            (activityData: any) => {
              return (
                <SwiperSlide key={getUUID()}>
                  <div className={styles["swiper_slide_wrapper"]}>
                    {activityData["activityName"] && (
                      <p className={styles["post_images__text"]}>
                        {activityData["activityName"]}
                      </p>
                    )}
                    <img
                      className={styles["post_images__single_image"]}
                      src={activityData["imageUrl"]}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
              );
            }
          )}

          {/* {activityImageArray.map((image: any) => {
            return (
              <SwiperSlide key={getUUID()}>
                <img
                  className={styles["post_images__single_image"]}
                  src={image}
                  alt=""
                />
              </SwiperSlide>
            );
          })} */}
        </Swiper>
      </div>

      {isOpen && (
        <div className={styles["modal-wrapper"]}>
          <div className={styles["modal"]}>
            <div className={styles["modal-header"]}>
              <h3>Share on</h3>
              <button onClick={closeModal}>
                <ImCross />
              </button>
            </div>

            <div className={styles["modal-header-main"]}>
              <TwitterShareButton
                title={postTitle}
                via="ayush"
                hashtags={[]}
                related={["related"]}
                url={`program.braincells.in/community/post/${$oid}`}
              >
                {/* <FacebookIcon /> */}
                <TwitterIcon />
              </TwitterShareButton>

              <FacebookShareButton
                title={postTitle}
                url={`program.braincells.in/community/post/${$oid}`}
              >
                {/* <FacebookIcon /> */}
                <FacebookIcon />
              </FacebookShareButton>

              <WhatsappShareButton
                title={postTitle}
                url={`program.braincells.in/community/post/${$oid}`}
              >
                {/* <FacebookIcon /> */}
                <WhatsappIcon />
              </WhatsappShareButton>

              <TelegramShareButton
                title={postTitle}
                url={`program.braincells.in/community/post/${$oid}`}
              >
                {/* <FacebookIcon /> */}
                <TelegramIcon />
              </TelegramShareButton>

              <EmailShareButton
                title={postTitle}
                url={`program.braincells.in/community/post/${$oid}`}
              >
                {/* <FacebookIcon /> */}
                <EmailIcon />
              </EmailShareButton>
            </div>
            <div className={styles["modal-footer"]}>
              {/* <button
                onClick={() => {
                  closeModal();
                }}>
                Close
              </button> */}
            </div>
          </div>
        </div>
      )}

      <div className={styles["liked_people"]}>
        <UsersLikeCircle likesArray={likesArr} />
      </div>
      <div className={styles["interaction_buttons"]}>
        {isLiked ? (
          <button
            onClick={(e) => likeClickHandler(e)}
            className={`${styles["interaction_buttons__clap__liked"]} animate__animated animate__heartBeat`}
          >
            {/* <i className="fa fa-solid fa-thumbs-up fa-lg"></i> */}
            <img
              className={styles["clap-img"]}
              src="/assets/community/clap-solid.png"
              alt="clapped"
            />
          </button>
        ) : (
          <button
            onClick={(e) => likeClickHandler(e)}
            className={styles["interaction_buttons__clap"]}
          >
            {/* <i className="far fa-thumbs-up fa-lg"></i> */}
            <img
              className={styles["clap-img"]}
              src="/assets/community/clap-outline.png"
              alt="clapped"
            />
          </button>
        )}

        <hr className={styles["vertical_line"]} />

        <button
          onClick={(e) => commentClickHandler(e)}
          className={styles["interaction_buttons__comment"]}
        >
          <i className="far fa-comment-dots"></i>{" "}
        </button>

        <hr className={styles["vertical_line"]} />

        <button
          className={styles["interaction_buttons__share"]}
          onClick={() => setIsOpen(true)}
        >
          <i className="far fa-share-square"></i>
        </button>
      </div>
    </div>
  );
};

const CommentBox = ({
  commentsArr,
  hideCommentBox,
  postId,
  setCommentsArr,
  profilePictureLink,
  name,
  date,
  time,
}: any) => {
  const { addComment } = usePostCreator();
  const commentInputRef = useRef<any>(null);
  const submitComment = () => {
    const comment: string = `${commentInputRef.current.value}`;
    if (comment.length > 0 && comment.length < 500) {
      addComment(
        commentsArr,
        commentInputRef.current.value,
        postId,
        setCommentsArr
      );
    }
  };

  return (
    <div className={styles["comment_box_background"]}>
      <div
        className={`${styles["comment_box_wrapper"]} animate__animated animate__fadeInUp`}
      >
        <div className={styles["comment_box"]}>
          {/* <div className={styles["comment_box-header"]}>
            {" "}
            <img
              src={profilePictureLink}
              alt="profile-pic"
              className={styles["comment_box_img"]}
            />
            <div className={styles["comment_box_name"]}>
              {" "}
              <h2>{name + "   "} </h2>
              <div className={styles["comment_box-header"]}>
                <p>{date}</p>
                <p>at</p>
                <p> {time}</p>
              </div>
            </div>
          </div> */}

          <i
            onClick={hideCommentBox}
            className={`fas fa-times ${styles["comment_box_wrapper__close"]}`}
          ></i>
          {commentsArr.length === 0 ? (
            <div className={styles["comment_box__all_comments"]}>
              Be the first to comment
            </div>
          ) : (
            <div className={styles["comment_box__all_comments"]}>
              {commentsArr.map((comment: any, index: any) => {
                const { date, time } = timeStampToDate(comment.createdAt);
                console.log(comment);
                return (
                  <div className={styles["comment_box-header"]}>
                    <img
                      src={`${cloudinaryAvatarLink}${comment.userId}`}
                      alt=""
                      className={styles["comment_img"]}
                      onError={(e: any) => {
                        e.target.src = "/assets/community/people.png";
                      }}
                    />
                    <div>
                      {" "}
                      <h2>{name}</h2>
                      <p
                        key={`${index}${comment.userId}`}
                        className={styles["comment_box_single_comment1"]}
                      >
                        {comment.comment}
                      </p>
                      <p>
                        {`${date} at
                        ${time}`}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles["comment_box__add_comment"]}>
            <input
              className={styles["comment_box__add_comment__input"]}
              ref={commentInputRef}
              type="text"
              placeholder="Add a comment"
            />
            <button
              className={styles["comment_box__submit_btn"]}
              onClick={submitComment}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
