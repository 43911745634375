import { useEffect, useRef, useState } from "react";
import { getHttpData } from "../../../../utils/http-util";
import { CommunityLoader } from "../../../generalComponents/loaders/CommunityLoader/CommunityLoader";
import StoryBook from "./Components/StoryBook/StoryBook";
import styles from "./StoryBookActivity.module.css";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const StoryBookActivity = ({
  closeActivity,
  unlockedStoryId,
  openLockedModal,
  storyId,
}: any) => {
  //STATE
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [language, setLanguage] = useState<any>("english");
  const [showMenuButton, setShowMenuButton] = useState<boolean>(true);
  //REFS
  const storyJsonRef: any = useRef();
  const currentStoryIdRef: any = useRef(storyId);
  //FUNCTIONS
  const fetchActivityData = async (storyId: any) => {
    let path = `https://api.braincells.in:8080/story_telling_json?story=Story-${storyId}&json=story-${storyId}`;
    const storyJson = await getHttpData(path);
    return storyJson;
  };

  const startStorybook = async (storyId: any = null) => {
    if (storyId) {
      currentStoryIdRef.current = storyId;
    }
    setIsLoading(true);
    const storyJson = await fetchActivityData(storyId);
    storyJsonRef.current = storyJson.data;
    // storyJsonRef.current = storyId
    //   ? require("./newActivityData.json")
    //   : require("./activityData.json");
    setIsLoading(false);
  };
  //USE EFFECT
  useEffect(() => {
    if (storyId) {
      startStorybook(storyId);
    }
  }, [storyId]);

  const startNewStory = (id: any) => {
    if (
      true
      // unlockedStoryId.includes(id)
    ) {
      startStorybook();
    } else {
      closeActivity();
      openLockedModal();
    }
  };

  const menuBarClick = () => {
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const changeLanguage = (lang: any) => {
    if (lang !== language) {
      setLanguage(lang);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  };

  useEffect(() => {
    if (showMenu) {
      setShowMenuButton(false);
    } else {
      setShowMenuButton(true);
    }
  }, [showMenu]);

  return (
    <div className={styles["activity_wrapper"]}>
      {showMenuButton && (
        <button
          title="menu"
          onClick={menuBarClick}
          className={styles["slider_wrapper"]}
        >
          <i className="fas fa-ellipsis-v"></i>
        </button>
      )}
      {showMenu && (
        <Menu
          changeLanguage={changeLanguage}
          closeMenu={closeMenu}
          language={language}
        />
      )}

      {isLoading ? (
        <div className={styles["loader_wrapper"]}>
          <CommunityLoader />
        </div>
      ) : (
        language && (
          <StoryBook
            selectedLanguage={language}
            activityJson={storyJsonRef.current}
            setShowMenu={setShowMenuButton}
            startNewStory={startNewStory}
          />
        )
      )}
    </div>
  );
};

const Menu = ({ closeMenu, changeLanguage, language }: any) => {
  const languageButtonClick = (lang: any) => {
    changeLanguage(lang);
    closeMenu();
  };

  const title = `Share this story with other parents`;
  const link = `google.com`;

  return (
    <div className={styles["menu_wrapper"]}>
      <div
        className={`${styles["menu"]} animate__animated animate__fadeInLeftBig`}
      >
        {language && (
          <button
            onClick={closeMenu}
            title="menu close"
            className={styles["menu_close"]}
          >
            <i className="fas fa-times"></i>
          </button>
        )}
        <div className={styles["select_lang"]}>
          <label>Select Language</label>
          <div className={styles["lang_options_wrapper"]}>
            <button
              onClick={() => languageButtonClick("english")}
              title="language"
              className={styles["lang_button"]}
            >
              English
            </button>
            <button
              onClick={() => languageButtonClick("hindi")}
              title="hindi"
              className={styles["lang_button"]}
            >
              हिंदी
            </button>
          </div>
        </div>
        <div className={styles["share_container"]}>
          <label>Share with other parents</label>
          <div className={styles["share_buttons"]}>
            <WhatsappShareButton title={title} url={`${link} `}>
              {/* <FacebookIcon /> */}
              <WhatsappIcon borderRadius={7} size={45} />
            </WhatsappShareButton>
            <TwitterShareButton title={title} url={`${link}`}>
              <TwitterIcon borderRadius={7} size={45} />
            </TwitterShareButton>
            <FacebookShareButton title={title} url={`${link}`}>
              {/* <FacebookIcon /> */}
              <FacebookIcon borderRadius={7} size={45} />
            </FacebookShareButton>
            <TelegramShareButton title={title} url={`${link}`}>
              {/* <FacebookIcon /> */}
              <TelegramIcon borderRadius={7} size={45} />
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryBookActivity;
