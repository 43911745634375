/** @format */

import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/react";
import styles from "./ReportHome.module.css";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";

export const ReportsHome = () => {
  const reportPictures = [
    {
      id: uuid(),
      img: "/assets/parentReport/attendance-image.svg",
      textImg: "/assets/parentReport/attendance-button.svg",
      name: "attendence",
      redirect: "attendence",
    },
    {
      id: uuid(),
      img: "/assets/parentReport/interest-image.svg",
      textImg: "/assets/parentReport/interest-button.svg",
      name: "interest",
      redirect: "child-interest",
    },
    {
      id: uuid(),
      img: "/assets/parentReport/brain-function-image.svg",
      textImg: "/assets/parentReport/brain-functions-button.svg",
      name: "brain",
      redirect: "brain-function",
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/parents' />
          </IonButtons>
          <IonTitle>Braincells Report</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div
          style={{
            backgroundImage: `url("/assets/parentLanding/background-cropped.svg")`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
          className={styles.reports}>
          <div className={styles.reportsContainer}>
            {reportPictures.map(({ id, name, img, textImg, redirect }) => {
              return (
                <div
                  className={`${styles.reportImages} ${styles[name]}`}
                  key={id}>
                  <Link to={`reports/${redirect}`}>
                    <img className={styles.reportImage} src={img} alt={name} />
                    <img
                      className={styles.reportTextImage}
                      src={textImg}
                      alt={name}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
