import React, { useState } from "react";
import styles from "./RazorPayment.module.css";
import axios from "axios";
import { useCallback } from "react";
import { useSubscriptionContext } from "../../../../store/context/subscription-context";
import { useUserContext } from "../../../../store/context/user-context";
import { useHistory } from "react-router";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { postHttpData, putHttpData } from "../../../../utils/http-util";
import {
  keyTest,
  keyLive,
  paymentMode,
  rzrpaySecret,
  recurringKeyTest,
  recurringKeyLive,
  recurringRzrpaySecret,
} from "../../../../configs/RazorpayConfig";

export const RazorpayLogic = (props: any) => {
  //      --RAZORPAY USING SCRIPT
  const myJwtToken = localStorage.getItem("USER_TOKEN") || "";
  const subscriptionCtx = useSubscriptionContext();
  const userCtx = useUserContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<any>(false);
  const isRecurringPayment = props.recurringPaymentData?.subscription_id;
  const [paymentIsOpen, setPaymentIsOpen] = props.isRazorpayOpen;
  const loadScript = async (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const programName = props.val.progName;
  const razorPayKey = paymentMode === "TEST" ? keyTest : keyLive;
  const razorPayRecurringKey =
    paymentMode === "TEST" ? recurringKeyTest : recurringKeyLive;
  const showRazorpay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const options = {
      key: isRecurringPayment ? razorPayRecurringKey : razorPayKey,
      secret: isRecurringPayment ? recurringRzrpaySecret : rzrpaySecret,
      amount:
        props.currency === "INR"
          ? props.val.progPrice * 100
          : //doing toFixed bcuz 99 multiplied by 100 shows weird behavior so converting to integer
            (props.val.progPrice * 100).toFixed(0),
      currency: props.currency,
      name: "BrainCells - Thought River EduTech Private Limited",
      description: props.val.progName + "-" + props.val.progDuration + "Month",
      image: require("../../../../assets/logo/brainCells-logo-small.png"),
      ...props.recurringPaymentData,
      handler: (response: any) => {
        (async () => {
          if (isRecurringPayment) {
            //NO POST REQ AS THIS IS RECURRING PAYMENT
            localStorage.setItem(
              "PAYMENT_SUCCESS_DETAILS",
              JSON.stringify({
                PAYMENT_TYPE: "RECURRING",
                PAYMENT_STATUS: true,
              })
            );
            props.paymentDoneRef.current = true;
            history.push(`/parents/subscriptions/paymentsuccess`);
          } else {
            try {
              props.setIsLoading(true);
              const paymentSuccessResponse: any = await postHttpData(
                programName === "Rumble-Tumble-Time"
                  ? `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/rtt_payment`
                  : `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/payment_success`,
                {
                  payment: "success",
                  paymentId: response.razorpay_payment_id,
                  userId: props.val.userId,
                  programId: props.val.programId,
                  programPrice: props.val.programPrice,
                  programDurationVar: props.val.progDuration,
                  subId: props.val.subId,
                  subExpired: "",
                  endDate: props.val.endDate,
                  couponId: props.val.couponId,
                  amountPaid: props.val.progPrice,
                  discountAmount: props.val.discountAmount,
                  coupon: props.val.coupon,
                  programName: `${programName}-${props.val.progDuration}-${
                    props.val.progDuration > 1 ? "MONTHS" : "MONTH"
                  }`,
                }
              );
              if (props.val.couponId) {
                putHttpData(
                  `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon?id=${props.val.couponId}`,
                  {
                    userId: `${userCtx.user.id}`,
                  }
                );
              }
              props.paymentDoneRef.current = true;
              subscriptionCtx.loadSubscriptionContext(
                userCtx.user.id,
                myJwtToken
              );
              localStorage.setItem(
                "PAYMENT_SUCCESS_DETAILS",
                JSON.stringify({
                  ...paymentSuccessResponse,
                  programDuration: props.val.progDuration,
                })
              );
              // props.showPaymentSuccess();
              props.setIsLoading(false);
              history.push(`/parents/subscriptions/paymentsuccess`);
            } catch (err) {
              console.log(err);
            }
          }
        })();
      },
      prefill: {
        name: props.val.clientName,
        email: props.val.clientEmail,
        contact: props.val.clientNumber,
      },

      modal: {
        onDismiss: () => {
          setPaymentIsOpen(false);
        },
      },
    };

    var rzp1 = new (window as any).Razorpay(options);

    if (paymentIsOpen) {
      rzp1.open();
    }

    rzp1.on("payment.failed", function (response: any) {
      alert("Payment Failed, Please try again");
      alert(response.error.code);
      alert(response.error.description);
    });
  };

  if (props.val.progPrice === 0) {
    (async () => {
      try {
        props.setIsLoading(true);
        const paymentSuccessResponse: any = await postHttpData(
          programName === "Rumble-Tumble-Time"
            ? `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/rtt_payment`
            : `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/payment_success`,
          {
            payment: "success",
            paymentId: "",
            userId: props.val.userId,
            programId: props.val.programId,
            programPrice: props.val.programPrice,
            programDurationVar: props.val.progDuration,
            subId: props.val.subId,
            subExpired: "",
            endDate: props.val.endDate,
            couponId: props.val.couponId,
            coupon: props.val.coupon,
            amountPaid: "0",
            discountAmount: 0,
            programName: `${programName}-${props.val.progDuration}-${
              props.val.progDuration > 1 ? "MONTHS" : "MONTH"
            }`,
          }
        );
        props.paymentDoneRef.current = true;
        subscriptionCtx.loadSubscriptionContext(userCtx.user.id, myJwtToken);
        localStorage.setItem(
          "PAYMENT_SUCCESS_DETAILS",
          JSON.stringify({
            ...paymentSuccessResponse,
            programDuration: props.val.progDuration,
          })
        );
        // props.showPaymentSuccess();
        props.setIsLoading(false);
        history.push(`/parents/subscriptions/paymentsuccess`);
      } catch (err) {
        console.log(err);
      }
    })();
  } else {
    showRazorpay();
  }

  return <div></div>;
};
