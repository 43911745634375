export const BACK_BUTTON: string = "/assets/program/back button.svg";
export const COMPLETE_STAR: string = "/assets/program/complete star.svg";
export const EMPTY_STAR: string = "/assets/program/empty star.svg";
export const HALF_STAR: string = "/assets/program/half star.svg";
export const COMPLETED_EXCERCISE_BUTTON: string =
  "/assets/program/completes lesson button.svg";
export const PENDING_EXCERCISE_BUTTON: string =
  "/assets/program/pending lesson button.svg";
export const CURRENT_EXCERCISE_BUTTON: string =
  "/assets/program/current lesson button.svg";
export const UPCOMING_EXCERCISE_BUITTON: string =
  "/assets/program/remaining lesson button.svg";
export const HOLIDAY_BUTTON: string = "/assets/program/holiday.svg";
export const TODAYS_EXERCISE_BUTTON: string = `/assets/program/todays exercise.svg`;
export const TROPHY_BUTTON: string = "/assets/program/trophy.svg";
export const TROPHY_BOX_CLOSED: string = "/assets/program/closed box.svg";
export const TROPHY_BOX_HALF_OPEN: string = "assets/program/half open box.svg";
export const OPEN_BOX: string = "assets/program/open box.svg";
export const PATH_START: string = "/assets/program/path start.svg";
export const PATH_MIDDLE: string = "/assets/program/path middle.svg";
export const PATH_END: string = "/assets/program/path end.svg";
export const PANDA: string = "/assets/program/panda.png";
export const SUBSCRIBE_TO_CONTINUE: string =
  "/assets/program/subscribeToContinue.svg";
export const BUBBLE: string = "/assets/program/bubble.svg";
export const TROPHY_BOX_OPEN: string = "/assets/program/open box.svg";
export const RUMBLE_TUMBLE_GUIDE = "Rumble-Tumble-Guide";
export const RUMBLE_TUMBLE_TIME = "Rumble-Tumble-Time";
export const NOTIFICATION_LAST = "NOTIFICATION_LAST";
export const ZERO_TO_ONE_AVAILABLE_DAYS = "ZERO_TO_ONE_AVAILABLE_DAYS";
