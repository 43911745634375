/** @format */

import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonCard,
  IonImg,
  IonCardContent,
  IonCardHeader,
  IonList,
  IonItem,
} from "@ionic/react";
import styles from "./ChildInterest.module.css";
import BubbleChart from "../BrainFunction/Components/BubbleChart";
export const ChildInterest = () => {
  let avatar = "https://www.pngfind.com/pngs/b/521-5216556_avatar-aang-png.png";
  let interestData = {
    interest: ["Sports", "Cars", "Engineering", "Science", "Animals"],
    professions: ["Cricketer", "Astronaut", "Engineer", "Doctor"],
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>Child Interest Report</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.mainContainer}>
          <BubbleChart />

          {/* <IonCard className={styles.creationContainer}>
          
          {
            interestData['interest'].map((item:any,i)=>{
                  return(<InterestComponent key={i} interest={item} />)
            })
          }
        </IonCard>
        <IonCard className={styles.professionsList}>

            <IonList style={{width:'100%',textAlign:'center'}} >
              {

                interestData['professions'].map((item:any,i)=>{
                  return(
                  <IonItem className={styles.listItem} >
                      <h3> {item} </h3>
                  </IonItem>
                  )
                })
              }
            </IonList>
        </IonCard>

           */}
        </div>
      </IonContent>
    </IonPage>
  );
};

function InterestComponent(props: any) {
  const { key, interest } = props;

  return (
    <IonCard className={styles.interestComponent}>
      <IonCardHeader> {interest} </IonCardHeader>
    </IonCard>
  );
}
