import axios from "axios";
import moment from "moment";
import {
  communityPostLink,
  communityPutLink,
} from "../constants/Services/services-constants";
import { useUserContext } from "../store/context/user-context";
import { postHttpData } from "../utils/http-util";

export const usePostCreator = () => {
  const { user } = useUserContext();
  const { id, name, childName } = user;

  const postCreator = (
    randomImage: any,
    activityName: any,
    timeSpent: any,
    otherImages: any = []
  ) => {
    const newPost = {
      userId: `${id}`,
      userName: name,
      childName: childName,
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      active: "True",
      pinned: "False",
      title: `${activityName}`,
      description: `${name} completed this activity ${activityName}`,
      hashtags: [],
      likes: [],
      comments: [],
      activityDetails: [
        {
          activityName: activityName,
          timeSpent: timeSpent,
          streak: "",
          imageUrl: [randomImage],
        },
        ...otherImages.map((image: any) => {
          return {
            activityName: "",
            timeSpent: 0,
            streak: "",
            imageUrl: [image],
          };
        }),
      ],
      customImages: [],
    };

    postToServer({ ...newPost });
  };

  const addComment = (
    comments: any,
    commentText: any,
    postId: any,
    setCommentsArr: any
  ) => {
    const newComment = {
      userId: id,
      userName: name,
      comment: commentText,
      createdAt: moment().unix(),
    };
    const updatedCommentArr = {
      comments: [...comments, newComment],
    };
    setCommentsArr((prev: any) => {
      return [...prev, newComment];
    });
    updatePost(postId, updatedCommentArr);
  };
  const likePost = (postId: any, likesArr: any) => {
    const likedPost = {
      likes: [
        ...likesArr,
        {
          userId: `${id}`,
          userName: name,
          type: "like",
          createdAt: moment().unix(),
        },
      ],
    };

    updatePost(postId, likedPost);
  };
  const unlikePost = (post: any) => {
    const unlikePost = {
      likes: post.likes.filter((like: any) => like.userId !== `${id}`),
    };
    updatePost(post._id.$oid, unlikePost);
  };

  const addCustomImage = (
    postId: any,
    imageUrlArr: any,
    prevCustomPost: any,
    title: any = ""
  ) => {
    const imageUrlFinalArr = imageUrlArr.map((url: any) => {
      return {
        imageUrl: url,
        createdAt: moment().unix(),
      };
    });

    const customImage = {
      customImages: [...prevCustomPost, ...imageUrlFinalArr],
    };
    updatePost(postId, customImage);
  };

  const postToServer = async (newPost: any) => {
    const res: any = await postHttpData(`${communityPostLink}`, newPost);
    console.log(res);
  };

  const updatePost = async (postId: any, data: any) => {
    const res: any = await axios.put(`${communityPutLink}?_id=${postId}`, data);
    console.log(res);
  };

  return { postCreator, unlikePost, likePost, addComment, addCustomImage };
};
