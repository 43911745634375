import axios from "axios";
import { useState } from "react";
import { cloudinaryUploadPreset } from "../constants/Services/services-constants";
import { useUserContext } from "../store/context/user-context";

const useImageUpload = () => {
  const nonBearerAxios = axios.create();
  const { user } = useUserContext();
  // TESTING CLOUDINARY LINK
  const testingLink = "https://api.cloudinary.com/v1_1/dz83gg4nf/image/upload";

  const [inputImage, setInputImage] = useState<any>();

  const selectImage = (e: any) => {
    console.log(e.target.files);
    setInputImage(e.target.files);
  };

  const cloudinaryMultipleImages = async (imageArr: any) => {
    const imagesKeys = Object.keys(imageArr);
    const imagePromiseArr = imagesKeys.map((imageKey: any) => {
      const image = imageArr[imageKey];
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", cloudinaryUploadPreset);
      data.append("folder", "post");

      return nonBearerAxios.post(`${testingLink}`, data);
    });

    const res = await Promise.all(imagePromiseArr);
    console.log(res);

    return res.map((image: any) => image.data.secure_url);
  };

  const uploadImage = async (image: any, id: any = false) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", cloudinaryUploadPreset);
    if (id) {
      data.append("public_id", `avatar/${id}`);
    } else {
      data.append("folder", "post");
    }
    const cloudinaryRes = await nonBearerAxios.post(`${testingLink}`, data);
    return cloudinaryRes.data.secure_url;
  };

  const postImageUpload = async () => {
    const res = await uploadImage(inputImage);
    return res;
  };

  const uploadMultipleImages = async () => {
    const res = await cloudinaryMultipleImages(inputImage);
    return res;
  };
  const avatarImageUpload = (selectedAvatarLink: any = null) => {
    uploadImage(selectedAvatarLink || inputImage, user.id);
  };

  return {
    postImageUpload,
    selectImage,
    avatarImageUpload,
    uploadMultipleImages,
  };
};

export default useImageUpload;
