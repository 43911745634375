import styles from "./SingleNotification.module.css";
import ProfilePictureRound from "../ProfilePictureRound/ProfilePictureRound";
import { cloudinaryAvatarLink } from "../../../../constants/Services/services-constants";
import { timeStampToDate } from "../../../../utils/general-utils";

const SingleNotification = ({ notificationDetails }: any) => {
  const { type, userId, userName, createdAt } = notificationDetails;
  const { date, time } = timeStampToDate(createdAt);
  return (
    <div className={styles["single_notification"]}>
      <div className={styles["single_notification__profile_pic"]}>
        <ProfilePictureRound
          profilePictureLink={`${cloudinaryAvatarLink}${userId}`}
        />
      </div>
      <div className={styles["single_notification__content_container"]}>
        {type === "like" && (
          <p className={styles["single_notification__content"]}>
            {userName} has liked your post, {time} {date}
          </p>
        )}

        {type === "comment" && (
          <p className={styles["single_notification__content"]}>
            {userName} has commented on your post, {time} {date}
          </p>
        )}
      </div>
    </div>
  );
};

export default SingleNotification;
