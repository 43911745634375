import SinglePost from "../components/SinglePost/SinglePost";
import styles from "./ProfilePage.module.css";
import CommunityNavbar from "../components/CommunityNavbar/CommunityNavbar";
import CommunityFooter from "../components/CommunityFooter/CommunityFooter";
import { useEffect, useState } from "react";
import { getHttpData } from "../../../utils/http-util";
import { useUserContext } from "../../../store/context/user-context";
import { CommunityLoader } from "../../../components/generalComponents/loaders/CommunityLoader/CommunityLoader";
import { communityGetLink } from "../../../constants/Services/services-constants";
import AvatarSelection from "../components/AvatarSelection/AvatarSelection";
import { useCommunityContext } from "../../../store/context/community-context";

const ProfilePage = () => {
  const [userPosts, setUserPosts] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const { user } = useUserContext();

  const communityCtx = useCommunityContext();

  const { communityPosts, getCommunityPosts, avatarDispatch, avatarState } =
    communityCtx;

  useEffect(() => {
    const fetchUserPosts = async () => {
      setLoading(true);
      try {
        const response = await getHttpData(
          `${communityGetLink}?userId=${user.id}`
        );
        setUserPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserPosts();
  }, []);

  return (
    <div className={styles["profile_page"]}>
      <div>
        <CommunityNavbar heading={"My Canvas"} />
      </div>

      <div className={styles["profile_page__my_posts"]}>
        {loading ? (
          <CommunityLoader />
        ) : userPosts.length === 0 ? (
          <div className={styles["notification__zero"]}>
            <i
              className={`fas fa-paint-brush ${styles["profile_page__icon"]}`}
            ></i>
            <h1 className={styles["profile_page__title"]}>
              you have not posted anything
            </h1>
          </div>
        ) : (
          [...userPosts].map((post, index) => {
            return (
              <SinglePost
                key={`${post["_id"]["$oid"]}${index}`}
                postData={post}
              />
            );
          })
        )}
      </div>

      <div>
        <CommunityFooter activeTab={"mycanvas"} />
      </div>

      <div>{avatarState ? <AvatarSelection /> : null}</div>
    </div>
  );
};

export default ProfilePage;
