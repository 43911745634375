import CommunityNavbar from "../components/CommunityNavbar/CommunityNavbar";
import SingleNotification from "../components/SingleNotification/SingleNotification";
import styles from "./NotificationPage.module.css";
import { useEffect, useState } from "react";
import { getHttpData } from "../../../utils/http-util";
import { useUserContext } from "../../../store/context/user-context";
import { CommunityLoader } from "../../../components/generalComponents/loaders/CommunityLoader/CommunityLoader";
import CommunityFooter from "../components/CommunityFooter/CommunityFooter";
import { NOTIFICATION_LAST } from "../../../constants/Program/program-constants";
import { communityGetLink } from "../../../constants/Services/services-constants";

const NotificationPage = () => {
  const [userPosts, setUserPosts] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [finalNotificationArr, setFinalNotificationArr] = useState<any>([]);
  const { user } = useUserContext();
  let mainNotificationArr: any = [];

  useEffect(() => {}, []);

  useEffect(() => {
    const fetchUserPosts = async () => {
      setLoading(true);
      try {
        const response = await getHttpData(
          `${communityGetLink}?userId=${user.id}`
        );
        setUserPosts(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserPosts();
  }, []);

  useEffect(() => {
    const notificationArr: any = [];
    userPosts.forEach((element: any) => {
      element["comments"].forEach((comment: any) => {
        comment["type"] = "comment";
        notificationArr.push(comment);
      });
      element["likes"].forEach((like: any) => {
        like["type"] = "like";
        notificationArr.push(like);
      });
    });
    mainNotificationArr = [...notificationArr];
    setFinalNotificationArr(
      mainNotificationArr.sort((a: any, b: any) => {
        return b["createdAt"] - a["createdAt"];
      })
    );

    localStorage.setItem(NOTIFICATION_LAST, `${mainNotificationArr.length}`);
  }, [userPosts]);

  return (
    <div className={styles["notification_page"]}>
      <div>
        <CommunityNavbar />
      </div>
      <div className={styles["notification_page__notifications"]}>
        {loading ? (
          <CommunityLoader />
        ) : finalNotificationArr.length === 0 ? (
          <div className={styles["notification__zero"]}>
            <i
              className={`far fa-bell ${styles["notification_page__icon"]}`}
            ></i>
            <h1 className={styles["notification_page__title"]}>
              no notifications
            </h1>
          </div>
        ) : (
          finalNotificationArr.map((post: any, index: any) => {
            return (
              <SingleNotification
                key={`${post["createdAt"]}${index}`}
                notificationDetails={{
                  type: post["type"],
                  userId: post["userId"],
                  userName: post["userName"],
                  createdAt: post["createdAt"],
                }}
              />
            );
          })
        )}
      </div>
      <div>
        <CommunityFooter activeTab={"community"} />
      </div>
    </div>
  );
};

export default NotificationPage;
