import { IonPage } from "@ionic/react";
import { useEffect, useRef } from "react";
import Confetti from "react-confetti";
import { useHistory } from "react-router";
import BlockLoader from "../../../../components/generalComponents/loaders/BlockLoader/BlockLoader";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { useSubscriptionContext } from "../../../../store/context/subscription-context";
import { useUserContext } from "../../../../store/context/user-context";
import { getHttpData } from "../../../../utils/http-util";
import styles from "./PaymentSuccess.module.css";
const PaymentSuccessModal = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const subscriptionCtx = useSubscriptionContext();
  const newSubDetails: any = JSON.parse(
    localStorage.getItem("PAYMENT_SUCCESS_DETAILS")!
  );
  const clearIntervalRef = useRef<any>();
  const { ageGroup } = newSubDetails;

  const redirectLink =
    ageGroup === "0-1"
      ? "/program/BrainCells/zeroToOne"
      : `/program/${newSubDetails.programName}`;

  const clickHandler = () => {
    // const { ageGroup } = newSubDetails;
    // if (ageGroup === "0-1") {
    //   history.push(`/program/BrainCells/zeroToOne`);
    // } else {
    //   history.push(`/program/${newSubDetails.programName}`);
    // }
    history.push(`/dashboard`);
  };

  const loadSubscriptionContext = async (userId: number, token: string) => {
    try {
      const response: any = await getHttpData(
        `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/subscription?userId=${userId}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const checkIsRecurringSubCreated = async () => {
    const totalSubs = await loadSubscriptionContext(user.id, "");
    const activeSub = totalSubs.filter((sub: any) => {
      return (
        sub.programName === "BrainCells" &&
        sub.status === "ACTIVE" &&
        sub.recurringPaymentStatus === "LIVE"
      );
    });
    // console.log(activeSub);
    // console.log(clearIntervalRef.current);

    if (activeSub.length > 0) {
      localStorage.setItem(
        "PAYMENT_SUCCESS_DETAILS",
        JSON.stringify({
          PAYMENT_TYPE: "RECURRING",
          PAYMENT_STATUS: false,
        })
      );
      // console.log("cleared");
      clearTimeout(clearIntervalRef.current);
      subscriptionCtx.loadSubscriptionContext(user.id, "");
      clickHandler();
    } else {
      // console.log("called again");
      clearIntervalRef.current = setTimeout(() => {
        checkIsRecurringSubCreated();
      }, 5000);
    }
  };

  useEffect(() => {
    const paymentSuccessData = newSubDetails;
    if (
      paymentSuccessData["PAYMENT_TYPE"] === "RECURRING" &&
      paymentSuccessData["PAYMENT_STATUS"] === true
    ) {
      checkIsRecurringSubCreated();
    }
  }, []);

  return (
    <IonPage>
      <div className={styles["container"]}>
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={450}
          recycle={false}
        />
        <div className={styles["wrapper"]}>
          <p className={styles["top_heading"]}>Congratulations !</p>
          <hr />
          {newSubDetails["PAYMENT_TYPE"] === "RECURRING" &&
          newSubDetails["PAYMENT_STATUS"] === true ? (
            <>
              <p className={styles["heading"]}>
                Please wait for few minutes while your subscription is being
                generated.
              </p>
              <div>
                <BlockLoader />
              </div>
            </>
          ) : (
            <>
              <p className={styles["heading"]}>
                Your subscription of <br></br>{" "}
                <span
                  style={{ fontSize: "2.7rem", lineHeight: "4rem" }}
                  className={styles["duration_span"]}
                >
                  {newSubDetails.programName} {newSubDetails.programDuration}{" "}
                  {`${newSubDetails.programDuration > 1 ? "months" : "month"}`}
                </span>
                <br></br>
                is Active now.
              </p>
              <p className={styles["validity_para"]}>
                Valid until: {newSubDetails.endDate}
              </p>
              <a href={redirectLink}>
                <button
                  className={`${styles["button"]} animate__animated animate__shakeY`}
                  onClick={clickHandler}
                >
                  Go to the Exercise
                </button>
              </a>
            </>
          )}
        </div>
      </div>
    </IonPage>
  );
};

export default PaymentSuccessModal;
