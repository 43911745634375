import { isExerciseCompleted } from "./array-utils";

export const crrActivityJson = (
  activityData: any,
  currentActivity: any,
  programType: any,
  exerciseId: any,
  zeroToOneVersionV2: any = false
) => {
  const activityFile = zeroToOneVersionV2
    ? require("../assets/activityJson/zero-to-one-activity-file.json")
    : require("../assets/activityJson/activity-file.json");
  const selectedActivity = activityFile[currentActivity];
  const { component } = selectedActivity.activity;
  const showIntro =
    !checkNoIntroProgram(programType) || currentActivity === "Mindfullness";
  //For reports

  const activityId = `${exerciseId}_${currentActivity}`;
  return {
    initializer: {
      componentName: selectedActivity?.initializer?.component || null,
      category: selectedActivity?.initializer?.component
        ? "Initializers"
        : null,
    },
    intro: {
      category: "Intros",
      componentName: selectedActivity.intro.component,
      props: {
        introHeading: selectedActivity.name,
        icon: selectedActivity.intro.icon || "fas fa-child",
        activityData,
        currentActivity,
        showIntro,
      },
    },
    activity: {
      category: "Activities",
      pre: selectedActivity.activity.pre || null,
      post: selectedActivity.activity.post || null,
      componentName: component,
      props: { activityData, activityId },
    },
    exit: {
      category: "Exits",
      componentName: selectedActivity.exit.component,
    },
  };
};

export const imgFetchArrCreator = (activityData: any, currentActivity: any) => {
  switch (currentActivity) {
    case "Bit-Cards":
      const bitCardImgArr: string[] = [];
      const imgArrData = [...activityData.bit].map((obj: any) => {
        bitCardImgArr.push(obj.file);
      });
      return bitCardImgArr;
    case "Bit-Cards-Revision":
      const bitRevisionCardImgArr: string[] = [];
      const imgRevisionArrData = [...activityData.bit].map((obj: any) => {
        bitRevisionCardImgArr.push(obj.file);
      });
      return bitRevisionCardImgArr;
    case "Whos-The-Guest":
      let whoTheGuestImgArr: any = [];
      const allActivityImages = [...activityData["whos-the-guest"]].map(
        (obj: any) => {
          whoTheGuestImgArr.push(obj.guest);
          return obj.set.map((img: string) => {
            whoTheGuestImgArr.push(img);
          });
        }
      );
      return whoTheGuestImgArr;
    case "Word-Cards":
      const wordCardImgArr: string[] = [];
      const imgArrDataWord = [...activityData.words].map((obj: any) => {
        wordCardImgArr.push(obj.file);
      });
      return wordCardImgArr;
    case "Linking-Memory":
      const data: any = Object.values(activityData)[0];
      const imgArr = data.map((item: any) => item.file);
      return imgArr;
    case "Peg-Memory":
      const pegData: any = Object.values(activityData)[0];
      const prefetchImgArr: any = [];
      pegData.forEach((item: any) => {
        prefetchImgArr.push(
          item.file,
          item.answerOptions[0],
          item.answerOptions[1]
        );
      });
      return prefetchImgArr;
    case "You-Got-It":
      const youGotItData: any = activityData["you-got-it"];
      const imagesArr: any = [];
      youGotItData.forEach((item: any) => {
        imagesArr.push(item.set[0]);
        imagesArr.push(item.set[1]);
      });
      return imagesArr;
    case "Memory-Grid":
      const activData: any = Object.values(activityData)[0];
      const preFetchImgArr: any = [];
      activData.map((item: any) => {
        preFetchImgArr.push(...item.set);
      });
      return preFetchImgArr;
    case "Word-Play":
      const wordPlayData: any = Object.values(activityData)[0];
      const wordPreFetchImgArr: any = [];
      wordPlayData.forEach((item: any) => {
        wordPreFetchImgArr.push(item["image"], ...item["answerOptions"]);
      });
      return wordPreFetchImgArr;
    case "Life-Cycle":
      const lifeCycleImgArr: String[] = [];
      const activityImgData = [...activityData["life-cycle"]].map(
        (obj: any) => {
          lifeCycleImgArr.push(obj.file);
        }
      );
      return lifeCycleImgArr;
    case "Dot-Cards":
      const dotImgArr: string[] = [];
      const dotImgArrData = [...activityData.dot].map((obj: any) => {
        dotImgArr.push(obj.file);
      });
      return dotImgArr;
    case "Connections":
      const connectionsImgArr: string[] = [];
      const connectionsImgArrData = [...activityData["connections"]].map(
        (obj: any) => {
          connectionsImgArr.push(obj.file);
        }
      );
      return connectionsImgArr;
    case "Geometric-Progression":
      const geoImgArr: string[] = [];
      const geoImgArrData = [...activityData.dot].map((obj: any) => {
        geoImgArr.push(obj.file);
      });
      return geoImgArr;
    case "Quantitude":
      const quantitudeData: any = activityData["you-got-it"];
      const quantitudeImagesArr: any = [];
      quantitudeData.forEach((item: any) => {
        quantitudeImagesArr.push(item.set[0]);
        quantitudeImagesArr.push(item.set[1]);
      });
      return quantitudeImagesArr;
    case "Mandala":
      const mandalaData: any = activityData["mandala"];
      const mandalaImagesArr: any = [mandalaData[0]["colorFile"]];
      mandalaImagesArr.push(mandalaData[0]["blankFile"]);
      return mandalaImagesArr;
      break;
    case "Photo-Play":
      const photoPlayData: any = activityData["photoPlay"];
      return [photoPlayData["backgroundImage"]];
      break;
    default:
      return [];
  }
};

const checkNoIntroProgram = (programType: any) => {
  const noIntroPrograms = [
    "Rumble-Tumble-Time",
    "Rumble-Tumble-Guide",
    "Rumble-Tumble-Time-Online",
  ];
  return noIntroPrograms.includes(programType);
};
export const generateZeroToOnePath = (
  daysArr: any,
  programName: any,
  userExercise: any,
  programExercise: any,
  currentProgramDay: any,
  finalProgramJsonRef: any
) => {
  const programData: any = [];

  for (let i: any = 0; i <= daysArr[daysArr.length - 1]; i++) {
    const programDay = Number(daysArr[i]);
    const exerciseCompleted = isExerciseCompleted(
      programName,
      userExercise,
      programExercise,
      programDay
    );
    // console.log(programDay, currentProgramDay);
    if (i === daysArr.length - 1) {
      programData.push({
        hasEnded: true,
      });
      break;
    } else if (programDay === currentProgramDay) {
      if (finalProgramJsonRef.current[programDay]?.holiday) {
        if (i === 0) {
          programData.push({
            ...finalProgramJsonRef.current[programDay],
            showHoliday: true,
          });
        } else {
          programData.push({
            ...finalProgramJsonRef.current[programDay],
            showHoliday: true,
          });
          break;
        }
      } else {
        programData.push({
          ...finalProgramJsonRef.current[programDay],
          completionStatus: "current",
        });
      }
    } else if (programDay < currentProgramDay) {
      if (finalProgramJsonRef.current[programDay]?.holiday) {
        programData.push({
          ...finalProgramJsonRef.current[programDay],
          showHoliday: true,
        });
        continue;
      } else {
        if (exerciseCompleted) {
          programData.push({
            ...finalProgramJsonRef.current[programDay],
            completionStatus: "completed",
          });
        } else {
          programData.push({
            ...finalProgramJsonRef.current[programDay],
            completionStatus: "pending",
          });
        }
      }
    } else {
      if (finalProgramJsonRef.current[programDay]?.holiday) {
        programData.push({
          ...finalProgramJsonRef.current[programDay],
          showHoliday: true,
        });
        break;
      } else {
        programData.push({
          ...finalProgramJsonRef.current[programDay],
          completionStatus: "upcoming",
        });
      }
    }
  }
  return programData;
};
