import { Redirect, useHistory } from "react-router";
import { Route } from "react-router-dom";
import OrientationChecker from "./generalComponents/OrientationChecker";
// import all hooks
import { useUserContext } from "../store/context/user-context";
import { getHttpData } from "../utils/http-util";
import { useEffect, useState } from "react";
import {
  ACTIVITY_TRACKING_SERVER,
  ACTIVITY_PORT,
} from "../configs/ServerConfig";
import CouponCountdown from "./generalComponents/CouponCountdown/CouponCountdown";
import Tawk from "./generalComponents/Tawk/Tawk";
import { useSubscriptionContext } from "../store/context/subscription-context";

export const ProtectedRoute = ({
  component: Component,
  isLoggedIn,
  path,
  portraitMode = false,
  ...rest
}: any) => {
  const history = useHistory();
  const subscriptionCtx = useSubscriptionContext();
  const {
    user: { id, ageGroup, childBirthDate },
  } = useUserContext();

  const questionException = ["/parents/subscriptions/paymentsuccess"];
  const reloadSubPages = ["/dashboard"];

  const tawkToPages = [
    "/parents",
    "/parents/subscriptions/paymentsuccess",
    "/parents/subscriptions",
    "/parents/subscriptions/coupon/:couponCode",
  ];

  const askUserQuestion = async () => {
    try {
      const res = await getHttpData(
        `${ACTIVITY_TRACKING_SERVER}:${ACTIVITY_PORT}/userInfo?dataType=parents-question&userId=${id}`,
        {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      );

      if (res.data.length > 0) {
      } else {
        history.push("/child-survey");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const isAgeZeroToOne = ageGroup === "0-1";
    if (isAgeZeroToOne && !childBirthDate) {
    } else {
      //Adding this path so that the user is not asked the question when they are on the subscription page
      questionException.push("/parents/subscriptions");
      questionException.push("/parents/subscriptions/coupon/:couponCode");
    }
    if (id && !questionException.includes(path)) {
      askUserQuestion();
    }
  }, [id]);

  useEffect(() => {
    //RELOAD SUBS ON THESE PAGES
    if (reloadSubPages.includes(path)) {
      subscriptionCtx.loadSubscriptionContext(id, "");
    }
  }, []);

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <div style={{ zIndex: 1 }}>
            {!portraitMode && <OrientationChecker />}
            {id && <CouponCountdown />}
            <Component {...props} />
            {tawkToPages.includes(path) && <Tawk />}
          </div>
        ) : (
          <Redirect to="/signin" />
        );
      }}
    />
  );
};
