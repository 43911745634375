import styles from "./CommunityLoader.module.css";

const CommunityLoader = ({ color = "#fc5200" }) => {
  return (
    <div className={styles["community_feed_loading"]}>
      <div className={styles["lds-ellipsis"]}>
        <div
          style={{
            background: color,
          }}
        ></div>
        <div
          style={{
            background: color,
          }}
        ></div>
        <div
          style={{
            background: color,
          }}
        ></div>
        <div
          style={{
            background: color,
          }}
        ></div>
      </div>
    </div>
  );
};

export { CommunityLoader };
