import React, { useEffect, useState } from "react";
import styles from "./DatePicker.module.css";

const DatePicker = ({ dateChangeHandler }: any) => {
  const [date, setDate] = useState({
    day: "",
    month: "",
    year: "",
  });

  const dateDDMMYYYY = `${date.day}/${date.month}/${date.year}`;
  const dateYYYYMMDD = `${date.year}-${date.month}-${date.day}`;

  useEffect(() => {
    if (date.day !== "" && date.month !== "" && date.year !== "") {
      dateChangeHandler(dateDDMMYYYY, dateYYYYMMDD);
    }
  }, [date]);

  return (
    <label
      className={` ${styles["question_two"]} animate__animated animate__slideInRight`}
      htmlFor="date"
    >
      <p className={`${styles["modalQuestions"]}`}>
        <strong>Enter Child's Birth Date</strong>
      </p>

      <div className={styles["dropdown_container"]}>
        <div>
          <label htmlFor="day">Date</label>

          <select
            onChange={(e) => setDate({ ...date, day: e.target.value })}
            name="day"
            id="day"
          >
            <option selected={true} value="-">
              -
            </option>
            {Array(31)
              .fill(0)
              .map((_, i) => {
                return (
                  <option value={i + 1 <= 9 ? `0${i + 1}` : `${i + 1}`}>
                    {i + 1}
                  </option>
                );
              })}
          </select>
        </div>
        <div>
          <label htmlFor="month">Month</label>

          <select
            onChange={(e) => setDate({ ...date, month: e.target.value })}
            name="month"
            id="month"
          >
            <option selected={true} value="-">
              -
            </option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div>
          <label htmlFor="year">Year</label>

          <select
            onChange={(e) => {
              setDate({ ...date, year: e.target.value });
            }}
            name="year"
            id="year"
          >
            <option selected={true} value="-">
              -
            </option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2023">2024</option>
          </select>
        </div>
      </div>
    </label>
  );
};

export default DatePicker;
