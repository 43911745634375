import { tts } from "../utils/Genie";
import { detectIsIOSDevice } from "./general-utils";

const textToSpeech = (
  speech: string,
  lang: string,
  callBackFunc: () => void,
  speed: any = 1
) => {
  const prom = tts(speech, lang);
  prom
    .then((res: any) => {
      let base64MP3 = res.data;
      var snd = new Audio("data:audio/mp3;base64," + base64MP3);
      snd.playbackRate = speed;
      const promise = snd.play();
      snd.onended = function () {
        callBackFunc();
        return;
      };
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export default textToSpeech;
