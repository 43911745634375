import styles from "../Excercise.module.css";

const ActivityCard = ({ activity, currentActivity, isCompleted }: any) => {
  const isSelected = currentActivity.name === activity.name;

  return (
    <div
      style={{
        // border: `${isSelected ? `solid 7px ${color} ` : "none"}`,
        backgroundColor: isCompleted ? "#8DD54F" : "#344966",
      }}
      className={styles["activity_card"]}
    >
      <div className={styles["inner-border"]}>
        <div className={styles["activity-details"]}>
          <i
            className={`${activity.iconClass} ${styles["activity-details-icon"]}`}
          ></i>
          <span className={styles["activity-details-text"]}>
            {activity.name.split("-").join(" ")}
          </span>
        </div>
      </div>
    </div>
  );
};

export { ActivityCard };
