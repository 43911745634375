export const testIp = "http://65.2.34.234:8080";
export const activityTrackerIp = "http://13.232.239.176:8080";
export const imagesLink = "https://course.braincells.in/";
export const ttsLink = "http://65.2.34.234:8081/tts?";
export const cloudinaryAvatarLink =
  "https://res.cloudinary.com/dz83gg4nf/image/upload/avatar/";
export const cloudinaryPostLink =
  "https://res.cloudinary.com/dz83gg4nf/image/upload/post/";
export const communityPostLink =
  process.env.REACT_APP_COMMUNITY_POST_LIVE === "true"
    ? `https://api.braincells.in:8082/community/community_posts`
    : `https://staging2.braincells.in:8082/community/community_posts`;
export const communityPutLink =
  process.env.REACT_APP_COMMUNITY_POST_LIVE === "true"
    ? `https://api.braincells.in:8082/community_posts`
    : `https://staging2.braincells.in:8082/community_posts`;
export const communityGetLink =
  process.env.REACT_APP_COMMUNITY_POST_LIVE === "true"
    ? `https://api.braincells.in:8082/community_posts`
    : `https://staging2.braincells.in:8082/community_posts`;
export const cloudinaryUploadPreset = "xwdomfxh";

// ? THIS IS THE LINK FOR LOCAL HOST FOR TESTING ACTIVITY
export const testLink = "http://localhost:3000/db";
