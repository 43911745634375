import styles from "./Orientation.module.css";

const OrientationChecker = () => {
  return (
    <div className={styles["container"]}>
      <img src="./assets/program/rotate-to-landscape.gif" alt="" />
      <p className={styles["text"]}>Please Rotate your device</p>
      <p className={styles["instructions_text"]}>
        Please turn on <span className={styles["span"]}>Auto Rotate</span> ,{" "}
        <br></br> from your
        <span className={styles["span"]}> Device settings </span>
        <br></br>
        <i className="fas fa-cogs"></i>
      </p>
    </div>
  );
};

export default OrientationChecker;
