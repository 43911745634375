import axios from "axios";

const addAxiosInterceptor = () => {
  axios.interceptors.request.use(
    (config: any) => {
      const token = localStorage.getItem("USER_TOKEN");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

export default addAxiosInterceptor;
