import { useEffect, useRef, useState } from "react";
import ReactConfetti from "react-confetti";
import { useLocation, useParams } from "react-router";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
} from "../../../../configs/ServerConfig";
import { useUserContext } from "../../../../store/context/user-context";
import { getHttpData } from "../../../../utils/http-util";
import styles from "./Coupon.module.css";

const CouponLogic = ({ paymentDetails, setPaymentDetails, crrId = 1 }: any) => {
  const userCtx = useUserContext();
  const params: any = useParams();
  const search = useLocation().search;
  const coupon = new URLSearchParams(search).get("c");
  const type = new URLSearchParams(search).get("ct");
  const [showCoupon, setShowCoupon] = useState<any>(false);
  const [couponError, setCouponError] = useState({
    isError: false,
    message: "Applied Coupon is NOT valid",
  });
  const appliedCoupon: any = useRef();
  useEffect(() => {
    if (type) {
      couponHandler(params.couponCode || coupon);
    } else if (params.couponCode || coupon) {
      couponHandler(params.couponCode || coupon, true);
    }
  }, []);
  const couponHandler = async (
    couponCode: string,
    isCouponId: boolean = false
  ) => {
    try {
      const firstResponse: any = await Promise.all([
        getHttpData(`${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon`, {
          params: {
            [isCouponId ? "id" : "code"]: couponCode,
            userId: userCtx.user.id,
            status: "ASSIGNED",
          },
        }),
        getHttpData(`${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/coupon`, {
          params: {
            code: couponCode,
            usageType: "GENERIC",
            status: "ASSIGNED",
          },
        }),
      ]);

      const response = [...firstResponse[0].data, ...firstResponse[1].data];
      if (response.length !== 0 && crrId !== 0) {
        const couponObj = response[0];
        appliedCoupon.current = couponObj.code;
        setPaymentDetails({
          ...paymentDetails,
          isCouponApplied: true,
          couponAmount:
            couponObj.discountAmount === "0"
              ? couponObj.discountPercent[
                  couponObj.discountPercent.split("").length - 1
                ] === "%"
                ? Number(couponObj.discountPercent.split("%")[0])
                : Number(couponObj.discountPercent)
              : Number(couponObj.discountAmount),
          couponType: couponObj.discountAmount === "0" ? "percent" : "fixed",
          couponCode: couponObj.code,
          couponId: couponObj.id,
          coupon: couponObj,
        });
        setShowCoupon(true);
        setCouponError({
          ...couponError,
          isError: false,
        });
      } else {
        if (crrId === 0) {
          setCouponError({
            message: "Coupon cannot be applied to recurring payment.",
            isError: true,
          });
        } else {
          setCouponError({
            message: "Applied Coupon NOT Valid",
            isError: true,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  // paymentDetails.isCouponApplied
  return paymentDetails.isCouponApplied && showCoupon ? (
    <div
      onClick={(e) => setShowCoupon(false)}
      className={styles["coupon_applied_container"]}
    >
      <ReactConfetti
        height={window.innerHeight}
        width={window.innerWidth}
        confettiSource={{
          x: 0.5 * window.innerWidth,
          y: 0.5 * window.innerHeight,
          w: 0,
          h: 30,
        }}
        recycle={false}
      />
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles["coupon_wrapper"]}
      >
        <img
          src="./assets/parentLanding/discount2.png"
          alt="giftbox"
          className={styles["giftbox_image"]}
        ></img>
        <p className={styles["coupon_name"]}>
          "{paymentDetails.couponCode}" APPLIED
        </p>
        <p className={styles["coupon_amount"]}>
          {paymentDetails.couponType === "fixed"
            ? `₹${paymentDetails.couponAmount}/-`
            : `${paymentDetails.couponAmount}%`}
        </p>
        <p className={styles["coupon_heading"]}>Savings with this coupon</p>
        <div className={styles["bar"]}></div>
        <p className={styles["coupon_content"]}>
          YAY! Your coupon is successfully applied
        </p>
        <hr />
        <button
          onClick={() => setShowCoupon(false)}
          className={styles["button"]}
        >
          WooHoo!
        </button>
      </div>
    </div>
  ) : (
    <div className={styles.couponContainer}>
      <p className={styles["coupon_label"]}>Enter Coupon Code</p>
      <div>
        <input
          title="coupon code"
          type="text"
          placeholder=""
          className={styles["coupon_input"]}
          onChange={(e) =>
            setPaymentDetails({
              ...paymentDetails,
              couponCode: e.target.value,
            })
          }
        />
        <button
          className={styles["coupon_button"]}
          onClick={() => {
            if (paymentDetails.couponCode)
              couponHandler(paymentDetails.couponCode);
          }}
        >
          Apply Coupon
        </button>
        {couponError.isError ? (
          <p style={{ color: "red", fontWeight: "600" }}>
            {couponError.message}
          </p>
        ) : null}
        {paymentDetails.isCouponApplied && (
          <p style={{ color: "green", fontWeight: "600" }}>
            {appliedCoupon.current} applied successfully !
          </p>
        )}
      </div>
    </div>
  );
};

export default CouponLogic;
