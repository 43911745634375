export const BACKGROUND_IMG = "/assets/excercise/background.svg";
export const BACKGROUND_DESIGN =
  "/assets/excercise/complete background design.svg";
export const BLUE_BACK_BUTTON = "/assets/excercise/blue back button.svg";
export const GREEN_BACK_BUTTON = "/assets/excercise/green back button.svg";
export const ORANGE_BACK_BUTTON = "/assets/excercise/orange back button.svg";
export const PINK_BACK_BUTTON = "/assets/excercise/pink back button.svg";
export const PURPLE_BACK_BUTTON = "/assets/excercise/purple back button.svg";

export const COMPLETED_BUTTON =
  "/assets/excercise/completed activity button.svg";
export const CURRENT_BUTTON = "/assets/excercise/current activity button.svg";
export const REMAINING_BUTTON =
  "/assets/excercise/remaining activity button.svg";

export const BLUE_START_BUTTON =
  "/assets/excercise/blue start activity button.svg";
export const GREEN_START_BUTTON = "/assets/excercise/green play button.svg";
export const PINK_START_BUTTON = "/assets/excercise/pink play button.svg";
export const ORANGE_START_BUTTON = "/assets/excercise/orange play button.svg";
export const PURPLE_START_BUTTON = "/assets/excercise/purple play button.svg";

export const BIT_CARDS = "/assets/excercise/bit cards.svg";
