import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRouterLink,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import axios from "axios";
import React, { useState } from "react";
import classes from "./SignUp.module.css";
import { decodeToken, isExpired } from "react-jwt";
import { useUserContext } from "../../store/context/user-context";
import { useHistory } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useSubscriptionContext } from "../../store/context/subscription-context";
import {
  MAIN_REST_API_SERVER,
  MAIN_REST_PORT,
  ROOT_ENDPOINT,
} from "../../configs/ServerConfig";
import loginPage from "../../assets/img/login-page.png";

export const SignUp = () => {
  const thisYear = new Date().getFullYear();
  const queryParams = new URLSearchParams(window.location.search);
  const acLead: any = queryParams.get("s");

  const [signupData, setSignupData] = useState<any>({
    cohort: "DEFAULT",
    ageGroup: "",
    email: "",
    password: "",
    phone: "",
    name: "",
    childName: "",
    childBirthDate: "",
    acId: acLead || null,
  });

  const [showSignupError, setshowSignupError] = useState({
    showError: false,
    errorMessage: "",
  });

  const [googleCaptcha, setGoogleCaptcha] = useState({
    showCaptcha: false,
    captchaValue: null,
  });

  const userCtx = useUserContext();
  const subscriptionCtx = useSubscriptionContext();

  const history = useHistory();

  const googleCaptchaHandler = (value: any) => {
    setGoogleCaptcha({ ...googleCaptcha, captchaValue: value });
  };

  const signupHandler = (e: any) => {
    e.preventDefault();
    setSignupData({
      cohort: "DEFAULT",
      ageGroup: "",
      email: "",
      password: "",
      phone: "",
      name: "",
      childName: "",
      childBirthDate: "",
    });

    const createNewUser = async () => {
      try {
        const response = await axios.post(
          `${MAIN_REST_API_SERVER}:${MAIN_REST_PORT}/signup`,
          signupData
        );
        const myJwtToken = response.data.jwt;

        const decodedToken: any = decodeToken(myJwtToken);
        const isTokenExpired = isExpired(myJwtToken);

        if (!isTokenExpired && googleCaptcha.captchaValue !== null) {
          localStorage.setItem("USER_TOKEN", myJwtToken);
          localStorage.setItem("USER_DATA", JSON.stringify(decodedToken.sub));

          userCtx.loginUser(decodedToken.sub);
          subscriptionCtx.loadSubscriptionContext(
            decodedToken.sub.id,
            myJwtToken
          );
          history.push("/signup");
        } else {
          setshowSignupError({
            showError: true,
            errorMessage: "Complete the Captcha to Create a new account",
          });
        }
      } catch (err: any) {
        console.log(err);
        console.log(typeof err.response.status, err.response.status);

        if (err.response.status === 406) {
          setshowSignupError({
            showError: true,
            errorMessage: "Account already Exists",
          });
        }
      }
    };

    if (signupData.phone === "") {
      setshowSignupError({
        showError: true,
        errorMessage: "Enter Phone Number",
      });
    } else {
      createNewUser();
    }
  };
  return (
    <IonContent className="wow fadeInUp" data-wow-duration="1000ms">
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">Signup</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div
        className="row ion-padding"
        style={{ textAlign: "center", alignItems: "center" }}
      >
        <div className="col-lg-6 col-md-12 col-sm-6">
          <img
            src={loginPage}
            alt="children-playing"
            style={{ width: "75%" }}
          />
        </div>
        <div
          className="col-lg-6 col-md-12 col-sm-6"
          style={{ textAlign: "center" }}
        >
          <IonRow style={{ textAlign: "center" }}>
            <IonCol>
              <img
                src="/assets/logo/brainCells-logo-small.png"
                className={classes.braincellsLogo}
                alt="logo"
              />
              <p className={classes.logoText + " login-box-msg"}>BrainCells</p>
            </IonCol>
          </IonRow>
          <form
            onSubmit={(e) => signupHandler(e)}
            style={{ fontFamily: "Poppins" }}
          >
            <input type="hidden" name="is_register" value="1" />
            <input type="hidden" name="referralId" />
            <input type="hidden" name="partnerId" />
            <div className="input-group mb-3">
              <input
                onChange={(e) => {
                  setSignupData({ ...signupData, name: e.target.value });
                  if (e.target.value.length === 0) {
                    setGoogleCaptcha({
                      ...googleCaptcha,
                      showCaptcha: false,
                    });
                  } else {
                    setGoogleCaptcha({
                      ...googleCaptcha,
                      showCaptcha: true,
                    });
                  }
                }}
                value={signupData.name}
                minLength={2}
                type="text"
                name="name"
                className="form-control"
                placeholder="Parent's Full Name"
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                onChange={(e) =>
                  setSignupData({ ...signupData, email: e.target.value })
                }
                value={signupData.email}
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <IntlTelInput
                preferredCountries={["in", "sg", "de", "us", "co"]}
                inputClassName="form-control"
                onPhoneNumberChange={(b, n, c, number) => {
                  setSignupData({ ...signupData, phone: number });
                }}
                style={{ width: "100%" }}
                placeholder="Mobile (provide WhatsApp number for better help & support)"
              />
            </div>

            <div className="input-group mb-3">
              <input
                onChange={(e) =>
                  setSignupData({ ...signupData, childName: e.target.value })
                }
                value={signupData.childName}
                minLength={2}
                type="text"
                name="childName"
                className="form-control"
                placeholder="Child's First Name only"
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user"></span>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <select
                title="age group"
                onChange={(e) =>
                  setSignupData({ ...signupData, ageGroup: e.target.value })
                }
                value={signupData.ageGroup}
                id="ageGroupSelect"
                style={{ color: "#6c757d" }}
                name="ageGroup"
                className="form-control"
                required
              >
                <option disabled selected hidden value="">
                  Select Childs Age Group{" "}
                </option>

                <option>0-1</option>
                <option>1-2</option>
                <option>2-4</option>
                <option>4-Plus</option>
              </select>
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-chevron-down"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                onChange={(e) =>
                  setSignupData({ ...signupData, password: e.target.value })
                }
                value={signupData.password}
                minLength={6}
                name="password"
                type="password"
                className="form-control"
                placeholder="Create New Password (Minimum 6 Characters)"
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>

            <div className=" input-group mb-3">
              {googleCaptcha.showCaptcha ? (
                <div id="reCaptchaEl" className="g-recaptcha">
                  <ReCAPTCHA
                    sitekey="6Le9UGgcAAAAAKyu7_FSKRYsC3ymdVpKca4hwdIu"
                    onChange={googleCaptchaHandler}
                  />
                </div>
              ) : null}
            </div>

            <div>
              {showSignupError.showError ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    border: "1px solid red",
                    fontWeight: "bolder",
                    margin: "1rem",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {showSignupError.errorMessage}
                </p>
              ) : null}
            </div>

            <div className="row">
              <div className="col-12">
                {/* <button
                  type="submit"
                  style={{
                    fontFamily: "sensei",
                    fontSize: "1.8rem",
                    borderRadius: "1rem",
                    background: "#1360a0",
                  }}
                  className="btn btn-primary btn-block"
                >
                  Signup
                </button> */}
                <IonButton type="submit" expand="block">
                  Signup
                </IonButton>
              </div>
            </div>

            <p style={{ fontSize: "medium", marginTop: "1rem" }}>
              <a href="/signin">Have an account? Login!</a>
            </p>
          </form>
        </div>

        <div className="row" style={{ color: "#747474", marginTop: "2rem" }}>
          <strong>
            Version v4.0.2 | Copyright &copy; {thisYear} BrainCells | Thought
            River EduTech Private Limited |{" "}
            <a target="_blank" href="/parents/TNC">
              Privacy Policy
            </a>
          </strong>
        </div>
      </div>
    </IonContent>
  );
};
