import axios from "axios";
import addAxiosInterceptor from "../configs/AxiosConfig";
import { GENIE_PORT, MAIN_REST_API_SERVER } from "../configs/ServerConfig";
import { detectIsIOSDevice } from "./general-utils";

addAxiosInterceptor();
export async function getHttpData(url: string, params: any = null) {
  try {
    const response = await axios.get(url, params);
    return response;
  } catch (error) {
    throw error;
  }
}

export const postHttpData = async (url: string, data: any = {}) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const putHttpData = async (url: string, data: any) => {
  try {
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTTSAudio = async (text: string, lang: string = "ENGLISH") => {
  let audioData = await getHttpData(
    `${MAIN_REST_API_SERVER}:${GENIE_PORT}/tts?lang=${lang}&text=${text}`
  );
  let prefix = "data:audio/wav;base64,";
  let audio = new Audio(prefix + audioData.data);
  return audio;
};
