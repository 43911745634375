import React, { useState } from "react";
import styles from "./FreeProgramFooter.module.css";
import { FacebookShareButton } from "react-share";
import { useUserContext } from "../../../../../../store/context/user-context";
import ShareModal from "../ShareModal/ShareModal";
import { imagesLink } from "../../../../../../constants/Services/services-constants";

const FreeProgramFooter = ({
  downloadData,
  downloadId,
  isLiked,
  setIsLiked,
}: any) => {
  const userCtx = useUserContext();
  const user = userCtx.user;

  const [showShareOptions, setShowShareOptions] = useState(false);
  const inAws: boolean = downloadData?.displayImageMain?.split("//").length > 1;

  return (
    <div className={styles["community_footer"]}>
      {showShareOptions && (
        <ShareModal
          link={`program.braincells.in/free-programs/free-download-program/${downloadId}`}
          title={downloadData.title}
          setShowShareOptions={setShowShareOptions}
        />
      )}
      <div className={styles["community_footer__my_canvas_button_container"]}>
        <button
          onClick={() => setShowShareOptions(true)}
          className={styles["community_footer__my_canvas_button"]}
        >
          <i className="fas fa-share"></i> Share
        </button>
      </div>
      {/* <a
        href="#"
        className={styles["community_footer__start_activities_button"]}
      >
        <i style={{ color: "white" }} className="fas fa-download"></i>
        <p>Download</p>

        <p>BrainCells</p>
        <p>Activities</p>
      </a> */}

      {userCtx.isUserLoggedIn ? (
        <a
          className={styles["community_footer__start_activities_button"]}
          href={`${inAws ? "" : imagesLink}${downloadData.pdfPath}`}
          download={downloadData.download}
        >
          <i className="fas fa-download"></i> Download
        </a>
      ) : (
        <a
          className={styles["community_footer__start_activities_button"]}
          href="https://braincells.in/free-downloadable-worksheets"
        >
          <i className="fas fa-sign-in-alt"></i>
          <p> Signup</p>
          {/* <p>Free Worksheets</p> */}
        </a>
      )}
      <div className={styles["community_footer__my_canvas_button_container"]}>
        <button
          onClick={() => setIsLiked(true)}
          className={styles["community_footer__my_canvas_button"]}
        >
          {isLiked ? (
            <p
              className={`${styles["like_count__liked"]} animate__animated animate__bounceIn`}
            >
              {Number(downloadData.numberOfLikes) + 1}
            </p>
          ) : (
            <p className={styles["like_count"]}>{downloadData.numberOfLikes}</p>
          )}
          <i
            className={`fas fa-heart ${
              isLiked ? styles["like_count__liked"] : styles["like_icon"]
            }`}
          ></i>{" "}
        </button>
      </div>
    </div>
  );
};

export default FreeProgramFooter;
