import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonRouterLink, IonInput } from '@ionic/react';
import { useState } from 'react';
export const ProfileCreation = () => {
  const[name,setName] = useState<string>()
  function changeName(val:any){
    setName(val.target.value)
  }
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Create Child's Profile</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>
      <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">BrainCells</IonTitle>
          
        </IonToolbar>
      </IonHeader>
      <IonRouterLink href="/profile-creation/set-name" color='danger'> Proceed </IonRouterLink> <br/><br/>
    </IonContent>
  </IonPage>
  )
}